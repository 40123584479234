// Dependencies
import { Box, Drawer } from "@mui/material";
import AutofillField from "components/autofill-field/autofill-field.component";

import { styled } from "@mui/material/styles";

const drawerWidth = "240px";

const SearchBoxFixed = styled(AutofillField)({
  width: "100%",
});

const LeftPaneContainer = styled(Box)({
  display: "flex",
});

const DrawerBody = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiPaper-root": { width: drawerWidth },
});

export default {
  SearchBoxFixed,
  LeftPaneContainer,
  DrawerBody,
};
