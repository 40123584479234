/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  List,
  ListItem as ListItemBase,
  ListItemProps,
  ListItemIcon as ListItemIconBase,
  ListItemText as ListItemTextBase,
  ListItemTextProps,
  ListItemSecondaryAction as ListItemSecondaryActionBase,
  Divider,
  Collapse as CollapseBase,
  Container,
} from "@mui/material";
import {
  ArrowRight as ArrowRightBase,
  ArrowDropDown as ArrowDropDownBase,
} from "@mui/icons-material";
import FormatedValue from "components/formatted-value/formatted-value";

import { styled } from "@mui/material/styles";

interface CustomListItemIconProps extends ListItemProps {
  level?: number;
  has_items: boolean;
}

interface CustomListItemProps extends ListItemProps {
  level?: number;
  has_items: boolean;
}

interface CustomListItemTextProps extends ListItemTextProps {
  isTitle?: boolean;
}

const ListItem = styled(ListItemBase, {
  shouldForwardProp: (prop) => prop !== "level" && prop !== "has_items",
})<CustomListItemProps>(({ level = 0, has_items }) => ({
  borderBottom: "0.03rem solid rgba(0, 0, 0, 0.12)",
  paddingTop: `0.28rem`,
  paddingBottom: "0.28rem",
  paddingLeft: has_items && level <= 1 ? 0 : `${level * 2.28}em`,
}));

ListItem.defaultProps = {
  level: 0,
};

const ListItemIcon = styled(ListItemIconBase, {
  shouldForwardProp: (prop) => prop !== "level" && prop !== "has_items",
})<CustomListItemIconProps>(({ level = 0, has_items }) => ({
  cursor: "pointer",
  minWidth: "2.2rem",
  visibility: has_items && level > 0 ? "visible" : "hidden",
  display: has_items && level > 0 ? "block" : "none",
}));

const ListItemText = styled(ListItemTextBase, {
  shouldForwardProp: (prop) => prop !== "isTitle",
})<CustomListItemTextProps>(({ isTitle }) => ({
  margin: 0,
  "& .MuiListItemText-primary": {
    fontWeight: isTitle ? "bold" : "normal",
    fontSize: "1rem",
    lineHeight: "1.71rem",
  },
}));

const ListItemFloatingText = styled(ListItemTextBase)({
  flex: "none",
  position: "absolute",
  right: "-19.68rem",
  top: 1,
  marginTop: 0,
  "& .MuiListItemText-primary": {
    fontWeight: "bold",
  },
});

const ListItemSecondaryAction = styled(ListItemSecondaryActionBase)({
  right: 0,
});

const Collapse = styled(CollapseBase)(() => ({
  wrapperInner: {
    "& .MuiList-root": {
      paddingBottom: "0px",
    },
  },
}));

const ArrowRight = styled(ArrowRightBase)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const ArrowDropDown = styled(ArrowDropDownBase)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export default {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemFloatingText,
  ListItemSecondaryAction,
  Divider,
  Collapse,
  ArrowRight,
  ArrowDropDown,
  Container,
  FormatedValue,
};
