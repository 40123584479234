import { FilterCondition } from "components/table-filter/table-filter.component";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getConditionsFromDefaultFilters = <T extends Record<string, any>>(
  defaultFilters: T,
  filterAccessor: Record<keyof T, string | string[]>
): (
  | { [x: string]: { _ilike: string } }
  | { _or: { [x: string]: { _ilike: string } }[] }
)[] => {
  return Object.entries(defaultFilters)
    .filter(
      ([filterName, filterValue]) =>
        filterValue !== "" && filterName in filterAccessor
    )
    .map(
      ([columnName, columnValue]):
        | { [x: string]: { _ilike: string } }
        | { _or: { [x: string]: { _ilike: string } }[] } => {
        const formatCondition = (accessorName: string) => ({
          [accessorName]: {
            _ilike: `${columnValue}%`,
          },
        });
        const accessor: string | string[] =
          filterAccessor[columnName as unknown as keyof T];
        if (Array.isArray(accessor)) {
          return {
            _or: accessor.map(formatCondition),
          };
        }
        return formatCondition(accessor);
      }
    );
};
export const getHasUnhandledDefaultFilter = <T extends Record<string, any>>(
  defaultFilters: T,
  filterAccessor: Record<keyof T, string | string[]>
): boolean => {
  return Object.entries(defaultFilters).some(
    ([filterName, filterValue]) =>
      filterValue && !(filterName in filterAccessor)
  );
};

export const mergeWithDefaultConditions = (
  defaultFilterConditions: FilterCondition[],
  filterConditions: FilterCondition[]
): { _and: FilterCondition[] | undefined } => {
  const conditions = defaultFilterConditions.concat(filterConditions);

  return {
    _and: conditions.length > 0 ? conditions : undefined,
  };
};
