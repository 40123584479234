/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Box, Slider as SliderBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const SliderWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(5),
}));

const Slider = styled(SliderBase)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiSlider-valueLabel": {
    height: "32px",
    width: "32px",
    transform: "rotate(-45deg) !important",
    borderRadius: "50% 50% 50% 0%",
    backgroundColor: "#00589c",
    left: "calc(13% - 1px)",
    top: "calc(-50% - 45px)",

    "span ": {
      transform: "rotate(45deg)",
      color: "#fff",
    },

    "&::before": {
      display: "none",
    },
  },
}));

export default {
  Slider,
  SliderWrapper,
};
