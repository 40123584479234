import React from "react";
import { CLIENT_NAME } from "graphql/client";
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import {
  Query_RootRtcs_Db_Ph_Aggregate_OwnerReferenceArgs,
  useAggregateOwnerReferenceQuery,
} from "graphql/hasura/types-and-hooks";
import { affiliatesTableColumnsKCCA } from "../affiliates-tab.schema";

import SC from "./kcca-affiliates-table.styles";

export interface AggregateAffiliatesProps {
  customerId?: string;
}

const KccaAffiliates: React.FC<AggregateAffiliatesProps> = ({ customerId }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Aggregate_OwnerReferenceArgs = {
    // TODO: After testing _eq works as _in
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-ignore */
    where: { CustomerId: { _eq: customerId?.split(",") } },
  };

  const { data, error } = useAggregateOwnerReferenceQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customerId,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  useAppErrorHandler(error);

  const AggregateOwnerReferenceData =
    data?.rtcs_db_Fnaggregate_OwnerReference || [];

  const valueMapping: { [key: string]: string } = {
    "kcca#CustomerReference": "Reference",
    "kcca#CustomerBusinessAssociate": "Business Associate",
    "kcca#CustomerContactPerson": "Contact Person",
    "kcca#CustomerGuardian": "Guardian",
  };

  const References = AggregateOwnerReferenceData.map((affiliate) => {
    return {
      ...affiliate,
      relationship: valueMapping[affiliate.SourcedFromTable ?? ""],
    };
  });

  return (
    <SC.Table
      title="Kampala City Council Authority (KCCA)"
      persistenceId="32770e1e-7f6a-423d-be3c-5298c9addade"
      actionsOnRight={[]}
      onAction={() => {}}
      columns={affiliatesTableColumnsKCCA}
      data={References ?? []}
    />
  );
};

export default KccaAffiliates;
