import React from "react";

import {
  usePropertyImagesLazyQuery,
  Query_RootRtcs_Db_Kcca_ImgsDataArgs,
} from "graphql/hasura/types-and-hooks";

import { useGetFileLazyQuery } from "graphql/types-and-hooks";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { CLIENT_NAME } from "graphql/client";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import SC from "./image-list.styles";

const ImageList: React.FC<{
  propertyNo: string;
  camvId?: string;
  load: boolean;
}> = ({ propertyNo }) => {
  const [imagesQueryVariables] = React.useState<
    Query_RootRtcs_Db_Kcca_ImgsDataArgs | undefined
  >({
    where: { PropertyNo: { _eq: propertyNo } },
  });

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchImagesFn,
    { data: imagesData, loading: imagesLoading, error: imagesError },
  ] = usePropertyImagesLazyQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      params: jsonToStringQueryVariables(imagesQueryVariables ?? {}),
    },
  });

  const [GetImagesFn, { loading, data }] = useGetFileLazyQuery({
    // TODO: If there are issues with downloading images, uncomment this line to disable cache.
    // fetchPolicy: "no-cache",
    variables: {
      filename: imagesData?.rtcs_db_Fnkcca_ImgsData[0]?.ImgPath || "",
      folder: imagesData?.rtcs_db_Fnkcca_ImgsData[0]?.FolderName || "",
    },
  });

  if (imagesError) {
    throw imagesError;
  }

  const images: string[] = React.useMemo(() => {
    if (imagesData && imagesData?.rtcs_db_Fnkcca_ImgsData.length > 0) {
      GetImagesFn({
        variables: {
          filename: imagesData?.rtcs_db_Fnkcca_ImgsData[0]?.ImgPath || "",
          folder: imagesData?.rtcs_db_Fnkcca_ImgsData[0]?.FolderName || "",
        },
      });
    }

    if (data && data?.GetFile.length > 0) {
      return data.GetFile.map(({ content }) => content);
    }
    return [];
  }, [GetImagesFn, data, imagesData]);

  return images && images.length < 1 ? (
    <>
      <ProgressIndicator open={loading || imagesLoading} />
      <SC.MainImage rowHeight={100} gap={1} cols={4}>
        <SC.GridListTile cols={4} rows={3}>
          <SC.ImagePlaceholder />
        </SC.GridListTile>
      </SC.MainImage>
      {/* <SC.ImageThumbnails cellHeight={100} spacing={1} cols={4}>
        <SC.GridListTile>
          <SC.Button
            disabled
            variant="contained"
            color="secondary"
            startIcon={<SC.PhotoLibraryIcon />}
          >
            View Photos
          </SC.Button>
        </SC.GridListTile>
      </SC.ImageThumbnails> */}
    </>
  ) : (
    <SC.Carousel showArrows>
      {images?.map((image, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SC.Box key={index}>
          <img alt="img" src={image} />
        </SC.Box>
      ))}
    </SC.Carousel>
  );
};

export default ImageList;
