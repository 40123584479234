// Dependencies
import { Box as BoxBase } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";

const Box = BoxBase;

export default {
  Box,
  RoomIcon,
};
