// Dependencies
import React from "react";

// Components
import TextField from "components/text-field/text-field.component";
import LandingPage from "components/landing-page/landing-page.component";
import { AlertProps } from "components/alert/alert.component";
import PasswordField from "components/password-field/password-field.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Assets
import SC from "components/landing-page/landing-page.styles";

// Graphql
import { useLogInLazyQuery } from "graphql/types-and-hooks";
import auth from "graphql/authentication";

export type UserCredentials = {
  email: string;
  password: string;
};

const LoginPage: React.FC = () => {
  const [alertMessage, setAlertMessage] = React.useState<
    AlertProps | undefined
  >();
  const [userCredentials, setUserCredentials] = React.useState<UserCredentials>(
    { email: "", password: "" },
  );

  const handleUserCredentials = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setUserCredentials((oldUserCredentials) => ({
      ...oldUserCredentials,
      [name]: value,
    }));
  };

  const closeAlertMessage = () => {
    setAlertMessage(undefined);
  };

  const [loginQuery, { loading, error, data }] = useLogInLazyQuery({
    fetchPolicy: "no-cache",
    context: {
      noAuthNeeded: true,
    },
  });

  React.useEffect(() => {
    if (error) {
      setAlertMessage({ message: error.message, severity: "error" });
    }

    if (data) {
      auth.logIn(data.LogIn);
    }
  }, [data, error]);

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      setAlertMessage(undefined);

      loginQuery({
        variables: {
          email: userCredentials.email,
          password: userCredentials.password,
        },
      });
    },
    [loginQuery, userCredentials.email, userCredentials.password],
  );

  const PageContent = (
    <SC.Box component="form" onSubmit={handlerOnSubmit}>
      <ProgressIndicator open={loading} />
      <SC.TexFieldBox>
        <TextField
          name="email"
          label="Email"
          value={userCredentials.email}
          required
          fullWidth
          onChange={handleUserCredentials}
        />
      </SC.TexFieldBox>
      <SC.HelpContainerBox>
        <SC.HelpBoxLeft>
          <SC.HelpText>Enter your Email Address</SC.HelpText>
        </SC.HelpBoxLeft>
      </SC.HelpContainerBox>
      <SC.TexFieldBox>
        <PasswordField
          name="password"
          label="Password"
          placeholder="Password"
          value={userCredentials.password}
          required
          isStrengthBarHidden
          onChange={handleUserCredentials}
        />
      </SC.TexFieldBox>
      <SC.HelpContainerBox>
        <SC.HelpBoxLeft>
          <SC.HelpText>Enter your Password</SC.HelpText>
        </SC.HelpBoxLeft>
        <SC.HelpBoxRight>
          <SC.Link to="/forgot-password">Forgot Password?</SC.Link>
        </SC.HelpBoxRight>
      </SC.HelpContainerBox>
      <SC.ButtonContainer>
        <SC.Button color="primary" variant="contained" type="submit">
          Login
        </SC.Button>
      </SC.ButtonContainer>
      {alertMessage && (
        <SC.Alert
          elevation={0}
          icon={false}
          severity={alertMessage.severity}
          message={alertMessage.message}
          onClose={closeAlertMessage}
        />
      )}
      {/* <SC.RememberBox>
        <SC.Checkbox name="remember" size="small" />
        Remember me
      </SC.RememberBox> */}
    </SC.Box>
  );

  return <LandingPage>{PageContent}</LandingPage>;
};

export default LoginPage;
