import React from "react";
import { createDate, getMonth, getYear } from "utils/internationalization";

import { MONTHS } from "./calendar";

import SC from "./table-range-actions.styles";
import { SelectChangeEvent } from "@mui/material";

const getMonthIndex = (inputMonth: string) => {
  const monthfilter = MONTHS.find((month) => month.value === inputMonth);
  return monthfilter ? monthfilter.index : 0;
};
interface TableRangeActionsProps {
  dateRangeParams?: DateRangeParams;
  rangeOfYears: number[];
  onFilter: (params: DateRangeParams) => void;
}

export interface DateRangeParams {
  startDate: string;
  endDate: string;
}

const TableRangeActions: React.FC<TableRangeActionsProps> = ({
  dateRangeParams, // : initialDateRangeParams,
  rangeOfYears,
  onFilter,
}) => {
  const yearRange = React.useMemo(
    () =>
      rangeOfYears.map((year, index) => ({
        label: year.toString(),
        index,
        value: year.toString(),
      })),
    [rangeOfYears],
  );

  const months = React.useMemo(
    () => (yearRange.length === 0 ? [] : MONTHS),
    [yearRange],
  );

  const disableSelect = React.useMemo(
    () => yearRange.length === 0,
    [yearRange],
  );

  const onSelectChange = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    const { name, value } = event.target as { name: string; value: unknown };

    if (!name || !dateRangeParams) return;

    const newDateRangeParams = { ...dateRangeParams };

    if (name.startsWith("startDate")) {
      if (name.endsWith("Month")) {
        newDateRangeParams.startDate = createDate(
          getYear(dateRangeParams.startDate),
          getMonthIndex(value as string),
          1,
        );
      } else {
        newDateRangeParams.startDate = createDate(
          value as string,
          getMonthIndex(getMonth(dateRangeParams.startDate)),
          1,
        );
      }
    } else if (name.endsWith("Month")) {
      newDateRangeParams.endDate = createDate(
        getYear(dateRangeParams.endDate),
        getMonthIndex(value as string),
        1,
      );
    } else {
      newDateRangeParams.endDate = createDate(
        value as string,
        getMonthIndex(getMonth(dateRangeParams.endDate)),
        1,
      );
    }

    onFilter(newDateRangeParams);
  };

  return (
    <SC.Grid container justifyContent="flex-start" alignItems="baseline">
      <SC.Grid alignContent="flex-end" justifyContent="flex-end" item xs={1}>
        <SC.Typography variant="h6">Date&nbsp;Range </SC.Typography>
      </SC.Grid>

      <SC.Grid container justifyContent="flex-start" alignItems="center" xs={6}>
        <SC.Grid item xs={1} alignContent="stretch">
          <SC.Typography variant="body1">From: </SC.Typography>
        </SC.Grid>

        <SC.Grid item xs={2}>
          <SC.SelectField
            label="startDateMonth"
            data={months}
            disabled={disableSelect}
            value={getMonth(dateRangeParams?.startDate ?? "")}
            onChange={onSelectChange}
          />
        </SC.Grid>

        <SC.Grid item xs={2}>
          <SC.SelectField
            label="startDateYear"
            data={yearRange}
            disabled={disableSelect}
            value={getYear(dateRangeParams?.startDate ?? "")}
            onChange={onSelectChange}
          />
        </SC.Grid>

        <SC.Grid item xs={1}>
          <SC.Typography variant="body1">To: </SC.Typography>
        </SC.Grid>

        <SC.Grid item xs={2}>
          <SC.SelectField
            label="endDateMonth"
            data={months}
            disabled={disableSelect}
            value={getMonth(dateRangeParams?.endDate ?? "")}
            onChange={onSelectChange}
          />
        </SC.Grid>

        <SC.Grid item xs={2}>
          <SC.SelectField
            label="endDateYear"
            data={yearRange}
            disabled={disableSelect}
            value={getYear(dateRangeParams?.endDate ?? "")}
            onChange={onSelectChange}
          />
        </SC.Grid>
      </SC.Grid>
    </SC.Grid>
  );
};

export default TableRangeActions;
