/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Box as ContainerBase, Snackbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: theme.table.toolbar.fontSize,
  textAlign: "left",
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(3),
  opacity: 0.8,
}));

const Container = styled(ContainerBase)(() => ({
  margin: "0px",
  padding: "0px",
}));

const Box = ContainerBase;

export default {
  Box,
  Snackbar,
  Title,
  Container,
};
