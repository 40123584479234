import {
  List as ListBase,
  Box,
  Typography as TypographyBase,
} from "@mui/material";
import NestListItem from "../nested-list-item/nested-list-item.component";

import { styled } from "@mui/material/styles";

const Typography = styled(TypographyBase)({
  fontWeight: "bold",
});

const List = styled(ListBase)({
  margin: "0px",
  paddingRight: "0px",
  paddingBottom: "0px",
  paddingTop: `2.28rem`,
  "& li ": {
    width: "78%",
  },
});

export default {
  List,
  NestListItem,
  Typography,
  Box,
};
