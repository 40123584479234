// Dependencies
import { Box as BoxBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const Box = styled(BoxBase)(() => ({
  overflowY: "scroll",
  height: "70%",
}));

export default {
  Box,
  BoxBase,
};
