// Dependencies
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Components
import TextField, {
  TextFieldProps,
} from "components/text-field/text-field.component";

// Assets
import SC from "./password-field.styles";

const PasswordStrengthBar = React.lazy(
  () => import("react-password-strength-bar"),
);

export type PasswordFieldProps = {
  value: string;
  /** Is used to specify that you want to hide StrengthBar */
  isStrengthBarHidden?: boolean;
} & TextFieldProps;

const PasswordField: React.FC<PasswordFieldProps & TextFieldProps> = ({
  value,
  isStrengthBarHidden,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <SC.Box>
      <TextField
        fullWidth
        type={showPassword ? "text" : "password"}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {!isStrengthBarHidden && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <PasswordStrengthBar password={value} />
        </React.Suspense>
      )}
    </SC.Box>
  );
};

export default PasswordField;
