/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  IconButton as IconButtonBase,
  Grid as GridBase,
  Typography,
  ListSubheader,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const LabelButtonText = styled(Typography)(({ theme }) => ({
  fontSize: theme.expandAllButton.fontSize,
  fontWeight: theme.expandAllButton.fontWeight,
  textAlign: "center",
}));

const Grid = styled(GridBase)(({ theme }) => ({
  backgroundColor: theme.accordionBody.backgroundColor,
  zIndex: 1,
  maxWidth: "inherit",
}));

const IconButton = styled(IconButtonBase)(({ theme }) => ({
  marginRight: theme.spacing(2),
  padding: "3px",
  "&:hover": {
    backgroundColor: theme.accordionBody.backgroundColor,
  },
}));

export default {
  IconButton,
  Grid,
  LabelButtonText,
  ListSubheader,
};
