import React from "react";
import { makeVar, useReactiveVar } from "@apollo/client";
import { useErrorBoundary } from "react-error-boundary";

// Errors
import {
  ServerErrorType,
  ServerErrorCode,
  ServerErrorCodes,
  isServerErrorCode,
  isServerError,
  extractServerError,
  NetworkError,
} from "errors/server.errors";
import { GraphQLFormattedError } from "graphql";
import {
  UIError,
  UIErrorType,
  UIErrorCode,
  UIErrorCodes,
  isUIErrorCode,
  isUIError,
} from "./ui.errors";
import { isNetworkErrorCode } from "./network.errors";

type AppErrorCode = ServerErrorCode & UIErrorCode;

interface AppErrorData {
  code: AppErrorCode;
  message: string;
}

const appErrorDataVar = makeVar<AppErrorData | undefined>(undefined);

const isAppErrorCode = (
  maybeAppErrorCode: unknown,
): maybeAppErrorCode is AppErrorCode => {
  return (
    isUIErrorCode(maybeAppErrorCode) || isServerErrorCode(maybeAppErrorCode)
  );
};

const isAppError = (error: Error): boolean => {
  return isServerError(error) || isUIError(error);
};

const networkErrorToAppErrorData = (networkError: NetworkError): AppErrorData =>
  ({
    code: networkError.code,
    message: networkError.message,
  }) as AppErrorData;

const serverErrorToAppErrorData = (
  serverError: readonly GraphQLFormattedError[],
): AppErrorData => {
  const appErrorData = serverError.reduce(
    (appError, gqlError, index) => {
      const hasSeparator = index > 0;
      const separator = hasSeparator
        ? { code: ",", msg: "\n" }
        : { code: "", msg: "" };

      return {
        code: `${appError.code}${separator.code}${gqlError.extensions?.code}`,
        message: `${appError.message}${separator.msg}${gqlError.message}`,
      } as AppErrorData;
    },
    { code: "", message: "" } as AppErrorData,
  );

  return appErrorData;
};

const extractAppErrorData = (error: Error): AppErrorData | undefined => {
  if (isUIError(error)) {
    const { code, message } = error;
    return { code, message } as AppErrorData;
  }

  const serverError = extractServerError(error);

  if (serverError instanceof NetworkError) {
    return networkErrorToAppErrorData(serverError);
  }

  // Important: serverError instanceof GraphQLError does not work
  if (serverError) {
    return serverErrorToAppErrorData(serverError);
  }

  return undefined;
};

function useAppError(): AppErrorData | undefined {
  const appErrorData = useReactiveVar(appErrorDataVar);

  return appErrorData;
}

function useAppErrorHandler(
  givenError?: Error,
): (gError?: Error | undefined) => void {
  const errorHandler = React.useCallback((gError?: Error): void => {
    let extractedAppErrorData;

    if (gError) {
      extractedAppErrorData = extractAppErrorData(gError);

      if (
        !extractedAppErrorData ||
        isNetworkErrorCode(extractedAppErrorData.code)
      )
        throw gError;
    }

    appErrorDataVar(extractedAppErrorData);
  }, []);

  React.useEffect(() => {
    if (givenError) {
      errorHandler(givenError);
    }
  }, [errorHandler, givenError]);

  return errorHandler;
}

export {
  useAppError,
  useAppErrorHandler,
  appErrorDataVar,
  isAppErrorCode,
  isAppError,
  serverErrorToAppErrorData,
  networkErrorToAppErrorData,
  extractAppErrorData,
  ServerErrorCodes,
  isServerErrorCode,
  isServerError,
  extractServerError,
  UIError,
  UIErrorCodes,
  isUIErrorCode,
  isUIError,
};
export type {
  AppErrorData,
  AppErrorCode,
  ServerErrorType,
  ServerErrorCode,
  UIErrorType,
  UIErrorCode,
};
