import { Grid as GridBase, Typography as TypographyBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const Grid = styled(GridBase)(() => ({}));
const Label = styled(TypographyBase)(() => ({
  fontWeight: "bold",
}));
const Value = styled(TypographyBase)(() => ({
  textAlign: "right",
}));

export default {
  Grid,
  Label,
  Value,
};
