// TODO: Remove this after changes are made to Non Individual Composite
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import { UraNonIndividualQuery_UraNonIndividual_ElementKey } from "graphql/hasura/rtcs.types";
import {
  Query_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs,
  useUraNonIndividualQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import {
  AccordionHeader,
  Header,
  Content,
} from "components/accordion-header/accordion-header.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

export interface UraNonIndividual {
  label: string;
  accessorKey?: UraNonIndividualQuery_UraNonIndividual_ElementKey;
}

// TODO: Uncoment if this is required
export const identifiers: UraNonIndividual[] = [
  { label: "Trading Name", accessorKey: "TradingName" },
  { label: "Business Name", accessorKey: "BsnsName" },
  { label: "Business Certification No.", accessorKey: "BsnsCertNumber" },
  { label: "TIN", accessorKey: "TinNo" },
  { label: "RNID", accessorKey: "Rnid" },
];
export const businessAddress: UraNonIndividual[] = [
  { label: "Plot No.", accessorKey: "BsnsaddrPlotNo" },
  { label: "Street Address", accessorKey: "BsnsaddrStreetAddr" },
  { label: "Building Name", accessorKey: "BsnsaddrBuildname" },
  { label: "District Name", accessorKey: "BsnsaddrDistrictName" },
  { label: "Town", accessorKey: "BsnsaddrTownName" },
  { label: "Sub County", accessorKey: "BsnsaddrSubCountyName" },
  { label: "Parish", accessorKey: "BsnsaddrParishName" },
  { label: "Village", accessorKey: "BsnsaddrVillageName" },
];

// TODO: Uncomment if this is required
export const contactInfo: UraNonIndividual[] = [
  { label: "Email Address", accessorKey: "EmailId" },
  { label: "Landline Phone No.", accessorKey: "LandlineNumber" },
  { label: "Mobile No.", accessorKey: "MobileNumber" },
  { label: "" }, // create separator
  { label: "Income Source Rental?", accessorKey: "IsIncomeSrcRental" },
  { label: "Income Source Business?", accessorKey: "IsIncomeSrcBsns" },
  { label: "Income Source Property?", accessorKey: "IsIncomeSrcPrpty" },
];

export interface NonIndividualTabHeaderUraProps {
  rnid: string;
  tinNos: string;
}

export const NonIndividualTabHeaderUra: React.FC<
  NonIndividualTabHeaderUraProps
> = ({ rnid, tinNos }) => {
  const tinNo = tinNos.split(",")?.[0];

  const queryVariablesaNonIndividuals: Query_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs =
    {
      where: { TinNo: { _eq: tinNos } },
      limit: 1,
    };

  const { data, error, loading } = useUraNonIndividualQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      params: jsonToStringQueryVariables(queryVariablesaNonIndividuals),
    },
  });

  useAppErrorHandler(error);

  const nonIndividual = data?.rtcs_db_Fnura_RegNonIndividual?.[0];

  if (!nonIndividual) {
    return null;
  }

  const header: Header[] = [
    { index: 0, label: `${nonIndividual.TaxPayerName ?? ""}`, type: "bold" },
    { index: 1, label: "RNID", type: "bold" },
    { index: 2, label: rnid },
    { index: 3, label: "TIN", type: "bold" },
    { index: 4, label: `${tinNo}` },
  ];

  const mapFunction = (info: UraNonIndividual, index: number) => ({
    ...info,
    index,
    value: info.accessorKey ? nonIndividual[info.accessorKey] : "",
  });
  const content: Content[] = [
    {
      index: 0,
      title: "Names and Identifiers",
      data: identifiers.map(mapFunction),
    },
    {
      index: 1,
      title: "Business Address",
      data: businessAddress.map(mapFunction),
    },
    {
      index: 2,
      title: "Contact Info and Related",
      data: contactInfo.map(mapFunction),
    },
  ];

  const isLoading = loading;

  return (
    <AccordionHeader
      header={header}
      content={content}
      source="URA"
      isLoading={isLoading}
    />
  );
};

export default NonIndividualTabHeaderUra;
