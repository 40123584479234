/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Box as BoxBase, Typography, Button, Divider } from "@mui/material";

import { styled } from "@mui/material/styles";

const Container = styled(BoxBase)(({ theme }) => ({
  ...theme.tableFilter.container,
}));

const Label = styled(Typography)(() => ({
  paddingBottom: "2.28rem",
  fontSize: "1.44rem",
}));

const Box = styled(BoxBase)(({ theme }) => ({
  ...theme.tableFilter.box,
}));

const BoxButton = styled(BoxBase)(({ theme }) => ({
  ...theme.tableFilter.boxButton,
}));

const ButtonSearch = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textTransform: "capitalize",
}));

export default {
  Container,
  Box,
  Label,
  BoxButton,
  ButtonSearch,
  Divider,
};
