/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";

import { TableColumn } from "components/table/table.component";
import { IndividualCompliancePlusRiskReportFragment } from "graphql/hasura/types-and-hooks";

export type ReportTableSchema = Omit<
  IndividualCompliancePlusRiskReportFragment,
  | "__typename"
  | "Customerid"
  | "Aggregatebirthdate"
  | "Aggregatemobile1"
  | "Aggregatemobile2"
  | "Aggregateemail"
  | "Aggregatetelephone"
  | "RiskCode"
  | "Source"
>;

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Rnid: "string",
  Uratinno: "string",
  Uramiddlename: "string",
  Urafirstname: "string",
  Urasurname: "string",
  Matchscore: "number",
  Aggregatetin: "string",
  Sumrateablevalue: "number",
  Sumnonrateablevalue: "number",
  Sumgrossvalue: "number",
  Sumadjustedincome: "number",
  Rtnperiodyear: "string",
  Rtnno: "string",
  Propincincome: "number",
  Averagerateperrentedproperty: "number",
  Compliancecategory: "string",
  Countura: "number",
  Percentagedifference: "number",
  Numrentedproperties: "number",
  Numproperties: "number",
  Nationalid: "string",
  Mobilenumber: "string",
  Matchscoreattributes: "string",
  Matchcategory: "string",
  Aggregatesurname: "string",
  Aggregatefirstname: "string",
  Isincomesrcrental: "string",
  Isincomesrcprpty: "string",
  Isincomesrcemp: "string",
  Isincomesrcbsns: "string",
  Isamendment: "string",
  Grossrentalincome: "number",
  Emailid: "string",
  Difference: "string",
  RiskScore: "number",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | AccessorFn<ReportTableSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    header: "RNID",
    accessorKey: "Rnid",
    align: "left",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => (
      <Link to={`/individuals/${Rnid}/identification`} color="inherit">
        <>{getValue()}</>
      </Link>
    ),
  },
  {
    header: "Risk Score",
    accessorKey: "RiskScore",
    align: "center",
    meta: { sort: true },
  },
  {
    header: "URA First Name",
    accessorKey: "Urafirstname",
    align: "left",
  },
  {
    header: "URA Middle Name",
    accessorKey: "Uramiddlename",
    align: "left",
  },
  {
    header: "URA Surname",
    accessorKey: "Urasurname",
    align: "left",
  },
  {
    header: "rMATCH Score",
    accessorKey: "Matchscore",
    align: "center",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => (
      <Link to={`/individuals/${Rnid}/analysis`} color="inherit">
        <>{getValue()}</>
      </Link>
    ),
  },
  {
    header: "TIN No.",
    accessorKey: "Uratinno",
    align: "center",
  },
  {
    header: "Rental Income?",
    accessorKey: "Isincomesrcrental",
    align: "center",
  },
  {
    header: "URA Mobile No.",
    accessorKey: "Mobilenumber",
    align: "center",
  },
  {
    header: "URA Email",
    accessorKey: "Emailid",
    align: "center",
  },
  {
    header: "URA NIN",
    accessorKey: "Nationalid",
    align: "center",
  },
  {
    header: "Total Number of Properties",
    accessorKey: "Numproperties",
    align: "center",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => (
      <Link to={`/individuals/${Rnid}/properties`} color="inherit">
        <>{getValue()}</>
      </Link>
    ),
  },
  {
    header: "Total Number of Rental Properties",
    accessorKey: "Numrentedproperties",
    align: "center",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => (
      <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
        <>{getValue()}</>
      </Link>
    ),
  },
  {
    header: "Total Adjusted Gross Rental Income",
    accessorKey: "Sumadjustedincome",
    align: "right",
    format: "currency",
  },
  {
    header: "Average Rate Per Property",
    accessorKey: "Averagerateperrentedproperty",
    align: "right",
    format: "currency",
  },
  {
    header: "Return No.",
    accessorKey: "Rtnno",
    align: "center",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => {
      const value = getValue() as string;
      return (
        <Link to={`/individuals/${Rnid}/tax-history/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Amendment",
    accessorKey: "Isamendment",
    align: "center",
  },
  {
    header: "Property Income",
    accessorKey: "Propincincome",
    align: "right",
    format: "currency",
  },
  {
    header: "Return Period",
    accessorKey: "Rtnperiodyear",
    align: "center",
  },
  {
    header: "URA Gross Rent Income",
    accessorKey: "Grossrentalincome",
    align: "right",
    format: "currency",
  },
  {
    header: "Difference",
    accessorKey: "Difference",
    align: "right",
    format: "currency",
  },
];
