/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Box, Button as ButtonBase } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelCircleIcon from "@mui/icons-material/Cancel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { DialogActions as MuiDialogActions } from "@mui/material";

import { styled } from "@mui/material/styles";

const RoleForm = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const FormActions = styled(MuiDialogActions)({
  justifyContent: "left",
});

const Button = styled(ButtonBase)(({ theme }) => ({
  height: "1.8rem",
  color: theme.table.toolbarActions.iconColor,
  borderColor: theme.table.toolbarActions.iconColor,
  textTransform: "capitalize",
  "&:hover": {
    borderColor: theme.table.toolbarActions.iconColor,
  },

  "&.MuiButton-contained": {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
  },
}));

export default {
  RoleForm,
  FormActions,
  EditIcon,
  CancelCircleIcon,
  CheckCircle,
  ErrorOutlineIcon,
  Button,
};
