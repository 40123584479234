/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import _ from "lodash";
import {
  AutocompleteChangeReason,
  AutocompleteProps as AutocompletePropsBase,
} from "@mui/material/Autocomplete";
import {
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
  AutocompleteValue,
} from "@mui/material/useAutocomplete";

// Material-UI Components
import { TextFieldProps } from "@mui/material";

// Assets
import SC from "./autofill-field.styles";

export interface ServerSideSearchProps {
  onTriggerSearch?: (value: string) => void;
  minLengthSearch?: number | undefined;
  msDelaySearch?: number | undefined;
}

export interface AutofillFieldProps<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Omit<
      AutocompletePropsBase<Value, Multiple, DisableClearable, FreeSolo>,
      "renderInput" | "popupIcon"
    >,
    ServerSideSearchProps {
  serverSideSearchProps?: ServerSideSearchProps;
  textFieldProps?: TextFieldProps;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AutofillField<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(props: AutofillFieldProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { textFieldProps, serverSideSearchProps, ...muiProps } = props;
  const inputValue = muiProps?.inputValue ?? "";

  const minLengthSearch = serverSideSearchProps?.minLengthSearch ?? 0;
  const msDelaySearch = serverSideSearchProps?.msDelaySearch ?? 0;
  const searchFunction = serverSideSearchProps?.onTriggerSearch;
  // we can perform the search when the following conditions are true:
  // minLengthSearch: actual input length >= minLengthSearch
  // msDelaySearch: time in ms has been ocurred

  const [shouldExecuteSearch, setShouldExecuteSearch] = React.useState(true);

  React.useEffect(() => {
    const performSearch = inputValue.length >= minLengthSearch;
    if (shouldExecuteSearch && performSearch) {
      const timeOutIdFunc = _.debounce(() => {
        searchFunction?.(inputValue);
        setShouldExecuteSearch(false);
      }, msDelaySearch);
      timeOutIdFunc();
    }
    return undefined;
  }, [
    searchFunction,
    inputValue,
    minLengthSearch,
    msDelaySearch,
    shouldExecuteSearch,
  ]);

  const { onChange, onInputChange, ...remainingProps } = muiProps;

  const handlerOnChange = React.useCallback(
    (
      event: React.SyntheticEvent,
      value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<T>,
    ) => {
      switch (reason) {
        case "selectOption":
        case "blur":
          setShouldExecuteSearch(false);
          break;

        default:
          setShouldExecuteSearch(true);
          break;
      }

      onChange?.(event, value, reason, details);
    },
    [onChange],
  );

  const handlerOnInputChange = React.useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      setShouldExecuteSearch(true);

      if (onInputChange) onInputChange(event, value, reason);
    },
    [onInputChange],
  );

  return (
    <SC.AutofillField
      {...remainingProps}
      /* @ts-ignore */
      onChange={handlerOnChange}
      onInputChange={handlerOnInputChange}
      popupIcon={<SC.SearchIcon />}
      renderInput={(params) => <SC.TextField {...textFieldProps} {...params} />}
    />
  );
}

export default AutofillField;
