/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  AppBar,
  Tabs as TabsBase,
  Tab as TabBase,
  TabProps,
} from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import { styled } from "@mui/material/styles";

const Navigation = styled(AppBar)({
  alignSelf: "flex-end",
  backgroundColor: "transparent",
  boxShadow: "none",
  width: "fit-content",
  "& div": {
    minHeight: "auto",
  },
});

const Tabs = styled(TabsBase)({
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
});

const LinkTab: React.FC<LinkTabProps> = (
  props: TabProps & LinkProps,
): JSX.Element => <TabBase component={Link} {...props} />;

type LinkTabProps = TabProps &
  LinkProps & {
    isActive: boolean;
  };

const Tab = styled(LinkTab, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<LinkTabProps>(({ isActive, theme }) => ({
  alignSelf: "flex-end",
  padding: "0px",
  margin: "0px",
  minHeight: "0px",
  textTransform: "none",
  opacity: "1",
  fontWeight: theme.navigation.fontWeight,
  [theme.breakpoints.down("xl")]: {
    paddingTop: theme.navigationSmall.paddingTop,
    paddingRight: theme.navigationSmall.paddingRight,
    paddingBottom: theme.navigationSmall.paddingBottom,
    paddingLeft: theme.navigationSmall.paddingLeft,
    borderTopRightRadius: theme.navigationSmall.borderTopRightRadius,
    borderTopLeftRadius: theme.navigationSmall.borderTopLeftRadius,
  },
  [theme.breakpoints.up("xl")]: {
    paddingTop: theme.navigation.paddingTop,
    paddingRight: theme.navigation.paddingRight,
    paddingBottom: theme.navigation.paddingBottom,
    paddingLeft: theme.navigation.paddingLeft,
    borderTopRightRadius: theme.navigation.borderTopRightRadius,
    borderTopLeftRadius: theme.navigation.borderTopLeftRadius,
  },
  minWidth: "auto",

  flexDirection: "row",
  "& > .MuiSvgIcon-root": {
    marginBottom: "0px",
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.navigationIconsSmall.fontSize,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.navigationIcons.fontSize,
    },
  },
  "& span": {
    minWidth: "fit-content",
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.navigationSmall.fontSize,
      lineHeight: theme.navigationSmall.lineHeight,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.navigation.fontSize,
      lineHeight: theme.navigation.lineHeight,
    },
  },
  "&:hover": {
    background: theme.navigation.background,
  },
  ...(isActive && {
    background: theme.navigationTabActive.background,
    color: theme.navigationTabActive.color,
    "&:hover": {
      background: theme.navigationTabActive.background,
    },
  }),
}));

export default {
  Navigation,
  Tabs,
  Tab,
};
