/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  Card as CardBase,
  CardHeader as CardHeaderBase,
  Fab,
  Box,
  Button as ButtonBase,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const Card = styled(CardBase)(() => ({
  maxWidth: "fit-content",
}));

const CardHeader = styled(CardHeaderBase)(({ theme }) => ({
  action: {
    marginLeft: theme.spacing(1),
    alignSelf: "center",
  },
}));

const StatusIndicator = styled(Fab)(() => ({
  cursor: "default",
}));

const ButtonWrapper = styled(Box)(() => ({
  position: "relative",
}));

const Button = styled(ButtonBase)(() => ({
  label: {
    textTransform: "capitalize",
  },
}));

const IndicatorProgress = styled(CircularProgress)(() => ({
  position: "absolute",
  top: -6,
  left: -6,
  zIndex: 1,
}));

const ButtonProgress = styled(CircularProgress)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-12px",
  marginLeft: "-12px",
}));

const Text = styled(Typography)(() => ({}));

export default {
  Card,
  CardHeader,
  StatusIndicator,
  ButtonWrapper,
  Button,
  IndicatorProgress,
  ButtonProgress,
  Text,
};
