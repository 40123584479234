import { TableColumn } from "components/table/table.component";

import { AggregatePropertyFragment } from "graphql/hasura/types-and-hooks";

export type AggregatePropertyAlternateOwnerTableSchema = Omit<
  AggregatePropertyFragment,
  "__typename"
>;

export const AggregatePropertyAlternateOwnerTableColumns: TableColumn<AggregatePropertyAlternateOwnerTableSchema>[] =
  [
    {
      header: "Contact Email",
      accessorKey: "ContactEmail",
      align: "center",
      format: "string",
    },
    {
      header: "Contact Mobile_1",
      accessorKey: "ContactMobile1",
      align: "right",
      format: "string",
    },
    {
      header: "Contact Mobile_2",
      accessorKey: "ContactMobile2",
      align: "right",
      format: "string",
    },
    {
      header: "Contact Address",
      accessorKey: "ContactAddress",
      align: "center",
      format: "string",
    },
    {
      header: "Contact Subcounty",
      accessorKey: "ContactSubcountryDivision",
      align: "center",
      format: "string",
    },
    {
      header: "Contact Parish_Ward",
      accessorKey: "ContactParishWard",
      align: "center",
      format: "string",
    },
    {
      header: "Contact County",
      accessorKey: "ContactCountyMunicipality",
      align: "center",
      format: "string",
    },
    {
      header: "Contact District",
      accessorKey: "ContactDistrict",
      align: "center",
      format: "string",
    },
    {
      header: "Contact Country",
      accessorKey: "ContactCountry",
      align: "center",
      format: "string",
    },
    {
      header: "Contact Village",
      accessorKey: "ContactVillageCellZone",
      align: "center",
      format: "string",
    },
  ];
