/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useMemo } from "react";

// Styles
import SC from "./properties.styles";

export const PropertyPage = () => {
  return <SC.Box></SC.Box>;
};

export default PropertyPage;
