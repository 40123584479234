/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/style-prop-object */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import React from "react";
import { formatNumber } from "utils/math";
import { Link } from "react-router-dom";
import { NonIndividualPropertyDetailsFragment } from "graphql/hasura/types-and-hooks";
import FormattedValue from "components/formatted-value/formatted-value";
import { getColumnTotal } from "components/table/utils";

export type PropertyTableSchema = {
  source_id: string;
  Source: string;
  last_update: string;
  propertytype: string;
  plot_no: string;
  block_no: string;
  house_no: string;
  road_name: string;
  address: string;
  village: string;
  division: string;
  district: string;
  rented_status: string;
  gross_value: string;
  relatable_value: string;
  current_relatable_value: string;
  balance: string;
};

export const propertiesTableColumns: TableColumn<
  NonIndividualPropertyDetailsFragment | Record<string, unknown>
>[] = [
  {
    header: "RNID",
    accessorKey: "Propertyrnid",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { Propertyrnid },
      },
    }) => {
      return (
        <Link to={`${Propertyrnid}/details`} color="inherit">
          <>{getValue()}</>
        </Link>
      );
    },
  },
  {
    header: "Source Property ID",
    accessorKey: "Propertyno",
    footer: "Totals",
    align: "center",
    meta: { sort: true },
  },
  {
    header: "Source",
    accessorKey: "Source",
    align: "center",
  },
  {
    header: "Entity Legal Name",
    accessorKey: "Entitylegalname",
    format: "string",
  },
  // TODO: uncomment {
  //   header: "Mobile Number",
  //   accessorKey: "Mobilenumber",
  //   format: "string",
  // },
  {
    header: "Owner First Name",
    accessorKey: "Ownerfirstname",
    format: "string",
  },
  {
    header: "Owner Legal Surname Maiden",
    accessorKey: "Ownerlegalsurnamemaiden",
    format: "string",
  },
  // TODO: uncomment {
  //   header: "Organisation Type",
  //   accessorKey: "OrgType",
  //   align: "left",
  // },
  // {
  //   header: "Organisation Sub Type",
  //   accessorKey: "OrgSubType",
  //   align: "left",
  // },
  {
    header: "Trading Center",
    accessorKey: "Tradingcenter",
  },
  {
    header: "Aggregate Customer ID",
    accessorKey: "Aggregatecustomerid",
    format: "string",
  },
  {
    header: "Aggregate Name",
    accessorKey: "Aggregatename",
    format: "string",
  },
  {
    header: "Aggregate TIN",
    accessorKey: "Tin",
    format: "string",
  },
  {
    header: "Sub Property Type",
    accessorKey: "Subpropertytype",
    align: "center",
  },
  {
    header: "Last Update",
    accessorKey: "LastUpdated",
    align: "center",
    format: "date",
  },
  {
    header: "Property Type",
    accessorKey: "Propertytype",
    align: "center",
  },
  {
    header: "Plot No.",
    accessorKey: "Plotno",
    align: "center",
  },
  {
    header: "Block No.",
    accessorKey: "Blockno",
    align: "center",
  },
  {
    header: "House No.",
    accessorKey: "Houseno",
    align: "center",
  },
  {
    header: "Flat No.",
    accessorKey: "Flatno",
    align: "center",
  },
  {
    header: "Street",
    accessorKey: "Streetid",
  },
  {
    header: "Parish",
    accessorKey: "Parish",
  },
  {
    header: "Address",
    accessorKey: "Address",
  },
  {
    header: "Village",
    accessorKey: "Village",
  },
  {
    header: "Division",
    accessorKey: "Division",
  },
  {
    header: "District",
    accessorKey: "District",
  },
  {
    header: "Rented Status",
    accessorKey: "Propertyrentedstatus",
    align: "center",
  },
  // TODO: uncomment {
  //   header: "Rnid Customer",
  //   accessorKey: "Rnidcustomer",
  //   align: "center",
  // },
  {
    header: "Rnid Owner",
    accessorKey: "Rnidowner",
    align: "center",
  },
  {
    header: "Gross Value",
    accessorKey: "Grossvalue",
    align: "right",
    format: "currency",
    footer: (props): React.ReactNode => {
      const total = getColumnTotal(props);
      return <FormattedValue format="currency" value={total} />;
    },
  },

  {
    header: "Rateable Value",
    accessorKey: "Rateablevalue",
    align: "right",
    format: "currency",
    footer: (props): React.ReactNode => {
      const total = getColumnTotal(props);
      return <FormattedValue format="currency" value={total} />;
    },
  },

  {
    header: "Current Rateable Value",
    accessorKey: "Currentrateablevalue",
    align: "right",
    format: "currency",
    footer: (props): React.ReactNode => {
      const total = getColumnTotal(props);
      return <FormattedValue format="currency" value={total} />;
    },
  },

  {
    header: "Adjusted Gross Income",
    accessorKey: "Grossrentvalueadjusted",
    align: "right",
    format: "currency",
    footer: (props): React.ReactNode => {
      const total = getColumnTotal(props);
      return <FormattedValue format="currency" value={total} />;
    },
  },
  {
    header: "Field Assess Date",
    accessorKey: "Createddate",
    align: "center",
    format: "date",
  },
  {
    header: "Latitude",
    accessorKey: "Latitude",
    align: "center",
  },
  {
    header: "Longitude",
    accessorKey: "Longitude",
    align: "center",
  },
  // {
  //   header: "Customer Matchscore",
  //   accessorKey: "Customermatchscore",
  //   align: "center",
  // },
];
