import { TableColumn } from "components/table/table.component";
import { AggregateCustomerSecondaryOwnerFragment } from "graphql/hasura/types-and-hooks";

export type AggregateCustomerSecondaryOwnerTableSchema = Omit<
  AggregateCustomerSecondaryOwnerFragment,
  "__typename"
>;

export const AggregateOwnerSecondaryOwnerTableColumns: TableColumn<AggregateCustomerSecondaryOwnerTableSchema>[] =
  [
    {
      header: "Applicant Type",
      accessorKey: "CustomerApplicantType",
      align: "center",
      format: "string",
    },
    {
      header: "Business Type",
      accessorKey: "CustomerBusinessType",
      align: "center",
      format: "string",
    },
    {
      header: "Customer Type",
      accessorKey: "CustomerType",
      align: "center",
      format: "string",
    },
    {
      header: "Street Name",
      accessorKey: "StreetName",
      align: "center",
      format: "string",
    },
    {
      header: "Residential Address Village",
      accessorKey: "ResidentialAddressVillage",
      align: "center",
      format: "string",
    },
    {
      header: "Residential Address Subcounty",
      accessorKey: "ResidentialAddressSubCounty",
      align: "center",
      format: "string",
    },
    {
      header: "Residential Address County",
      accessorKey: "ResidentialAddressCounty",
      align: "center",
      format: "string",
    },
    {
      header: "Residential Address Parish",
      accessorKey: "ResidentialAddressParish",
      align: "center",
      format: "string",
    },
    {
      header: "Residential Address District",
      accessorKey: "ResidentialAddressDistrict",
      align: "center",
      format: "string",
    },
    {
      header: "Email",
      accessorKey: "Email",
      align: "center",
      format: "string",
    },
    {
      header: "Mobile No.",
      accessorKey: "Mobile",
      align: "right",
      format: "string",
    },
    {
      header: "National ID No.",
      accessorKey: "IdentificationNationalId",
      align: "center",
      format: "string",
    },
    {
      header: "NSSF No.",
      accessorKey: "IdentificationNssfNumber",
      align: "center",
      format: "string",
    },
    {
      header: "Mother Maiden Name",
      accessorKey: "MotherMaidenName",
      align: "left",
      format: "string",
    },
  ];
