/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { UgandaRegistrationSbFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type UgandaRegistrationTableSchema = Omit<
  UgandaRegistrationSbFragment,
  "__typename"
>;

export interface UgandaRegistrationTableFilters {
  businessName: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof UgandaRegistrationTableFilters,
  string | string[]
> = {
  businessName: ["BusinessName"],
};

export const UGANDA_REGISTRATION_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof UgandaRegistrationTableSchema,
  "string" | "number" | "Date"
> = {
  BusinessName: "string",
  CompanyName: "string",
  RegistrationNumber: "number",
  RegistrationDate: "Date",
  Subtype: "string",
  CompanyType: "string",
  PartnersFirstname: "string",
  PartnersSurname: "string",
  DirectorFirstname: "string",
  DirectorSurname: "string",
  ShareholderFirstname: "string",
  ShareholdersSurname: "string",
  District: "string",
  Village: "string",
  Town: "string",
  Trading: "string",
  Street: "string",
  Parish: "string",
};

export const getAccessorType = (
  accessor:
    | keyof UgandaRegistrationTableSchema
    | AccessorFn<UgandaRegistrationTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return UGANDA_REGISTRATION_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const UgandaRegistrationColumns: TableColumn<UgandaRegistrationTableSchema>[] =
  [
    {
      header: "Business Name",
      accessorKey: "BusinessName",
      align: "left",
      format: "string",
    },
    {
      header: "Company Name",
      accessorKey: "CompanyName",
      align: "left",
      format: "string",
    },
    {
      header: "Registration Number",
      accessorKey: "RegistrationNumber",
      align: "center",
      meta: { sort: true },
    },
    {
      header: "Registration Date",
      accessorKey: "RegistrationDate",
      align: "center",
      format: "date",
    },
    {
      header: "Company Type",
      accessorKey: "CompanyType",
      align: "center",
      format: "string",
    },
    {
      header: "Business Type",
      accessorKey: "Subtype",
      align: "center",
      format: "string",
    },
    {
      header: "Partners First Name",
      accessorKey: "PartnersFirstname",
      align: "left",
      format: "string",
    },
    {
      header: "Partners Surname",
      accessorKey: "PartnersSurname",
      align: "left",
      format: "string",
    },
    {
      header: "Director First Name",
      accessorKey: "DirectorFirstname",
      align: "left",
      format: "string",
    },
    {
      header: "Director Surname",
      accessorKey: "DirectorSurname",
      align: "left",
      format: "string",
    },
    {
      header: "Shareholder First Name",
      accessorKey: "ShareholderFirstname",
      align: "left",
      format: "string",
    },
    {
      header: "Shareholders Surname",
      accessorKey: "ShareholdersSurname",
      align: "left",
      format: "string",
    },
    {
      header: "District",
      accessorKey: "District",
      align: "center",
      format: "string",
    },
    {
      header: "Village",
      accessorKey: "Village",
      align: "center",
      format: "string",
    },
    { header: "Town", accessorKey: "Town", align: "center", format: "string" },
    {
      header: "Trading",
      accessorKey: "Trading",
      align: "center",
      format: "string",
    },
    {
      header: "Street",
      accessorKey: "Street",
      align: "center",
      format: "string",
    },
    {
      header: "Parish",
      accessorKey: "Parish",
      align: "center",
      format: "string",
    },
  ];
