/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box as BoxBase,
  Typography,
  Dialog,
  DialogActions as DialogActionsBase,
  DialogTitle,
  DialogContent as DialogContentBase,
  Grid as GridBase,
  Button as ButtonBase,
  TextField as TextFieldBase,
  Select,
  Tooltip,
  IconButton as IconButtonBase,
  InputAdornment,
  MenuItem as MenuItemBase,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

const Container = styled(BoxBase)(() => ({}));

const SelectField = styled(Select)(() => ({
  width: "100%",
  borderWidth: "0px",
  ".MuiInputBase-root": {
    width: "100%",
  },

  select: {
    width: "100%",
  },
  selectMenu: {
    width: "100%",
  },
}));

const MenuItem = MenuItemBase;

const DialogActions = styled(DialogActionsBase)(({ theme }) => ({
  paddingBottom: theme.spacing(5),
  paddingTop: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  justifyContent: "flex-start",
}));

const DialogContent = styled(DialogContentBase)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const Label = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.primary.main,
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  alignSelf: "center",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
  textTransform: "none",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textAlign: "center",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
}));

const CancelButton = styled(ButtonBase)(({ theme }) => ({
  alignSelf: "center",
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  border: "#D2DCE6 1px solid",
  textTransform: "none",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginRight: theme.spacing(3),
  textAlign: "center",
  "&:hover": {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
}));

const TextButton = styled(ButtonBase)(({ theme }) => ({
  alignSelf: "center",
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  border: "#fff 1px solid",
  textTransform: "none",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginRight: theme.spacing(3),
  textAlign: "center",
  "&:hover": {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
  disabled: {
    border: "#fff 0px solid !important",
  },
}));

const Grid = GridBase;

const GridSelect = styled(GridBase)(({ theme }) => ({
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiInput-underline:before": {
    borderBottomWidth: "0px",
  },
  "& .MuiInput-underline:after": {
    borderBottomWidth: "0px",
    backgroundColor: "transparent",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderColor: theme.palette.background.default,
    "&:hover": {
      borderColor: theme.palette.background.default,
    },
  },
}));

const TextField = styled(TextFieldBase)(() => ({
  width: "100%",
  marginTop: "1.2rem",
}));

const Box = BoxBase;

const IconButton = styled(IconButtonBase)(({ theme }) => ({
  padding: theme.spacing(1),
  "& .MuiSvgIcon-root": {
    fontSize: theme.table.toolbarActions.fontSize,
  },
  "&:not(.Mui-disabled) .MuiSvgIcon-root": {
    color: theme.universalFilter.color,
  },
}));

export default {
  Container,
  Label,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  GridSelect,
  SelectField,
  Button,
  CancelButton,
  TextButton,
  TextField,
  Box,
  Tooltip,
  DeleteIcon,
  IconButton,
  InputAdornment,
  MenuItem,
};
