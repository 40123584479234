// Dependencies
import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs,
  Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column,
  useGetPaginatedIndividualReportsQuery,
  Order_By,
  useIndividualReportsFilterQuery,
  useGetMaxMinIndividualReportsQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import { useAppErrorHandler } from "errors/app.errors";

// Utils
import {
  aggregateArrayToObject,
  jsonToStringQueryVariables,
} from "graphql/hasura/rtcs.utils";

// component depend
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";
import {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import TableFilter, {
  FilterCondition,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";
import {
  columns as reportColumns,
  getAccessorType,
} from "./individuals.schema";

// Assets
import SC from "./individuals.styles";

const ReportType = { Compliancecategory: { _eq: "UNDERREPORTED" } };

const TABLE_NAME = "rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk";

const TaxpayersWithUnderreportedIncome: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs | undefined
  >({
    where: { ...ReportType },
    limit: 0,
  });
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginatedIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables?.limit,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const {
    data: maxMinData,
    loading: maxMinLoading,
    error: maxMinError,
  } = useGetMaxMinIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      params: jsonToStringQueryVariables({ where: { ...ReportType } }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || maxMinError || exportError);

  const columns = useMemo(() => reportColumns, []);

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.RiskScore,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    [],
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm[0]
      .value ?? "-1",
    10,
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData],
  );

  const { refetch: ReportFilterFn } = useIndividualReportsFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs =
            {
              where: { [column]: { _ilike: `${value}%` }, _and: [ReportType] },
              distinct_on: [
                column as Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column,
              ],
              limit: 10,
            };
          const individualReportFilterData = await ReportFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            individualReportFilterData.data.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column],
            );
        }

        return await Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [ReportFilterFn],
  );

  const newObj = aggregateArrayToObject(
    maxMinData?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm,
  );

  const minTotalRentedProperties = newObj?.['min("Numrentedproperties")'];

  const maxTotalRentedProperties = newObj?.['max("Numrentedproperties")'];

  const minSumadjustedincome = newObj?.['min("Sumadjustedincome")'];

  const maxSumadjustedincome = newObj?.['max("Sumadjustedincome")'];

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "First Name",
        columnName:
          Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.Urafirstname,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Surname",
        columnName:
          Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.Urasurname,
        fetchSuggestions,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Total Number of Rented Properties",
        min: minTotalRentedProperties,
        max: maxTotalRentedProperties,
        columnName:
          Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.Numrentedproperties,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Adjusted Gross Rental Income",
        min: minSumadjustedincome,
        max: maxSumadjustedincome,
        columnName:
          Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.Sumadjustedincome,
      },
    ];
  }, [
    fetchSuggestions,
    maxSumadjustedincome,
    maxTotalRentedProperties,
    minSumadjustedincome,
    minTotalRentedProperties,
  ]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: [...conditions, ReportType],
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    [],
  );

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.header}`,
        value: `${column.accessorKey}`,
        type: `${getAccessorType(column.accessorKey)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }

    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: {
        ...condition,
      },
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  return (
    <>
      <SC.ProgressIndicator open={loading || maxMinLoading || exportLoading} />
      <SC.Breadcrumb label="Analytics" />
      <SC.Table
        title="Taxpayers with underreported income"
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        persistenceId="823f48e4-5ee1-47a5-b7b4-95f5bafbdb69"
        stickyHeader
        columns={columns}
        data={data?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk ?? []}
        paginationControlled={paginationControlled}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        leftPanel={
          <TableFilter
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            defaultUniversalFilterConditions={[ReportType]}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        exportData={exportDataFn}
      />
    </>
  );
};

export default TaxpayersWithUnderreportedIncome;
