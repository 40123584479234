/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from "lodash";
import { RptRentalTenantPaymentsFragment } from "graphql/hasura/types-and-hooks";
import { AccessorFn } from "@tanstack/react-table";
import { TableColumn } from "components/table/table.component";

export type ReportTableSchema = Omit<
  RptRentalTenantPaymentsFragment,
  "__typename"
>;
export interface TenantRentalPaymentsTableFilters {
  firstName: string;
  surname: string;
}

export const TENANTS_RENTAL_PAYMENT_TABLE_SCHEMA_DATA_TYPE: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  LandlordName: "string",
  LandlordTin: "string",
  TenantName: "string",
  TenantTin: "string",
  PremiseLocation: "string",
  RentPaid: "number",
  RtnNo: "string",
  TenancyPeriodFrom: "string",
  TenancyPeriodTo: "string",
  RtnRentPaidId: "string",
  File: "string",
  IsAmendment: "string",
  LastUpdated: "string",
  Line: "string",
};
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof TenantRentalPaymentsTableFilters,
  string | string[]
> = {
  firstName: ["LandlordName", "TenantName"],
  surname: ["LandlordName", "TenantName"],
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | AccessorFn<ReportTableSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return TENANTS_RENTAL_PAYMENT_TABLE_SCHEMA_DATA_TYPE[accessor];
};

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    header: "LandLord Name",
    accessorKey: "LandlordName",
    meta: { sort: false },
  },
  {
    header: "Landlord TIN",
    accessorKey: "LandlordTin",
  },
  {
    header: "Tenant Name",
    accessorKey: "TenantName",
    meta: { sort: false },
  },
  {
    header: "Tenant TIN",
    accessorKey: "TenantTin",
  },
  {
    header: "Premises Location",
    accessorKey: "PremiseLocation",
    format: "string",
  },
  {
    header: "Rent Paid",
    accessorKey: "RentPaid",
    format: "currency",
    align: "right",
  },
  {
    header: "Return No.",
    accessorKey: "RtnNo",
  },
  {
    header: "Return Rent Paid Id",
    accessorKey: "RtnRentPaidId",
  },
  {
    header: "Tenancy Period From",
    accessorKey: "TenancyPeriodFrom",
    format: "date",
  },
  {
    header: "Tenancy Period To",
    accessorKey: "TenancyPeriodTo",
    format: "date",
  },
  {
    header: "Is Amendment",
    accessorKey: "IsAmendment",
    format: "string",
  },
];
