// Dependencies
import React from "react";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { IntlProvider } from "react-intl";

// Components
import App from "components/app/app.component";
import ThemeProvider from "components/theme-provider/theme-provider.component";

// Utils & Services
import client from "graphql/client";
import reportWebVitals from "reportWebVitals";
import { DEFAULT_TIME_ZONE } from "utils/internationalization";

// Get the root element
const container = document.getElementById("root");

if (!container) {
  throw new Error("Root element not found");
}

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <IntlProvider
        locale="en-UG"
        defaultLocale="en-US"
        timeZone={DEFAULT_TIME_ZONE}
      >
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </IntlProvider>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
