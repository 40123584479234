/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";

const NoteAttachment = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  marginRight: theme.notes.attachments.attachment.marginRight,
  color: theme.palette.text.disabled,
}));

export default {
  NoteAttachment,
};
