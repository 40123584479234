/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { AggregateOwnerFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type AggregateOwnerSchema = Omit<AggregateOwnerFragment, "__typename">;

export interface CustomerRegistrationTableFilters {
  email: string;
  firstName: string;
  surname: string;
  businessName: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof CustomerRegistrationTableFilters,
  string | string[]
> = {
  firstName: ["Firstname"],
  surname: ["Surname"],
  email: ["Email"],
  businessName: ["BusinessName"],
};

// !TODO ts-ignore to be removed when new changes of the table are relected.
// @ts-ignore
export const AggregateOwnerSchemaMap: Record<
  keyof AggregateOwnerSchema,
  "string" | "number"
> = {
  Id: "number",
  Coin: "string",
  CustomerType: "string",
  Firstname: "string",
  MiddleName: "string",
  Surname: "string",
  MotherMaidenName: "string",
  Tin: "string",
  IdentificationNationalId: "string",
  Birthdate: "string",
  EntityLegalName: "string",
  BuildingName: "string",
  BusinessName: "string",
  CustomerApplicantType: "string",
  CustomerBusinessType: "string",
  DateOfIncorporation: "string",
  Email: "string",
  Mobile: "string",
  SecondMobile: "string",
};

export const getAccessorType = (
  accessor:
    | keyof AggregateOwnerSchema
    | AccessorFn<AggregateOwnerSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return AggregateOwnerSchemaMap[accessor];
};

export const customerTableColumns: TableColumn<AggregateOwnerSchema>[] = [
  {
    header: "Aggregate ID",
    accessorKey: "Id",
    align: "center",
    meta: { sort: false },
  },
  {
    header: "Aggregate COIN",
    accessorKey: "Coin",
    align: "center",
    meta: { sort: false },
  },
  {
    header: "Customer Type",
    accessorKey: "CustomerType",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "First Name",
    accessorKey: "Firstname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Middle Name",
    accessorKey: "MiddleName",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Surname",
    accessorKey: "Surname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Mother's Maiden Name",
    accessorKey: "MotherMaidenName",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Aggregate TIN No.",
    accessorKey: "Tin",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "NIN",
    accessorKey: "IdentificationNationalId",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Date Of Birth",
    accessorKey: "Birthdate",
    align: "center",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Legal Name",
    accessorKey: "EntityLegalName",
    align: "right",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Building Name",
    accessorKey: "BuildingName",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Business Name",
    accessorKey: "BusinessName",
    align: "right",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Applicant Type",
    accessorKey: "CustomerApplicantType",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Business Type",
    accessorKey: "CustomerBusinessType",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Incorporation Date",
    accessorKey: "DateOfIncorporation",
    align: "center",
    meta: { sort: false },
    format: "date",
  },
  {
    header: "Email",
    accessorKey: "Email",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Mobile",
    accessorKey: "Mobile",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Mobile2",
    accessorKey: "SecondMobile",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Aggregate Emails",
    accessorKey: "AggregateEmails",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Aggregate Phones",
    accessorKey: "AggregatePhones",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Aggregate National Identifiers",
    accessorKey: "AggregateNationalIdentifiers",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
];
