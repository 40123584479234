import { TableColumn } from "components/table/table.component";
import { AggregateOwnerPrimaryOwnerFragment } from "graphql/hasura/types-and-hooks";

export type AggregatePrimaryOwnerTableSchema = Omit<
  AggregateOwnerPrimaryOwnerFragment,
  "__typename"
>;

export const aggregateOwnerPrimaryOwnerTableColumns: TableColumn<AggregatePrimaryOwnerTableSchema>[] =
  [
    {
      header: "COIN",
      accessorKey: "Coin",
      align: "right",
      format: "string",
    },
    {
      header: "First Name",
      accessorKey: "Firstname",
      align: "left",
      format: "string",
    },
    {
      header: "Middle Name",
      accessorKey: "MiddleName",
      align: "left",
      format: "string",
    },
    {
      header: "Surname",
      accessorKey: "Surname",
      align: "left",
      format: "string",
    },
    {
      header: "Mother Maiden Name",
      accessorKey: "MotherMaidenName",
      align: "left",
      format: "string",
    },
    {
      header: "Business Name",
      accessorKey: "BusinessName",
      align: "left",
      format: "string",
    },
    {
      header: "Legal Name",
      accessorKey: "EntityLegalName",
      align: "left",
      format: "string",
    },
    {
      header: "Customer TIN",
      accessorKey: "Tin",
      align: "center",
      format: "string",
    },
    {
      header: "Customer ID",
      accessorKey: "Id",
      align: "center",
      format: "string",
    },
  ];
