/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  Accordion as AccordionBase,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails,
  Typography,
  IconButton as IconButtonBase,
  Grid,
  Paper as PaperBase,
  Box as BoxBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import FormatedValue from "components/formatted-value/formatted-value";

const Box = styled(BoxBase)(({ theme }) => ({
  backgroundColor: theme.accordionBody.backgroundColor,
}));

const Accordion = styled(AccordionBase)({
  borderWidth: "0px",
});

const AccordionSummary = styled(AccordionSummaryBase)({
  ".MuiAccordionSummary-content": {
    alignItems: "center",
    borderTopWidth: "0px",
  },
});

const Label = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const LabelSubtitle = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  fontSize: theme.accordionBody.subtitle.fontSize,
  fontWeight: theme.accordionBody.subtitle.fontWeight,
  color: theme.label.color,
}));

const LabelTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontWeight: theme.accordionBody.subtitle.fontWeight,
  fontSize: theme.accordionBody.title.fontSize,
}));

const Paper = styled(PaperBase)(() => ({
  justifyContent: "left",
  borderRadius: "0px",
}));

const LabelButtonText = styled(Label)(() => ({
  fontSize: "0.8rem",
  textAlign: "center",
}));

const IconButton = styled(IconButtonBase)(() => ({
  justifyContent: "flex-end",
  alignSelf: "flex-end",
}));

export default {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Label,
  LabelSubtitle,
  LabelButtonText,
  FormatedValue,
  LabelTitle,
  IconButton,
  Grid,
  Paper,
  Box,
};
