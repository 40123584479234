// Dependencies
import React, { useMemo } from "react";
import Table from "components/table/table.component";
import { PropertiesValuationFragment } from "graphql/hasura/types-and-hooks";
import { AggregateColumns } from "./aggregate-local-councel.schema";

interface PropertyProps {
  data: PropertiesValuationFragment[];
}

const PrimaryTable: React.FC<PropertyProps> = ({ data }) => {
  const Columns = useMemo(() => AggregateColumns, []);

  const valuationData = useMemo(() => {
    return [
      {
        source: data[0].Source,
        parameter: "Adjusted Gross Income Rent",
        value: data[0].Grossrentvalueadjusted,
      },
      {
        source: data[0].Source,
        parameter: "Gross Value",
        value: data[0].Grossvalue,
      },
      {
        source: data[0].Source,
        parameter: "Net Rateable Value",
        value: data[0].Rateablevalue,
        Grossrentvalueadjusted: data[0].Grossrentvalueadjusted,
      },
    ];
  }, [data]);
  return (
    <Table
      // stickyHeader
      isPaginationHidden
      onAction={() => {}}
      title="Aggregate Local Council"
      initialRowsPerPage={10000}
      columns={Columns}
      data={valuationData}
    />
  );
};

export default PrimaryTable;
