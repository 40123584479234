/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Dialog as MuiDialog, CircularProgress } from "@mui/material";

import { styled } from "@mui/material/styles";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backgroundColor: theme.progressIndicator.backgroundColor,
  },
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const ProgressIndicator = styled(CircularProgress)(({ theme }) => ({
  margin: theme.spacing(2),
}));

export default {
  Dialog,
  ProgressIndicator,
};
