/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  Toolbar as ToolbarBase,
  Box,
  Typography,
  TablePagination as TablePaginationBase,
} from "@mui/material";

import { styled } from "@mui/material/styles";

// Components
import TableToolbarActions from "components/table-toolbar-actions/table-toolbar-actions.component";
import TableToolbarButton from "components/table-toolbar-button/table-toolbar-button.component";

const Toolbar = styled(ToolbarBase)(({theme}) => ({
  padding: theme.spacing(0, 2),
  justifyContent: "space-between",
}));

const TitleActionsContainer = styled(Box)({
  display: "inline-flex",
  alignItems: "center",
});

const TitleWrapper = styled(Box)(({theme}) => ({
  marginRight: theme.spacing(1),
}));

const Title = styled(Typography)(({theme}) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: theme.table.toolbar.fontSize,
}));

const TablePagination = styled(TablePaginationBase)(({theme}) => ({
  caption: theme.typography.subtitle1,
  input: {
    border: theme.table.tablePagination.inputBorder,
    backgroundColor: theme.palette.background.default,
  },
  selectIcon: {
    color: theme.table.tablePagination.selectIconColor,
  },
  "& .MuiTablePagination-caption": {
    fontSize: theme.table.toolbar.fontSize,
  },
}));

export default {
  Toolbar,
  TitleActionsContainer,
  TitleWrapper,
  Title,
  TableToolbarButton,
  TablePagination,
  TableToolbarActions,
};
