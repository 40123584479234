/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React, { PropsWithChildren } from "react";
import {
  Container as ContainerBase,
  ContainerProps,
  TableContainer as TableContainerBase,
  Table as TableBase,
  TableHead as TableHeadBase,
  TableFooter as TableFooterBase,
  TableRow as TableRowBase,
  TableCell as TableCellBase,
  TableBody as TableBodyBase,
  TableSortLabel as TableSortLabelBase,
  IconButton,
  Grid as GridBase,
  Box as BoxBase,
  BoxProps,
  Checkbox,
  TableCellProps,
} from "@mui/material";

import { styled } from "@mui/material/styles";

interface CustomBoxProps extends PropsWithChildren<BoxProps> {
  pane?: boolean;
  maxHeight?: number | string;
}

const TableFooter = TableFooterBase;

const RightPaneBoxStyled = styled(BoxBase, {
  shouldForwardProp: (prop) => prop !== "pane",
})<CustomBoxProps>(({ pane }) => ({
  width: pane ? "calc(100% - 23rem)" : "100%",
}));

interface CustomContainerProps extends PropsWithChildren<ContainerProps> {
  flexgrow?: number;
  maxHeight?: number;
  expanded?: boolean;
}

const ContainerStyled = styled(ContainerBase, {
  shouldForwardProp: (prop) => prop !== "maxHeight",
})<CustomContainerProps>(({ maxHeight }) => ({
  overflowX: "auto",
  maxHeight: maxHeight ? `${maxHeight}px` : "inherit",
  height: maxHeight ? `${maxHeight}px` : "inherit",
}));

const Container = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => <ContainerStyled {...props} ref={ref} />,
);

const RightPaneBox = ({ children, ...rest }: CustomBoxProps): JSX.Element => {
  return <RightPaneBoxStyled {...rest}>{children}</RightPaneBoxStyled>;
};

const LeftPaneBoxStyled = styled(BoxBase, {
  shouldForwardProp: (prop) => prop !== "pane",
})<CustomBoxProps>(({ pane }) => ({
  width: "23rem",
  minWidth: "23rem",
  overflowY: "auto",
  display: pane ? "inherit" : "none",
}));

const LeftPaneBox = ({ children, ...rest }: CustomBoxProps): JSX.Element => {
  return <LeftPaneBoxStyled {...rest}>{children}</LeftPaneBoxStyled>;
};

const TopPaneBox = styled(BoxBase)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const ExpandedContainer = styled(ContainerBase)(() => ({
  position: "absolute",
  height: "100vh",
  background: "#fff",
  zIndex: 1200,
  width: "100vw",
  top: 0,
  left: 0,
}));

const ExpansionContainer: React.FC<CustomContainerProps> = ({
  expanded,
  ...props
}) => {
  return expanded ? (
    <ExpandedContainer {...props} />
  ) : (
    <ContainerBase {...props} />
  );
};

const TableContainer = styled(TableContainerBase)(() => ({
  height: "100%",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
}));

const Table = styled(TableBase)(({ theme }) => ({
  tableLayout: "auto",
  backgroundColor: theme.palette.background.default,
}));

const Grid = GridBase;

const Box = BoxBase;

const Resizer = styled(BoxBase)(() => ({
  minWidth: ".5rem !important",
  width: ".5rem !important",
  cursor: "ew-resize !important",
  overflowX: "auto",
  backgroundColor: "transparent",
  padding: "0px",
  flex: "0 0 auto !important",
}));

const useTableStyles = () => {
  const rootStyles = {
    display: "flex",
    flexDirection: "row",
    margin: `0px !important`,
    padding: `0px !important`,
  };

  const tableContainer = styled(TableContainerBase)(() => ({
    overflowX: "auto",
  }));

  return {
    rootStyles,
    tableContainer,
  };
};

const TableHead = styled(TableHeadBase)(({ theme }) => ({
  borderBottom: `0.05rem solid ${theme.border.color}`,
  top: 0,
  position: "sticky",
  zIndex: 1,
}));

const TableBody = TableBodyBase;

const TableRow = styled(TableRowBase)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.table.tableRow.evenRowBackgroundColor,
  },
  "& .MuiTableRow-head": {
    zIndex: 0,
  },
  "& .MuiGrid-align-items-xs-baseline": {
    backgroundColor: "white",
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  "& .MuiContainer-root": {
    backgroundColor: "white",
  },
}));

const TableCell = styled(TableCellBase)(({ theme }) => ({
  overflow: "hidden",
  cursor: "default !important",
  whiteSpace: "nowrap",
  border: "none",
  textOverflow: "ellipsis",
  paddingRight: "0.4rem",
  "&.MuiTableCell-body": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "& a": {
      textDecoration: theme.table.link.textDecoration,
      color: theme.table.link.color,
    },
  },
  "&.MuiTableCell-head": {
    fontWeight: theme.table.head.fontWeight,
    fontSize: theme.table.head.fontSize,
    position: "relative",
  },
  "&.MuiTableCell-footer": {
    fontWeight: theme.table.head.fontWeight,
    color: "black !important",
    fontSize: theme.table.head.fontSize,
  },
}));

interface TableCellHeaderProps extends PropsWithChildren<BoxProps> {
  align?: TableCellProps["align"];
}

const alignToJustifyContentMap: Record<
  NonNullable<TableCellProps["align"]>,
  React.CSSProperties["justifyContent"]
> = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  justify: "normal",
  inherit: "inherit",
};

const TableCellHeaderStyled = styled(BoxBase)(
  ({ align }: TableCellHeaderProps) => ({
    display: "flex",
    justifyContent: align ? alignToJustifyContentMap.center : "normal",
    "& span": {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      whiteSpace: "normal",
      width: "auto !important",
      minWidth: "auto !important",
    },
  }),
);

const TableCellHeader = ({
  children,
  ...rest
}: TableCellHeaderProps): JSX.Element => {
  return <TableCellHeaderStyled {...rest}>{children}</TableCellHeaderStyled>;
};

const TableSortLabel = styled(TableSortLabelBase)(({ theme }) => ({
  color: `${theme.table.icon.default.color} !important`,
  "& .MuiSvgIcon-root": {
    cursor: "pointer",
    fontSize: theme.spacing(3),
    padding: "0.1rem",
  },
  icon: {
    fontSize: theme.spacing(3),
    cursor: "pointer",
    color: `${theme.palette.primary.main} !important`,
  },
}));

const ExpanderIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1.8),
}));

const VisibilityIcon = styled(Checkbox)(() => ({
  paddingTop: "0px",
  zIndex: 0,
}));

export default {
  Container,
  ExpansionContainer,
  TableContainer,
  Table,
  TableHead,
  TableFooter,
  TableRow,
  TableCell,
  TableBody,
  TableCellHeader,
  TableSortLabel,
  ExpanderIconButton,
  Grid,
  Box,
  LeftPaneBox,
  TopPaneBox,
  RightPaneBox,
  useTableStyles,
  Resizer,
  VisibilityIcon,
};
