/* eslint-disable @typescript-eslint/ban-ts-comment */
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import React from "react";
import { CLIENT_NAME } from "graphql/client";
import { ColumnDef } from "@tanstack/react-table";
import useExportData from "exports/useExportData";

// Utils
import {
  createDateFromISO,
  getMonth,
  getYear,
  subtractYear,
} from "utils/internationalization";
import { range } from "utils/math";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

import { useAppErrorHandler } from "errors/app.errors";

// GraphQL
import {
  Query_RootRtcs_Db_Ph_Nwsc_Rpt_TransactionsArgs,
  Order_By,
  useMinMaxUraTransactionsQuery,
  useNwscTransactionsLazyQuery,
  usePropertyUtilitiesQuery,
} from "graphql/hasura/types-and-hooks";

import TableRangeActions, {
  DateRangeParams,
} from "components/table-range-actions/table-range-actions.component";

import {
  utilitiesTableColumns,
  UtilitiesTableSchema,
} from "pages/properties-tab/utilities-tab/utilities-tab.schema";

import SC from "./nwsc-transactions.styles";

export const MONTHS = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

interface NwscTransactionsProps {
  reference: string;
  exportToExcel?: boolean;
}

enum Utility {
  PAY = "Pay",
  CONS = "Cons",
  TRANS = "Trans",
}

type UtilityTransaction = {
  month: string;
  year: string;
  pay: string;
  cons: string;
  trans: string;
  period: string;
};

const NwscTransactions: React.FC<NwscTransactionsProps> = ({
  reference,
  exportToExcel,
}) => {
  const columns = React.useMemo(() => utilitiesTableColumns, []);
  const [dateParams, setDateParams] = React.useState<DateRangeParams>();

  const queryVariablesMinMaxTransaction: Query_RootRtcs_Db_Ph_Nwsc_Rpt_TransactionsArgs =
    {
      where: { Customerreference: { _eq: reference } },
    };

  const {
    data: maxMin,
    error: minMaxError,
    loading: minMaxLoading,
  } = useMinMaxUraTransactionsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !reference,
    variables: {
      aggregateParams: jsonToStringQueryVariables(
        queryVariablesMinMaxTransaction ?? {},
      ),
    },
  });

  const [
    getTransactions,
    {
      loading: transactionsLoading,
      data: transactionsData,
      error: transactionsError,
    },
  ] = useNwscTransactionsLazyQuery({
    context: { clientName: CLIENT_NAME.HASURA },
  });

  const arrayAux = maxMin?.rtcs_db_Fnnwsc_RPT_Transactions_aggregatetxt;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapped = arrayAux?.map((item: any) => ({
    [item.operation]: item.value,
  }));

  const newObj = mapped ? Object.assign({}, ...mapped) : null;

  const maxPeriod = newObj?.['max("Period")'];

  const minPeriod = newObj?.['min("Period")'];

  const queryVariablesUtilities: Query_RootRtcs_Db_Ph_Nwsc_Rpt_TransactionsArgs =
    {
      where: {
        _and: [
          {
            Period: {
              _gte: subtractYear(maxPeriod ?? new Date(), 2),
            },
          },
          { Customerreference: { _eq: reference } },
        ],
      },
      // The next two lines was added to igoner the error market by vscode because orderBy is workig properly
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      orderBy: { Period: Order_By.Desc },
    };

  const { data, error, loading } = usePropertyUtilitiesQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !reference,
    variables: {
      params: jsonToStringQueryVariables(queryVariablesUtilities ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData("nwsc_RPT_Transactions");

  useAppErrorHandler(error || transactionsError || minMaxError || exportError);

  const utilities = React.useMemo(() => {
    const utilitiesData: Record<
      string,
      Record<string, UtilityTransaction>
    > = {};

    let temp = data?.rtcs_db_Fnnwsc_RPT_Transactions;

    if (transactionsData) {
      temp = transactionsData?.rtcs_db_Fnnwsc_RPT_Transactions;
    }

    temp?.forEach(({ Period, Type, Value }) => {
      const year = getYear(Period);
      const month = getMonth(Period);

      if (!utilitiesData[year]) {
        utilitiesData[year] = {};
      }
      if (!utilitiesData[year][month]) {
        // @ts-ignore
        utilitiesData[year][month] = {};
        utilitiesData[year][month].period = Period;
      }

      switch (Type) {
        case Utility.PAY:
          utilitiesData[year][month].pay = Value;
          break;
        case Utility.TRANS:
          utilitiesData[year][month].trans = Value;
          break;
        case Utility.CONS:
          utilitiesData[year][month].cons = Value;
          break;
        default:
          break;
      }
    });

    const transformedData: UtilityTransaction[] = [];

    Object.keys(utilitiesData)
      .sort()
      .reverse()
      .forEach((year) => {
        MONTHS.slice(1)
          .reverse()
          .forEach((month) => {
            const utilityTransaction = utilitiesData[year][month];

            if (utilityTransaction) {
              const { pay, cons, trans, period } = utilityTransaction;

              transformedData.push({
                month: `${month} ${year}`,
                year,
                pay,
                cons,
                trans,
                period,
              });
            }
          });
      });

    return transformedData;
  }, [data, transactionsData]);

  React.useEffect(() => {
    if (transactionsError) return;
    const startDate = createDateFromISO(
      utilities[utilities.length - 1]?.period,
    );
    const endDate = createDateFromISO(utilities[0]?.period);

    setDateParams({ startDate, endDate });
  }, [utilities, transactionsError]);

  const handlefilterDate = (params: DateRangeParams) => {
    setDateParams(params);
    const queryVariablesTransactions = {
      where: {
        _and: [
          {
            _and: [
              { Period: { _gte: createDateFromISO(params.startDate) } },
              { Period: { _lte: createDateFromISO(params.endDate) } },
            ],
          },
          { Customerreference: { _eq: reference } },
        ],
      },
      // The next two lines was added to igoner the error market by vscode because orderBy is workig properly
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      orderBy: { Period: Order_By.Desc },
    };
    getTransactions({
      variables: {
        params: jsonToStringQueryVariables(queryVariablesTransactions ?? {}),
      },
    });
  };

  const rangeOfYears = range(
    parseInt(getYear(maxPeriod), 10),
    parseInt(getYear(minPeriod), 10),
    -1,
  );

  return (
    <>
      <ProgressIndicator
        open={transactionsLoading || loading || minMaxLoading || exportLoading}
      />
      <TableRangeActions
        dateRangeParams={dateParams}
        onFilter={handlefilterDate}
        rangeOfYears={rangeOfYears}
      />
      <SC.Table
        stickyHeader
        isPaginationHidden
        actionsOnRight={exportToExcel ? ["export-to-excel-sheet/csv"] : []}
        onAction={() => {}}
        initialRowsPerPage={10000}
        columns={columns}
        // @ts-ignore
        data={utilities}
        exportData={(columnz: ColumnDef<UtilitiesTableSchema>[]) =>
          exportDataFn(columnz, undefined, utilities)
        }
      />
    </>
  );
};

export default NwscTransactions;
