/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box as BoxBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const Box = styled(BoxBase)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const PasswordContainer = styled(BoxBase)(({ theme }) => ({
  marginTop: theme.spacing(4),
  width: "14rem",
}));

export default {
  Box,
  PasswordContainer,
};
