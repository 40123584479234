// Dependencies
import { Box, Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";

const HasuraPoc = styled(Box)({
  minHeight: "100VH",
});

export default {
  HasuraPoc,
  Snackbar,
};
