/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal as ModalBase, Backdrop, Fade as Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const Modal = styled(ModalBase)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Fade = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: theme.lightbox.fade.border,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}));

export default {
  Modal,
  Backdrop,
  Fade,
};
