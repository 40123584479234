/* eslint-disable @typescript-eslint/no-explicit-any */
import { Paper, Grid, Container as ContainerBase } from "@mui/material";

import { styled } from "@mui/material/styles";

import PropertyDetailsBase from "components/property-details/property-details.component";
import ImageList from "components/image-list/image-list.component";

const Container = styled(ContainerBase)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: "0px",
  overflowY: "scroll",
  height: "100%",
}));

const PropertyDetails = styled(PropertyDetailsBase)(() => ({}));

export default {
  Paper,
  Grid,
  PropertyDetails,
  ImageList,
  Container,
};
