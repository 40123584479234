import React from "react";
import { TableColumn } from "components/table/table.component";
import {
  OwnersAndOfficersFragment,
  NonIndividualsAssociatedIndividualsFragment,
  IndividualsAssociatedBusinessFragment,
} from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";
import { isValidTin } from "graphql/hasura/rtcs.utils";

export const associatedBusinessescolumns: TableColumn<NonIndividualsAssociatedIndividualsFragment>[] =
  [
    {
      header: "TIN No.",
      accessorKey: "TinNo",
      align: "left",
    },
    {
      header: "Associate TIN",
      accessorKey: "AssociateTin",
      align: "left",
      cell: ({
        getValue,
        row: {
          original: { AssociateTin },
        },
      }): React.ReactNode => {
        const value = getValue() as string;
        return isValidTin(AssociateTin as string) ? (
          <Link
            to={`/non-individuals/${AssociateTin}/identification`}
            color="inherit"
          >
            {value}
          </Link>
        ) : (
          <>{AssociateTin}</>
        );
      },
    },
    {
      header: "Associated Business",
      accessorKey: "AssociatedNonIndividual",
      align: "left",
    },
    {
      header: "Main Activity  ",
      accessorKey: "MainActivity",
      align: "left",
    },

    {
      header: "Activity Description",
      accessorKey: "ActvtyDesc",
      align: "left",
    },
  ];

export const associatedIndividualscolumns: TableColumn<IndividualsAssociatedBusinessFragment>[] =
  [
    {
      header: "TIN No.",
      accessorKey: "TinNo",
      align: "left",
      cell: ({
        getValue,
        row: {
          original: { TinNo },
        },
      }): React.ReactNode => {
        return isValidTin(TinNo as string) ? (
          <Link to={`/individuals/${TinNo}/identification`}>
            <>{getValue()}</>
          </Link>
        ) : (
          <>{TinNo}</>
        );
      },
      meta: { sort: false },
    },
    {
      header: "First Name",
      accessorKey: "FirstName",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Surname",
      accessorKey: "Surname",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Other Name",
      accessorKey: "OtherName",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Family Name",
      accessorKey: "FamilyName",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Business TIN",
      accessorKey: "AssociateTin",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Entity Relationship",
      accessorKey: "EntityDesignation",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Main Activity",
      accessorKey: "MainActivity",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Activity Description",
      accessorKey: "ActvtyDesc",
      align: "left",
      meta: { sort: false },
    },
  ];

export const columns: TableColumn<OwnersAndOfficersFragment>[] = [
  {
    header: "Individual TIN No.",
    accessorKey: "Individualtin",
    align: "left",
    meta: { sort: false },
    cell: ({
      getValue,
      row: {
        original: { Individualtin },
      },
    }): React.ReactNode => {
      return isValidTin(Individualtin as string) ? (
        <Link
          to={`/individuals/${Individualtin}/identification`}
          color="inherit"
        >
          <>{getValue()}</>
        </Link>
      ) : (
        <>{Individualtin}</>
      );
    },
  },
  {
    header: "Name",
    accessorKey: "Name",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Business TIN No.",
    accessorKey: "Tinno",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Business Name",
    accessorKey: "Taxpayername",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Designation",
    accessorKey: "Designation",
    align: "center",
    meta: { sort: false },
  },
  {
    header: "Percent Owned",
    accessorKey: "Pershare",
    align: "right",
    meta: { sort: false },
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Return No.",
    accessorKey: "Rtnno",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Tax Year",
    accessorKey: "Rtnperiodyear",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Officers Index No.",
    accessorKey: "Rtnholdingofficersdtlid",
    align: "left",
    meta: { sort: false },
    hiddenColumn: true,
  },
  {
    header: "Owners Index No.",
    accessorKey: "Rtnholdingownersdtlid",
    align: "left",
    meta: { sort: false },
    hiddenColumn: true,
  },
];

export default {
  columns,
};
