/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Paper as PaperBase,
  Typography as TypographyBase,
  List as ListBase,
  ListItem as ListItemBase,
  ListItemText as ListItemTextBase,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import PropertyDetailsItemBase from "components/property-details-item/property-details-item.component";

const Paper = styled(PaperBase)(({ theme }) => ({
  textAlign: "left",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),

  "& .MuiGrid-container:not(:last-child)": {
    borderBottom: theme.propertyDetails.item.marginBottom,
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Typography = styled(TypographyBase)(({ theme }) => ({
  fontSize: theme.propertyDetails.title.fontSize,
  fontWeight: theme.propertyDetails.title.fontWeight,
  marginBottom: theme.spacing(1),
}));

const List = ListBase;
const ListItem = ListItemBase;
const ListItemText = ListItemTextBase;

const PropertyDetailsItem = styled(PropertyDetailsItemBase)(() => ({
  padding: "1rem",
}));

export default {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  PropertyDetailsItem,
};
