import { TableColumn } from "components/table/table.component";
import {
  AggregateCustomerSecondaryOwnerFragment,
  PropertiesValuationFragment,
} from "graphql/hasura/types-and-hooks";

export type AggregatePrimaryOwnerTableSchema = Omit<
  AggregateCustomerSecondaryOwnerFragment,
  "__typename"
>;

export const ResidentialSubSFHColumns: TableColumn<
  PropertiesValuationFragment | Record<string, unknown>
>[] = [
  {
    header: "Village",
    accessorKey: "Village_x",
    align: "left",
    format: "string",
  },
  {
    header: "No. of Bedrooms",
    accessorKey: "DerivedNoOfBedrooms",
    align: "center",
    format: "string",
  },
  {
    header: "Monthly Gazette Value",
    accessorKey: "MonthlyGazetteValue",
    align: "right",
    format: "currency",
  },
  {
    header: "Annual Gazette Value",
    accessorKey: "AnnualGazetteValue",
    align: "right",
    format: "currency",
  },
];

type SupportedType = Record<string, unknown> | PropertiesValuationFragment;

export const CommercialColumns: TableColumn<SupportedType>[] = [
  {
    header: "Village",
    accessorKey: "Village_x",
    align: "left",
    format: "string",
  },
  {
    header: "Building Area (m2)",
    accessorKey: "SquareMeters",
    align: "center",
    format: "string",
  },
  {
    header: "Monthly Charge per Square Meter (UGX)",
    accessorKey: "MonthlyChargeSquare" as keyof SupportedType,
    align: "right",
    format: "currency",
  },
  {
    header: "Monthly Charge (UGX)",
    accessorKey: "MonthlyCharge" as keyof SupportedType,
    align: "right",
    format: "currency",
  },
  {
    header: "Annual Charge (UGX)",
    accessorKey: "AnnualCharge" as keyof SupportedType,
    align: "right",
    format: "currency",
  },
];
