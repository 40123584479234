/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useMemo } from "react";
import { CLIENT_NAME } from "graphql/client";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  jsonToStringQueryVariables,
  aggregateArrayToObject,
} from "graphql/hasura/rtcs.utils";

import {
  Query_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs,
  Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column,
  Order_By,
  useIndividualReportsFilterQuery,
  useGetPaginatedIndividualReportsQuery,
  useGetMaxMinIndividualReportsQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// Schema
import { reportTableColumns, getAccessorType } from "./individuals.schema";

// Assets
import SC from "./individuals.styles";

const ReportType = { Compliancecategory: { _eq: "NO MATCH" } };

const TABLE_NAME = "rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk";

export const IndividualPage = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs | undefined
  >({
    where: { ...ReportType },
    limit: 0,
  });
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginatedIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables?.limit,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  const {
    data: maxMinData,
    loading: maxMinLoading,
    error: maxMinError,
  } = useGetMaxMinIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      params: jsonToStringQueryVariables({ where: { ...ReportType } }),
    },
  });

  useAppErrorHandler(error || maxMinError || exportError);

  const columns = useMemo(() => reportTableColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.header}`,
        value: `${column.accessorKey}`,
        type: `${getAccessorType(column.accessorKey)}`,
      };
    });
  }, [columns]);

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.RiskScore,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    [],
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm[0]
      .value ?? "-1",
    10,
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [handlePaginatedFetchData, loading, totalRowsCount],
  );

  const { refetch: ReportFilterFn } = useIndividualReportsFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs =
            {
              where: {
                [column]: { _ilike: `${value}%` },
                _and: [ReportType],
              },
              distinct_on: [
                column as Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column,
              ],
              limit: 10,
            };
          const individualReportFilterData = await ReportFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            individualReportFilterData.data.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column],
            );
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [ReportFilterFn],
  );

  const newObj = aggregateArrayToObject(
    maxMinData?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm,
  );

  const minTotalRentedProperties = newObj?.['min("Numrentedproperties")'];

  const maxTotalRentedProperties = newObj?.['max("Numrentedproperties")'];

  const minSumadjustedincome = newObj?.['min("Sumadjustedincome")'];

  const maxSumadjustedincome = newObj?.['max("Sumadjustedincome")'];

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "First Name",
        columnName:
          Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.Aggregatefirstname,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Surname",
        columnName:
          Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.Aggregatesurname,
        fetchSuggestions,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Total Number of Rented Properties",
        min: minTotalRentedProperties,
        max: maxTotalRentedProperties,
        columnName:
          Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.Numrentedproperties,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Adjusted Gross Rental Income",
        min: minSumadjustedincome,
        max: maxSumadjustedincome,
        columnName:
          Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column.Sumadjustedincome,
      },
    ];
  }, [
    fetchSuggestions,
    maxSumadjustedincome,
    maxTotalRentedProperties,
    minSumadjustedincome,
    minTotalRentedProperties,
  ]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: [...conditions, ReportType],
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    [],
  );

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }

    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: {
        ...condition,
      },
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <SC.Box>
        <Table
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "filter-results",
            "export-to-excel-sheet/csv",
          ]}
          title="Unregistered Landlords"
          columns={columns}
          data={data?.rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk ?? []}
          // eslint-disable-next-line no-alert
          onAction={() => alert("under construction")}
          leftPanel={
            <TableFilter
              filterInputs={filterInputs}
              onFilterApply={onTableFilterApply}
              universalFilterColumns={universalColumns}
              defaultUniversalFilterConditions={[ReportType]}
              onUniversalFilterSubmit={onUniversalFilterer}
            />
          }
          persistenceId="b235a481-8793-4dfe-aa4c-c79119007413"
          paginationControlled={paginationControlled}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          pageIndexResetSignal={pageIndexResetSignal}
          stickyHeader
          exportData={exportDataFn}
        />
      </SC.Box>
      <ProgressIndicator open={loading || maxMinLoading || exportLoading} />
    </SC.Box>
  );
};

export default IndividualPage;
