/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const BannerGraphic = styled(Card)(({ theme }) => ({
  minWidth: "fit-content",
  boxShadow: "none",
  backgroundColor: "transparent",
  "& button": {
    display: "flex",
    height: "100%",
    pointerEvents: "none",
    flexDirection: "column",
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.bannerGraphicSmall.paddingTop,
      paddingRight: theme.bannerGraphicSmall.paddingRight,
      paddingBottom: theme.bannerGraphicSmall.paddingBottom,
      paddingLeft: theme.bannerGraphicSmall.paddingLeft,
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: theme.bannerGraphic.paddingTop,
      paddingRight: theme.bannerGraphic.paddingRight,
      paddingBottom: theme.bannerGraphic.paddingBottom,
      paddingLeft: theme.bannerGraphic.paddingLeft,
    },
  },
}));

const Logo = styled(CardMedia)({
  width: "32%",
  height: "4rem",
});

const TextLogoContent = styled(CardContent)({
  padding: "0px",
});

const Text = styled(Typography)(({ theme }) => ({
  marginBottom: "0px",
  fontWeight: theme.bannerGraphicText.fontWeight,
  color: theme.bannerGraphicText.color,
  [theme.breakpoints.down("xl")]: {
    fontSize: theme.bannerGraphicTextSmall.fontSize,
    lineHeight: theme.bannerGraphicTextSmall.lineHeight,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: theme.bannerGraphicText.fontSize,
    lineHeight: theme.bannerGraphicText.lineHeight,
  },
}));

export default {
  BannerGraphic,
  Logo,
  TextLogoContent,
  Text,
};
