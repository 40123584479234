import { Box } from "@mui/material";
import Cytoscape from "cytoscape";
import _ from "lodash";

// Types
import { MdaName, SchemaName, RMatchField } from "./graph-chart.utils";

const MDA_NAME: Record<MdaName, string> = {
  rmatch: "RNID",
  ura: "URA",
  kcca: "KCCA",
  nira: "NIRA",
  nwsc: "NWSC",
};

const SCHEMA_NAME: Record<SchemaName, string> = {
  RMatchIndividual: "",
  RegIndividual: "Individual",
  RMatchNonIndividual: "",
  RegNonIndividual: "Non-Individual",
  Customer: "Customer",
  Property: "Property",
  Nira: "Nira",
  CustomerReference: "CustomerReference",
  CustomerProperty: "CustomerProperty",
};

const NODE_COLOR: Record<SchemaName, string> = {
  RMatchIndividual: "#000000",
  RegIndividual: "#385723", // "#30A216",
  RMatchNonIndividual: "#000000",
  RegNonIndividual: "#7E5F00",
  Customer: "#4473C4", // "#025A9D",
  Property: "#8FAADC", // "#4681AF",
  Nira: "#AFAF3B", // "#AFAF3B",
  CustomerReference: "#4473C4", // "#025A9D",
  CustomerProperty: "#4473C4", // "#025A9D",
};

const RMATCH_FIELDS_ORDER: RMatchField[] = [
  "Firstname",
  "Surname",
  "Middlename",
  "Name",
  "EntityLegalName",
  "Email",
  "Mobile",
  "Phone",
  "CustomerId",
  "Nin",
  "Tin",
  "Dob",
  "PropertyNo",
  "Longitude",
  "Latitude",
];

const createNodeLabel = (node: Cytoscape.NodeSingular): string => {
  const mdaName = MDA_NAME[node.data("MdaName") as MdaName] ?? "";
  const schemaName = SCHEMA_NAME[node.data("SchemaName") as SchemaName] ?? "";

  return `${mdaName}\n${schemaName}\n\n${node.data("name")}`;
};

const stringMatchWithField = (str: string, field: RMatchField) => {
  // str ends with field or field1 or field2?
  const re = new RegExp(`${field}[12]?$`);
  return re.test(str);
};

const getNodeBackgroundColor = (node: Cytoscape.NodeSingular): string =>
  NODE_COLOR[node.data("SchemaName") as SchemaName] ?? "";

const createEdgeLabel = (edge: Cytoscape.EdgeSingular): string => {
  const matches = edge.data("matches") as Record<string, string>;

  if (_.isEmpty(matches)) return "";

  const labels = RMATCH_FIELDS_ORDER.reduce<string[]>((labelList, field) => {
    const matchedFields = Object.keys(matches).filter(
      (match) =>
        stringMatchWithField(match, field) && !_.isEmpty(`${matches[match]}`)
    );

    if (matchedFields.length === 0) return labelList;

    let fieldLabel = "";
    matchedFields.forEach((matchedField) => {
      fieldLabel += `${fieldLabel !== "" ? "/" : ""}${matches[matchedField]}`;
    });

    if (labelList.includes(fieldLabel)) return labelList;

    return [...labelList, fieldLabel];
  }, []);

  return labels.join("\n");
};

const Stylesheet: Cytoscape.Stylesheet[] = [
  {
    selector: "node",
    style: {
      width: "100%",
      // height: "auto",
      shape: "round-rectangle",
      // "padding-top": ".75em",
      // "padding-bottom": ".75em",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      padding: "1em",
      backgroundColor: getNodeBackgroundColor,
      // "border-width": 2,
      // "border-color": getNodeBackgroundColor,
      label: createNodeLabel,
      color: "white",
      "text-wrap": "wrap",
      "text-justification": "center",
      "font-size": ".8em",
      "text-halign": "center",
      "text-valign": "center",
      // "text-margin-x": 0,
    },
  },
  {
    selector: "edge",
    style: {
      width: 2,
      "line-color": "#698FD7", // "#025A9D",
      label: createEdgeLabel,
      color: "#548235", // "#30A216",
      "font-size": ".8em",
      "text-wrap": "wrap",
      "text-max-width": "1000px",
      "text-overflow-wrap": "whitespace",
      "text-justification": "auto",
      "text-margin-x": 50,
      "text-margin-y": 0,
    },
  },
];

const Container = Box;

export default {
  Stylesheet,
  Container,
};

export type { SchemaName };
