/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import { TenantIncomeDetailFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";

export type TenantIncomeDetailTableSchema = Omit<
  TenantIncomeDetailFragment,
  "__typename"
>;

export const TENANT_INCOME_DETAIL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof TenantIncomeDetailTableSchema,
  "string" | "number" | "Date"
> = {
  RtnRentPaidId: "string",
  RtnNo: "string",
  TenantTin: "number",
  TenantTaxPayerName: "string",
  LandlordTin: "string",
  LandlordName: "string",
  PremiseLocation: "string",
  RmatchScore: "number",
  TenancyPeriodFrom: "Date",
  TenancyPeriodTo: "Date",
  RentPaid: "number",
  TaxYear: "string",
  LandlordPeriodFrom: "Date",
  LandlordPeriodTo: "Date",
  Contribution: "number",
  ContributionAmount: "number",
};

export const getAccessorType = (
  accessor:
    | keyof TenantIncomeDetailTableSchema
    | AccessorFn<TenantIncomeDetailTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;
  return TENANT_INCOME_DETAIL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const TenantIncomeDetailColumns: TableColumn<TenantIncomeDetailTableSchema>[] =
  [
    {
      header: "Return ID No.",
      accessorKey: "RtnRentPaidId",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      header: "Tenant Return No.",
      accessorKey: "RtnNo",
      align: "center",
      format: "string",
    },
    {
      header: "Tenant TIN",
      accessorKey: "TenantTin",
      align: "center",
      format: "string",
    },
    {
      header: "Tenant Taxpayer Name",
      accessorKey: "TenantTaxPayerName",
      align: "left",
      format: "string",
    },
    {
      header: "Landlord TIN",
      accessorKey: "LandlordTin",
      align: "center",
      format: "string",
    },
    {
      header: "Landlord Name",
      accessorKey: "LandlordName",
      align: "left",
      format: "string",
    },
    {
      header: "Premises Location",
      accessorKey: "PremiseLocation",
      align: "center",
      format: "string",
    },
    {
      header: "rMATCH Score",
      accessorKey: "RmatchScore",
      align: "center",
      format: "string",
    },
    {
      header: "Tenancy Period From",
      accessorKey: "TenancyPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      header: "Tenancy Period To",
      accessorKey: "TenancyPeriodTo",
      align: "center",
      format: "date",
    },
    {
      header: "Rent Reported",
      accessorKey: "RentPaid",
      align: "right",
      format: "currency",
    },
    {
      header: "Landlord Tax Year",
      accessorKey: "TaxYear",
      align: "center",
      format: "string",
    },
    {
      header: "Landlord Return Period From",
      accessorKey: "LandlordPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      header: "landlord Return Period To",
      accessorKey: "LandlordPeriodTo",
      align: "center",
      format: "date",
    },
    {
      header: "Tenant Rent Contribution %",
      accessorKey: "Contribution",
      align: "center",
      format: "percent",
    },
    {
      header: "Tenant Rent Contribution Amount",
      accessorKey: "ContributionAmount",
      align: "right",
      format: "currency",
    },
  ];
