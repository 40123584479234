/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TaxHistoryAccordion from "components/tax-history-accordion/tax-history-accordion";
import NestedList from "components/nested-list/nested-list.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

const SectionSubHeaderTypography = styled(Typography)(({ theme }) => ({
  paddingTop: "2.28rem",
  paddingBottom: "0.28rem",
  fontSize: theme.taxHistory.financialSummary.subHeader.fontSize,
  fontWeight: theme.taxHistory.financialSummary.subHeader.fontWeight,
  lineHeight: "1.71rem",
  "& .MuiTypography-gutterBottom": {
    marginBottom: "0px",
  },
}));

export default {
  SectionSubHeaderTypography,
  TaxHistoryAccordion,
  NestedList,
  ProgressIndicator,
};
