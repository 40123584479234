/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { OwnersAndOfficersFragment } from "graphql/hasura/types-and-hooks";
import { isValidTin } from "graphql/hasura/rtcs.utils";

export type OfficersAndOwnersSchema = Omit<
  OwnersAndOfficersFragment,
  "__typename"
>;

export interface OfficersAndOwnersRegistrationTableFilters {
  businessName: string;
  firstName: string;
  surname: string;
}

export const OfficersAndOwnersSchemaMap: Record<
  keyof OfficersAndOwnersSchema,
  "string" | "number"
> = {
  Individualtin: "string",
  Name: "string",
  Tinno: "string",
  Taxpayername: "string",
  Designation: "string",
  Pershare: "number",
  Address: "string",
  Rtnno: "string",
  Rtnperiodyear: "string",
  Rtnholdingofficersdtlid: "string",
  Rtnholdingownersdtlid: "string",
  Rnid: "string",
};
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof OfficersAndOwnersRegistrationTableFilters,
  string
> = {
  businessName: "Taxpayername",
  firstName: "Name",
  surname: "Name",
};

export const getAccessorType = (
  accessor:
    | keyof OfficersAndOwnersSchema
    | AccessorFn<OfficersAndOwnersSchema[keyof OfficersAndOwnersSchema]>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return OfficersAndOwnersSchemaMap[accessor];
};

export const OfficersAndOwndersTableColumns: TableColumn<OfficersAndOwnersSchema>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Individual TIN No.",
      accessorKey: "Individualtin",
      align: "left",
      meta: { sort: false },
      cell: ({ getValue }): React.ReactNode => {
        const value = getValue();
        return isValidTin(`${value}`) ? (
          <Link to={`/individuals/${value}/identification`} color="inherit">
            <>{value}</>
          </Link>
        ) : (
          <>{value}</>
        );
      },
    },
    {
      header: "Name",
      accessorKey: "Name",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Business TIN No.",
      accessorKey: "Tinno",
      align: "left",
      meta: { sort: false },
      cell: ({ getValue }): React.ReactNode => {
        const Tinno = getValue();
        return isValidTin(`${Tinno}`) ? (
          <Link to={`/non-individuals/${Tinno}/identification`}>
            <>{Tinno}</>
          </Link>
        ) : (
          <>{Tinno}</>
        );
      },
    },
    {
      header: "Business Name",
      accessorKey: "Taxpayername",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Designation",
      accessorKey: "Designation",
      align: "center",
      meta: { sort: false },
    },
    {
      header: "Percent Owned",
      accessorKey: "Pershare",
      align: "right",
      meta: { sort: false },
    },
    {
      header: "Address",
      accessorKey: "Address",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Return No.",
      accessorKey: "Rtnno",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Tax Year",
      accessorKey: "Rtnperiodyear",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Officers Index No.",
      accessorKey: "Rtnholdingofficersdtlid",
      align: "left",
      meta: { sort: false },
      hiddenColumn: true,
    },
    {
      header: "Owners Index No.",
      accessorKey: "Rtnholdingownersdtlid",
      align: "left",
      meta: { sort: false },
      hiddenColumn: true,
    },
  ];
