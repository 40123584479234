/* eslint-disable no-alert */
// Dependencies
import React, { useCallback, useEffect, useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Order_By,
  Query_RootRtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs,
  Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column,
  useGetPaginatedSubscriberAndPropertyQuery,
  useSubscriberAndPropertyFilterQuery,
} from "graphql/hasura/types-and-hooks";

import Alert from "components/alert/alert.component";

import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";

import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// Utils
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import { ColumnDef } from "@tanstack/react-table";
import {
  DEFAULT_FILTER_ACCESSOR,
  getAccessorType,
  SubscriberAndPropertyTableFilters,
  TableColumns,
} from "./subscriber-and-property.schema";

// Assets
import SC from "./subscriber-and-property.style";

interface SubscriberAndPropertyTableProps {
  defaultFilters?: SubscriberAndPropertyTableFilters;
  initialRowsPerPage?: number;
  displayHeader?: boolean;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  onLoaded?: () => void;
  title?: string;
}

const TABLE_NAME = "rtcs_db_Fnumeme_SubscriberAndProperty";

export const SubscriberAndPropertyTable: React.FC<SubscriberAndPropertyTableProps> =
  React.memo(
    ({
      defaultFilters,
      initialRowsPerPage = INITIAL_ROWS_PER_PAGE.REPORTS,
      displayHeader,
      maxHeight,
      noDataComponent,
      onLoaded,
      title = "Subscriber and Property",
    }) => {
      const defaultFilterConditions = useMemo(() => {
        const { firstName, phoneNumber } = defaultFilters ?? {
          firstName: "",
          phoneNumber: "",
        };

        return getConditionsFromDefaultFilters(
          {
            ...(defaultFilters ?? {}),
            firstName: firstName === "" ? "" : `%${firstName}`,
            phoneNumber: phoneNumber === "" ? "" : `%${phoneNumber}`,
          },
          DEFAULT_FILTER_ACCESSOR,
        );
      }, [defaultFilters]);

      const hasUnhandledDefaultFilter = useMemo(
        () =>
          getHasUnhandledDefaultFilter(
            defaultFilters ?? {},
            DEFAULT_FILTER_ACCESSOR,
          ),
        [defaultFilters],
      );

      const [filterConditions, setFilterConditions] = React.useState<
        FilterCondition[]
      >([] as FilterCondition[]);

      const [queryVariables, setQueryVariables] = React.useState<
        Query_RootRtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs | undefined
      >({
        where: mergeWithDefaultConditions(
          defaultFilterConditions,
          filterConditions,
        ),
      });

      const [pageIndexResetSignal, setPageIndexResetSignal] =
        React.useState(false);

      const [infoMessage, setInfoMessage] = React.useState<string | undefined>(
        "",
      );

      const { data, loading, error } =
        useGetPaginatedSubscriberAndPropertyQuery({
          context: { clientName: CLIENT_NAME.HASURA },
          skip: !queryVariables || hasUnhandledDefaultFilter,
          variables: {
            params: jsonToStringQueryVariables(queryVariables ?? {}),
            aggregateParams: jsonToStringQueryVariables({
              where: queryVariables?.where,
            }),
          },
        });

      const [exportDataFn, { loading: exportLoading, error: exportError }] =
        useExportData(TABLE_NAME, queryVariables);

      //! implement the no data error
      useEffect(() => {
        if (data && data.rtcs_db_Fnumeme_SubscriberAndProperty.length === 0) {
          setInfoMessage("Filter Query Returned No Data");
          return;
        }
        setInfoMessage(undefined);
      }, [data]);

      useAppErrorHandler(error || exportError);

      const dataExport = useCallback(
        (cols: ColumnDef<any, any>[]) => {
          return exportDataFn(cols.filter((col) => col.id !== "expander"));
        },
        [exportDataFn],
      );

      const sapData = React.useMemo(() => {
        const sap = data?.rtcs_db_Fnumeme_SubscriberAndProperty ?? [];
        return sap;
      }, [data]);

      const columns = useMemo(() => TableColumns, []);

      const universalColumns: ColumnsProps[] = useMemo(() => {
        return columns.map((column, i) => {
          return {
            index: i,
            label: `${column.header}`,
            value: `${column.accessorKey}`,
            type: `${getAccessorType(column.accessorKey)}`,
          };
        });
      }, [columns]);

      const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
        const columnObject = condition[Object.keys(condition)[0]];
        if (columnObject[Object.keys(columnObject)[0]] === "") {
          // eslint-disable-next-line no-param-reassign
          condition = {};
        }
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: condition,
        }));
        setPageIndexResetSignal((previousSignal) => !previousSignal);
      };

      const handlePaginatedFetchData: TableFetchDataFunction =
        React.useCallback(({ pageIndex, pageSize, sortBy }) => {
          const defaultSortColumn = {
            id: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column.MeterNo,
            desc: true,
          };
          const sortByColumn =
            sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
          setQueryVariables((oldVariables) => ({
            ...oldVariables,
            orderBy: {
              [sortByColumn.id]: sortByColumn.desc
                ? Order_By.Desc
                : Order_By.Asc,
            },
            limit: pageSize,
            offset: pageIndex * pageSize,
          }));
        }, []);

      const totalRowsCount = parseInt(
        data?.rtcs_db_Fnumeme_SubscriberAndProperty_aggregatecm[0].value ??
          "-1",
        10,
      );

      const paginationControlled = React.useMemo(
        () => ({
          fetchData: handlePaginatedFetchData,
          loading,
          totalRowsCount,
        }),
        [totalRowsCount, loading, handlePaginatedFetchData],
      );

      // TODO: Replace for useLazyQuery when the function has been updated
      // to return a Promise instead of void.
      // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
      const { refetch: umemeFilterFn } = useSubscriberAndPropertyFilterQuery({
        skip: true,
        context: {
          clientName: CLIENT_NAME.HASURA,
        },
      });

      const fetchSuggestions = React.useCallback(
        async (value: string, column: string) => {
          try {
            let suggestions: string[] = [];

            if (value !== "") {
              const filterVariables: Query_RootRtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs =
                {
                  where: { [column]: { _ilike: `${value}%` } },
                  distinct_on: [
                    column as Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column,
                  ],
                  limit: 10,
                };
              const customerData = await umemeFilterFn({
                params: jsonToStringQueryVariables(filterVariables),
              });
              suggestions =
                customerData.data?.rtcs_db_Fnumeme_SubscriberAndProperty.map(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (option) => option[column],
                );
            }

            // eslint-disable-next-line @typescript-eslint/return-await
            return Promise.resolve((suggestions ?? []) as string[]);
          } catch (err) {
            return Promise.reject(err);
          }
        },
        [umemeFilterFn],
      );

      const filterInputs: TableFilterInput[] = React.useMemo(() => {
        return [
          {
            type: TableFilterType.AUTOFILL,
            label: "Customer Name",
            columnName:
              Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column.CustomerName,
            fetchSuggestions,
          },
          {
            type: TableFilterType.AUTOFILL,
            label: "Telephone",
            columnName:
              Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column.Telephone,
            fetchSuggestions,
          },
        ];
      }, [fetchSuggestions]);

      const onTableFilterApply = React.useCallback(
        (conditions: FilterCondition[]) => {
          setFilterConditions(conditions);
          setPageIndexResetSignal((previousSignal) => !previousSignal);
        },
        [],
      );

      useEffect(() => {
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        }));
      }, [defaultFilterConditions, filterConditions]);

      useEffect(() => {
        if (!loading) onLoaded?.();
      }, [loading, onLoaded]);

      return (
        <SC.Container pl={2} pr={2} pt={2}>
          <Table
            actionsOnRight={[
              "hide/show-columns",
              "filter-results",
              "export-to-excel-sheet/csv",
            ]}
            columns={columns}
            data={sapData}
            onAction={() => alert("under construction")}
            title={title}
            headerPanel={
              displayHeader ? (
                <SC.Title>MDA - Subscriber and Property</SC.Title>
              ) : undefined
            }
            leftPanel={
              <TableFilter
                filterInputs={filterInputs}
                onFilterApply={onTableFilterApply}
                universalFilterColumns={universalColumns}
                onUniversalFilterSubmit={onUniversalFilterer}
              />
            }
            persistenceId="0f8d3f59-a86b-458e-af12-99e78d6304b2"
            paginationControlled={paginationControlled}
            stickyHeader
            initialRowsPerPage={initialRowsPerPage}
            pageIndexResetSignal={pageIndexResetSignal}
            exportData={dataExport}
            maxHeight={maxHeight}
            noDataComponent={noDataComponent}
          />
          {infoMessage && <Alert message={infoMessage} />}
          <ProgressIndicator open={loading || exportLoading} />
        </SC.Container>
      );
    },
  );

const SubscriberAndProperty: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <SubscriberAndPropertyTable displayHeader />
    </SC.Box>
  );
};

export default SubscriberAndProperty;
