/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";

// Styles
import SC from "./visibility.styles";

export const VisibilityPage = () => {
  return <SC.Box></SC.Box>;
};

export default VisibilityPage;
