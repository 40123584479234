/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { TextField as TextFieldBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const TextField = styled(TextFieldBase)(({ theme }) => ({
  lineHeight: "1",
  "& label.Mui-focused:not(.Mui-error)": {
    color: theme.textField.labelTextColor,
  },
  "& .MuiFilledInput-root": {
    backgroundColor: theme.textField.input.backgroundColor,
    "& .MuiInputBase-input": {
      color: theme.textField.input.textColor,
    },
  },
  "& .MuiFilledInput-root.Mui-disabled": {
    backgroundColor: theme.textField.input.disabledBackgroundColor,
  },
  "&:hover .MuiFilledInput-root": {
    backgroundColor: theme.textField.input.hoverBackgroundColor,
  },
  "&:hover .MuiFilledInput-underline:before": {
    borderColor: "transparent !important",
  },
  "& .MuiFilledInput-underline": {
    "&:before": {
      borderColor: "transparent",
    },
    "&.Mui-disabled:before": {
      borderColor: theme.textField.inputUnderline.disabledBorderColor,
    },
    "&:after": {
      borderColor: theme.textField.inputUnderline.borderColor,
    },
    "&.Mui-error:after": {
      borderColor: theme.textField.inputUnderline.errorBorderColor,
    },
  },
  "& .MuiFormHelperText-root.Mui-focused:not(.Mui-error)": {
    color: theme.textField.helperTextColor,
  },
}));

export default {
  TextField,
};
