/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  CardContent,
  TextField as TextFieldBase,
  Box,
  Button,
  Typography,
} from "@mui/material";
import NoteToolbar from "../note-toolbar/note-toolbar.component";
import { styled } from "@mui/material/styles";

const NoteForm = styled(Card)(() => ({
  marginBottom: "1.14rem",
}));

const NoteFormRow = styled(Box)(() => ({
  paddingTop: "1.14rem",
  display: "flex",
  alignItems: "center",
}));

const NoteFormContent = styled(CardContent)(() => ({
  paddingTop: "1.14rem",
  paddingBottom: "1.14rem",
  paddingRight: "2.28rem",
  paddingLeft: "2.28rem",
  "&:last-child": {
    paddingBottom: "1.14rem",
  },
}));

const FileAttachmentContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  marginTop: "1.14rem",
  "& > *": {
    marginRight: "1.14rem",
  },
  "& input": {
    position: "absolute",
    top: 0,
    zIndex: "-1",
  },
  "& input:focus + label": {
    outline: "2px solid",
  },
  "& label": {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& > h6": {
      fontSize: theme.notes.body.fontSize,
      marginRight: "1.14rem",
    },
  },
}));

const PostButton = styled(Button)(() => ({
  marginLeft: "0px",
  marginRight: "1.14rem",
}));

const CancelButton = styled(Button)(() => ({
  marginLeft: "0px",
}));

const TextField = styled(TextFieldBase)(() => ({
  margin: "0px",
  marginRight: "1.14rem",
  flexGrow: 1,
  "& MuiInputBase-input": {
    backgroundColor: "green",
    maxHeight: "13.21rem",
  },
}));

const Attachment = styled(Typography)(({ theme }) => ({
  marginRight: "1.14rem",
  fontSize: "0.75rem",
  color: theme.palette.text.disabled,
}));

export default {
  TextField,
  NoteForm,
  NoteToolbar,
  NoteFormContent,
  NoteFormRow,
  FileAttachmentContainer,
  PostButton,
  CancelButton,
  Typography,
  Attachment,
};
