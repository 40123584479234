/* eslint-disable @typescript-eslint/ban-ts-comment */
// Dependencies
import React from "react";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import ExpandedMoreIcon from "@mui/icons-material/ExpandMore";

// Component
import FilterListItem from "components/filter-list-item/filter-list-item.component";

// Assets
import SC from "./filter-list.styles";

export interface FilterValue {
  [itemKey: string]: boolean;
}

interface InitParams<T extends Record<string, unknown>> {
  items: T[];
  getItemKey: (item: T) => string;
  defaultState: boolean;
}

const init = <T extends Record<string, unknown>>({
  items,
  getItemKey,
  defaultState,
}: InitParams<T>): FilterValue => {
  const fValue: FilterValue = {};

  items.forEach((item) => {
    const key = getItemKey(item);
    fValue[key] = defaultState;
  });

  return fValue;
};

enum FilterActionKind {
  Reset = "RESET",
  Change = "CHANGE",
}

interface FilterActionReset<T extends Record<string, unknown>> {
  type: FilterActionKind.Reset;
  payload: InitParams<T>;
}

interface FilterActionChange {
  type: FilterActionKind.Change;
  payload: string;
}

const reducer = <T extends Record<string, unknown>>(
  state: FilterValue,
  action: FilterActionReset<T> | FilterActionChange,
): FilterValue => {
  switch (action.type) {
    case FilterActionKind.Reset:
      return init({ ...action.payload });

    case FilterActionKind.Change:
      return { ...state, [action.payload]: !state[action.payload] };

    default:
      return state;
  }
};

export interface FilterListProps<T extends Record<string, unknown>> {
  label?: string;
  items?: T[];
  inactiveByDefault?: boolean;
  // value?: FilterValue;
  getItemKey: (item: T) => string;
  getItemLabel: (item: T) => string;
  onChange: (value: FilterValue) => void;
}

export const FilterList = <T extends Record<string, unknown>>({
  label = "",
  items = [],
  inactiveByDefault = false,
  getItemKey,
  getItemLabel,
  onChange,
}: FilterListProps<T>): JSX.Element => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { items, getItemKey, defaultState: !inactiveByDefault },
    init,
  );

  React.useEffect(() => {
    if (items.length > 0) {
      dispatch({
        type: FilterActionKind.Reset,
        // @ts-ignore
        payload: { items, getItemKey, defaultState: !inactiveByDefault },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, inactiveByDefault]);

  React.useEffect(() => {
    onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handlerActionsOnClick:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { id } = event.currentTarget;
      event.stopPropagation();

      switch (id) {
        case "selectAll":
          dispatch({
            type: FilterActionKind.Reset,
            // @ts-ignore
            payload: { items, getItemKey, defaultState: true },
          });
          break;
        case "clearAll":
          dispatch({
            type: FilterActionKind.Reset,
            // @ts-ignore
            payload: { items, getItemKey, defaultState: false },
          });
          break;

        default:
          break;
      }
    },
    [getItemKey, items],
  );

  const handlerActionsOnFocus: React.FocusEventHandler<HTMLButtonElement> =
    React.useCallback((event) => {
      event.stopPropagation();
    }, []);

  const handlerListItemOnClick: React.MouseEventHandler<HTMLDivElement> =
    React.useCallback((event) => {
      dispatch({
        type: FilterActionKind.Change,
        payload: event.currentTarget.id,
      });
    }, []);

  return (
    <SC.Accordion>
      <SC.AccordionSummary expandIcon={<ExpandedMoreIcon />}>
        <SC.Label>{label}</SC.Label>
        <SC.IconButton
          id="selectAll"
          aria-label="Select All"
          title="Select All"
          onClick={handlerActionsOnClick}
          onFocus={handlerActionsOnFocus}
        >
          <LibraryAddCheckIcon />
        </SC.IconButton>
        <SC.IconButton
          id="clearAll"
          aria-label="Clear All"
          title="Clear All"
          onClick={handlerActionsOnClick}
          onFocus={handlerActionsOnFocus}
        >
          <FilterNoneIcon />
        </SC.IconButton>
      </SC.AccordionSummary>
      <SC.AccordionDetails>
        <SC.List dense>
          {items?.map((item) => {
            const key = getItemKey(item);

            return (
              <FilterListItem
                key={key}
                name={key}
                label={getItemLabel(item)}
                checked={!!state[key]}
                onClick={handlerListItemOnClick}
              />
            );
          })}
        </SC.List>
      </SC.AccordionDetails>
    </SC.Accordion>
  );
};

export default FilterList;
