/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { UraNonIndividualReportFragment } from "graphql/hasura/types-and-hooks";

export type NonIndividualTableSchema = Omit<
  UraNonIndividualReportFragment,
  "__typename"
>;
export interface NonIndividualRegistrationTableFilters {
  businessName: string;
  email: string;
  phoneNumber: string;
}

export const NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof NonIndividualTableSchema,
  "string" | "number" | "Date"
> = {
  ActvtyDesc: "string",
  AppliedForTin: "string",
  AuthctcdEmailId: "string",
  AuthctcdFamilyName: "string",
  AuthctcdFirstName: "string",
  AuthctcdLandlineNumber: "string",
  AuthctcdMobileNumber: "string",
  AuthctcdOtherName: "string",
  AuthctcdPrsnDesgn: "string",
  AuthctcdSurname: "string",
  BsnsaddrBuildname: "string",
  BsnsaddrDistrictName: "string",
  BsnsaddrLocalCouncil: "string",
  BsnsaddrParishName: "string",
  BsnsaddrPlotNo: "string",
  BsnsaddrStreetAddr: "string",
  BsnsaddrSubCountyName: "string",
  BsnsaddrTownName: "string",
  BsnsaddrTradeCentre: "string",
  BsnsaddrVillageName: "string",
  BsnsCertNumber: "string",
  BsnsName: "string",
  BusinessStartDt: "Date",
  EmailId: "string",
  ExistTinNo: "string",
  HoldingCompName: "string",
  HoldingCompTin: "string",
  IsHldCmpInUganda: "string",
  IsIncomeSrcBsns: "string",
  IsIncomeSrcRental: "string",
  IsIncomeSrcPrpty: "string",
  IsSubsidaryComp: "string",
  LandlineNumber: "string",
  MainActivity: "string",
  MobileNumber: "string",
  OrgSubType: "string",
  OrgSubTypeDesc: "string",
  OrgType: "string",
  PostaddrDistictName: "string",
  PostaddrId: "string",
  RefEmailId: "string",
  RefFamilyName: "string",
  RefFirstName: "string",
  RefLandlineNumber: "string",
  RefMiddleName: "string",
  RefMobileNumber: "string",
  RefOtherName: "string",
  RefPrsnDesgn: "string",
  RefSurname: "string",
  RefTin: "string",
  Rnid: "string",
  TaxPayerName: "string",
  TaxPayerType: "string",
  TinNo: "string",
  TradingName: "string",
};
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof NonIndividualRegistrationTableFilters,
  string
> = {
  businessName: "BsnsName",
  email: "EmailId",
  phoneNumber: "MobileNumber",
};

export const getAccessorType = (
  accessor:
    | keyof NonIndividualTableSchema
    | AccessorFn<NonIndividualTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const nonIndividualTableColumns: TableColumn<NonIndividualTableSchema>[] =
  [
    {
      header: "TIN No.",
      accessorKey: "TinNo",
      align: "left",
      cell: ({
        getValue,
        row: {
          original: { TinNo },
        },
      }) => {
        const value = getValue();
        return (
          <Link to={`/non-individuals/${TinNo}/identification`} color="inherit">
            <>{value}</>
          </Link>
        );
      },
    },
    {
      header: "Entity's Legal Name",
      accessorKey: "TaxPayerName",
      align: "left",
      format: "string",
    },
    {
      header: "Main Trading/Business Name (if different)",
      accessorKey: "TradingName",
      align: "center",
      format: "string",
    },
    {
      header: "Business Address - County/Municipality",
      accessorKey: "BsnsaddrTownName",
      align: "center",
      format: "string",
    },
    {
      header: "Business Address - Parish",
      accessorKey: "BsnsaddrParishName",
      align: "center",
      format: "string",
    },
    {
      header: "Business Address - Mobile Phone Number",
      accessorKey: "MobileNumber",
      align: "center",
      format: "string",
    },
    {
      header: "Business Address - Landline Number",
      accessorKey: "LandlineNumber",
      align: "center",
      format: "string",
    },
    {
      header: "Business Address - Email Address",
      accessorKey: "EmailId",
      align: "center",
      format: "string",
    },
    {
      header: "Entity Type",
      accessorKey: "OrgType",
      align: "center",
      format: "string",
    },
    {
      header: "Entity SubType",
      accessorKey: "OrgSubType",
      align: "center",
      format: "string",
    },
    {
      header: "Entity Subtype Description",
      accessorKey: "OrgSubTypeDesc",
      align: "center",
      format: "string",
    },
    {
      header: "Select Income Source - Rental ",
      accessorKey: "IsIncomeSrcRental",
      align: "center",
      format: "string",
    },
    {
      header: "Select Income Source - Business",
      accessorKey: "IsIncomeSrcBsns",
      align: "center",
      format: "string",
    },
    {
      header: "Select Income Source - Property",
      accessorKey: "IsIncomeSrcPrpty",
      align: "center",
      format: "string",
    },
    {
      header: "Select Income Source - Major Business Activity",
      accessorKey: "MainActivity",
      align: "center",
      format: "string",
    },
    {
      header: "Select Income Source - Best Match  of Business Activity",
      accessorKey: "ActvtyDesc",
      align: "center",
      format: "string",
    },
    {
      header: "Previously Issued a TIN",
      accessorKey: "AppliedForTin",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Authenticator - Email",
      accessorKey: "AuthctcdEmailId",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Persons Associated with Entity - Father Surname",
      accessorKey: "AuthctcdFamilyName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Authenticator - First Name",
      accessorKey: "AuthctcdFirstName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Authenticator - Land Line Number",
      accessorKey: "AuthctcdLandlineNumber",
      align: "right",
      hiddenColumn: true,
      format: "number",
    },
    {
      header: "Authenticator - Mobile Phone Numberee",
      accessorKey: "AuthctcdMobileNumber",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Authenticator - Acquired Name",
      accessorKey: "AuthctcdOtherName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Authenticator - Position Held",
      accessorKey: "AuthctcdPrsnDesgn",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Authenticator - Surname/Maiden Name",
      accessorKey: "AuthctcdSurname",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Address - Building Name",
      accessorKey: "BsnsaddrBuildname",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Address -District/City",
      accessorKey: "BsnsaddrDistrictName",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Address - Local Councile",
      accessorKey: "BsnsaddrLocalCouncil",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Address - Plot Number",
      accessorKey: "BsnsaddrPlotNo",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Address - Street Name",
      accessorKey: "BsnsaddrStreetAddr",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Address - Sub-County/Town Council/Division",
      accessorKey: "BsnsaddrSubCountyName",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Adddress- Trade Center",
      accessorKey: "BsnsaddrTradeCentre",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Address - Village",
      accessorKey: "BsnsaddrVillageName",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Registrattion Number",
      accessorKey: "BsnsCertNumber",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Business Name",
      accessorKey: "BsnsName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Date Entity Established",
      accessorKey: "BusinessStartDt",
      align: "center",
      hiddenColumn: true,
      format: "date",
    },

    {
      header: "If Yes, Previous TIN",
      accessorKey: "ExistTinNo",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Holding Company Registered Company Name",
      accessorKey: "HoldingCompName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Holding Company TIN",
      accessorKey: "HoldingCompTin",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Principlee Holding Company in Uganda",
      accessorKey: "IsHldCmpInUganda",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Registering a Subsidiary",
      accessorKey: "IsSubsidaryComp",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "District/City where box is located",
      accessorKey: "PostaddrDistictName",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Post Office Box Number/Private Bag",
      accessorKey: "PostaddrId",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Referee Contact - email",
      accessorKey: "RefEmailId",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Referee Contact - Family/Father's Surname",
      accessorKey: "RefFamilyName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Referee Contact - First Name",
      accessorKey: "RefFirstName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Referee Contact - Land Line Number",
      accessorKey: "RefLandlineNumber",
      align: "right",
      hiddenColumn: true,
      format: "number",
    },
    {
      header: "Referee Contact - Middle Name",
      accessorKey: "RefMiddleName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Referee Contact - Mobile Phone Numberee",
      accessorKey: "RefMobileNumber",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Referee Contact - Acquired Name",
      accessorKey: "RefOtherName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Referee Contact - Designation/Relationship",
      accessorKey: "RefPrsnDesgn",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Referee Contact - Surname/Maiden Name",
      accessorKey: "RefSurname",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Referee Contact - Existing TIN",
      accessorKey: "RefTin",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Tax Payer Type",
      accessorKey: "TaxPayerType",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
  ];
