/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Container as ContainerBase,
  Box,
  Typography,
  IconButton as IconButtonBase,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Note from "components/note/note.component";
import NoteCreate from "components/note-create/note-create.component";

const Container = styled(ContainerBase)(({ theme }) => ({
  backgroundColor: theme.pane.backgroundColor,
  textAlign: theme.notes.tab.textAlign,
  padding: theme.notes.tab.padding,
}));

const TitleActionsContainer = styled(Box)(({ theme }) => ({
  display: theme.notes.toolbarAction.display,
  alignItems: theme.notes.toolbarAction.alignItems,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.notes.title.fontWeight,
  fontSize: theme.notes.title.fontSize,
  marginRight: theme.spacing(1),
}));

const IconButton = styled(IconButtonBase)(({ theme }) => ({
  padding: theme.spacing(1),
  "&:not(.Mui-disabled) .MuiSvgIcon-root": {
    color: theme.table.toolbarActions.iconColor,
  },
}));

export default {
  Container,
  TitleActionsContainer,
  Title,
  IconButton,
  Add,
  Note,
  NoteCreate,
  ProgressIndicator,
};
