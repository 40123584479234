/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from "react";
import { Snackbar, Box, SnackbarCloseReason } from "@mui/material";
import { Reference, StoreObject } from "@apollo/client";
import { isEqual } from "lodash";

// Components
import { TableColumn } from "components/table/table.component";
import { Alert, AlertProps } from "components/alert/alert.component";
import UserForm from "components/user-form/user-form.container";
import { UserFilteringLeftPane } from "components/user-filtering-left-pane/user-filtering-left-pane.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { ActionButton } from "components/action-button/action-button.component";
import {
  useAppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { UserFormState } from "components/user-form/user-form.component";

// GraphQL
import {
  UserData,
  GroupData,
  useGetAllUsersQuery,
  useGetGroupQuery,
  useCreateUserMutation,
  useAddUserGroupMutation,
  useUpdateUserDataMutation,
  useUpdateUserOrganisationMutation,
  useUpdateUserDeparmentMutation,
  useUpdateUserGroupMutation,
  useDeleteUserMutation,
  useSendEmailVerificationMutation,
  Maybe,
  Job,
  DagsterPipelineRunStatus,
} from "graphql/types-and-hooks";
import { createEmptyUserData, NEW_USER_FRAGMENT_GQL } from "graphql/rbac.utils";

// Utils
import useJobStatus from "jobs/useJobStatus";
import {
  useAppErrorHandler,
  isServerError,
  UIError,
  UIErrorCodes,
} from "errors/app.errors";

// Assets
import { Delete as DeleteIcon } from "@mui/icons-material";
// import EditIcon from "@mui/icons-material/Edit";
import SC from "./user-management.styles";

const UserManagementPage: React.FC = () => {
  const initialUserDataEmpty = createEmptyUserData();
  const [initialUserData, setInitialUserData] =
    React.useState(initialUserDataEmpty);
  const [openLeftPane, setOpenLeftPane] = React.useState(false);
  const [openAddUserForm, setOpenAddUserForm] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [saveActive, setSaveActive] = React.useState(false);

  const [createUser, { loading: createUserLoading }] = useCreateUserMutation({
    update(cache, { data }) {
      const newUser = data?.AddUser[0];
      if (newUser) {
        cache.modify({
          fields: {
            GetUser(existingUsers = []) {
              const newUserRef = cache.writeFragment({
                id: cache.identify(newUser),
                data: newUser,
                fragment: NEW_USER_FRAGMENT_GQL,
              });
              return [...existingUsers, newUserRef];
            },
          },
        });
      }
    },
  });

  const [addUserGroup, { loading: addUserGroupLoading }] =
    useAddUserGroupMutation();
  const [updateUserData, { loading: updateUserDataLoading }] =
    useUpdateUserDataMutation();
  const [updateUserDepartment, { loading: updateUserDepartmentLoading }] =
    useUpdateUserDeparmentMutation();
  const [updateUserOrganisation, { loading: updateUserOrganisationLoading }] =
    useUpdateUserOrganisationMutation();
  const [updateUserGroup, { loading: updateUserGroupLoading }] =
    useUpdateUserGroupMutation();

  const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserMutation({
    update(cache, { data }) {
      const userToDeleteId = data?.DeleteUser;
      cache.modify({
        fields: {
          GetUser(existingUsers, { readField }) {
            return existingUsers.filter(
              (user: Reference | StoreObject | undefined) =>
                userToDeleteId !== readField("id", user),
            );
          },
        },
      });
    },
  });

  const {
    data: usersData,
    loading: usersDataLoading,
    error: usersDataError,
  } = useGetAllUsersQuery({});

  const {
    data: groupsData,
    loading: groupsDataLoading,
    error: groupsDataError,
  } = useGetGroupQuery();

  const [sendEmailVerification, { error: emailVerificationError }] =
    useSendEmailVerificationMutation();

  const [runUpdateUsersJob, { jobStatus, jobRunning }] = useJobStatus(
    Job.UpdateUsers,
  );

  const errorHandler = useAppErrorHandler(
    usersDataError || groupsDataError || emailVerificationError,
  );

  const users = React.useMemo(
    () => (usersData?.GetUser as UserData[]) ?? [],
    [usersData],
  );
  const [filteredUsers, setFilteredUsers] = React.useState<UserData[]>();

  const loading =
    createUserLoading ||
    addUserGroupLoading ||
    updateUserDataLoading ||
    updateUserDepartmentLoading ||
    updateUserOrganisationLoading ||
    updateUserGroupLoading ||
    deleteUserLoading ||
    usersDataLoading ||
    groupsDataLoading ||
    jobRunning ||
    jobStatus === DagsterPipelineRunStatus.Starting ||
    jobStatus === DagsterPipelineRunStatus.Started;

  const handlerCreateUserFormOnSubmit = React.useCallback(
    async (userData: UserFormState) => {
      try {
        const groups = userData.groups?.map((group) => group?.id);

        const response = await createUser({
          variables: {
            email: userData.email ?? "",
            firstName: userData.firstName ?? "",
            lastName: userData.lastName ?? "",
            active: userData.active ?? false,
            profilePicture: userData.profilePicture ?? "",
            mobilePhone: userData?.mobilePhone ?? "",
            workAddress: userData?.work?.address ?? "",
            workPhone: userData.work?.phone ?? "",
            password: userData.password ?? "",
          },
        });
        const userId = response.data?.AddUser[0]?.id ?? "";

        await updateUserOrganisation({
          variables: {
            userID: userId ?? "",
            organisationID: userData?.organisation?.id,
          },
        });

        await updateUserDepartment({
          variables: {
            userID: userId ?? "",
            departmentID: userData?.department?.id,
          },
        });

        if (groups?.length !== 0) {
          await addUserGroup({
            variables: {
              userID: userId ?? "",
              groupID: groups as Maybe<string>[],
            },
          });
        }

        await runUpdateUsersJob();

        await sendEmailVerification({
          variables: {
            userEmail: userData.email,
          },
        });

        setOpenAddUserForm(false);
        setSnackBarMessage({
          message: "The user has been created successfully",
          severity: "success",
        });
      } catch (error) {
        if (isServerError(error as Error)) {
          errorHandler(error as Error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the user",
            ),
          );
        }
      }
    },
    [
      createUser,
      updateUserOrganisation,
      updateUserDepartment,
      sendEmailVerification,
      addUserGroup,
      errorHandler,
      runUpdateUsersJob,
    ],
  );

  const handlerEditUserFormOnSubmit = React.useCallback(
    async (userData: UserFormState) => {
      try {
        const userId = userData.id ?? "";
        const OriginalUserData = users.find((user) => user.id === userId);
        const initialCompanyId = OriginalUserData?.organisation?.id ?? "";
        const initialDepartmentId = OriginalUserData?.department?.id ?? "";
        const initialGroups = OriginalUserData?.groups?.map(
          (group) => group?.id,
        );
        const groupsSelected = userData.groups?.map((group) => group?.id);

        await updateUserData({
          variables: {
            id: userData?.id ?? "",
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            profilePicture: userData?.profilePicture,
            email: userData?.email,
            active: userData?.active,
            mobilePhone: userData?.mobilePhone,
            workAddress: userData?.work?.address,
            workPhone: userData?.work?.phone,
            password: userData.password,
          },
        });

        if (initialCompanyId !== userData?.organisation?.id)
          await updateUserOrganisation({
            variables: {
              userID: userData?.id ?? "",
              organisationID: userData?.organisation?.id,
            },
          });

        if (initialDepartmentId !== userData?.department?.id)
          await updateUserDepartment({
            variables: {
              userID: userData?.id ?? "",
              departmentID: userData?.department?.id,
            },
          });

        if (!isEqual(initialGroups, groupsSelected))
          await updateUserGroup({
            variables: {
              userID: userData?.id ?? "",
              groupID: initialGroups as string[],
              groupIDNew: groupsSelected as string[],
            },
          });

        await runUpdateUsersJob();

        setSnackBarMessage({
          message: "The profile has been updated successfully",
          severity: "success",
        });
      } catch (error) {
        if (isServerError(error as Error)) {
          errorHandler(error as Error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while updating the profile",
            ),
          );
        }
      }
    },
    [
      errorHandler,
      updateUserData,
      updateUserDepartment,
      updateUserGroup,
      updateUserOrganisation,
      runUpdateUsersJob,
      users,
    ],
  );

  const handlerDeleteUser = React.useCallback(
    async (userId: Maybe<string> | undefined) => {
      try {
        const OriginalUserData = users.find((user) => user.id === userId);

        await deleteUser({
          variables: {
            userID: OriginalUserData?.id ?? "",
          },
        });

        await runUpdateUsersJob();

        setSnackBarMessage({
          message: "The user has been deleted successfully",
          severity: "success",
        });
      } catch (error) {
        if (isServerError(error as Error)) {
          errorHandler(error as Error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while deleting the user",
            ),
          );
        }
      }
    },
    [deleteUser, errorHandler, runUpdateUsersJob, users],
  );

  const handleTableActions = (action: TableToolbarAction) => {
    if (action === "filter-results") {
      if (openLeftPane) setOpenLeftPane(false);
      else setOpenLeftPane(true);
    }

    if (action === "add") {
      if (openAddUserForm) setOpenAddUserForm(false);
      else {
        setOpenAddUserForm(true);
        setSaveActive(false);
        setInitialUserData(initialUserDataEmpty);
      }
    }
  };

  const handlerResendVerificationEmail = React.useCallback(
    async (userEmail: string) => {
      try {
        await sendEmailVerification({
          variables: {
            userEmail,
          },
        });
        setSnackBarMessage({
          message: "The email verification has been sent successfully",
          severity: "success",
        });
      } catch (error) {
        if (isServerError(error as Error)) {
          errorHandler(error as Error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has occurred while sending the email verification",
            ),
          );
        }
      }
    },
    [errorHandler, sendEmailVerification],
  );

  const handleCloseSnack = React.useCallback(
    (
      event?: React.SyntheticEvent<any, Event> | Event,
      reason?: SnackbarCloseReason,
    ) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    [],
  );

  const handleCancelAddUser = () => {
    setSaveActive(false);
    setOpenAddUserForm(false);
  };

  const columns: TableColumn<UserData>[] = React.useMemo(
    () => [
      {
        header: "First Name",
        accessorKey: "firstName",
        align: "left",
        size: 200,
      },
      {
        header: "Last Name",
        accessorKey: "lastName",
        align: "left",
        size: 150,
      },
      {
        header: "Organisation",
        accessorFn: (user) => user.organisation?.name,
        align: "left",
        size: 150,
      },
      {
        header: "Email",
        accessorKey: "email",
        align: "left",
        size: 250,
      },
      {
        header: "Groups",
        accessorFn: (user) =>
          user.groups?.map((group, i, arr) => {
            if (arr.length - 1 === i) {
              return group?.name;
            }
            return `${group?.name}, `;
          }),
        align: "left",
        size: 200,
      },
      {
        header: "Active",
        accessorKey: "active",
        cell: ({ getValue }) => <SC.Checkbox checked={Boolean(getValue())} />,
        align: "center",
        size: 40,
      },
      {
        id: "actions",
        header: "Actions",
        accessorKey: "id",
        cell: ({ getValue }) => (
          <>
            <ActionButton
              key="Delete"
              title="Delete"
              icon={<DeleteIcon />}
              handler={() => handlerDeleteUser(`${getValue()}`)}
              displayPopover
              popoverMessage="Are you sure you want to delete this item??
You won't be able to recover them"
              popoverButtons
            />
          </>
        ),
        align: "center",
        size: 40,
      },
    ],
    [handlerDeleteUser],
  );

  const appPermissionValidator = useAppPermissionValidator();
  const addUserForm = openAddUserForm ? (
    <UserForm
      initialUserData={initialUserData}
      initialEditable
      saveActive={saveActive}
      isProfile={false}
      isCreatingUser
      onSubmit={handlerCreateUserFormOnSubmit}
      onCancel={handleCancelAddUser}
      onClickResendEmail={() => {}}
    />
  ) : undefined;

  return (
    <Box>
      <Breadcrumb isSimple />
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>

      <ProgressIndicator open={loading} />

      <SC.UserTable
        title="Users"
        persistenceId="798b733f-ef60-40de-876e-d85e039165b9"
        data={filteredUsers ?? users}
        columns={columns}
        onAction={handleTableActions}
        actionsOnLeft={
          appPermissionValidator?.(APP_PERMISSION.ADMIN_ADDEDIT_USERS)
            ? ["add"]
            : undefined
        }
        actionsOnRight={["filter-results"]}
        leftPanel={
          <UserFilteringLeftPane
            users={users}
            groups={groupsData?.GetGroup as GroupData[]}
            onFilter={setFilteredUsers}
          />
        }
        topPanel={addUserForm}
        renderExpandedRowSubComponent={(row) => (
          <UserForm
            initialUserData={row.original}
            initialEditable={false}
            onSubmit={handlerEditUserFormOnSubmit}
            onCancel={() => null}
            onClickResendEmail={handlerResendVerificationEmail}
          />
        )}
      />
    </Box>
  );
};

export default UserManagementPage;
