import {
  Card as CardBase,
  CardContent,
  Typography,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Components
import Alert from "components/alert/alert.component";

const Card = styled(CardBase)(() => ({
  width: "50%",
  margin: "auto",
}));

const Message = styled(Typography)(() => ({}));

export default {
  Card,
  CardContent,
  Message,
  Snackbar,
  Alert,
};
