/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid as GridBase,
  Box as BoxBase,
  Switch as SwitchBase,
  Container as ContainerBase,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const Grid = GridBase;

const Box = styled(BoxBase)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.switch.fontSize,
}));

const Switch = styled(SwitchBase)({
  "& .MuiSwitch-thumb": {
    color: "#2e3f50",
  },
});

const Container = styled(ContainerBase)({
  padding: "0px",
});

export default {
  Grid,
  Box,
  Switch,
  Container,
};
