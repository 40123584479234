import React from "react";

// Component
import ExpandedMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandedLessIcon from "@mui/icons-material/ExpandLess";

// Styles
import SC from "./accordion.styles";

// 

export interface AccordionProps {
  defaultExpanded?: boolean;
  displayButtonLabel?: boolean;
  header: JSX.Element;
  content: JSX.Element;
}

export const Accordion: React.FC<AccordionProps> = ({
  defaultExpanded = false,
  displayButtonLabel = false,
  header,
  content,
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  return (
    <SC.Accordion
      expanded={expanded}
      square
      elevation={0}
      onChange={(_event: React.ChangeEvent<{}>, newExpanded: boolean) =>
        setExpanded(newExpanded)
      }
    >
      <SC.AccordionSummary aria-controls="panel1c-content" id="panel1c-header">
        <SC.Grid container>
          <SC.Grid item xs={10}>
            {header}
          </SC.Grid>
          <SC.Grid
            container
            item
            xs={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            {" "}
            {expanded ? <ExpandedLessIcon /> : <ExpandedMoreIcon />}
            {displayButtonLabel ? (
              <SC.LabelButtonText variant="subtitle2">
                {expanded ? "Collapse" : "Expand"}
              </SC.LabelButtonText>
            ) : undefined}
          </SC.Grid>
        </SC.Grid>
      </SC.AccordionSummary>
      <SC.Divider />
      <SC.AccordionDetails>{content}</SC.AccordionDetails>
    </SC.Accordion>
  );
};

export default Accordion;
