/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { IndividualCompliancePlusRiskReportFragment } from "graphql/hasura/types-and-hooks";
import { ReactNode } from "react";

export type ReportTableSchema = Omit<
  IndividualCompliancePlusRiskReportFragment,
  "__typename" | "RiskCode"
>;

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Averagerateperrentedproperty: "number",
  Compliancecategory: "string",
  Countura: "string",
  Customerid: "string",
  Difference: "string",
  Emailid: "string",
  Grossrentalincome: "number",
  Isamendment: "string",
  Isincomesrcbsns: "string",
  Isincomesrcemp: "string",
  Isincomesrcprpty: "string",
  Isincomesrcrental: "string",
  Aggregatebirthdate: "number",
  Aggregateemail: "string",
  Aggregatefirstname: "string",
  Aggregatemobile1: "string",
  Aggregatemobile2: "string",
  Aggregatesurname: "string",
  Aggregatetelephone: "string",
  Aggregatetin: "string",
  Matchcategory: "string",
  Matchscore: "string",
  Matchscoreattributes: "string",
  Mobilenumber: "string",
  Nationalid: "string",
  Numproperties: "number",
  Numrentedproperties: "number",
  Percentagedifference: "number",
  Propincincome: "string",
  Rnid: "string",
  Rtnno: "string",
  Rtnperiodyear: "string",
  Source: "string",
  Sumadjustedincome: "number",
  Sumgrossvalue: "number",
  Sumnonrateablevalue: "number",
  Sumrateablevalue: "number",
  Urafirstname: "string",
  Uramiddlename: "string",
  Urasurname: "string",
  Uratinno: "string",
  RiskScore: "number",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | AccessorFn<ReportTableSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const reportTableColumns: TableColumn<ReportTableSchema>[] = [
  {
    header: "RNID",
    accessorKey: "Rnid",
    align: "left",
    cell: ({
      row: {
        original: { Rnid },
      },
    }) => {
      return (
        <Link to={`/individuals/${Rnid}/identification`} color="inherit">
          {Rnid}
        </Link>
      );
    },
  },
  {
    header: "Risk Score",
    accessorKey: "RiskScore",
    align: "center",
    meta: { sort: true },
  },
  {
    header: "Customer ID",
    accessorKey: "Customerid",
    align: "left",
  },
  {
    header: "First Name",
    accessorKey: "Aggregatefirstname",
    align: "left",
  },
  {
    header: "Surname",
    accessorKey: "Aggregatesurname",
    align: "left",
  },
  {
    header: "Mobile",
    accessorKey: "Aggregatemobile1",
    align: "left",
  },
  {
    header: "Mobile2",
    accessorKey: "Aggregatemobile2",
    align: "left",
  },
  {
    header: "Telephone",
    accessorKey: "Aggregatetelephone",
    align: "left",
  },
  {
    header: "Birth Date",
    accessorKey: "Aggregatebirthdate",
    align: "center",
    format: "date",
  },
  {
    header: "Aggregate NIN",
    accessorKey: "Nationalid",
    align: "left",
  },
  {
    header: "Email",
    accessorKey: "Aggregateemail",
    align: "left",
  },

  {
    header: "Total No. Of Properties",
    accessorKey: "Numproperties",
    align: "right",
    cell: ({
      getValue,
      row: {
        original: { Rnid },
      },
    }): ReactNode => {
      return (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          <>{getValue()}</>
        </Link>
      );
    },
  },
  {
    header: "Total No. Of Rented Properties",
    accessorKey: "Numrentedproperties",
    align: "right",
    cell: ({
      getValue,
      row: {
        original: { Rnid },
      },
    }): ReactNode => {
      return (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          <>{getValue()}</>
        </Link>
      );
    },
  },
  {
    header: "Sum Rateable Value Of Rented Properties",
    accessorKey: "Sumrateablevalue",
    align: "right",
    format: "currency",
  },
  {
    header: "Sum Adjusted Gross Rental Income",
    accessorKey: "Sumadjustedincome",
    align: "right",
    format: "currency",
  },
  {
    header: "Average Rate Per Rented Property",
    accessorKey: "Averagerateperrentedproperty",
    align: "right",
    format: "currency",
  },
];
