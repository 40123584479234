import React from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import { AggregateOwnerQuery_AggregateOwner_ElementKey } from "graphql/hasura/rtcs.types";
import {
  Query_RootRtcs_Db_Ph_Aggregate_OwnerArgs,
  useAggregateOwnerQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";
import {
  AccordionHeader,
  Header,
  Content,
} from "components/accordion-header/accordion-header.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

export interface UraIndividual {
  label: string;
  accessorKey?: AggregateOwnerQuery_AggregateOwner_ElementKey;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

export const identifiers: UraIndividual[] = [
  { label: "First Name", accessorKey: "Firstname" },
  { label: "Middle Name", accessorKey: "MiddleName" },
  { label: "Surname", accessorKey: "Surname" },
  // { label: "Family Name", accessorKey: "FamilyName" },
  { label: "Birth Date", accessorKey: "Birthdate" },
  // { label: "Birth City", accessorKey: "TownName" },
  { label: "National ID", accessorKey: "IdentificationNationalId" },
  { label: "Customer ID", accessorKey: "Id" },
  { label: "RNID", accessorKey: "Rnid" },
];
export const businessAddress: UraIndividual[] = [
  { label: "Plot No.", accessorKey: "PlotNumber" },
  { label: "Street Address", accessorKey: "StreetName" },
  { label: "Building Name", accessorKey: "BuildingName" },
  { label: "District Name", accessorKey: "ResidentialAddressDistrict" },
  // { label: "Town", accessorKey: "HomeAddrTownName" },
  { label: "County", accessorKey: "ResidentialAddressCounty" },
  { label: "Sub County", accessorKey: "ResidentialAddressSubCounty" },
  { label: "Parish", accessorKey: "ResidentialAddressParish" },
  { label: "Village", accessorKey: "ResidentialAddressVillage" },
  { label: "Trade Centre", accessorKey: "TradingCenter" },
  // { label: "Local Council", accessorKey: "HomeAddrLocalCouncil" },
];
export const contactInfo: UraIndividual[] = [
  { label: "Email Address", accessorKey: "Email" },
  { label: "Landline Phone No.", accessorKey: "Telephone" },
  { label: "Mobile No.", accessorKey: "Mobile" },
  { label: "" }, // create separator
  // { label: "Income Source Rental", accessorKey: "IsIncomeSrcRental" },
  // { label: "Income Source Business?", accessorKey: "IsIncomeSrcBsns" },
  // { label: "Income Source Property?", accessorKey: "IsIncomeSrcPrpty" },
  // { label: "Income Source Employment?", accessorKey: "IsIncomeSrcEmp" },
];

export interface IndividualTabHeaderKccaProps {
  rnid: string;
  customerIds: string;
}

export const IndividualTabHeaderKcca: React.FC<
  IndividualTabHeaderKccaProps
> = ({ rnid, customerIds }) => {
  const customerId = customerIds.split(",")?.[0];
  const queryVariablesNonIndividuals: Query_RootRtcs_Db_Ph_Aggregate_OwnerArgs =
    {
      where: { Id: { _eq: customerId } },
      limit: 1,
    };
  const { data, error, loading } = useAggregateOwnerQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      params: jsonToStringQueryVariables(queryVariablesNonIndividuals),
    },
  });

  const customer = data?.rtcs_db_Fnaggregate_Owner?.[0];

  useAppErrorHandler(error);

  if (!customer) {
    return null;
  }

  const header: Header[] = [
    {
      index: 0,
      label: `${customer.Firstname ?? ""} ${customer.Surname ?? ""}`,
      type: "bold",
    },
    { index: 1, label: "RNID", type: "bold" },
    { index: 2, label: rnid },
    { index: 3, label: "CustomerId", type: "bold" },
    { index: 4, label: `${customerId}` },
  ];

  const mapFunction = (info: UraIndividual, index: number) => {
    const { accessorKey } = info;
    let value = "";

    if (accessorKey) value = customer?.[accessorKey];

    return { ...info, index, value };
  };
  const content: Content[] = [
    {
      index: 0,
      title: "Names and Identifiers",
      data: identifiers.map(mapFunction),
    },
    {
      index: 1,
      title: "Home Address",
      data: businessAddress.map(mapFunction),
    },
    {
      index: 2,
      title: "Contact Info and Related",
      data: contactInfo.map(mapFunction),
    },
  ];

  const isLoading = loading;

  return (
    <AccordionHeader
      header={header}
      content={content}
      source="KCCA"
      isLoading={isLoading}
    />
  );
};

export default IndividualTabHeaderKcca;
