/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { NwscCustomersFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export interface WaterAndSewageTableFilters {
  phoneNumber: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof WaterAndSewageTableFilters,
  string | string[]
> = {
  phoneNumber: ["Customerphoneno1Pay", "Customerphoneno1Cons"],
};

export type NwscCustomersTableSchema = Omit<
  NwscCustomersFragment,
  "__typename"
>;

export const NWSC_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof NwscCustomersTableSchema,
  "string" | "number"
> = {
  Customerreference: "number",
  CustomernamePay: "string",
  Customerphoneno1Pay: "string",
  Customerphoneno1Cons: "string",
  AddressPay: "string",
  AreaPay: "string",
  LatitudePay: "string",
  LongitudePay: "string",
};

export const getAccessorType = (
  accessor:
    | keyof NwscCustomersTableSchema
    | AccessorFn<NwscCustomersTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NWSC_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const nwscCustomersTableColumns: TableColumn<NwscCustomersTableSchema>[] =
  [
    {
      header: "Customer Reference",
      accessorKey: "Customerreference",
      align: "center",
      meta: { sort: true },
    },
    {
      header: "Customer Name",
      accessorKey: "CustomernamePay",
      align: "center",
      format: "string",
    },
    {
      header: "Phone No. 1",
      accessorKey: "Customerphoneno1Pay",
      align: "center",
      format: "string",
    },
    {
      header: "Phone No. 2",
      accessorKey: "Customerphoneno1Cons",
      align: "center",
      format: "string",
    },
    {
      header: "Address",
      accessorKey: "AddressPay",
      align: "center",
      format: "string",
    },
    {
      header: "Area",
      accessorKey: "AreaPay",
      align: "center",
      format: "string",
    },
    {
      header: "Latitude",
      accessorKey: "LatitudePay",
      align: "center",
    },
    {
      header: "Longitude",
      accessorKey: "LongitudePay",
      align: "center",
    },
  ];
