/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { UraNonIndividualTaxReportFragment } from "graphql/hasura/types-and-hooks";

export type NonIndividualTaxTableSchema = Omit<
  UraNonIndividualTaxReportFragment,
  "__typename"
>;
export interface NonIndividualTaxTableFilters {
  businessName: string;
  email: string;
  phoneNumber: string;
}

export const NON_INDIVIDUAL_TAX_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof NonIndividualTaxTableSchema,
  "string" | "number" | "Date"
> = {
  TinNo: "string",
  RtnPeriodYear: "string",
  RtnFromDt: "string",
  RtnDt: "string",
  RtnNo: "string",
  TaxPayerName: "string",
  BsnsName: "string",
  OrgType: "string",
  IsAmendment: "string",
  BusinessIncome: "number",
  RentincIncome: "number",
  RentincTax: "number",
  RentincRate: "number",
  TotIncome: "number",
  TotTax: "number",
  GrossRentalIncome: "number",
  TotExpPurelyRental: "number",
  OperatingExp: "number",
  AdministratingExp: "number",
  FinanceExp: "number",
  ProfitLossRental: "number",
  NonAllowblExp: "number",
  AdjProfitLossRental: "number",
  AllowblApportionedCap: "number",
  OthrAllowblExp: "number",
  AdjRentlIncmLoss: "number",
  BrghtFwdAssessedLoss: "number",
  AssessedChrgblRentlIncm: "number",
  AssessedRentlLoss: "number",
  BadDebtWriteOff: "number",
  BalanceCarryForward: "string",
  BalancePrevPeriod: "string",
  BusinessRate: "number",
  CostOfSales: "number",
  BusinessTax: "number",
  Debenture: "number",
  DeferTaxLiability: "number",
  Depreciation: "number",
  EquityShare: "number",
  FactoryRentRates: "number",
  FixedDeposit: "number",
  GovtSecurities: "number",
  GrossProfit: "number",
  IsResident: "string",
  LandBuild: "string",
  OtherInvestments: "number",
  PayableAmtInc: "number",
  PayableAmtRent: "number",
  ProfitAfterDividend: "number",
  ProfitAfterTax: "number",
  ProfitBeforeTax: "number",
  ProposedDividend: "number",
  ProvisionIncomeTax: "number",
  RefundAmtInc: "number",
  RefundAmtRent: "number",
  RentRates: "number",
  Rnid: "string",
  SecureTotLoan: "string",
  SelfAssessTaxPaidInc: "number",
  SelfAssessTaxPaidRent: "number",
  SourceFund: "string",
  TotalPropFund: "string",
  TotalReserve: "string",
  TotalShareCapital: "number",
  TotAdminExpense: "number",
  TotBsnsProfSales: "number",
  TotFinancialExpense: "number",
  TotFixedAsset: "string",
  TotNonChargeableIncome: "number",
  TotOperationExpense: "number",
  TotOtherExpense: "number",
  TotRentAdminiExpns: "number",
  TotRentFinancExpns: "number",
  TotRentIncmExpns: "number",
  TotRentOprtgExpns: "number",
  TotTaxesPaidInc: "number",
  TotTaxLiability: "number",
  TotTaxesPaidRent: "number",
  TotTaxLiabilityInc: "number",
  TotTaxLiabilityRent: "number",
  TotTradeInvestment: "string",
  TotWthdTaxDeduct: "number",
  TotRate: "number",
  UnsecureTotLoan: "string",
  TradingName: "string",
  OrgSubType: "string",
  IsIncomeSrcRental: "string",
  IsIncomeSrcPrpty: "string",
  MainActivity: "string",
  ReturnType: "string",
  // RENTINC_P: "number",
  // RENTAL_TAX_P: "number",
  // INCOME_TAX_P: "number",
};
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof NonIndividualTaxTableFilters,
  string
> = {
  businessName: "BsnsName",
  email: "EmailId",
  phoneNumber: "MobileNumber",
};

export const getAccessorType = (
  accessor:
    | keyof NonIndividualTaxTableSchema
    | AccessorFn<NonIndividualTaxTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NON_INDIVIDUAL_TAX_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const nonIndividualTaxTableColumns: TableColumn<NonIndividualTaxTableSchema>[] =
  [
    {
      header: "TIN No.",
      accessorKey: "TinNo",
      align: "center",
      cell: ({
        getValue,
        row: {
          original: { TinNo },
        },
      }) => {
        const value = getValue();
        return (
          <Link to={`/non-individuals/${TinNo}/identification`} color="inherit">
            <>{value}</>
          </Link>
        );
      },
    },
    {
      header: "Year of Income",
      accessorKey: "RtnPeriodYear",
      align: "center",
      meta: { sort: true },
    },
    {
      header: "Return From Date",
      accessorKey: "RtnFromDt",
      align: "center",
      format: "date",
    },
    {
      header: "Return Date",
      accessorKey: "RtnDt",
      align: "center",
      format: "date",
      meta: { sort: true },
    },
    {
      header: "Return No.",
      accessorKey: "RtnNo",
      align: "center",
      cell: ({
        getValue,
        row: {
          original: { TinNo, RtnNo },
        },
      }) => {
        const value = getValue();
        return (
          <Link
            to={`/non-individuals/${TinNo}/tax-history/${RtnNo}`}
            color="inherit"
          >
            <>{value}</>
          </Link>
        );
      },
    },
    {
      header: "Entity's Legal Name",
      accessorKey: "TaxPayerName",
      align: "left",
      format: "string",
    },
    {
      header: "Business Name",
      accessorKey: "BsnsName",
      align: "left",
      format: "string",
    },
    {
      header: "Entity Type",
      accessorKey: "OrgType",
      align: "center",
      format: "string",
    },
    {
      header: "Amendment?",
      accessorKey: "IsAmendment",
      align: "center",
      format: "string",
    },
    {
      header: "ReturnType",
      accessorKey: "ReturnType",
    },
    {
      header: "Business and Profession Income",
      accessorKey: "BusinessIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Assessed Chargeable Rental Income",
      accessorKey: "RentincIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Assessed Chargeable Rental Income Tax",
      accessorKey: "RentincTax",
      align: "right",
      format: "currency",
    },
    {
      header: "Assessed Chargeable Rental Income Rate",
      accessorKey: "RentincRate",
      align: "right",
      format: "currency",
    },
    {
      header: "Total Income",
      accessorKey: "TotIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Total Tax",
      accessorKey: "TotTax",
      align: "right",
      format: "currency",
    },
    {
      header: "Gross Rental Income (SecF Line 4(d))",
      accessorKey: "GrossRentalIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "2d. Total Expenses purely related to Rental Income",
      accessorKey: "TotExpPurelyRental",
      align: "right",
      format: "currency",
    },
    {
      header: "2a. Operating Expenses",
      accessorKey: "OperatingExp",
      align: "right",
      format: "currency",
    },
    {
      header: "2b. Administrative Expenses",
      accessorKey: "AdministratingExp",
      align: "right",
      format: "currency",
    },
    {
      header: "2c. Financing Expenses",
      accessorKey: "FinanceExp",
      align: "right",
      format: "currency",
    },
    {
      header: "3. Profit/Loss on Account of Rental Income for Year",
      accessorKey: "ProfitLossRental",
      align: "right",
      format: "currency",
    },
    {
      header: "4. Add: Allowable Expenses/Deductions",
      accessorKey: "NonAllowblExp",
      align: "right",
      format: "currency",
    },
    {
      header: "5. Adjusted Profit or Loss on Rents",
      accessorKey: "AdjProfitLossRental",
      align: "right",
      format: "currency",
    },
    {
      header: "6. Allowable Apportioned Capital Allowance",
      accessorKey: "AllowblApportionedCap",
      align: "right",
      format: "currency",
    },
    {
      header: "7. Less: Other Allowable Expenses/Deductions",
      accessorKey: "OthrAllowblExp",
      align: "right",
      format: "currency",
    },
    {
      header: "8. Adjusted Rental Income or Loss for the Year",
      accessorKey: "AdjRentlIncmLoss",
      align: "right",
      format: "currency",
    },
    {
      header: "9. Brought forward Assessed Loss from Previous Year",
      accessorKey: "BrghtFwdAssessedLoss",
      align: "right",
      format: "currency",
    },
    {
      header: "10. Assessed Chargeable Income for Year",
      accessorKey: "AssessedChrgblRentlIncm",
      align: "right",
      format: "currency",
    },
    {
      header: "11. Assessed Rental Loss for the Year",
      accessorKey: "AssessedRentlLoss",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Operating Expenses - Bad Debts Written Off",
      accessorKey: "BadDebtWriteOff",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Balance carried to balance sheet",
      accessorKey: "BalanceCarryForward",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Balance of Profit/Loss brought from previous period",
      accessorKey: "BalancePrevPeriod",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Business and Profession Tax Rate",
      accessorKey: "BusinessRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Cost of Sales",
      accessorKey: "CostOfSales",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Business and Profession Principal Tax",
      accessorKey: "BusinessTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Debentures",
      accessorKey: "Debenture",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Deferred Tax Liability",
      accessorKey: "DeferTaxLiability",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Accumulated Depreciation/Amortization",
      accessorKey: "Depreciation",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Shares",
      accessorKey: "EquityShare",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Factory Rent",
      accessorKey: "FactoryRentRates",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Fixed Deposits",
      accessorKey: "FixedDeposit",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Government Securities",
      accessorKey: "GovtSecurities",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Grosss Profit",
      accessorKey: "GrossProfit",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Resident Status",
      accessorKey: "IsResident",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Land and Building",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Other Investmenets",
      accessorKey: "OtherInvestments",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Amount of Tax Payable on Busines Income",
      accessorKey: "PayableAmtInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Amount of Tax Payable on Rental Income",
      accessorKey: "PayableAmtRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Profit after Tax and Dividend",
      accessorKey: "ProfitAfterDividend",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Profit after Tax",
      accessorKey: "ProfitAfterTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Profit Before Tax",
      accessorKey: "ProfitBeforeTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Dividend",
      accessorKey: "ProposedDividend",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Income Tax Expense",
      accessorKey: "ProvisionIncomeTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Amount of Tax Refund on Business Income",
      accessorKey: "RefundAmtInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Amount of Tax Refund on Rental Income",
      accessorKey: "RefundAmtRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Operating Expenses - Rent",
      accessorKey: "RentRates",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Balance Sheet - Total Secured Liabilities",
      accessorKey: "SecureTotLoan",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Self Assessment on Business Income Tax",
      accessorKey: "SelfAssessTaxPaidInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Self Assessment on Rental Income Tax",
      accessorKey: "SelfAssessTaxPaidRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Equity and Long Term Liabilities",
      accessorKey: "SourceFund",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Shareholder's Fund",
      accessorKey: "TotalPropFund",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Reserve and Surplus",
      accessorKey: "TotalReserve",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Share Capital",
      accessorKey: "TotalShareCapital",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Administration Expenses",
      accessorKey: "TotAdminExpense",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Gross Income",
      accessorKey: "TotBsnsProfSales",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Financial Expenses",
      accessorKey: "TotFinancialExpense",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Fixed Assets",
      accessorKey: "TotFixedAsset",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Other Income",
      accessorKey: "TotNonChargeableIncome",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Operating Expenses",
      accessorKey: "TotOperationExpense",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Other Expenditure Expenses",
      accessorKey: "TotOtherExpense",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Rental Income Administrative Expenses Total",
      accessorKey: "TotRentAdminiExpns",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Rental Income Finance Expenses Total",
      accessorKey: "TotRentFinancExpns",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Expenses Related to Rental Income",
      accessorKey: "TotRentIncmExpns",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Operating Expenses Total",
      accessorKey: "TotRentOprtgExpns",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Taxes Paid for Business Income",
      accessorKey: "TotTaxesPaidInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Tax Paid",
      accessorKey: "TotTaxLiability",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Taxes Paid for Rental Income",
      accessorKey: "TotTaxesPaidRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Tax Liability Self-Assessed Income Tax",
      accessorKey: "TotTaxLiabilityInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Tax Liability Self-Assessed Rental Tax",
      accessorKey: "TotTaxLiabilityRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Investments",
      accessorKey: "TotTradeInvestment",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Gross Witholding Tax at Source from Income",
      accessorKey: "TotWthdTaxDeduct",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Tax Rate",
      accessorKey: "TotRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Balance Sheet - Total Unsecured Liabilities",
      accessorKey: "UnsecureTotLoan",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Main Trading/Business Name (if different)",
      accessorKey: "TradingName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Organization Sub Type",
      accessorKey: "OrgSubType",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Select Income Source - Rental",
      accessorKey: "IsIncomeSrcRental",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Select Income Source - Property",
      accessorKey: "IsIncomeSrcPrpty",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Select Income Source - Major Business Activity",
      accessorKey: "MainActivity",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
  ];
