/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
// Dependencies
import React from "react";
import { useNavigate, useParams } from "react-router";
import SC from "./styles";

class ControlledListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleElement: false,
      reRender: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { currentFeature } = props;
    const { reRender } = state;

    if (currentFeature) {
      const { scroll } = currentFeature;

      if (scroll && !reRender) {
        return {
          reRender: true,
          toggleElement: !!scroll,
        };
      }
      return null;
    }
    return null;
  }

  shouldComponentUpdate() {
    return true;
  }

  handleFirstTierOnClick = (index) => {
    const { property } = this.props;
    this.props.onSetCurrentFeature(property, index);
    this.setState({ toggleElement: false });
  };

  handleToggleElementOnClick = (toggleElement) => {
    this.setState({ toggleElement: !toggleElement });
  };

  goToProperty = (propertyId) => {
    this.props.history.push(`/properties/${propertyId}/details`);
  };

  render() {
    const { property, index, currentFeature } = this.props;
    const { toggleElement } = this.state;
    const { id, properties } = property;
    const {
      name,
      housenumber,
      plotnumber,
      village,
      parish,
      division,
      website,
      Rnid,
    } = properties;

    let activeId = null;
    if (currentFeature) {
      const { id: currentFeatureId } = currentFeature;
      activeId = currentFeatureId;
    }

    const selected = id === activeId;

    return (
      <SC.ControlledListItem
        key={id}
        selected={selected}
        onClick={() =>
          selected
            ? this.handleToggleElementOnClick(toggleElement)
            : this.handleFirstTierOnClick(index)
        }
      >
        <SC.Container container>
          <SC.Grid item xs={11}>
            {selected ? (
              <SC.ListItemTextSelected primary={name} />
            ) : (
              <SC.ListItemText primary={name} />
            )}
            {selected && toggleElement && (
              <>
                <SC.MapContentLabel>Address</SC.MapContentLabel>
                {housenumber && <SC.ListItemText primary={housenumber} />}
                {plotnumber && <SC.ListItemText primary={plotnumber} />}
                {village && <SC.ListItemText primary={village} />}
                {parish && <SC.ListItemText primary={parish} />}
                {division && <SC.ListItemText primary={division} />}
                <SC.MapContentLink
                  href={website}
                  onClick={() => this.goToProperty(Rnid)}
                  underline="none"
                >
                  {" "}
                  Go to property{" "}
                </SC.MapContentLink>
              </>
            )}
          </SC.Grid>
          {selected && (
            <SC.Grid item xs={1}>
              {toggleElement ? <SC.ExpandLess /> : <SC.ExpandMore />}
            </SC.Grid>
          )}
        </SC.Container>
      </SC.ControlledListItem>
    );
  }
}

const ControlledListItemWrapper = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  return <ControlledListItem {...props} navigate={navigate} params={params} />;
};

export default ControlledListItemWrapper;
