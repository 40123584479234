/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { ListProps as ListPropsBase } from "@mui/material/List";

// Assets
import SC from "./list.styles";

export interface ListProps<T> extends ListPropsBase {
  items?: T[];
  getItemKey: (item: T) => string;
  getItemLabel: (item: T) => string;
}

export const List = <T extends Record<string, unknown>>({
  items = undefined,
  getItemKey,
  getItemLabel,
  ...props
}: ListProps<T>): JSX.Element => (
  <SC.List {...props}>
    {items?.map((item) => (
      <SC.ListItem key={getItemKey(item)}>
        <SC.ListItemText primary={getItemLabel(item)} />
      </SC.ListItem>
    ))}
  </SC.List>
);

export default List;
