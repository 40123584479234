// Dependencies
import React, { forwardRef } from "react";
import { AlertProps as AlertPropsBase } from "@mui/material";

// Assets
import SC from "./alert.styles";

export interface AlertProps extends AlertPropsBase {
  message?: string;
}

export const Alert: React.ForwardRefExoticComponent<
  Omit<AlertProps, "ref"> & React.RefAttributes<HTMLDivElement>
> = forwardRef<HTMLDivElement, AlertProps>(({ message, ...props }, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div ref={ref}>
    <SC.AlertBase elevation={6} variant="filled" {...props}>
      {message}
    </SC.AlertBase>
  </div>
));

export default Alert;
