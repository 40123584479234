/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { TableColumn } from "components/table/table.component";
import {
  NonIndividualCompliancePlusRiskReportFragment,
  LandlordIncomeSummaryFragment,
} from "graphql/hasura/types-and-hooks";
import FormattedValue from "components/formatted-value/formatted-value";
import { Link } from "react-router-dom";

export const unregisteredColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "center",
    },
    {
      header: "Source",
      accessorKey: "Source",
      align: "center",
      format: "string",
    },
    {
      header: "Source Customer Id",
      accessorKey: "Aggregatecustomerid",
      align: "center",
      format: "string",
    },
    {
      header: "Source Entity Legal Name",
      accessorKey: "Aggregatename",
      align: "left",
      format: "string",
    },
    {
      header: "rMATCH Score",
      accessorKey: "Matchscore",
      align: "center",
    },
    {
      header: "Source Tin",
      accessorKey: "Tinno",
      align: "center",
      format: "string",
    },
    {
      header: "Source Mobile",
      accessorKey: "Mobilenumber",
      align: "right",
      format: "string",
    },
    {
      header: "Source Email",
      accessorKey: "Emailid",
      align: "center",
      format: "string",
    },
    {
      header: "Total No. of Properties",
      accessorKey: "Numproperties",
      align: "center",
    },
    {
      header: "Total No. of Rented Properties",
      accessorKey: "Numrentedproperties",
      align: "center",
    },
    {
      header: "Sum Rateable Value of Rented Properties",
      accessorKey: "Sumrateablevalue",
      align: "right",
      format: "currency",
    },
    {
      header: "Sum of the Adjusted Gross Income",
      accessorKey: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Rented Property",
      accessorKey: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
  ];

export const noReturnsColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "center",
    },
    {
      header: "Source",
      accessorKey: "Source",
      align: "center",
      format: "string",
    },
    {
      header: "Entity Legal Name",
      accessorKey: "Aggregatename",
      align: "left",
      format: "string",
    },
    {
      header: "rMATCH Score",
      accessorKey: "Matchscore",
      align: "center",
    },
    {
      header: "TIN No.",
      accessorKey: "Uratinno",
      align: "center",
      format: "string",
    },
    {
      header: "Rental Income?",
      accessorKey: "Isincomesrcrental",
      align: "center",
      format: "string",
    },
    {
      header: "Tax Year",
      accessorKey: "Rtnperiodyear",
      align: "center",
    },
    {
      header: "Total Number of Properties",
      accessorKey: "Numproperties",
      align: "center",
    },
    {
      header: "Total Number of Rental Properties",
      accessorKey: "Numrentedproperties",
      align: "center",
    },
    {
      header: "Total Adjusted Gross Rental Income",
      accessorKey: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Rented Property",
      accessorKey: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
  ];

export const noIncomeColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "center",
    },
    {
      header: "Source",
      accessorKey: "Source",
      align: "center",
      format: "string",
    },
    {
      header: "Entity Legal Name",
      accessorKey: "Aggregatename",
      align: "left",
      format: "string",
    },
    {
      header: "rMATCH Score",
      accessorKey: "Matchscore",
      align: "center",
    },
    {
      header: "TIN No.",
      accessorKey: "Uratinno",
      align: "center",
      format: "string",
    },
    {
      header: "Rental Income?",
      accessorKey: "Isincomesrcrental",
      align: "center",
      format: "string",
    },
    {
      header: "Total Number of Properties",
      accessorKey: "Numproperties",
      align: "center",
    },
    {
      header: "Total Number of Rental Properties",
      accessorKey: "Numrentedproperties",
      align: "center",
    },
    {
      header: "Total Adjusted Gross Rental Income",
      accessorKey: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Rented Property",
      accessorKey: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
    {
      header: "Return No.",
      accessorKey: "Rtnno",
      align: "center",
    },
    {
      header: "Amendment",
      accessorKey: "Isamendment",
      align: "center",
      format: "string",
    },
    {
      header: "Return Period",
      accessorKey: "Rtnperiodyear",
      align: "center",
      format: "string",
    },
    {
      header: "URA Gross Rent Income",
      accessorKey: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Difference",
      accessorKey: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const underreportedColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "center",
    },
    {
      header: "URA Entity Legal Name",
      accessorKey: "Aggregatename",
      align: "left",
      format: "string",
    },
    {
      header: "rMATCH Score",
      accessorKey: "Matchscore",
      align: "center",
    },
    {
      header: "TIN No.",
      accessorKey: "Uratinno",
      align: "center",
      format: "string",
    },
    {
      header: "Rental Income?",
      accessorKey: "Isincomesrcrental",
      align: "center",
      format: "string",
    },
    {
      header: "Total Number of Properties",
      accessorKey: "Numproperties",
      align: "center",
    },
    {
      header: "Total Number of Rental Properties",
      accessorKey: "Numrentedproperties",
      align: "center",
    },
    {
      header: "Total Adjusted Gross Rental Income",
      accessorKey: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Property",
      accessorKey: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
    {
      header: "Return No.",
      accessorKey: "Rtnno",
      align: "center",
    },
    {
      header: "Amendment",
      accessorKey: "Isamendment",
      align: "center",
      format: "string",
    },
    {
      header: "Return Period",
      accessorKey: "Rtnperiodyear",
      align: "center",
      format: "string",
    },
    {
      header: "URA Gross Rent Income",
      accessorKey: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Difference",
      accessorKey: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const compliantreportedColumns: TableColumn<NonIndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "center",
    },
    {
      header: "URA Entity Legal Name",
      accessorKey: "Aggregatename",
      align: "left",
      format: "string",
    },
    {
      header: "rMATCH Score",
      accessorKey: "Matchscore",
      align: "center",
    },
    {
      header: "TIN No.",
      accessorKey: "Uratinno",
      align: "center",
      format: "string",
    },
    {
      header: "Rental Income?",
      accessorKey: "Isincomesrcrental",
      align: "center",
      format: "string",
    },
    {
      header: "Total Number of Properties",
      accessorKey: "Numproperties",
      align: "center",
    },
    {
      header: "Total Number of Rental Properties",
      accessorKey: "Numrentedproperties",
      align: "center",
    },
    {
      header: "Total Adjusted Gross Rental Income",
      accessorKey: "Sumofadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Property",
      accessorKey: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
    {
      header: "Return No.",
      accessorKey: "Rtnno",
      align: "center",
    },
    {
      header: "Amendment",
      accessorKey: "Isamendment",
      align: "center",
      format: "string",
    },
    {
      header: "Return Period",
      accessorKey: "Rtnperiodyear",
      align: "center",
      format: "string",
    },
    {
      header: "URA Gross Rent Income",
      accessorKey: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Difference",
      accessorKey: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const LandlordIncomeSummaryColumns: TableColumn<LandlordIncomeSummaryFragment>[] =
  [
    {
      header: "Landlord TIN",
      accessorKey: "LandlordTin",
      align: "center",
    },
    {
      header: "Landlord Taxpayer Name",
      accessorKey: "LandlordTaxpayerName",
      align: "left",
      format: "string",
    },
    {
      header: "Tax Year",
      accessorKey: "TaxYear",
      align: "center",
      format: "string",
    },
    {
      header: "Return No.",
      accessorKey: "ReturnNo",
      align: "center",
      format: "string",
    },
    {
      header: "Return Period From",
      accessorKey: "ReturnPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      header: "Return Period To",
      accessorKey: "ReturnPeriodTo",
      align: "center",
      format: "date",
    },
    {
      header: "Rent Income Reported by Tenant",
      accessorKey: "RentReportedByTenants",
      align: "right",
      cell: ({
        getValue,
        row: {
          original: { LandlordTin, TaxYear },
        },
        cell,
      }) => {
        const value = getValue() as number;

        return (
          <Link
            to={`analysis/tenant/${LandlordTin}/${TaxYear}/detail`}
            color="inherit"
          >
            {cell.row.id === "0" ? (
              <FormattedValue format="currency" value={value || ""} />
            ) : (
              <FormattedValue value={value || ""} format="number" />
            )}
          </Link>
        );
      },
    },
    {
      header: "Rent Income Reported on Tax Form",
      accessorKey: "GrossRentalIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Rent Income Declared by Landlord Details",
      accessorKey: "RentDeclaredByLandlord",
      align: "right",
      cell: ({
        getValue,
        row: {
          original: { ReturnNo },
        },
        cell,
      }) => {
        const value = getValue() as number;
        return (
          <Link to={`landlord/${ReturnNo}/detail`} color="inherit">
            {cell.row.id === "0" ? (
              <FormattedValue format="currency" value={value} />
            ) : (
              <FormattedValue value={value} format="number" />
            )}
          </Link>
        );
      },
    },
    {
      header: "rMATCH Score",
      accessorKey: "RmatchScore",
      align: "center",
      format: "string",
    },
    {
      header: "Difference - Tenant Report vs Landlord Declared",
      accessorKey: "Difference",
      align: "right",
      format: "currency",
    },
    {
      header: "Diff %",
      accessorKey: "DifferencePercentage",
      align: "center",
      format: "percent",
    },
    {
      header: "Landlord TIN Source",
      accessorKey: "LandlordTinSource",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      header: "Return No. Source",
      accessorKey: "ReturnNoSource",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
  ];
