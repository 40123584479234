/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";

// GraphQL
import {
  OrganisationData,
  DepartmentData,
  GroupData,
  useGetOrganisationQuery,
  useGetDepartmentQuery,
  useGetGroupQuery,
} from "graphql/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Components
import { UserForm, UserFormProps } from "./user-form.component";

type UserFormContainerProps = Omit<
  UserFormProps,
  "organisations" | "departments" | "groups"
>;

const UserFormContainer: React.FC<UserFormContainerProps> = (props) => {
  const { data: departmentsData, error: departmentsError } =
    useGetDepartmentQuery();
  const { data: organisationsData, error: organisationsError } =
    useGetOrganisationQuery();
  const { data: groupsData, error: groupsError } = useGetGroupQuery();

  useAppErrorHandler(departmentsError || organisationsError || groupsError);

  return (
    <UserForm
      {...props}
      organisations={organisationsData?.GetOrganisation as OrganisationData[]}
      departments={departmentsData?.GetDepartment as DepartmentData[]}
      groups={groupsData?.GetGroup as GroupData[]}
    />
  );
};

export default UserFormContainer;
