/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

// Assets
import SC from "./revision.styles";

export const VersionNumber: React.FC = () => {
  return <SC.Box></SC.Box>;
};

export default VersionNumber;
