import React from "react";
import { useLocation } from "react-router-dom";

// Components
import LandingPage from "components/landing-page/landing-page.component";
import { AlertProps } from "components/alert/alert.component";

// Assets
import SC from "components/landing-page/landing-page.styles";

// GraphQL
import { useValidateEmailVerificationCodeMutation } from "graphql/types-and-hooks";

// Utils
import { useAppErrorHandler, isServerError } from "errors/app.errors";

const EmailVerification: React.FC = () => {
  const [alertMessage, setAlertMessage] = React.useState<AlertProps>();
  const location = useLocation();
  const { pathname } = location;
  const verificationCode = pathname.split("/").slice(2)[0];

  const [verifyEmailCode] = useValidateEmailVerificationCodeMutation({
    context: {
      noAuthNeeded: true,
    },
  });

  const errorHandler = useAppErrorHandler();

  const verifyEmail = React.useCallback(async () => {
    try {
      await verifyEmailCode({
        variables: {
          code: verificationCode,
        },
      });

      setAlertMessage({
        message: "Your email address has been verified successfully",
        severity: "success",
      });
    } catch (error) {
      const err = error as Error;
      if (isServerError(err)) {
        setAlertMessage({
          message: err.message,
          severity: "error",
        });
      } else {
        errorHandler(err);
      }
    }
  }, [verifyEmailCode, verificationCode, errorHandler]);

  React.useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PageContent = (
    <SC.Box marginTop={120}>
      {alertMessage && (
        <SC.Alert
          elevation={0}
          icon={false}
          severity={alertMessage.severity}
          message={alertMessage.message}
        />
      )}
      {alertMessage?.severity === "success" && (
        <SC.ButtonContainer>
          <SC.Button color="primary" variant="contained" href="/login">
            Go To Login Page
          </SC.Button>
        </SC.ButtonContainer>
      )}
    </SC.Box>
  );

  return <LandingPage>{PageContent}</LandingPage>;
};

export default EmailVerification;
