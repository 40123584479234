// Dependencies
import { Box, Drawer } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AutofillField from "components/autofill-field/autofill-field.component";
import { styled } from "@mui/material/styles";

const drawerWidth = `240px`;

const SearchBoxFixed = styled(AutofillField)({
  width: "100%",
});

const LeftPaneContainer = styled(Box)({
  display: "flex",
});
const HidePaneIconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});

const DrawerBody = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiPaper-root": { width: drawerWidth },
});

const HidePaneIcon = styled(ChevronLeftIcon)({
  cursor: "pointer",
});

export default {
  SearchBoxFixed,
  LeftPaneContainer,
  DrawerBody,
  HidePaneIconContainer,
  HidePaneIcon,
};
