/* eslint-disable @typescript-eslint/no-explicit-any */
import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.reports.title.fontWeight,
  fontSize: theme.reports.title.fontSize,
  paddingRight: theme.reports.title.paddingRight,
  textAlign: theme.reports.title.textAlign,
}));

const Description = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.reports.description.paddingLeft,
  paddingRight: theme.reports.description.paddingRight,
  paddingTop: theme.reports.description.paddingTop,
  paddingBottom: theme.reports.description.paddingBottom,
  textAlign: theme.reports.description.textAlign,
}));

export default {
  Table,
  ProgressIndicator,
  Breadcrumb,
  Title,
  Description,
};
