/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const LabelText = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  textAlign: "right",
  fontWeight: 600,
  fontSize: theme.labelKeyValue.label.fontSize,
}));

const LabelValue = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  textAlign: "left",
  fontWeight: 100,
  fontSize: theme.labelKeyValue.value.fontSize,
}));

export default {
  LabelText,
  LabelValue,
  Grid,
};
