/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useParams } from "react-router-dom";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  useIndividualPropertyDetailsQuery,
  Query_RootRtcs_Db_Rmatch_IndividualComplianceDetailReportArgs,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { ServerDataToLayer } from "rView/home/utils/layer";
import useExportData from "exports/useExportData";

// Assets
import SC from "./properties-tab.styles";
import { propertiesTableColumns } from "./properties-tab.schema";

const persistenceId = "6594992a-c285-41f4-b187-6148fcbb2f53";
const TABLE_NAME = "RPT_PropertiesComposite";
interface IndividualPropertiesProps {
  rnid: string;
}

const IndividualProperties: React.FC<IndividualPropertiesProps> = ({
  rnid,
}) => {
  const { rentedStatus } = useParams<{ rentedStatus: string }>();
  const queryVariablesKccaCustomerIndividual: Query_RootRtcs_Db_Rmatch_IndividualComplianceDetailReportArgs =
    {
      where: {
        _and: [
          { Rnidcustomer: { _eq: rnid } },
          rentedStatus === "rented"
            ? { Propertyrentedstatus: { _eq: "RENTED" } }
            : {},
        ],
      },
    };

  const { data, error } = useIndividualPropertyDetailsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      // @ts-ignore
      params: jsonToStringQueryVariables(queryVariablesKccaCustomerIndividual),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariablesKccaCustomerIndividual);

  useAppErrorHandler(error || exportError);

  const properties = useMemo(() => {
    return data?.rtcs_db_Fnrmatch_IndividualComplianceDetailReport ?? [];
  }, [data]);

  return (
    <>
      <SC.SwitchViews
        LeftView={
          <SC.MapTableContainer
            columns={propertiesTableColumns}
            properties={properties}
            persistenceId={persistenceId}
            paginationControlled={undefined}
            initialRowsPerPage={undefined}
            pageIndexResetSignal={undefined}
            stickyHeader
            exportData={(columns: ColumnDef<any, any>[]) =>
              exportDataFn(columns, undefined, properties)
            }
          />
        }
        leftViewName="Table"
        RightView={
          <SC.MapContainer properties={ServerDataToLayer(properties)} />
        }
        rightViewName="Map"
      />
      <SC.ProgressIndicator open={exportLoading} />
    </>
  );
};

export default IndividualProperties;
