// Dependencies
import { Box as BoxBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const Box = styled(BoxBase)(() => ({
  maxWidth: "inherit",
}));

const BoxInner = styled(BoxBase)(() => ({
  paddingTop: "20px",
  paddingBottom: "100px",
}));

export default {
  Box,
  BoxInner,
};
