/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Toolbar as ToolbarBase,
  IconButton as IconButtonBase,
  Box,
  Typography,
} from "@mui/material";
import { FileCopyOutlined } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

const Toolbar = styled(ToolbarBase)(({ theme }) => ({
  padding: theme.notes.toolbar.padding,
  justifyContent: theme.notes.toolbar.justifyContent,
  minHeight: theme.notes.toolbar.minHeight,
}));

const ToolbarActions = styled(Box)({});

const IconButton = styled(IconButtonBase)(({ theme }) => ({
  padding: theme.notes.toolbar.padding,
  marginRight: theme.notes.toolbarAction.marginRight,
  "&:not(.Mui-disabled) .MuiSvgIcon-root": {
    color: theme.notes.toolbarAction.color,
    fontSize: theme.notes.toolbarAction.fontSize,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.notes.toolbar.fontWeight,
  fontSize: theme.notes.title.fontSize,
}));

const ToolBarAction = styled(Box)(({ theme }) => ({
  display: theme.notes.toolbarAction.display,
  alignItems: theme.notes.toolbarAction.alignItems,
}));

const ActionText = styled(Typography)(({ theme }) => ({
  display: theme.notes.toolbarActionText.display,
  fontSize: theme.notes.toolbarActionText.fontSize,
  color: theme.notes.toolbarActionText.color,
}));

export default {
  Title,
  Toolbar,
  ToolbarActions,
  IconButton,
  FileCopyOutlined,
  ToolBarAction,
  ActionText,
};
