/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const NoRecordsStatement = styled(Typography)(({ theme }) => ({
  textAlign: theme.table.noRecordsStatement.textAlign,
  margin: theme.spacing(0),
  padding: theme.spacing(0),
}));

export default {
  NoRecordsStatement,
};
