/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { TableColumn } from "components/table/table.component";
import { isValidTin } from "graphql/hasura/rtcs.utils";
import {
  RegIndividualAffiliatesFragment,
  AggregateOwnerReferenceFragment,
} from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";

export const affiliatesTableColumnsKCCA: TableColumn<AggregateOwnerReferenceFragment>[] =
  [
    {
      header: "Customer ID",
      accessorKey: "Id",
    },
    {
      header: "Relationship",
      accessorKey: "RelationshipDesignation",
      align: "left",
    },
    {
      header: "First Name",
      accessorKey: "Firstname",
      align: "left",
    },
    {
      header: "Middle Name",
      accessorKey: "MiddleName",
      align: "left",
    },
    {
      header: "Last Name",
      accessorKey: "Surname",
      align: "left",
    },
  ];

export const affiliatesTableColumnsURA: TableColumn<RegIndividualAffiliatesFragment>[] =
  [
    {
      header: "TIN No.",
      accessorKey: "RefTin",
      align: "left",
      cell: ({
        getValue,
        row: {
          original: { RefTin },
        },
      }): React.ReactNode => {
        return isValidTin(RefTin as string) ? (
          <Link to={`/individuals/${RefTin}/identification`}>
            <>{getValue()}</>
          </Link>
        ) : (
          <>{RefTin}</>
        );
      },
    },
    {
      header: "Relationship",
      accessorKey: "RefPrsnDesgn",
      align: "left",
    },
    {
      header: "First Name",
      accessorKey: "RefFirstName",
      align: "left",
    },
    {
      header: "Surname",
      accessorKey: "RefSurname",
      align: "left",
    },
    {
      header: "Other Name",
      accessorKey: "RefOtherName",
      align: "left",
    },
    {
      header: "Family Name",
      accessorKey: "RefFamilyName",
      align: "left",
    },

    {
      header: "Email",
      accessorKey: "RefEmailId",
      align: "left",
    },
    {
      header: "Mobile Number",
      accessorKey: "RefMobileNumber",
      align: "center",
    },
    {
      header: "Landline Number",
      accessorKey: "RefLandlineNumber",
      align: "center",
    },
  ];
