/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Link } from "react-router-dom";
import { TableColumn } from "components/table/table.component";

import {
  RCapturePropertyLessorsFragment,
  RCapturePropertyTenantFragment,
  RCapturePropertyUnitTypeFragment,
} from "graphql/hasura/types-and-hooks";

export type LessorTableSchema = Omit<
  RCapturePropertyLessorsFragment,
  "__typename"
>;
export type TenantTableSchema = Omit<
  RCapturePropertyTenantFragment,
  "__typename"
>;
export type UnitTypesTableSchema = Omit<
  RCapturePropertyUnitTypeFragment,
  "__typename"
>;

// lessor Columns
export const lessorColumns: TableColumn<LessorTableSchema>[] = [
  {
    header: "uuid",
    accessorKey: "Id",
    cell: ({ getValue }) => {
      const value = getValue() as string;
      return (
        <Link to={`/reports/MDA/rCAPTURE/lessors/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "SGUID",
    accessorKey: "Sguid",
  },
  {
    header: "Identifier",
    accessorKey: "Identifier",
  },
  {
    header: "Type",
    accessorKey: "Type",
  },
  {
    header: "Title",
    accessorKey: "Title",
  },
  {
    header: "FirstName",
    accessorKey: "Firstname",
  },
  {
    header: "LastName",
    accessorKey: "Lastname",
  },
  {
    header: "BusinessTradingName",
    accessorKey: "Businesstradingname",
  },
  {
    header: "LegalBusinessName",
    accessorKey: "Legalbusinessname",
  },
  {
    header: "RegisteredBusinessNumber",
    accessorKey: "Registeredbusinessnumber",
  },
  {
    header: "TradingLicenseNumber",
    accessorKey: "Tradinglicensenumber",
  },
  {
    header: "TIN",
    accessorKey: "Tin",
  },
  {
    header: "PositionInCompany",
    accessorKey: "Positionincompany",
  },
  {
    header: "CIN",
    accessorKey: "Cin",
  },
  {
    header: "Phone1",
    accessorKey: "Phone1",
  },
  {
    header: "Phone2",
    accessorKey: "Phone2",
  },
  {
    header: "Phone3",
    accessorKey: "Phone3",
  },
  {
    header: "Phone4",
    accessorKey: "Phone4",
  },
  {
    header: "Email",
    accessorKey: "Email",
  },
  {
    header: "Nationality",
    accessorKey: "Nationality",
  },
  {
    header: "BuildingName",
    accessorKey: "Buildingname",
  },
  {
    header: "PlotNumber",
    accessorKey: "Plotnumber",
  },
  {
    header: "StreetName",
    accessorKey: "Streetname",
  },
  {
    header: "District",
    accessorKey: "District",
  },
  {
    header: "Village",
    accessorKey: "Village",
  },
  {
    header: "Parish",
    accessorKey: "Parish",
  },
  {
    header: "Subcounty",
    accessorKey: "Subcounty",
  },
  {
    header: "County",
    accessorKey: "County",
  },
  {
    header: "Region",
    accessorKey: "Region",
  },
  {
    header: "PostalPOBox",
    accessorKey: "Postalpobox",
  },
  {
    header: "PostalDistrict",
    accessorKey: "Postaldistrict",
  },
  {
    header: "RepTitle",
    accessorKey: "Reptitle",
  },
  {
    header: "RepFirstName",
    accessorKey: "Repfirstname",
  },
  {
    header: "RepLastname",
    accessorKey: "Replastname",
  },
  {
    header: "RepDOB",
    accessorKey: "Repdob",
    format: "date",
  },
  {
    header: "RepPhone1",
    accessorKey: "Repphone1",
  },
  {
    header: "RepOtherCountry",
    accessorKey: "Repothercountry",
  },
  {
    header: "RepNationality",
    accessorKey: "Repnationality",
  },
  {
    header: "CustomerID",
    accessorKey: "Customerid",
  },
  {
    header: "CustomerIDSrc",
    accessorKey: "Customeridsrc",
  },
];

// tenant columns
export const tenantColumns: TableColumn<TenantTableSchema>[] = [
  {
    header: "uuid",
    accessorKey: "Id",
    cell: ({ getValue }) => {
      const value = getValue() as string;
      return (
        <Link to={`/reports/MDA/rCAPTURE/tenants/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Identifier",
    accessorKey: "Identifier",
  },
  {
    header: "FloorNumber",
    accessorKey: "Floornumber",
  },
  {
    header: "ShopNumber",
    accessorKey: "Shopnumber",
  },
  {
    header: "ShopName",
    accessorKey: "Shopname",
  },
  {
    header: "TypeOfGoodsSold",
    accessorKey: "Typeofgoodssold",
  },
  {
    header: "NumberBedrooms",
    accessorKey: "Numberbedrooms",
    align: "right",
    format: "number",
  },
  {
    header: "NumberBathrooms",
    accessorKey: "Numberbathrooms",
    align: "right",
    format: "number",
  },
  {
    header: "EstimatedShopSize",
    accessorKey: "Estimatedshopsize",
  },
  {
    header: "Type",
    accessorKey: "Type",
  },
  {
    header: "Title",
    accessorKey: "Title",
  },
  {
    header: "FirstName",
    accessorKey: "Firstname",
  },
  {
    header: "LastName",
    accessorKey: "Lastname",
  },
  {
    header: "BusinessTradingName",
    accessorKey: "Businesstradingname",
  },
  {
    header: "LegalBusinessName",
    accessorKey: "Legalbusinessname",
  },
  {
    header: "RegisteredBusinessNumber",
    accessorKey: "Registeredbusinessnumber",
  },
  {
    header: "TIN",
    accessorKey: "Tin",
  },
  {
    header: "MDA",
    accessorKey: "Mda",
  },
  {
    header: "PositionInCompany",
    accessorKey: "Positionincompany",
  },
  {
    header: "IsUnitLet",
    accessorKey: "Isunitlet",
  },
  {
    header: "OwnerOccupiedOrRented",
    accessorKey: "Owneroccupiedorrented",
  },
  {
    header: "Phone1",
    accessorKey: "Phone1",
  },
  {
    header: "Phone2",
    accessorKey: "Phone2",
  },
  {
    header: "Phone3",
    accessorKey: "Phone3",
  },
  {
    header: "Phone4",
    accessorKey: "Phone4",
  },
  {
    header: "Email",
    accessorKey: "Email",
  },
  {
    header: "Nationality",
    accessorKey: "Nationality",
  },
  {
    header: "RepTitle",
    accessorKey: "Reptitle",
  },
  {
    header: "RepTitleOther",
    accessorKey: "Reptitleother",
  },
  {
    header: "RepFirstName",
    accessorKey: "Repfirstname",
  },
  {
    header: "RepMiddleName",
    accessorKey: "Repmiddlename",
  },
  {
    header: "RepLastName",
    accessorKey: "Replastname",
  },
  {
    header: "RepEmail",
    accessorKey: "Repemail",
  },
  {
    header: "RepPhone1",
    accessorKey: "Repphone1",
  },
  {
    header: "RepNationality",
    accessorKey: "Repnationality",
  },
  {
    header: "RepOtherCountry",
    accessorKey: "Repothercountry",
  },
  {
    header: "Actualrentpaidpermonth",
    accessorKey: "Actualrentpaidpermonth",
    align: "right",
    format: "currency",
  },
  {
    header: "Cactualrentpaidpermonth",
    accessorKey: "Cactualrentpaidpermonth",
    align: "right",
    format: "currency",
  },
  {
    header: "Rentreportedbylessorpermonth",
    accessorKey: "Rentreportedbylessorpermonth",
    align: "right",
    format: "currency",
  },
  {
    header: "Currencyreportedrent",
    accessorKey: "Currencyreportedrent",
  },
  {
    header: "Amountofrentreceipts",
    accessorKey: "Amountofrentreceipts",
    align: "right",
    format: "currency",
  },
  {
    header: "Currencyactualrent",
    accessorKey: "Currencyactualrent",
  },
  {
    header: "Notes",
    accessorKey: "Notes",
  },
];

// unittype columns
export const unitTypeColumns: TableColumn<UnitTypesTableSchema>[] = [
  {
    header: "Id",
    accessorKey: "Id",
    cell: ({ getValue }) => {
      const value = getValue() as string;
      return (
        <Link to={`/reports/MDA/rCAPTURE/unittypes/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "UnitType",
    accessorKey: "Unittype",
  },
  {
    header: "UnitSize",
    accessorKey: "Unitsize",
    align: "right",
    format: "number",
  },
  {
    header: "NumberBedrooms",
    accessorKey: "Numberbedrooms",
    align: "right",
    format: "number",
  },
  {
    header: "NumberBathrooms",
    accessorKey: "Numberbathrooms",
    align: "right",
    format: "number",
  },
  {
    header: "UnitRent",
    accessorKey: "Unitrent",
    format: "currency",
  },
  {
    header: "Currencyadvertisedrent",
    accessorKey: "Currencyadvertisedrent",
    format: "currency",
  },
  {
    header: "UnitFurnished",
    accessorKey: "Unitfurnished",
  },
  {
    header: "NumberUnitsType",
    accessorKey: "Numberunitstype",
    align: "right",
  },
  {
    header: "Uuid_property",
    accessorKey: "Uuidproperty",
    cell: ({ getValue }) => {
      const value = getValue() as string;
      return (
        <Link
          to={`/reports/MDA/rCAPTURE/properties/${value}/details`}
          color="inherit"
        >
          {value}
        </Link>
      );
    },
  },
  {
    header: "UGUID",
    accessorKey: "Uguid",
  },
];
