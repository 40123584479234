/* eslint-disable @typescript-eslint/no-explicit-any */
import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { Card as CardBase } from "@mui/material";

import { styled } from "@mui/material/styles";

const Card = styled(CardBase)(({ theme }) => ({
  border: theme.tableCard.card.border,
  borderRadius: theme.tableCard.card.border,
  margin: theme.tableCard.card.margin,
}));

export default {
  Table,
  ProgressIndicator,
  Card,
};
