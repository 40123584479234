/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl as FormControlBase,
  Select as SelectBase,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const FormControl = styled(FormControlBase)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.default,
  "& .MuiInputBase-root": {
    height: theme.calendarSelectField.height,
  },
}));

const Select = styled(SelectBase)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.default,
  borderColor: theme.palette.background.default,
  borderWidth: "0px",
  textAlign: "left",
  fontSize: theme.calendarSelectField.fontSize,
  color: theme.palette.text.primary,

  "& .MuiSelect-select em": {
    fontStyle: "regular",
  },
}));

export default { FormControl, Select, MenuItem };
