/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { MlhudFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type MlhudSchema = Omit<MlhudFragment, "__typename">;

export interface MlhudRegistrationTableFilters {
  firstName: string;
  surname: string;
  phoneNumber: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof MlhudRegistrationTableFilters,
  string | string[]
> = {
  firstName: ["Firstname"],
  surname: ["Lastname"],
  phoneNumber: ["Phone"],
};

// @ts-ignore
export const MlhudSchemaMap: Record<keyof MlhudSchema, "string" | "number"> = {
  Firstname: "string",
  Middlename: "string",
  Fullname: "string",
  Lastname: "string",
  Gender: "string",
  Titlenumber: "string",
  Blocknumber: "string",
  Plotno: "string",
  Roadname: "string",
  Address: "string",
  Subcounty: "string",
  County: "string",
  District: "string",
  Municipality: "string",
  Tenuretype: "string",
  Legalarea: "number",
  Legalareaunittype: "string",
  Volume: "string",
  Folio: "string",
  Titlelocation: "string",
  Baunittype: "string",
  Landtype: "string",
  Landusetype: "string",
  Condounitfactor: "string",
  Condoplanno: "string",
  Lafnumber: "string",
  Phone: "string",
  Description: "string",
  Registrationdatetime: "string",
  Cadlatitude: "string",
  Cadlongitude: "string",
  Cadlotnumber: "string",
  Cadblocknumb: "string",
  Cadroadname: "string",
  Cadcounty: "string",
  MatchResult: "string",
};

export const getAccessorType = (
  accessor: keyof MlhudSchema | AccessorFn<MlhudSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return MlhudSchemaMap[accessor];
};

export const mlhudTableColumns: TableColumn<MlhudSchema>[] = [
  {
    header: "Full Name",
    accessorKey: "Fullname",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "First Name",
    accessorKey: "Firstname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Middle Name",
    accessorKey: "Middlename",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Last Name",
    accessorKey: "Lastname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Gender",
    accessorKey: "Gender",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Title Number",
    accessorKey: "Titlenumber",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Block",
    accessorKey: "Blocknumber",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Plot",
    accessorKey: "Plotno",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Road Name",
    accessorKey: "Roadname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Subcounty",
    accessorKey: "Subcounty",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "County",
    accessorKey: "County",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "District",
    accessorKey: "District",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Municipality",
    accessorKey: "Municipality",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Tenure Type",
    accessorKey: "Tenuretype",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Legal Area",
    accessorKey: "Legalarea",
    align: "right",
    meta: { sort: false },
  },
  {
    header: "Legal Area Unit Type",
    accessorKey: "Legalareaunittype",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Volume",
    accessorKey: "Volume",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Folio",
    accessorKey: "Folio",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Title Location",
    accessorKey: "Titlelocation",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Ba Unit Type",
    accessorKey: "Baunittype",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Land Type",
    accessorKey: "Landtype",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Land Use Type",
    accessorKey: "Landusetype",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Condo Unit Factor",
    accessorKey: "Condounitfactor",
    align: "right",
    meta: { sort: false },
  },
  {
    header: "Condo Plan No.",
    accessorKey: "Condoplanno",
    align: "center",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Laf No.",
    accessorKey: "Lafnumber",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Phone",
    accessorKey: "Phone",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Description",
    accessorKey: "Description",
    align: "left",
    meta: { sort: false },
  },

  {
    header: "Registration",
    accessorKey: "Registrationdatetime",
    align: "center",
    meta: { sort: false },
    format: "date",
  },

  {
    header: "Latitude",
    accessorKey: "Cadlatitude",
    align: "center",
    meta: { sort: false },
  },

  {
    header: "Longitude",
    accessorKey: "Cadlongitude",
    align: "center",
    meta: { sort: false },
    format: "string",
  },

  {
    header: "Card Lot",
    accessorKey: "Cadlotnumber",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Cad Block Number",
    accessorKey: "Cadblocknumb",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Cad Road",
    accessorKey: "Cadroadname",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Cad County",
    accessorKey: "Cadcounty",
    align: "left",
    meta: { sort: false },
    format: "string",
  },
  {
    header: "Match",
    accessorKey: "MatchResult",
    align: "left",
    meta: { sort: false },
  },
];
