/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UraRentalLandLordIncomeFragment } from "graphql/hasura/types-and-hooks";
import { TableColumn } from "components/table/table.component";

export type ReportTableSchema = Omit<
  UraRentalLandLordIncomeFragment,
  "__typename" | "TinNo" | "RtnRentalIncomeId"
>;

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    header: "Return No.",
    accessorKey: "RtnNo",
    meta: { sort: true },
  },
  {
    header: "TaxPayer Name",
    accessorKey: "TaxPayerName",
  },
  {
    header: "Tenant Name",
    accessorKey: "TenantName",
  },
  {
    header: "Premises Location",
    accessorKey: "PremiseLocation",
  },
  {
    header: "Rental Income",
    accessorKey: "RentalIncome",
    format: "currency",
    align: "right",
  },
  {
    header: "Return Date",
    accessorKey: "RtnDt",
    format: "date",
  },
  {
    header: "Return From Date",
    accessorKey: "RtnFromDt",
    format: "date",
  },
  {
    header: "Return To Date",
    accessorKey: "RtnToDt",
    format: "date",
  },

  {
    header: "Tenancy Period From",
    accessorKey: "TenancyPeriodFrom",
    format: "date",
  },
  {
    header: "Tenancy Period To",
    accessorKey: "TenancyPeriodTo",
    format: "date",
  },

  {
    header: "Tenant TIN",
    accessorKey: "TenantTin",
  },
];
