import { HeaderContext } from "@tanstack/react-table";
import _ from "lodash";
import React from "react";
import { formatNumber } from "utils/math";

export const getColumnTotal = <T>(props: HeaderContext<T, unknown>) => {
  const rows = props.table.getRowModel().rows;
  const total = React.useMemo(
    () =>
      _.sumBy(rows, (row) => {
        const value = (row.getValue(props.column.id) ?? 0) as number | string;
        return typeof value === "number" ? value : formatNumber(value);
      }),
    [rows, props.column.id],
  );
  return total;
};
