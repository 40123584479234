/* eslint-disable no-alert */
import React, { useEffect, useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Order_By,
  Query_RootRtcs_Db_Ph_Ucc_UccArgs,
  Rtcs_Db_Ph_Ucc_Ucc_Select_Column,
  useGetPaginateduccReportQuery,
  useUccReportFilterQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";

import Alert from "components/alert/alert.component";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import { useAppErrorHandler } from "errors/app.errors";

import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";

// component depend
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import {
  DEFAULT_FILTER_ACCESSOR,
  getAccessorType,
  MobilePhoneNumbersTableFilters,
  columns as tableColumns,
} from "./mobile-phone-numbers.schema";

import SC from "./mobile-phone-numbers.styles";

interface MobilePhoneNumbersTableProps {
  defaultFilters?: MobilePhoneNumbersTableFilters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  onLoaded?: () => void;
  title?: string;
}

const TABLE_NAME = "rtcs_db_Fnucc_UCC";

export const MobilePhoneNumbersTable: React.FC<MobilePhoneNumbersTableProps> =
  React.memo(
    ({
      defaultFilters,
      initialRowsPerPage = INITIAL_ROWS_PER_PAGE.REPORTS,
      maxHeight,
      noDataComponent,
      onLoaded,
      title = "Mobile Registration Data",
    }) => {
      const defaultFilterConditions = useMemo(() => {
        const { firstName, surname, phoneNumber } = defaultFilters ?? {
          firstName: "",
          surname: "",
          phoneNumber: "",
        };

        return getConditionsFromDefaultFilters(
          {
            ...(defaultFilters ?? {}),
            firstName: firstName === "" ? "" : `%${firstName}`,
            surname: surname === "" ? "" : `%${surname}`,
            phoneNumber: phoneNumber === "" ? "" : `%${phoneNumber}`,
          },
          DEFAULT_FILTER_ACCESSOR,
        );
      }, [defaultFilters]);

      const hasUnhandledDefaultFilter = useMemo(
        () =>
          getHasUnhandledDefaultFilter(
            defaultFilters ?? {},
            DEFAULT_FILTER_ACCESSOR,
          ),
        [defaultFilters],
      );

      const [filterConditions, setFilterConditions] = React.useState<
        FilterCondition[]
      >([] as FilterCondition[]);

      const [queryVariables, setQueryVariables] = React.useState<
        Query_RootRtcs_Db_Ph_Ucc_UccArgs | undefined
      >({
        where: mergeWithDefaultConditions(
          defaultFilterConditions,
          filterConditions,
        ),
      });

      const [pageIndexResetSignal, setPageIndexResetSignal] =
        React.useState(false);

      const [infoMessage, setInfoMessage] = React.useState<string | undefined>(
        "",
      );

      const { data, loading, error } = useGetPaginateduccReportQuery({
        context: { clientName: CLIENT_NAME.HASURA },
        skip: !queryVariables || hasUnhandledDefaultFilter,
        variables: {
          params: jsonToStringQueryVariables(queryVariables ?? {}),
          aggregateParams: jsonToStringQueryVariables({
            where: queryVariables?.where,
          }),
        },
      });

      const [exportDataFn, { loading: exportLoading, error: exportError }] =
        useExportData(TABLE_NAME, queryVariables);

      //! implement the no data error
      useEffect(() => {
        if (data && data.rtcs_db_Fnucc_UCC.length === 0) {
          setInfoMessage("Filter Query Returned No Data");
          return;
        }
        setInfoMessage(undefined);
      }, [data]);

      useAppErrorHandler(error || exportError);

      const reportData = React.useMemo(() => {
        const report = data?.rtcs_db_Fnucc_UCC ?? [];
        return report;
      }, [data]);

      const columns = useMemo(() => tableColumns, []);

      const universalColumns: ColumnsProps[] = useMemo(() => {
        return columns.map((column, i) => {
          return {
            index: i,
            label: `${column.header}`,
            value: `${column.accessorKey}`,
            type: `${getAccessorType(column.accessorKey)}`,
          };
        });
      }, [columns]);

      const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
        const columnObject = condition[Object.keys(condition)[0]];
        if (columnObject[Object.keys(columnObject)[0]] === "") {
          // eslint-disable-next-line no-param-reassign
          condition = {};
        }
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: condition,
        }));
        setPageIndexResetSignal((previousSignal) => !previousSignal);
      };

      const handlePaginatedFetchData: TableFetchDataFunction =
        React.useCallback((params) => {
          const { pageIndex, pageSize, sortBy } = params;
          const defaultSortColumn = {
            id: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Customerid,
            desc: true,
          };
          const sortByColumn =
            sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
          setQueryVariables((oldVariables) => ({
            ...oldVariables,
            orderBy: {
              [sortByColumn.id]: sortByColumn.desc
                ? Order_By.Desc
                : Order_By.Asc,
            },
            limit: pageSize,
            offset: pageIndex * pageSize,
          }));
        }, []);

      const totalRowsCount = parseInt(
        data?.rtcs_db_Fnucc_UCC_aggregatecm[0].value ?? "-1",
        10,
      );

      const paginationControlled = React.useMemo(
        () => ({
          fetchData: handlePaginatedFetchData,
          loading,
          totalRowsCount,
        }),
        [totalRowsCount, loading, handlePaginatedFetchData],
      );

      const { refetch: ReportFilterFn } = useUccReportFilterQuery({
        skip: true,
        context: {
          clientName: CLIENT_NAME.HASURA,
        },
      });

      const fetchSuggestions = React.useCallback(
        async (value: string, column: string) => {
          try {
            let suggestions: string[] = [];

            if (value !== "") {
              const filterVariables: Query_RootRtcs_Db_Ph_Ucc_UccArgs = {
                where: { [column]: { _ilike: `${value}%` } },
                distinct_on: [column as Rtcs_Db_Ph_Ucc_Ucc_Select_Column],
                limit: 10,
              };

              const uccReportFilterData = await ReportFilterFn({
                params: jsonToStringQueryVariables(filterVariables),
              });
              suggestions = uccReportFilterData.data.rtcs_db_Fnucc_UCC.map(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (option) => option[column],
              );
            }

            // eslint-disable-next-line @typescript-eslint/return-await
            return Promise.resolve((suggestions ?? []) as string[]);
          } catch (err) {
            return Promise.reject(err);
          }
        },
        [ReportFilterFn],
      );

      const filterInputs: TableFilterInput[] = React.useMemo(() => {
        return [
          {
            type: TableFilterType.AUTOFILL,
            label: "Mobile Phone",
            columnName: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Kccamobile,
            fetchSuggestions,
          },

          {
            type: TableFilterType.AUTOFILL,
            label: "First Name",
            columnName: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Firstname,
            fetchSuggestions,
          },

          {
            type: TableFilterType.AUTOFILL,
            label: "Surname",
            columnName: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Surname,
            fetchSuggestions,
          },

          {
            type: TableFilterType.AUTOFILL,
            label: "Identifier",
            columnName: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Idnumber,
            fetchSuggestions,
          },
        ];
      }, [fetchSuggestions]);

      const onTableFilterApply = React.useCallback(
        (conditions: FilterCondition[]) => {
          setFilterConditions(conditions);
          setPageIndexResetSignal((previousSignal) => !previousSignal);
        },
        [],
      );

      useEffect(() => {
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        }));
      }, [defaultFilterConditions, filterConditions]);

      useEffect(() => {
        if (!loading) onLoaded?.();
      }, [loading, onLoaded]);

      return (
        <SC.Container pl={2} pr={2} pt={2}>
          <Table
            actionsOnRight={[
              "hide/show-columns",
              "filter-results",
              "export-to-excel-sheet/csv",
            ]}
            columns={columns}
            data={reportData}
            onAction={() => alert("under construction")}
            title={title}
            leftPanel={
              <TableFilter
                filterInputs={filterInputs}
                onFilterApply={onTableFilterApply}
                universalFilterColumns={universalColumns}
                onUniversalFilterSubmit={onUniversalFilterer}
              />
            }
            stickyHeader
            persistenceId="8da59275-eec2-43f7-9a87-7d51fc8969ae"
            paginationControlled={paginationControlled}
            initialRowsPerPage={initialRowsPerPage}
            pageIndexResetSignal={pageIndexResetSignal}
            exportData={exportDataFn}
            maxHeight={maxHeight}
            noDataComponent={noDataComponent}
          />
          {infoMessage && <Alert message={infoMessage} />}
          <ProgressIndicator open={loading || exportLoading} />
        </SC.Container>
      );
    },
  );

const MobilePhoneNumbers: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <MobilePhoneNumbersTable />
    </SC.Box>
  );
};

export default MobilePhoneNumbers;
