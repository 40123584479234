import React, { useEffect, useMemo } from "react";

import {
  usePropertiesValuationGetLazyQuery,
  Query_RootRtcs_Db_Ph_Rpt_PropertyValuationArgs,
  AggregatePropertyFragment,
} from "graphql/hasura/types-and-hooks";

// GraphQL
import { CLIENT_NAME } from "graphql/client";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";
import property, { AggregatePropertyDetails } from "./property-details.data";

// Assets
import SC from "./details-tab.styles";
import {
  COMMERCIAL,
  COMMERCIAL_SUB_RETAIL_AND_OFFICES,
  COMMERCIAL_SUB_WAREHOUSE_AND_BARS,
  CONDOMINIUM,
  RESIDENTIAL,
  RESIDENTIAL_SUB_SFH,
} from "./details-constant";

interface DetailsTabProps {
  propertyData: AggregatePropertyFragment;
  Rnid: string;
}

interface Data {
  index: number;
  label: string;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
  value: string;
}

const DetailsTab: React.FC<DetailsTabProps> = ({ propertyData, Rnid }) => {
  const PropertyType = propertyData.PropertyType || "";
  const coordinates = `${propertyData.Latitude || ""}, ${
    propertyData.Longitude || ""
  }`;

  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_PropertyValuationArgs = {
    where: { Rnid: { _eq: Rnid } },
    limit: 1,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function returnContextData(
    schema: AggregatePropertyDetails[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    contextData: any,
  ) {
    return schema.map(({ index, label, accessorKey, format }) => {
      return {
        index,
        label,
        format,
        value:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          accessorKey === "PropertyType"
            ? PropertyType
            : contextData[accessorKey as keyof typeof contextData],
      };
    });
  }

  const [fetchValuationFn, { data, error, loading }] =
    usePropertiesValuationGetLazyQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
      },
    });

  useEffect(() => {
    fetchValuationFn();
  }, [fetchValuationFn]);

  useAppErrorHandler(error);

  const detailsLoadState = useMemo(() => {
    if (!data) return false;
    if (data?.rtcs_db_FnRPT_PropertyValuation.length > 0) {
      return true;
    }
    return false;
  }, [data]);

  if (loading) {
    return <ProgressIndicator open />;
  }

  let propertyDetails: Data[] = [];
  let particularDetails: Data[] = [];
  let accomodationDetails: Data[] = [];

  if (data?.rtcs_db_FnRPT_PropertyValuation !== undefined) {
    const propertyValuationData = data.rtcs_db_FnRPT_PropertyValuation[0];

    if (propertyValuationData && PropertyType) {
      propertyDetails = returnContextData(
        property.details,
        propertyValuationData,
      );

      propertyDetails.unshift({
        index: 1,
        label: "Coordinates",
        value: coordinates,
        format: "maplink",
      });

      if (
        (propertyValuationData.PropertyType === RESIDENTIAL ||
          propertyValuationData.PropertyType === CONDOMINIUM) &&
        propertyValuationData.RnSubpropertyType === RESIDENTIAL_SUB_SFH
      ) {
        particularDetails = returnContextData(
          property.particularsResidentialSFH,
          propertyValuationData,
        );

        accomodationDetails = returnContextData(
          property.accomodationsResidentialSFH,
          propertyValuationData,
        );
      }

      if (
        propertyValuationData.PropertyType === COMMERCIAL &&
        COMMERCIAL_SUB_RETAIL_AND_OFFICES.includes(
          `${propertyValuationData.RnSubpropertyType}`,
        )
      ) {
        particularDetails = returnContextData(
          property.particularCommercialRetailAndOffices,
          propertyValuationData,
        );
      }

      if (
        propertyValuationData.PropertyType === COMMERCIAL &&
        COMMERCIAL_SUB_WAREHOUSE_AND_BARS.includes(
          `${propertyValuationData.RnSubpropertyType}`,
        )
      ) {
        particularDetails = returnContextData(
          property.particularWarehouseAndBars,
          propertyValuationData,
        );
      }
    }
  }

  if (propertyDetails.length <= 0) {
    return null;
  }

  return (
    <SC.Container maxWidth={false}>
      <SC.Grid container spacing={2}>
        <SC.Grid item xs={6}>
          <SC.PropertyDetails title="Property" details={propertyDetails} />
          {particularDetails.length > 0 && (
            <SC.PropertyDetails
              title="Other Data"
              details={particularDetails}
            />
          )}
        </SC.Grid>
        <SC.Grid item xs>
          <AppPermissionValidator
            appPermission={APP_PERMISSION.PROPERTIES_VIEW_IMAGES}
          >
            <SC.ImageList
              load={detailsLoadState}
              camvId={propertyData.CamvId || ""}
              propertyNo={propertyData.PropertyNo || ""}
            />
          </AppPermissionValidator>

          {accomodationDetails.length > 0 && (
            <SC.PropertyDetails
              title="Accommodations"
              details={accomodationDetails}
            />
          )}
        </SC.Grid>
      </SC.Grid>
    </SC.Container>
  );
};

export default DetailsTab;
