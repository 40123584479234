import { TableColumn } from "components/table/table.component";

export type Schema = {
  question: string;
  answertext: string;
};

export const columns: TableColumn<Schema>[] = [
  {
    header: "Question Text",
    accessorKey: "question",
  },
  {
    header: "Answer Text",
    accessorKey: "answertext",
  },
];
