/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  List,
  ListItem as ListItemBase,
  ListItemProps,
  ListItemText as ListItemTextBase,
  ListItemTextProps,
  ListItemSecondaryAction as ListItemSecondaryActionBase,
} from "@mui/material";
import FormatedValue from "components/formatted-value/formatted-value";
import { styled } from "@mui/material/styles";

interface CustomListItemProps extends ListItemProps {
  level?: number;
  has_items: boolean;
}

interface CustomListItemTextProps extends ListItemTextProps {
  isTitle?: boolean;
  isMega?: boolean;
}

const StyledListItem = styled(ListItemBase, {
  shouldForwardProp: (prop) => prop !== "level" && prop !== "has_items",
})<CustomListItemProps>(({ theme, level = 0, has_items }) => ({
  borderBottom: theme.balanceSheet.listItem.borderBottom,
  paddingTop: theme.balanceSheet.listItem.paddingTop,
  paddingBottom: theme.balanceSheet.listItem.paddingBottom,
  paddingLeft: has_items && level <= 1 ? 0 : `${level * 2.28}em`,
}));

const ListItem = (props: CustomListItemProps): JSX.Element => {
  return <StyledListItem {...props} />;
};
ListItem.defaultProps = {
  level: 0,
};

const ListItemText = styled(ListItemTextBase, {
  shouldForwardProp: (prop) => prop !== "isTitle" && prop !== "isMega",
})<CustomListItemTextProps>(({ theme, isTitle, isMega }) => ({
  margin: "0px",
  "& .MuiListItemText-primary": {
    fontWeight: isTitle ? "bold" : "normal",
    fontSize: isMega ? "1.4286rem" : theme.nestedListItem.fontSize,
    lineHeight: theme.balanceSheet.listText.lineHeight,
  },
}));

const ListItemSecondaryAction = styled(ListItemSecondaryActionBase)(
  ({ theme }) => ({
    right: theme.balanceSheet.ListItemSecondaryAction.right,
  }),
);

export default {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormatedValue,
};
