/* eslint-disable @typescript-eslint/no-explicit-any */
// Material-UI
import {
  IconButton as IconButtonMui,
  Popover,
  Button,
  Typography,
  Paper as PaperMui,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const Paper = styled(PaperMui)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.popOverButton.backgroundColor,
}));

const Message = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  whiteSpace: "pre-line",
}));

const IconButton = styled(IconButtonMui)(({ theme }) => ({
  padding: theme.table.IconButton.padding,
}));

export default {
  Paper,
  IconButton,
  Popover,
  Button,
  Message,
};
