import React, { useState } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rpt_IndividualRegistrationArgs,
  Order_By,
  RegIndividualDataHpocFragment,
  useRegIndividualDataQuery,
  useRegIndividualSuggestionsLazyQuery,
} from "graphql/hasura/types-and-hooks";

// Components
import { Typography } from "@mui/material";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import AutofillField from "components/autofill-field/autofill-field.component";
import Table, { TableColumn } from "components/table/table.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Assets
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import SC from "./hasura-poc.styles";

const HasuraPoc: React.FC = () => {
  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_IndividualRegistrationArgs = {
    where: {},
    limit: 100,
  };

  const { data, loading, error } = useRegIndividualDataQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const DELAY_TIME = 3000;

  const [inputValue, setInputValue] = useState("");

  const [
    RegIndividualSuggestionsFunction,
    { loading: loadingS, data: dataS, error: errorS },
  ] = useRegIndividualSuggestionsLazyQuery();

  useAppErrorHandler(error || errorS);

  const columns: TableColumn<RegIndividualDataHpocFragment>[] =
    React.useMemo(() => {
      const result: TableColumn<RegIndividualDataHpocFragment>[] = [];

      if (data) {
        Object.keys(data?.rtcs_db_FnRPT_IndividualRegistration[0]).forEach(
          (key) => {
            if (key !== "__typename") {
              result.push({
                header: key,
                accessorKey: key as keyof RegIndividualDataHpocFragment,
                align: "left",
              });
            }
          },
        );
      }

      return result;
    }, [data]);

  const searchHandler = (value: string) => {
    const queryVariablesSuggestion: Query_RootRtcs_Db_Ph_Rpt_IndividualRegistrationArgs =
      {
        limit: 10,
        where: {
          _or: [
            { NationalId: { _ilike: `%${value}%` } },
            { TaxPayerName: { _ilike: `%${value}%` } },
          ],
        },
        // The next two lines was added to igoner the error market by vscode because orderBy is workig properly
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        orderBy: { TaxPayerName: Order_By.Asc },
      };

    RegIndividualSuggestionsFunction({
      context: { clientName: CLIENT_NAME.HASURA },
      variables: {
        params: jsonToStringQueryVariables(queryVariablesSuggestion ?? {}),
      },
    });
  };

  return (
    <SC.HasuraPoc>
      <Typography variant="h3">Hasura proof of concept</Typography>
      <Typography variant="caption">inputValue:{inputValue}</Typography>
      <AutofillField
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        loading={loadingS}
        serverSideSearchProps={{
          onTriggerSearch: searchHandler,
          minLengthSearch: 3,
          msDelaySearch: DELAY_TIME,
        }}
        textFieldProps={{
          label: "Search",
          helperText: "Enter Name, Nat'l ID or tax ID",
        }}
        options={dataS?.rtcs_db_FnRPT_IndividualRegistration ?? []}
        getOptionLabel={(option) => `${option.TinNo}|${option.TaxPayerName}`}
        isOptionEqualToValue={(option, value) => option.TinNo === value.TinNo}
      />
      <ProgressIndicator open={loading} />
      <Table
        columns={columns}
        data={data?.rtcs_db_FnRPT_IndividualRegistration ?? []}
        onAction={() => {}}
        rowsPerPageOptions={[5, 10, 25]}
        title="Individual Registration Dummy data"
      />
    </SC.HasuraPoc>
  );
};

export default HasuraPoc;
