/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from "lodash";
import { Link } from "react-router-dom";

import React from "react";

import { TableColumn } from "components/table/table.component";
import { RCapturePropertiesFragment } from "graphql/hasura/types-and-hooks";
import { AccessorFn } from "@tanstack/react-table";

export type PropertiesTableSchema = Omit<
  RCapturePropertiesFragment,
  "__typename"
>;

export interface RCapturePropertiesTableFilters {
  firstName: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof RCapturePropertiesTableFilters,
  string | string[]
> = {
  firstName: ["Lessor1firstname"],
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const PropertySchemaMap: Record<
  keyof PropertiesTableSchema,
  "string" | "number"
> = {
  Id: "string",
  Outcome: "string",
  Blocknumber: "string",
  Buildingcondition: "string",
  Buildingname: "string",
  County: "string",
  District: "string",
  Found: "string",
  Geolocation: "string",
  Identifier: "string",
  Lessor1firstname: "string",
  Landlord1identifier: "string",
  Lessor1lastname: "string",
  Lessor2firstname: "string",
  Landlotsize: "number",
  Latitude: "string",
  Lessor1identifier: "string",
  Lessor2identifier: "string",
  Longitude: "string",
  Modifiedby: "string",
  Notes: "string",
  Numberunits: "number",
  Owner1identifier: "string",
  Parish: "string",
  Pguid: "string",
  Photo: "string",
  Plotnumber: "string",
  Propertyid: "string",
  Propertyidsrc: "string",
  Propertymanager1identifier: "string",
  Region: "string",
  Streetname: "string",
  Subcounty: "string",
  Type: "string",
  Subtype: "string",
  Village: "string",
  Websiteurl: "string",
  Totallessors: "number",
  Totaltenants: "number",
  Totalunits: "number",
};

export const getAccessorType = (
  accessor:
    | keyof PropertiesTableSchema
    | AccessorFn<PropertiesTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return PropertySchemaMap[accessor];
};

export const columns: TableColumn<PropertiesTableSchema>[] = [
  {
    header: "Id",
    accessorKey: "Id",
    align: "left",
    cell: ({ getValue }) => {
      const value = getValue() as string;
      return (
        <Link to={`/reports/MDA/rCAPTURE/properties/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Found",
    accessorKey: "Found",
  },
  {
    header: "PGUID",
    accessorKey: "Pguid",
    cell: ({
      getValue,
      row: {
        original: { Id },
      },
    }) => {
      const value = getValue() as string;
      return (
        <Link to={`/reports/MDA/rCAPTURE/properties/${Id}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Identifier",
    accessorKey: "Identifier",
  },
  {
    header: "Geolocation",
    accessorKey: "Geolocation",
  },
  {
    header: "Latitude",
    accessorKey: "Latitude",
    align: "right",
  },
  {
    header: "Longitude",
    accessorKey: "Longitude",
    align: "right",
  },
  {
    header: "BlockNumber",
    accessorKey: "Blocknumber",
  },
  {
    header: "PlotNumber",
    accessorKey: "Plotnumber",
  },
  {
    header: "StreetName",
    accessorKey: "Streetname",
  },
  {
    header: "Region",
    accessorKey: "Region",
  },
  {
    header: "District",
    accessorKey: "District",
  },
  {
    header: "County",
    accessorKey: "County",
  },
  {
    header: "Subcounty",
    accessorKey: "Subcounty",
  },
  {
    header: "Parish",
    accessorKey: "Parish",
  },
  {
    header: "Village",
    accessorKey: "Village",
  },
  {
    header: "BuildingName",
    accessorKey: "Buildingname",
  },
  {
    header: "Type",
    accessorKey: "Type",
  },
  {
    header: "Subtype",
    accessorKey: "Subtype",
  },
  {
    header: "BuildingCondition",
    accessorKey: "Buildingcondition",
  },
  {
    header: "Notes",
    accessorKey: "Notes",
  },
  {
    header: "Photo",
    accessorKey: "Photo",
  },
  {
    header: "Website URL",
    accessorKey: "Websiteurl",
  },
  {
    header: "NumberUnits",
    accessorKey: "Numberunits",
    align: "right",
    format: "number",
  },
  {
    header: "Outcome",
    accessorKey: "Outcome",
  },
  {
    header: "LandLotSize",
    accessorKey: "Landlotsize",
    align: "right",
    format: "number",
  },
  {
    header: "PropertyID",
    accessorKey: "Propertyid",
  },
  {
    header: "PropertyIDSrc",
    accessorKey: "Propertyidsrc",
  },
  {
    header: "Lessor1Identifier",
    accessorKey: "Lessor1identifier",
  },
  {
    header: "Lessor1firstname",
    accessorKey: "Lessor1firstname",
  },
  {
    header: "Lessor1lastname",
    accessorKey: "Lessor1lastname",
  },
  {
    header: "Owner1identifier",
    accessorKey: "Owner1identifier",
  },
  {
    header: "Landlord1Identifier",
    accessorKey: "Landlord1identifier",
  },
  {
    header: "PropertyManager1Identifier",
    accessorKey: "Propertymanager1identifier",
  },
  {
    header: "Lessor2Identifier",
    accessorKey: "Lessor2identifier",
  },
  {
    header: "Lessor2FirstName",
    accessorKey: "Lessor2firstname",
  },
  {
    header: "Totallessors",
    accessorKey: "Totallessors",
    format: "number",
  },
  {
    header: "Totaltenants",
    accessorKey: "Totaltenants",
    format: "number",
  },
  {
    header: "Totalunits",
    accessorKey: "Totalunits",
    format: "number",
  },
];
