// Dependencies
import { Alert } from "@mui/material";
import { styled } from "@mui/material/styles";

const AlertBase = styled(Alert)(() => ({
  width: "100%",
  bottom: "0px",
}));

export default {
  Alert,
  AlertBase,
};
