/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import PropTypes from "prop-types";
import Responsive from "react-responsive";

// Components
import { Grid } from "@mui/material";
import TopMenuBar from "./components/TopMenuBar";
import OrganizationsList from "./components/OrganizationsList";
import Map from "./components/Map";
import Settings from "../settings";
import { DataToLayer } from "./utils/layer";
import dataLayer from "../mock-data";

// Assets
import "./index.css";

const Default = (props) => <Responsive {...props} minWidth={768} />;

class Home extends React.PureComponent {
  static propTypes = {
    properties: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      mapTypeId: "hybrid",
      currentFeature: null,
      route: "home",
    };
  }

  render() {
    const { location, properties } = this.props;
    const { route, currentFeature, mapTypeId } = this.state;
    // const { zone } = profile;
    return (
      <Grid container>
        <Grid item xs={2}>
          <TopMenuBar
            location={location}
            onLinkClick={(ro) => this.setState({ route: ro })}
          />
          <div className="LeftPaneContent tab-content">
            {route !== "settings" && (
              <div className="tab-pane fade">
                <Default>
                  <OrganizationsList
                    currentLayer={{
                      dataLayer: this.props.properties
                        ? this.props.properties
                        : DataToLayer(dataLayer),
                    }}
                    currentFeature={currentFeature}
                    route={route}
                    setCurrentFeature={(propCurrentFeature) =>
                      this.setState({ currentFeature: propCurrentFeature })
                    }
                  />
                </Default>
              </div>
            )}

            {route === "settings" && (
              <div className={`tab-pane fade `}>
                <Settings
                  onUpdateMapType={(propMapTypeId) => {
                    this.setState({ mapTypeId: propMapTypeId });
                  }}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={10}>
          {properties && (
            <Map
              currentLayer={{
                dataLayer: this.props.properties
                  ? this.props.properties
                  : DataToLayer(dataLayer),
              }}
              properties={this.props.properties}
              currentFeature={currentFeature}
              setCurrentFeature={(propCurrentFeature) =>
                this.setState({ currentFeature: propCurrentFeature })
              }
              mapTypeId={mapTypeId}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default Home;
