/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";

// styles
import SC from "./home.styles";

export const Home = () => {
  return <SC.Container></SC.Container>;
};

export default Home;
