/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from "react";
import {
  Container as ContainerBase,
  Card as CardBase,
  ContainerProps,
  Typography as TypographyBase,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import Table from "components/table/table.component";

interface CustomContainerProps extends PropsWithChildren<ContainerProps> {
  maxHeight?: number;
}

const Card = styled(CardBase)(({ theme }) => ({
  border: theme.tableCard.card.border,
  borderRadius: theme.tableCard.card.border,
  margin: theme.tableCard.card.margin,
}));

const ContainerStyled = styled(ContainerBase, {
  shouldForwardProp: (prop) => prop !== "maxHeight",
})<CustomContainerProps>(({ maxHeight }) => ({
  overflowX: "auto",
  maxHeight: maxHeight ? `${maxHeight}px` : "inherit",
  height: maxHeight ? `${maxHeight}px` : "inherit",
}));

const Container = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => <ContainerStyled {...props} ref={ref} />,
);

const Typography = styled(TypographyBase)(({ theme }) => ({
  marginTop: theme.analysis.noAnalysis.marginTop,
  textAlign: "left",
}));

export default {
  Container,
  Table,
  Card,
  Typography,
};
