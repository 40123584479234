/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Grid as GridMui,
  InputLabel as InputLabelMui,
  Box as BoxMui,
  GridProps,
  BoxProps,
  AppBar as AppBarMui,
  Tab as TabMui,
  TabProps,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
} from "@mui/material";

import { styled } from "@mui/material/styles";

interface CustomGridProps extends GridProps {
  MarginTop?: number;
  scrollable?: boolean;
  padding?: number;
}

const Grid = styled(GridMui, {
  shouldForwardProp: (prop) =>
    prop !== "MarginTop" && prop !== "scrollable" && prop !== "padding",
})<CustomGridProps>(({ MarginTop, scrollable, padding }) => ({
  marginTop: MarginTop ? `${MarginTop}px` : "0px",
  alignSelf: "center",
  textAlign: "center",
  overflow: scrollable ? "auto" : "unset",
  padding: padding ? padding : "unset",
}));

interface CustomBoxProps extends BoxProps {
  disabledMarginTop?: boolean;
}

const Box = styled(BoxMui, {
  shouldForwardProp: (prop) => prop !== "disabledMarginTop",
})<CustomBoxProps>(({ disabledMarginTop, theme }) => ({
  marginTop: disabledMarginTop ? "0px" : theme.spacing(4),
}));

interface CustomTabProps extends TabProps {
  isselected?: boolean;
}

const Tab = styled(TabMui, {
  shouldForwardProp: (prop) => prop !== "isselected",
})<CustomTabProps>(({ isselected }) => ({
  backgroundColor: isselected ? "lightgray" : "inherit",
}));

const InputLabel = styled(InputLabelMui)({
  textAlign: "left",
});

const AppBar = styled(AppBarMui)({
  width: "320px",
  boxShadow: "none",
});

const DescriptionBox = styled(BoxMui)({
  marginTop: "0px",
  height: "100%",
});

const ProgressIndicatorWrapper = styled(BoxMui)(() => ({
  position: "absolute",
  top: "50%",
  left: "10%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const ProgressIndicator = CircularProgress;

export default {
  Grid,
  Box,
  InputLabel,
  AppBar,
  Tab,
  FormControlLabel,
  Radio,
  RadioGroup,
  DescriptionBox,
  ProgressIndicatorWrapper,
  ProgressIndicator,
};
