/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { TableColumn } from "components/table/table.component";
import { NonIndividualCompliancePlusRiskReportFragment } from "graphql/hasura/types-and-hooks";
import FormattedValue from "components/formatted-value/formatted-value";

export type ReportTableSchema = Omit<
  NonIndividualCompliancePlusRiskReportFragment,
  | "__typename"
  | "Uratinno"
  | "Isincomesrcrental"
  | "Sumgrossvalue"
  | "Rtnno"
  | "Isamendment"
  | "Rtnperiodyear"
  | "Rentincincome"
  | "Difference"
  | "Grossrentalincome"
  | "Compliancecategory"
  | "Formerbusinessname"
  | "LastUpdated"
  | "Percentagedifference"
  | "Tradingcenter"
  | "Uraname"
  | "RiskCode"
>;

export const NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Rnid: "string",
  Aggregatecustomerid: "string",
  Aggregatename: "string",
  Matchscore: "number",
  Tinno: "string",
  Mobilenumber: "string",
  Emailid: "string",
  Numproperties: "number",
  Numrentedproperties: "number",
  Sumrateablevalue: "number",
  Averagerateperrentedproperty: "number",
  Sumofadjustedincome: "number",
  Source: "string",
  RiskScore: "number",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | AccessorFn<ReportTableSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const taxpayersColumns: TableColumn<ReportTableSchema>[] = [
  {
    header: "RNID",
    accessorKey: "Rnid",
    align: "center",
    cell: ({ cell: { getValue } }) => {
      const value = getValue() as string;
      return (
        <Link to={`/non-individuals/${value}/identification`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Risk Score",
    accessorKey: "RiskScore",
    align: "center",
    meta: { sort: true },
  },
  {
    header: "Source",
    accessorKey: "Source",
    align: "center",
    format: "string",
  },
  {
    header: "Source Customer Id",
    accessorKey: "Aggregatecustomerid",
    align: "center",
    format: "string",
  },
  {
    header: "Source Entity Legal Name",
    accessorKey: "Aggregatename",
    align: "left",
    format: "string",
  },
  {
    header: "rMATCH Score",
    accessorKey: "Matchscore",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { Rnid },
      },
      cell,
    }) => {
      const value = getValue() as number;
      let cellValue = Number(value);
      if (Number(value) === 0) return 0;

      if (cellValue > 0) {
        cellValue /= 100;
      }

      return (
        <Link to={`/non-individuals/${Rnid}/analysis`} color="inherit">
          {cell.row.id === "0" ? (
            <FormattedValue format="percent" value={cellValue} />
          ) : (
            <FormattedValue format="number" value={Number(value)} />
          )}
        </Link>
      );
    },
  },
  {
    header: "Source Tin",
    accessorKey: "Tinno",
    align: "center",
    format: "string",
  },
  {
    header: "Source Mobile",
    accessorKey: "Mobilenumber",
    align: "right",
    format: "string",
  },
  {
    header: "Source Email",
    accessorKey: "Emailid",
    align: "center",
    format: "string",
  },
  {
    header: "Total No. of Properties",
    accessorKey: "Numproperties",
    align: "center",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => (
      <Link to={`/non-individuals/${Rnid}/properties`} color="inherit">
        <>{getValue()}</>
      </Link>
    ),
  },
  {
    header: "Total No. of Rented Properties",
    accessorKey: "Numrentedproperties",
    align: "center",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => (
      <Link to={`/non-individuals/${Rnid}/properties/rented`} color="inherit">
        <>{getValue()}</>
      </Link>
    ),
  },
  {
    header: "Sum Rateable Value of Rented Properties",
    accessorKey: "Sumrateablevalue",
    align: "right",
    format: "currency",
  },
  {
    header: "Sum of the Adjusted Gross Income",
    accessorKey: "Sumofadjustedincome",
    align: "right",
    format: "currency",
  },
  {
    header: "Average Rate Per Rented Property",
    accessorKey: "Averagerateperrentedproperty",
    align: "right",
    format: "currency",
  },
];
