import { makeVar } from "@apollo/client";

export const TOKEN_KEY = "token";

export interface AuthenticationData {
  isLoggedIn: boolean;
  token: string | null;
}

export const initialAuthenticationData: AuthenticationData = {
  isLoggedIn: !!sessionStorage.getItem(TOKEN_KEY),
  token: sessionStorage.getItem(TOKEN_KEY),
};

export const authenticationDataVar = makeVar(initialAuthenticationData);

export default {
  initialAuthenticationData,
  authenticationDataVar,
};
