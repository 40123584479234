/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { UraIndividualTaxFragment } from "graphql/hasura/types-and-hooks";

export type IndividualTaxTableSchema = Omit<
  UraIndividualTaxFragment,
  "__typename"
>;
export interface IndividualTaxDetailTableFilters {
  businessName: string;
  email: string;
  firstName: string;
  phoneNumber: string;
  surname: string;
}

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof IndividualTaxTableSchema,
  "string" | "number"
> = {
  TinNo: "string",
  FirstName: "string",
  SurName: "string",
  IsIncomeSrcRental: "string",
  RtnNo: "string",
  RtnFromDt: "string",
  RtnToDt: "string",
  RtnDt: "string",
  RtnPeriodYear: "string",
  IsAmendment: "string",
  RentincTax: "number",
  TotIncome: "number",
  TotTax: "number",
  TotRentalIncm: "number",
  PartnerRentIncome: "number",
  GrossRentalIncome: "number",
  DeductIncmTax: "number",
  MotgageIntDecd: "number",
  NetRentIncm: "number",
  ThresholdAmnt: "number",
  ChargeableIncome: "number",
  TaxAssessed: "number",
  IsResident: "string",
  EmplymntIncome: "number",
  EmplymntTax: "number",
  EmplymntRate: "number",
  RentincRate: "number",
  PropincIncome: "number",
  PropincTax: "number",
  PropincRate: "number",
  TotRate: "number",
  ReturnType: "string",
};
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof IndividualTaxDetailTableFilters,
  string
> = {
  businessName: "BsnsName",
  email: "EmailId",
  firstName: "FirstName",
  phoneNumber: "MobileNumber",
  surname: "SurName",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualTaxTableSchema
    | AccessorFn<IndividualTaxTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const individualTaxTableColumns: TableColumn<IndividualTaxTableSchema>[] =
  [
    {
      header: "TIN No.",
      accessorKey: "TinNo",
      align: "center",
      cell: ({
        getValue,
        row: {
          original: { TinNo },
        },
      }) => {
        const value = getValue();
        return (
          <Link to={`/individuals/${TinNo}/identification`} color="inherit">
            <>{value}</>
          </Link>
        );
      },
    },
    {
      header: "First Name",
      accessorKey: "FirstName",
      align: "left",
      format: "string",
    },
    {
      header: "Surname",
      accessorKey: "SurName",
      align: "left",
      format: "string",
    },
    {
      header: "Income Rental?",
      accessorKey: "IsIncomeSrcRental",
      align: "center",
      format: "string",
    },
    {
      header: "Unique Return Identification Number",
      accessorKey: "RtnNo",
      align: "center",
      cell: ({
        getValue,
        row: {
          original: { TinNo, RtnNo },
        },
      }) => {
        return (
          <Link
            to={`/individuals/${TinNo}/tax-history/${RtnNo}`}
            color="inherit"
          >
            <>{getValue()}</>
          </Link>
        );
      },
    },
    {
      header: "ReturnType",
      accessorKey: "ReturnType",
      format: "string",
    },
    {
      header: "Return Period From Date",
      accessorKey: "RtnFromDt",
      align: "center",
      format: "date",
    },
    {
      header: "Return Period To Date",
      accessorKey: "RtnToDt",
      align: "center",
      format: "date",
    },
    {
      header: "Tax Year",
      accessorKey: "RtnPeriodYear",
      align: "center",
      meta: { sort: true },
    },
    {
      header: "Date Return Submitted",
      accessorKey: "RtnDt",
      align: "center",
      format: "date",
      meta: { sort: true },
    },
    {
      header: "Type of Return - Original/Amended",
      accessorKey: "IsAmendment",
      align: "center",
      format: "string",
    },
    {
      header: "Total Rental Income Tax",
      accessorKey: "RentincTax",
      align: "right",
      format: "currency",
    },
    {
      header: "Total Income",
      accessorKey: "TotIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Total Income Tax",
      accessorKey: "TotTax",
      align: "right",
      format: "currency",
    },
    {
      header: "Gross Rental Income Other than Partnership",
      accessorKey: "TotRentalIncm",
      align: "right",
      format: "currency",
    },
    {
      header: "Share of Gross Rental Income from Partnership",
      accessorKey: "PartnerRentIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Total Gross Rental Income (1+2)",
      accessorKey: "GrossRentalIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Allowable Deduction (20% of Gross)",
      accessorKey: "DeductIncmTax",
      align: "right",
      format: "currency",
    },
    {
      header: "Interest Expense",
      accessorKey: "MotgageIntDecd",
      align: "right",
      format: "currency",
    },
    {
      header: "Rental Income before Adjustment",
      accessorKey: "NetRentIncm",
      align: "right",
      format: "currency",
    },
    {
      header: "Threshold for Rental Income",
      accessorKey: "ThresholdAmnt",
      align: "right",
      format: "currency",
    },
    {
      header: "Chargeable Income",
      accessorKey: "ChargeableIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Tax Payable",
      accessorKey: "TaxAssessed",
      align: "right",
      format: "currency",
    },
    {
      header: "Resident Status - Resident/NonResident",
      accessorKey: "IsResident",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      header: "Employment Chargeable Income",
      accessorKey: "EmplymntIncome",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Employment Chargeable Income Total Tax",
      accessorKey: "EmplymntTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Employment Chargeable Tax Rate",
      accessorKey: "EmplymntRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Rental Tax Rate",
      accessorKey: "RentincRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Property Income",
      accessorKey: "PropincIncome",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Property Income Total Tax",
      accessorKey: "PropincTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Property Tax Rate",
      accessorKey: "PropincRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      header: "Total Tax Rate",
      accessorKey: "TotRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
  ];
