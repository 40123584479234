/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";

// Components
import SC from "./main.styles";

export const ReportsPage = () => {
  return <SC.Box pl={2} pr={2} pt={2}></SC.Box>;
};

export default ReportsPage;
