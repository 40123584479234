/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Grid as GridBase, Box as BoxBase, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled(GridBase)({
  flexGrow: 1,
  paddingLeft: "1.14rem",
});

const Box = BoxBase;

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: theme.table.toolbar.fontSize,
  textAlign: "left",
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(3),
  opacity: 0.8,
}));

export default {
  Container,
  Title,
  Box,
};
