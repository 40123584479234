/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography as TypographyBase,
  Container as ContainerBase,
  Paper as PaperBase,
  Grid,
} from "@mui/material";
import Table from "components/table/table.component";
import LabelKeyValue from "components/label-key-value/label-key-value.component";
import SelectField from "components/select-field/select-field.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import { styled } from "@mui/material/styles";

const Typography = styled(TypographyBase)(({ theme }) => ({
  fontSize: theme.UtilitiesTab.header.fontSize,
  fontWeight: "bold",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const Paper = styled(PaperBase)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Container = styled(ContainerBase)(() => ({
  textAlign: "left",
}));

export default {
  Table,
  Typography,
  Container,
  Paper,
  Grid,
  LabelKeyValue,
  SelectField,
  ProgressIndicator,
};
