/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton as IconButtonMui } from "@mui/material";
import { styled } from "@mui/material/styles";

const IconButton = styled(IconButtonMui)(({ theme }) => ({
  marginRight: theme.spacing(2),
  padding: theme.table.IconButton.paddindWithLef,
}));

export default {
  IconButton,
};
