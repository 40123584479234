/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { UraIndividualsAssociatedBusinessFragment } from "graphql/hasura/types-and-hooks";

export type IndividualTableSchema = Omit<
  UraIndividualsAssociatedBusinessFragment,
  "__typename"
>;
export interface AssociatedRegisteredIndividualsToNonIndividualsTableFilters {
  businessName: string;
  firstName: string;
  surname: string;
}

// @ts-ignore
export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof IndividualTableSchema,
  "string" | "number" | "Date"
> = {
  Surname: "string",
  TinNo: "string",
  // RegAssociatedEntityDtlId: "string",
  FirstName: "string",
  OtherName: "string",
  FamilyName: "string",
  AssociateTin: "string",
  EntityDesignation: "string",
  MainActivity: "string",
  ActvtyDesc: "string",
};
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof AssociatedRegisteredIndividualsToNonIndividualsTableFilters,
  string
> = {
  businessName: "AssociateTaxPayerName",
  firstName: "FirstName",
  surname: "Surname",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualTableSchema
    | AccessorFn<IndividualTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const individualTableColumns: TableColumn<IndividualTableSchema>[] = [
  {
    header: "TIN No.",
    accessorKey: "TinNo",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { TinNo },
      },
    }) => {
      return (
        <Link to={`/individuals/${TinNo}/identification`} color="inherit">
          <> {getValue()}</>
        </Link>
      );
    },
    meta: { sort: true },
  },
  {
    header: "First Name",
    accessorKey: "FirstName",
    align: "left",
    format: "string",
  },
  {
    header: "Surname",
    accessorKey: "Surname",
    align: "left",
    format: "string",
  },
  {
    header: "Other Name",
    accessorKey: "OtherName",
    align: "left",
    format: "string",
  },
  {
    header: "Family Name",
    accessorKey: "FamilyName",
    align: "left",
    format: "string",
  },
  {
    header: "Associate TIN",
    accessorKey: "AssociateTin",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { AssociateTin },
      },
    }) => {
      return (
        <Link
          to={`/non-individuals/${AssociateTin}/identification`}
          color="inherit"
        >
          <> {getValue()}</>
        </Link>
      );
    },
  },
  {
    header: "Legal Entity Name",
    accessorKey: "AssociateTaxPayerName",
    align: "left",
    format: "string",
  },
  {
    header: "Entity Designation",
    accessorKey: "EntityDesignation",
    align: "left",
    format: "string",
  },
  {
    header: "Main Activity",
    accessorKey: "MainActivity",
    align: "left",
    format: "string",
  },
  {
    header: "Activity Description",
    accessorKey: "ActvtyDesc",
    align: "left",
    format: "string",
  },
];
