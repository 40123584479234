/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// Assets
import bannerUgandaArms from "assets/images/Mask_Group_73.png";
import bannerBackground from "assets/images/Rectangle_Copy_12.png";

const BannerNavigation = styled(Box)(({ theme }) => ({
  fontSize: "initial",
  backgroundColor: "white",
  backgroundImage: `url(${bannerUgandaArms}), url(${bannerBackground})`,
  [theme.breakpoints.down("xl")]: {
    height: theme.bannerSmall.height,
  },
  [theme.breakpoints.up("xl")]: {
    height: theme.banner.height,
  },
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  display: "flex",
}));

export default {
  BannerNavigation,
};
