// Dependencies
import { Grid as GridBase, Box as BoxBase } from "@mui/material";

import { styled } from "@mui/material/styles";

const Container = styled(GridBase)({
  flexGrow: 1,
  paddingLeft: "1.14rem",
});

const Box = styled(BoxBase)(() => ({
  "::-webkit-scrollbar": {
    display: "none",
  },
  overflow: "auto",
}));

export default {
  Container,
  Box,
};
