/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Container, Checkbox as CheckboxMui } from "@mui/material";
import Table from "components/table/table.component";
import { styled } from "@mui/material/styles";

const MainContainer = styled(Container)({});

const UserTable = styled(Table)({
  leftPane: {
    width: "30%",
    minWidth: "30%",
  },
});

const Checkbox = styled(CheckboxMui)(({ theme }) => ({
  padding: theme.table.IconButton.padding,
}));

export default {
  UserTable,
  MainContainer,
  Checkbox,
};
