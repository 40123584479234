import {
  Container as ContainerBase,
  Box as BoxBase,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const Container = styled(ContainerBase)(() => ({
  overflowX: "hidden",
  maxWidth: "none !important",
}));

const Box = BoxBase;

export default {
  Container,
  Box,
  Typography,
};
