/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import React, { PropsWithChildren } from "react";
import {
  Container as ContainerBase,
  Box,
  Typography,
  IconButton as IconButtonBase,
  ContainerProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Add } from "@mui/icons-material";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Note from "components/note/note.component";
import NoteCreate from "components/note-create/note-create.component";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ts from "typescript";

interface CustomContainerProps extends PropsWithChildren<ContainerProps> {
  maxHeight?: number;
  expanded?: boolean;
}

const Container = styled(ContainerBase)(({ theme }) => ({
  backgroundColor: theme.pane.backgroundColor,
  textAlign: theme.notes.tab.textAlign,
  padding: theme.notes.tab.padding,
}));

const TitleActionsContainer = styled(Box)(({ theme }) => ({
  display: theme.notes.toolbarAction.display,
  alignItems: theme.notes.toolbarAction.alignItems,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.notes.title.fontWeight,
  fontSize: theme.notes.title.fontSize,
  marginRight: theme.spacing(1),
}));

const ContainerStyled = styled(ContainerBase, {
  shouldForwardProp: (prop) => prop !== "maxHeight",
})<CustomContainerProps>(({ maxHeight }) => ({
  overflowX: "auto",
  maxHeight: maxHeight ? `${maxHeight}px` : "inherit",
  height: maxHeight ? `${maxHeight}px` : "inherit",
}));

const NotesContainer = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => <ContainerStyled {...props} ref={ref} />,
);

const IconButton = styled(IconButtonBase)(({ theme }) => ({
  padding: theme.spacing(1),
  "&:not(.Mui-disabled) .MuiSvgIcon-root": {
    color: theme.table.toolbarActions.iconColor,
  },
}));

export default {
  Container,
  TitleActionsContainer,
  Title,
  IconButton,
  Add,
  Note,
  NoteCreate,
  ProgressIndicator,
  NotesContainer,
};
