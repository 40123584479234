/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from "react";
import _ from "lodash";

// GraphQL
import {
  ExportDataQuery,
  QueryExportDataArgs,
  useExportDataLazyQuery,
} from "graphql/types-and-hooks";
import { ColumnDef } from "@tanstack/react-table";
import handleExportToCSV, {
  handleExportDataReportToCSV,
} from "components/table/table.actions";
import { ApolloError } from "@apollo/client";

export const useExportData = (
  tableName: string,
  queryVariables?: Record<string, unknown>,
): [
  (
    columns: ColumnDef<any, any>[],
    newTableName?: string | undefined,
    data?: unknown[] | undefined,
  ) => void,
  {
    data: ExportDataQuery | undefined;
    loading: boolean;
    error: ApolloError | undefined;
  },
] => {
  const [
    GetExportDataFn,
    { data: exportData, loading: exportLoading, error: exportError },
  ] = useExportDataLazyQuery({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (exportData) {
      handleExportToCSV(exportData.ExportData, tableName);
    }
  }, [exportData, tableName]);

  const exportDataFnWrapper = (
    columns: ColumnDef<any, any>[],
    newTableName?: string,
    data?: unknown[],
  ) => {
    if (data) {
      return handleExportDataReportToCSV(columns, data, tableName);
    }
    const tableColumns: QueryExportDataArgs["tableColumns"] = columns.flatMap(
      (column) =>
        column.header !== "Map"
          ? [{ heading: `${column.header}`, fieldName: `${column.id}` }]
          : [],
    );

    if (tableColumns[tableColumns.length - 1].heading === "Map") {
      tableColumns.pop();
    }

    const params = _.omit({ where: {}, ...queryVariables }, [
      "offset",
      "limit",
    ]);

    return GetExportDataFn({
      variables: {
        tableName: newTableName || tableName,
        tableColumns,
        tableFilterJSONString: JSON.stringify(params),
      },
    });
  };

  return [
    exportDataFnWrapper,
    { data: exportData, loading: exportLoading, error: exportError },
  ];
};

export default useExportData;
