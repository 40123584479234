/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import React from "react";
import { useTheme } from "@mui/material";

import DatePicker from '@mui/lab/DatePicker';
import { DatePickerProps as Props } from '@mui/lab/DatePicker';

// Assets
import SC from "components/text-field/text-field.styles";

export type DateTimeFieldProps = Props<Date>;;

export const DateTimeField: React.FC<DateTimeFieldProps> = (props) => {
  const theme: any = useTheme();

  return (
    <DatePicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      variant="inline"
      inputVariant="filled"
      format={theme.formats.datetime}
      TextFieldComponent={SC.TextField}
    />
  );
};

export default DateTimeField;
