/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  Grid as GridMui,
  InputLabel as InputLabelMui,
  Checkbox,
  OutlinedInput,
  Box as BoxMui,
  GridProps,
  BoxProps,
} from "@mui/material";

import { styled } from "@mui/material/styles";

interface CustomGridProps extends GridProps {
  disabledMarginTop?: boolean;
  alignToRight?: boolean;
}

const Grid = styled(GridMui, {
  shouldForwardProp: (prop) =>
    prop !== "disabledMarginTop" && prop !== "alignToRight",
})<CustomGridProps>(({ disabledMarginTop, alignToRight }) => ({
  alignSelf: "center",
  textAlign: "center",
  marginTop: disabledMarginTop ? "0px" : "2px",
  justifyContent: alignToRight ? "right" : "inherit",
}));

interface CustomBoxProps extends BoxProps {
  disabledMarginTop?: boolean;
}

const Box = styled(BoxMui, {
  shouldForwardProp: (prop) => prop !== "disabledMarginTop",
})<CustomBoxProps>(({ disabledMarginTop }) => ({
  marginTop: disabledMarginTop ? "0px" : "4px",
}));

const InputLabel = styled(InputLabelMui)({
  textAlign: "left",
  transform: "translate(12px, 10px) scale(0.75)",
});

const InputLabelCenter = styled(InputLabelMui)({
  textAlign: "center",
});

export default {
  Grid,
  InputLabel,
  InputLabelCenter,
  Box,
  OutlinedInput,
  Checkbox,
};
