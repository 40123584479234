/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React, { useState } from "react";
import { IconProps, IconButtonProps } from "@mui/material";

import SC from "./popover-button.styles";

export interface PopoverButtonProps extends IconButtonProps {
  btnIcon: IconProps;
  popoverMessage?: string | JSX.Element;
  popoverHandler: () => void;
  buttonsActive: boolean;
}

export const PopoverButton: React.FC<PopoverButtonProps> = ({
  btnIcon,
  popoverMessage,
  popoverHandler,
  buttonsActive = true,
  ...iconButtonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const open = Boolean(anchorEl);
  const popOverId = open ? "popover" : undefined;

  const callHandler = () => {
    popoverHandler();
    setAnchorEl(null);
  };

  return (
    <>
      <SC.IconButton
        {...iconButtonProps}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <>{btnIcon}</>
      </SC.IconButton>
      <SC.Popover
        id={popOverId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SC.Paper>
          <SC.Message>{popoverMessage}</SC.Message>

          {buttonsActive && (
            <SC.Button key="cancel" onClick={() => setAnchorEl(null)}>
              Cancel
            </SC.Button>
          )}
          {buttonsActive && (
            <SC.Button key="confirm" onClick={callHandler}>
              Confirm
            </SC.Button>
          )}
        </SC.Paper>
      </SC.Popover>
    </>
  );
};

export default PopoverButton;
