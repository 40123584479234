/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { ExpandMore as ExpandMoreBase } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

const TaxHistoryViewContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.taxHistory.padding,
}));

const TaxHistoryViewAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: theme.taxHistory.accordion.boxShadow,
  "&:not(:last-child)": {
    borderBottom: theme.taxHistory.accordion.borderBottom,
  },
  "&:before": {
    display: theme.taxHistory.accordion.display,
  },
  "&$expanded": {
    margin: theme.taxHistory.accordion.margin,
  },
}));

const TaxHistoryItemHeader = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: theme.taxHistory.header.flexDirection,
  padding: theme.taxHistory.header.padding,
  alignItems: theme.taxHistory.header.alignItems,
  textAlign: theme.taxHistory.header.textAlign,
  "& .MuiAccordionSummary-content": {
    paddingLeft: theme.taxHistory.header.content.paddingLeft,
    paddingTop: theme.taxHistory.header.content.paddingTop,
  },
  "& .MuiAccordionSummary-expandIcon": {
    padding: theme.taxHistory.header.expandIcon.padding,
  },
  "& .MuiAccordionSummary-content.Mui-expanded, .MuiAccordionSummary-content": {
    margin: theme.taxHistory.header.expanded.margin,
  },
}));

const ExpandMore = styled(ExpandMoreBase)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const AccordionHeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.taxHistory.financialSummary.header.fontSize,
  fontWeight: theme.taxHistory.financialSummary.header.fontWeight,
}));

const TaxHistoryContent = styled(AccordionDetails)(({ theme }) => ({
  paddingTop: theme.taxHistory.content.paddingTop,
  paddingLeft: theme.taxHistory.content.paddingLeft,
  textAlign: theme.taxHistory.content.textAlign,
  height: theme.taxHistory.content.height,
  overflow: theme.taxHistory.content.overflow,
  "& .MuiContainer-root": {
    padding: theme.taxHistory.content.root.padding,
    marginTop: theme.taxHistory.content.root.marginTop,
    paddingRight: theme.taxHistory.content.root.paddingRight,
  },
}));

export default {
  TaxHistoryViewContainer,
  TaxHistoryViewAccordion,
  TaxHistoryItemHeader,
  TaxHistoryContent,
  Container,
  Typography,
  AccordionHeaderTypography,
  AccordionSummary,
  ExpandMore,
  Grid,
  Box,
};
