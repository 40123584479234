/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import React from "react";
import { Menu, SvgIcon } from "@mui/material";
import { useLocation } from "react-router-dom";

// Components
import AdministrationMenuItem from "components/administration-menu-item/administration-menu-item.component";
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";

// Assets
import { ReactComponent as AdminIcon } from "assets/icons/admin_panel_settings_black_24dp.svg";
import SC from "./administration-menu.styles";

type Item = {
  id: string;
  name: string;
  url?: string;
  children?: Item[];
  parent?: boolean;
};

const AdministrationMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const location = useLocation();
  const urlLocation = location.pathname.split("/");

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = () => {
    setAnchorEl(null);
  };

  const menuItems: Item[] = [
    {
      id: "582e4aeb-316c-412b-8e5d-fcc67e6a059b",
      name: "User Management",
      parent: true,
      children: [
        {
          id: "21ee291d-2f6a-4e46-8e49-df2768d1775f",
          name: "Users",
          url: "/administration/user-management/users",
        },
        {
          id: "ac999021-2e94-4e8b-8392-5140f4eeb0f6",
          name: "Groups",
          url: "/administration/user-management/groups",
        },
        {
          id: "e4666853-4ffb-4132-9290-acd199434411",
          name: "Roles",
          url: "/administration/user-management/roles",
        },
      ],
    },
  ];

  const appPermissionValidator = useAppPermissionValidator();

  if (appPermissionValidator?.(APP_PERMISSION.ADMIN_SET_TAX_EXEMPT)) {
    menuItems.push({
      id: "582e4feb-316d-412b-8e5r-4cc67v6a056b",
      name: "Tax Exempt Organisations",
      url: "/administration/tax-exempt-organisations",
      parent: true,
    });
  }

  if (appPermissionValidator?.(APP_PERMISSION.ADMIN_SET_VISIBILITY_LEVEL)) {
    menuItems.push({
      id: "20823e42-935a-4bf6-89fc-253c92b5bd58",
      name: "Visibility",
      url: "/administration/visibility",
      parent: true,
    });
  }

  menuItems.push({
    id: "",
    name: "Revision",
    url: "/administration/revision",
    parent: true,
  });

  return (
    <>
      <SC.ButtonAdmin
        onClick={handleClick}
        disabled={urlLocation[1] !== "administration"}
      >
        <SvgIcon component={AdminIcon} />
        <span>Administration</span>
      </SC.ButtonAdmin>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 4,
        }}
      >
        {menuItems.map((item: Item) => {
          const { id, name, url, children, parent } = item;
          return (
            <AdministrationMenuItem
              key={id}
              id={id}
              name={name}
              url={url}
              parent={parent}
              childrenItems={children}
              onClick={handleItemClick}
            />
          );
        })}
      </Menu>
    </>
  );
};

export default AdministrationMenu;
