/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { UraIndividualFragment } from "graphql/hasura/types-and-hooks";

export type IndividualTableSchema = Omit<UraIndividualFragment, "__typename">;
export interface IndividualRegistrationTableFilters {
  businessName: string;
  email: string;
  firstName: string;
  phoneNumber: string;
  surname: string;
}

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof IndividualTableSchema,
  "string" | "number" | "Date"
> = {
  TinNo: "string",
  TaxPayerType: "string",
  TaxPayerName: "string",
  TaxPayerId: "string",
  SurName: "string",
  RefTin: "string",
  RefSurname: "string",
  RefPrsnDesgn: "string",
  RefOtherName: "string",
  RefMobileNumber: "string",
  RefLandlineNumber: "string",
  RefFirstName: "string",
  RefFamilyName: "string",
  RefEmailId: "string",
  PostaddrId: "string",
  PostaddrDistictName: "string",
  OtherName: "string",
  NssfId: "string",
  NationalId: "string",
  MobileNumber: "string",
  MiddleName: "string",
  LandlineNumber: "string",
  IsIncomeSrcRental: "string",
  IsIncomeSrcPrpty: "string",
  IsIncomeSrcEmp: "string",
  IsIncomeSrcBsns: "string",
  HomeAddrVillageName: "string",
  HomeAddrTradeCentre: "string",
  HomeAddrTownName: "string",
  HomeAddrSubCountyName: "string",
  HomeAddrStrretAddr: "string",
  HomeAddrPlotno: "string",
  HomeAddrParishName: "string",
  HomeAddrLocalCouncil: "string",
  HomeAddrDistrictName: "string",
  HomeAddrBuildingname: "string",
  Gender: "string",
  FirstName: "string",
  FamilyName: "string",
  ExistTinNo: "string",
  EmailId: "string",
  CitizenCountry: "string",
  BsnsName: "string",
  BirthState: "string",
  BirthDt: "Date",
  BirthCountry: "string",
  TownName: "string",
  AuthctcdTin: "string",
  AuthctcdSurname: "string",
  AuthctcdPrsnDesgn: "string",
  AuthctcdOtherName: "string",
  AuthctcdMobileNumber: "string",
  AuthctcdLandlineNumber: "string",
  AuthctcdFirstName: "string",
  AuthctcdFamilyName: "string",
  AuthctcdEmailId: "string",
  AppliedForTin: "string",
};
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof IndividualRegistrationTableFilters,
  string
> = {
  businessName: "BsnsName",
  email: "EmailId",
  firstName: "FirstName",
  phoneNumber: "MobileNumber",
  surname: "SurName",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualTableSchema
    | AccessorFn<IndividualTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const individualTableColumns: TableColumn<IndividualTableSchema>[] = [
  {
    header: "TIN No.",
    accessorKey: "TinNo",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { TinNo },
      },
    }) => {
      return (
        <Link to={`/individuals/${TinNo}/identification`} color="inherit">
          <>{getValue()}</>
        </Link>
      );
    },
    meta: { sort: true },
  },
  {
    header: "First Name",
    accessorKey: "FirstName",
    align: "left",
    format: "string",
  },
  {
    header: "Middle Name",
    accessorKey: "MiddleName",
    align: "left",
    format: "string",
  },
  {
    header: "Surname",
    accessorKey: "SurName",
    align: "left",
    format: "string",
  },
  {
    header: "Family Name",
    accessorKey: "FamilyName",
    align: "left",
    format: "string",
  },
  {
    header: "Mobile Phone No.",
    accessorKey: "MobileNumber",
    align: "center",
    format: "string",
  },
  {
    header: "Birth Date",
    accessorKey: "BirthDt",
    align: "center",
    format: "date",
  },
  {
    header: "Email Address",
    accessorKey: "EmailId",
    align: "center",
    format: "string",
  },
  {
    header: "National ID",
    accessorKey: "NationalId",
    align: "center",
    format: "string",
  },
  {
    header: "Home District",
    accessorKey: "HomeAddrDistrictName",
    align: "center",
    format: "string",
  },
  {
    header: "Home Parish",
    accessorKey: "HomeAddrParishName",
    align: "center",
    format: "string",
  },
  {
    header: "Home Plot Number",
    accessorKey: "HomeAddrPlotno",
    align: "center",
    format: "string",
  },
  {
    header: "Home Street Name",
    accessorKey: "HomeAddrStrretAddr",
    align: "center",
    format: "string",
  },
  {
    header: "Home Sub-County",
    accessorKey: "HomeAddrSubCountyName",
    align: "center",
    format: "string",
  },
  {
    header: "Home Town",
    accessorKey: "HomeAddrTownName",
    align: "center",
    format: "string",
  },
  {
    header: "Is Income Source Rental?",
    accessorKey: "IsIncomeSrcRental",
    align: "center",
    format: "string",
  },
  {
    header: "Previously applied for TIN?",
    accessorKey: "AppliedForTin",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Authenticator Email",
    accessorKey: "AuthctcdEmailId",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Authenticator Family Name",
    accessorKey: "AuthctcdFamilyName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Authenticator First Name",
    accessorKey: "AuthctcdFirstName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Authenticator Landline Phone No.",
    accessorKey: "AuthctcdLandlineNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Authenticator Mobile Phone No.",
    accessorKey: "AuthctcdMobileNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Authenticator Other Name",
    accessorKey: "AuthctcdOtherName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Authenticator Designation",
    accessorKey: "AuthctcdPrsnDesgn",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Authenticator Surname",
    accessorKey: "AuthctcdSurname",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Authenticator TIN",
    accessorKey: "AuthctcdTin",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Birth City",
    accessorKey: "TownName",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Birth Country",
    accessorKey: "BirthCountry",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Birth State",
    accessorKey: "BirthState",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Business Name",
    accessorKey: "BsnsName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Citizen Country",
    accessorKey: "CitizenCountry",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Existing TIN",
    accessorKey: "ExistTinNo",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Gender",
    accessorKey: "Gender",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Home Building Name",
    accessorKey: "HomeAddrBuildingname",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Home Local Council",
    accessorKey: "HomeAddrLocalCouncil",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Home Trade Centre",
    accessorKey: "HomeAddrTradeCentre",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Home Village",
    accessorKey: "HomeAddrVillageName",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Is Income Source Business?",
    accessorKey: "IsIncomeSrcBsns",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Is Income Source Employment?",
    accessorKey: "IsIncomeSrcEmp",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Is Income Source Property?",
    accessorKey: "IsIncomeSrcPrpty",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Landline Phone No.",
    accessorKey: "LandlineNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "NSSF ID",
    accessorKey: "NssfId",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Other Name",
    accessorKey: "OtherName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Post Address District",
    accessorKey: "PostaddrDistictName",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Post Office Box No.",
    accessorKey: "PostaddrId",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Referee Email",
    accessorKey: "RefEmailId",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Referee Family Name",
    accessorKey: "RefFamilyName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Referee First Name",
    accessorKey: "RefFirstName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Referee Landline Phone No.",
    accessorKey: "RefLandlineNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Referee Mobile Phone No.",
    accessorKey: "RefMobileNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Referee Other Name",
    accessorKey: "RefOtherName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Referee Designation",
    accessorKey: "RefPrsnDesgn",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Referee Surname",
    accessorKey: "RefSurname",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Referee TIN",
    accessorKey: "RefTin",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Tax Payer ID",
    accessorKey: "TaxPayerId",
    align: "right",
    hiddenColumn: true,
    format: "number",
  },
  {
    header: "Tax Payer Name",
    accessorKey: "TaxPayerName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    header: "Entity Type",
    accessorKey: "TaxPayerType",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
];
