/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { AggregatePropertyFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import React from "react-dom";
import { Link } from "react-router-dom";

export type AggregatePropertySchema = Omit<
  AggregatePropertyFragment,
  | "__typename"
  | "ContactMobile2"
  | "ContactParishWard"
  | "ContactSubcountryDivision"
  | "ContactVillageCellZone"
  | "ContactAddress"
  | "ContactCountry"
  | "ContactCountyMunicipality"
  | "ContactDistrict"
  | "Id"
  | "LcSource"
>;

export interface PropertyRegistrationTableFilters {
  email: string;
  firstName: string;
  surname: string;
  businessName: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof PropertyRegistrationTableFilters,
  string | string[]
> = {
  firstName: ["Firstname"],
  surname: ["Surname"],
  email: ["Email"],
  businessName: ["BusinessName"],
};

export const AggregatePropertySchemaMap: Record<
  keyof AggregatePropertySchema,
  "string" | "number"
> = {
  Rnid: "string",
  RnidOwner: "string",

  CustomerId: "string",
  LocationAddress: "string",
  Address: "string",
  PlotNo: "string",
  FlatNo: "string",
  HouseNo: "string",
  BlockNo: "string",
  StreetId: "string",
  RoadOthers: "string",
  ParticularsFrontages: "string",
  Village: "string",
  Parish: "string",
  Division: "string",
  PropertyNo: "string",
  SerialNo: "string",
  Latitude: "number",
  Longitude: "number",
  PropertyType: "string",
  SubPropertyType: "string",
  PropertyRentedStatus: "string",
  GrossValue: "number",
  RateableValue: "number",
  CurrentRateableValue: "number",
  StatusId: "number",
  CamvId: "string",
  CreatedBy: "string",
  CreatedDate: "string",

  Firstname: "string",
  Surname: "string",
  MotherMaidenName: "string",
  EntityLegalName: "string",
  Birthdate: "string",
  Mobile: "string",
  SecondMobile: "string",
  Email: "string",
  BusinessName: "string",
  Coin: "string",
  MiddleName: "string",
  Tin: "string",

  OwnerTin: "string",
  OwnerFirstname: "string",
  OwnerLegalSurnameMaiden: "string",
  OwnerTitle: "string",
  ContactMobile1: "string",
  ContactLandline: "string",
  ContactEmail: "string",
  AccomodationBedrooms: "string",
  BuildingName: "string",
  CordinateX: "string",
  CordinateY: "string",
  PropertySubType: "string",
  Rv: "string",
  Gv: "string",
  ParticularsBlockName: "string",
  ParticularsBlockNumber: "string",
  ParticularsFolioNumber: "string",
  ParticularsHouseNumber: "string",
  ParticularsIsTitled: "string",
  ParticularsPlotNumber: "string",
  ParticularsPlotShape: "string",
  ParticularsPropertyName: "string",
  ParticularsRoadStreet: "string",
  ParticularsTypeOfLandInterest: "string",
  AccomodationBathroom: "string",
  AccomodationBathroomShower: "string",
  AccomodationBuiltUpArea: "string",
  AccomodationComment: "string",
  AccomodationDiningRooms: "string",
  AccomodationEntertainmentRoom: "string",
  AccomodationEnviromentalNoise: "string",
  AccomodationGarage: "string",
  AccomodationKitchen: "string",
  AccomodationLaundry: "string",
  AccomodationOthers: "string",
  AccomodationSecurity: "string",
  AccomodationShops: "string",
  AccomodationShower: "string",
  AccomodationSittingRoomLoungeNumber: "string",
  AccomodationStore: "string",
  AccomodationStudyRoom: "string",
  AccomodationVerandah: "string",
  AccomodationWc: "string",
  AccomodationWcBathroom: "string",
  AccomodationWcBathroomShower: "string",
  AccomodationWcShower: "string",
  ParticularsAccess: "string",
  ParticularsGpsEastingsUtm: "string",
  ParticularsGpsNorthingsUtm: "string",
  ParticularsLandReference: "string",
  ParticularsNeighborhood: "string",
  ParticularsTopographyOthers: "string",
  ParticularsTopograpy: "string",
  ParticularsVolume: "string",
  PropertyOwnerOccupiedOrRented: "string",
  Pvillage: "string",
};

export const getAccessorType = (
  accessor:
    | keyof AggregatePropertySchema
    | AccessorFn<AggregatePropertySchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return AggregatePropertySchemaMap[accessor];
};

export const propertyTableColumns: TableColumn<AggregatePropertySchema>[] = [
  {
    header: "Property RNID",
    accessorKey: "Rnid",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { Rnid },
      },
    }) => {
      const value = getValue() as string;
      return (
        <Link to={`/properties/${Rnid}/details`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Owner RNID",
    accessorKey: "RnidOwner",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { RnidOwner },
      },
    }) => {
      const value = getValue() as string;

      if (RnidOwner !== null && RnidOwner !== undefined) {
        return RnidOwner.startsWith("IND") ? (
          <Link to={`/individuals/${RnidOwner}/identification`} color="inherit">
            {value}
          </Link>
        ) : (
          <Link
            to={`/non-individuals/${RnidOwner}/identification`}
            color="inherit"
          >
            {value}
          </Link>
        );
      }
      return null;
    },
  },
  {
    header: "Property No.",
    accessorKey: "PropertyNo",
    align: "center",
  },
  {
    header: "Business Name",
    accessorKey: "BusinessName",
    align: "left",
    format: "string",
  },
  {
    header: "COIN",
    accessorKey: "Coin",
    align: "right",
    format: "string",
  },
  {
    header: "Legal Name",
    accessorKey: "EntityLegalName",
    align: "left",
    format: "string",
  },
  {
    header: "First Name",
    accessorKey: "Firstname",
    align: "left",
    format: "string",
  },
  {
    header: "Middle Name",
    accessorKey: "MiddleName",
    align: "left",
    format: "string",
  },
  {
    header: "Surname",
    accessorKey: "Surname",
    align: "left",
    format: "string",
  },
  {
    header: "Mother Maiden Name",
    accessorKey: "MotherMaidenName",
    align: "left",
    format: "string",
  },
  {
    header: "Aggregate TIN No.",
    accessorKey: "Tin",
    align: "center",
    format: "string",
  },
  {
    header: "Customer ID",
    accessorKey: "CustomerId",
    align: "center",
    format: "string",
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "center",
    format: "string",
  },
  {
    header: "Plot No.",
    accessorKey: "PlotNo",
    align: "center",
    format: "string",
  },
  {
    header: "Flat No.",
    accessorKey: "FlatNo",
    align: "center",
    format: "string",
  },
  {
    header: "House No.",
    accessorKey: "HouseNo",
    align: "center",
    format: "string",
  },
  {
    header: "Block No.",
    accessorKey: "BlockNo",
    align: "center",
    format: "string",
  },
  {
    header: "Street",
    accessorKey: "StreetId",
    align: "center",
    format: "string",
  },
  {
    header: "Village",
    accessorKey: "Village",
    align: "center",
    format: "string",
  },
  {
    header: "Parish",
    accessorKey: "Parish",
    align: "center",
    format: "string",
  },
  {
    header: "Division",
    accessorKey: "Division",
    align: "center",
    format: "string",
  },
  {
    header: "Property Rental Status",
    accessorKey: "PropertyRentedStatus",
    align: "center",
    format: "string",
  },
  {
    header: "Gross Value",
    accessorKey: "GrossValue",
    align: "right",
    format: "currency",
  },
  {
    header: "Rateable Value",
    accessorKey: "RateableValue",
    align: "right",
    format: "currency",
  },
  {
    header: "Current Rateable Value",
    accessorKey: "CurrentRateableValue",
    align: "right",
    format: "currency",
  },
  {
    header: "Location Address",
    accessorKey: "LocationAddress",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Other Road",
    accessorKey: "RoadOthers",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Frontages",
    accessorKey: "ParticularsFrontages",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Serial No.",
    accessorKey: "SerialNo",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Lat.",
    accessorKey: "Latitude",
    align: "center",
    hiddenColumn: true,
  },
  {
    header: "Long.",
    accessorKey: "Longitude",
    align: "center",
    hiddenColumn: true,
  },
  {
    header: "Property Type",
    accessorKey: "PropertyType",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Property SubType",
    accessorKey: "SubPropertyType",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Balance",
    accessorKey: "StatusId",
    align: "right",
    format: "currency",
    hiddenColumn: true,
  },
  {
    header: "Created By",
    accessorKey: "CreatedBy",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Created Date",
    accessorKey: "CreatedDate",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Date of Birth",
    accessorKey: "Birthdate",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Mobile",
    accessorKey: "Mobile",
    align: "right",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Mobile 2",
    accessorKey: "SecondMobile",
    align: "right",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "Email",
    accessorKey: "Email",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "P1Owner TIN",
    accessorKey: "OwnerTin",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "P1Owner First Name",
    accessorKey: "OwnerFirstname",
    align: "left",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "P1Owner Surname/Legal Name",
    accessorKey: "OwnerLegalSurnameMaiden",
    align: "left",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "P1Title",
    accessorKey: "OwnerTitle",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "P1Mobile No",
    accessorKey: "ContactMobile1",
    align: "right",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "P1Landline No",
    accessorKey: "ContactLandline",
    align: "right",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "P1Email",
    accessorKey: "ContactEmail",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
  {
    header: "P1Bedrooms",
    accessorKey: "AccomodationBedrooms",
    align: "center",
    format: "string",
    hiddenColumn: true,
  },
];
