/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from "react";
// Dependencies
import {
  Container as ContainerBase,
  ListSubheader as ListSubheaderBase,
  Box as BoxBase,
  ContainerProps,
} from "@mui/material";

import { styled } from "@mui/material/styles";

interface CustomContainerProps extends PropsWithChildren<ContainerProps> {
  maxHeight?: number;
}

const ContainerStyled = styled(ContainerBase, {
  shouldForwardProp: (prop) => prop !== "maxHeight",
})<CustomContainerProps>(({ maxHeight }) => ({
  overflowX: "auto",
  maxHeight: maxHeight ? `${maxHeight}px` : "inherit",
  height: maxHeight ? `${maxHeight}px` : "inherit",
}));

const TabsContainer = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => <ContainerStyled {...props} ref={ref} />,
);

const Container = styled(ContainerBase)(({ theme }) => ({
  overflowY: "hidden",
  padding: "0px",
  margin: "0px",
  paddingRight: "0px",
  marginRight: "0px",
  paddingBottom: "20px",
  backgroundColor: theme.accordionBody.backgroundColor,
  height: "100vh",
  width: "inherit",
  maxWidth: "none !important",
}));

const Box = styled(BoxBase)(() => ({
  height: "auto",
  paddingBottom: "5rem",
}));

const ListSubheader = styled(ListSubheaderBase)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

export default {
  Container,
  ListSubheader,
  Box,
  TabsContainer,
};
