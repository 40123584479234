/* eslint-disable @typescript-eslint/ban-ts-comment */
// Dependencies
import React, { useMemo } from "react";
import Table from "components/table/table.component";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Aggregate_OwnerArgs,
  useAggregateOwnerPrimaryOwnerQuery,
} from "graphql/hasura/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Schema
import { roundOffToNearestMillion } from "utils/math";
import { aggregateOwnerPrimaryOwnerTableColumns } from "./primary-table.schema";

interface PropertyProps {
  propertyIdentifier: string;
}

const PrimaryTable: React.FC<PropertyProps> = ({ propertyIdentifier }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Aggregate_OwnerArgs = {
    where: { Id: { _eq: propertyIdentifier } },
  };

  const { data: dataPrimaryOwner, error: errorPrimaryOwner } =
    useAggregateOwnerPrimaryOwnerQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !propertyIdentifier,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
      },
    });

  useAppErrorHandler(errorPrimaryOwner);

  const aggregatePrimaryOwnerData = React.useMemo(() => {
    const aggregate = dataPrimaryOwner?.rtcs_db_Fnaggregate_Owner ?? [];
    return aggregate.map((context) => {
      return {
        // ts-ignore
        Coin: context.Coin ? roundOffToNearestMillion(+context.Coin) : "",
        Firstname: context.Firstname,
        MiddleName: context.MiddleName,
        Surname: context.Surname,
        MotherMaidenName: context.MotherMaidenName,
        BusinessName: context.BusinessName,
        EntityLegalName: context.EntityLegalName,
        Tin: context.Tin,
        Id: context.Id,
      };
    });
  }, [dataPrimaryOwner]);

  const columnsPrimary = useMemo(
    () => aggregateOwnerPrimaryOwnerTableColumns,
    []
  );

  return (
    <Table
      // stickyHeader
      isPaginationHidden
      onAction={() => {}}
      title="Primary"
      initialRowsPerPage={10000}
      columns={columnsPrimary}
      // @ts-ignore
      data={aggregatePrimaryOwnerData}
    />
  );
};

export default PrimaryTable;
