/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import SC from "./individual.styles";

export const IndividualPage = () => {
  return <SC.Box></SC.Box>;
};

export default IndividualPage;
