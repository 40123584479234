/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box as BoxBase, Typography } from "@mui/material";
import {
  SimpleTreeView as TreeViewBase,
  TreeItem as TreeItemBase,
} from "@mui/x-tree-view";
import { styled } from "@mui/material/styles";

const Box = styled(BoxBase)(({ theme }) => ({
  margin: theme.spacing(3),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontSize: theme.revisionPage.title.fontSize,
  fontWeight: theme.revisionPage.title.fontWeight,
  textAlign: theme.revisionPage.title.textAlign,
}));

const TreeView = styled(TreeViewBase)(() => ({
  width: "auto",
}));

const TreeItem = styled(TreeItemBase)(({ theme }) => ({
  "& .MuiTreeItem-iconContainer": {
    width: theme.revisionPage.treeItemIcon.width,
    justifyContent: theme.revisionPage.treeItemIcon.justifyContent,
    fontWeight: theme.revisionPage.treeItemIcon.fontWeight,
  },
  "& .MuiTreeItem-label": {
    textAlign: theme.revisionPage.treeItemLabel.textAlign,
  },

  "& .MuiTreeItem-content": {
    padding: "0px 8px",
  },
}));

export default {
  Box,
  Title,
  TreeView,
  TreeItem,
};
