/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  makeStyles,
  Box,
  Button,
  Grow,
  GrowProps,
  Paper,
  MenuItem,
  MenuItemProps,
  Avatar,
} from "@mui/material";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { Link, LinkProps } from "react-router-dom";

import { styled } from "@mui/material/styles";

// Functions
import { adaptativeSmallScreen } from "components/theme-provider/theme.utils";

interface CustomGrowProps extends GrowProps {
  isActive: boolean;
}

const UserMenu = styled(Box)(({ theme }) => ({
  display: "flex",
  alignSelf: "center",
  marginLeft: "auto",
  position: "relative",
  alignItems: "center",
  justifyContent: "flex-end",
  [theme.breakpoints.down("xl")]: {
    paddingRight: theme.userMenuSmall.paddingRight,
  },
  [theme.breakpoints.up("xl")]: {
    paddingRight: theme.userMenu.paddingRight,
  },
  paddingLeft: adaptativeSmallScreen(2.18),
  "& div": {
    zIndex: 2,
  },
}));

const ButtonIcon = styled(Button)({
  minWidth: "auto",
  padding: "0px",
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const UserAvatar = styled(Avatar)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("xl")]: {
    width: theme.userMenuSmall.fontSize,
    height: theme.userMenuSmall.fontSize,
  },
  [theme.breakpoints.up("xl")]: {
    width: theme.userMenu.fontSize,
    height: theme.userMenu.fontSize,
  },
}));

const DefaultAvatarIcon = styled(AccountCircleIcon)(({ theme }) => ({
  color: "white",
  [theme.breakpoints.down("xl")]: {
    fontSize: theme.defaultUserIconSmall.fontSize,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: theme.defaultUserIcon.fontSize,
  },
}));

const TransitionAnimation = (props: CustomGrowProps): JSX.Element => {
  const { isActive, ...muiProps } = props;
  return (
    <Grow
      style={{
        transformOrigin: isActive ? "center top" : "center bottom",
      }}
      {...muiProps}
    />
  );
};

const MenuChildContainer = styled(Paper)({
  boxShadow:
    "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
});

const LinkOption: React.ComponentType<MenuItemProps & LinkProps> =
  MenuItem as React.ComponentType<MenuItemProps & LinkProps>;

type LinkOptionProps = MenuItemProps & LinkProps;

const Option = styled(LinkOption)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    fontSize: adaptativeSmallScreen(1),
    paddingTop: adaptativeSmallScreen(0.57),
    paddingLeft: adaptativeSmallScreen(1.14),
    paddingBottom: adaptativeSmallScreen(0.57),
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
    paddingTop: "0.57rem",
    paddingLeft: "1.14rem",
    paddingBottom: "0.57rem",
  },
}));

export default {
  UserMenu,
  ButtonIcon,
  UserAvatar,
  DefaultAvatarIcon,
  TransitionAnimation,
  MenuChildContainer,
  Option,
};
