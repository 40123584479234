/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box as BoxBase,
  Container as ContainerBase,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const Container = styled(ContainerBase)(({ theme }) => ({
  justifyContent: "flex-start",
  backgroundColor: theme.accordionBody.backgroundColor,
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  width: "100%",
  height: "100%",
  overflow: "inherit",
}));

const Box = styled(BoxBase)(() => ({
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
}));

const Label = styled(Typography)(({ theme }) => ({
  padding: "5px",
  alignSelf: "flex-start",
  textAlign: "left",
  fontWeight: 500,
  fontSize: theme.leftPane.fontSize,
}));

export default { Container, Box, Label };
