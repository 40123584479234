/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  Breadcrumbs,
  Link as LinkBase,
  Typography,
  Box as BoxBase,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as LinkBreadcrumb, LinkProps } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";

const Container = styled(BoxBase)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: theme.breadCrumps.fontSize,
}));

const CustomLink: React.ComponentType<LinkProps> =
  LinkBreadcrumb as React.ComponentType<LinkProps>;

type LinkBreadcrumbProps = LinkProps & { isActive?: boolean };

interface StyledLinkProps {
  isActive?: boolean;
}

const StyledLink = styled(LinkBreadcrumb, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<StyledLinkProps>(({ theme, isActive }) => ({
  color: isActive ? "inherit" : theme.palette.primary.main,
  pointerEvents: isActive ? "none" : "auto",
  fontSize: theme.breadCrumps.fontSize,
  textTransform: "capitalize",
  textDecoration: "none",
}));

const Link: React.FC<LinkBreadcrumbProps> = (props) => {
  const { isActive, to, ...muiProps } = props;
  const theme = useTheme();

  return <StyledLink to={to} isActive={isActive} theme={theme} {...muiProps} />;
};

const LinkSimple = styled(LinkBase)(({ theme }) => ({
  fontSize: theme.breadCrumps.fontSize,
  textTransform: "capitalize",
  textDecoration: "none",
}));

export default {
  Breadcrumbs,
  Link,
  LinkSimple,
  Label,
  NavigateNextIcon,
  Container,
};
