/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import {
  Accordion as AccordionBase,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails,
  Typography,
  TypographyProps,
  IconButton as IconButtonBase,
  List,
  Grid,
  AppBar,
  Divider,
  CircularProgress,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const Accordion = styled(AccordionBase)({
  width: "100%",
});

const AccordionSummary = styled(AccordionSummaryBase)(({ theme }) => ({
  padding: "0px",
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
    borderTopWidth: "0px",
    borderBottomWidth: "2px",
  },
}));

interface CustomTypographyProps extends TypographyProps {
  type?: string;
}

const Label = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "type",
})<CustomTypographyProps>(({ theme, type }) => ({
  fontWeight: type === "bold" ? "bold" : 100,
  marginRight: theme.spacing(2),
  fontSize: theme.accordionHeader.mainHeader.fontSize,
}));

const LabelTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontSize: theme.accordionHeader.groupHeader.fontSize,
  textAlign: "center",
  fontWeight: "bold",
}));

const LabelButtonText = styled(Label)(() => ({
  fontSize: "0.7rem",
  textAlign: "center",
}));

const IconButton = styled(IconButtonBase)(() => ({
  justifyContent: "flex-end",
  alignSelf: "flex-end",
  padding: "5px",
  "&:hover": {
    backgroundColor: "white",
  },
}));

const ProgressIndicator = CircularProgress;

export default {
  AppBar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Label,
  LabelButtonText,
  LabelTitle,
  IconButton,
  List,
  Grid,
  Divider,
  ProgressIndicator,
};
