/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useMemo } from "react";
import { CLIENT_NAME } from "graphql/client";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  jsonToStringQueryVariables,
  aggregateArrayToObject,
} from "graphql/hasura/rtcs.utils";

// graphql
import {
  Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs,
  Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column,
  Order_By,
  useExcessiveOwnerOccupancyFilterQuery,
  ExcessiveOwnerOccupancyFragment,
  useGetPaginatedExcessiveOwnerOccupancyReportsQuery,
  useGetMaxMinExcessiveOwnerOccupancyReportsQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// Schema
import { isNotNull } from "utils/string";
import {
  reportTableColumns,
  ReportTableSchema,
  getAccessorType,
} from "./report.schema";
import SC from "./report.styles";

const TABLE_NAME = "rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy";

const individualReportContextData = (
  context: ExcessiveOwnerOccupancyFragment,
) => {
  return {
    CustomerId: isNotNull(context.CustomerId),
    AggregateFirstName: isNotNull(context.AggregateFirstName),
    AggregateMiddleName: isNotNull(context.AggregateMiddleName),
    AggregateSurname: isNotNull(context.AggregateSurname),
    MatchScore: isNotNull(context.MatchScore),
    NumOwnedOccupied: isNotNull(context.NumOwnedOccupied),
    MatchAggregateUra: isNotNull(context.MatchAggregateUra),
    UraFirstname: isNotNull(context.UraFirstname),
    SumAdjustedIncome: isNotNull(context.SumAdjustedIncome),
    UraSurname: isNotNull(context.UraSurname),
    EmailId: isNotNull(context.EmailId),
    MobileNumber: isNotNull(context.MobileNumber),
    IsIncomeSrcRental: isNotNull(context.IsIncomeSrcRental),
    UraTin: isNotNull(context.UraTin),
    NationalId: isNotNull(context.NationalId),
    MatchState: "",
    Rnid: isNotNull(context.Rnid),
  };
};

export const IndividualPage = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    | Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs
    | undefined
  >(undefined);
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } =
    useGetPaginatedExcessiveOwnerOccupancyReportsQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !queryVariables,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
        aggregateParams: jsonToStringQueryVariables({
          where: queryVariables?.where,
        }),
      },
    });

  const {
    data: maxMinData,
    loading: maxMinLoading,
    error: maxMinError,
  } = useGetMaxMinExcessiveOwnerOccupancyReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  const individualReportData: ReportTableSchema[] = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const individual =
      data?.rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy ?? [];

    return individual.map((context: ExcessiveOwnerOccupancyFragment) => {
      return individualReportContextData(context);
    });
  }, [data]);

  useAppErrorHandler(error || maxMinError || exportError);

  const columns = useMemo(() => reportTableColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.header}`,
        value: `${column.accessorKey}`,
        type: `${getAccessorType(column.accessorKey)}`,
      };
    });
  }, [columns]);

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column.AggregateTin,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    [],
  );

  const totalRowsCount = parseInt(
    data
      ?.rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm[0]
      .value ?? "-1",
    10,
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [handlePaginatedFetchData, loading, totalRowsCount],
  );

  const { refetch: ReportFilterFn } = useExcessiveOwnerOccupancyFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
            {
              where: { [column]: { _ilike: `${value}%` } },
              distinct_on: [
                column as Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column,
              ],
              limit: 10,
            };
          const individualReportFilterData = await ReportFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            individualReportFilterData.data?.rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column],
            );
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [ReportFilterFn],
  );

  const newObj = aggregateArrayToObject(
    maxMinData?.rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm,
  );

  const minTotalRentedProperties = newObj?.['min("NumOwnedOccupied")'];

  const maxTotalRentedProperties = newObj?.['max("NumOwnedOccupied")'];

  const minSumGrossValue = newObj?.['min("SumRateableValue")'];

  const maxSumGrossValue = newObj?.['max("SumRateableValue")'];

  const minMatchScore = newObj?.['min("MatchScore")'];

  const maxMatchScore = newObj?.['max("MatchScore")'];

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "First Name",
        columnName:
          Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column.AggregateFirstName,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Last Name",
        columnName:
          Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column.AggregateSurname,
        fetchSuggestions,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Total Number of Owner Occupied Properties",
        min: minTotalRentedProperties,
        max: maxTotalRentedProperties,
        columnName:
          Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column.NumOwnedOccupied,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Gross Rental Income",
        min: minSumGrossValue,
        max: maxSumGrossValue,
        columnName:
          Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column.SumRateableValue,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "rMATCH Score",
        min: minMatchScore,
        max: maxMatchScore,
        columnName:
          Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column.MatchScore,
      },
    ];
  }, [
    fetchSuggestions,
    maxMatchScore,
    maxSumGrossValue,
    maxTotalRentedProperties,
    minMatchScore,
    minSumGrossValue,
    minTotalRentedProperties,
  ]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    [],
  );

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }

    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: {
        ...condition,
      },
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <SC.Box>
        <Table
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "filter-results",
            "export-to-excel-sheet/csv",
          ]}
          title="Excessive Owner Occupancy (Individuals Only)"
          headerPanel={
            <SC.Title>
              Analytics - Excessive Owner Occupancy (Individuals Only)
            </SC.Title>
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          data={individualReportData}
          // eslint-disable-next-line no-alert
          onAction={() => alert("under construction")}
          leftPanel={
            <TableFilter
              filterInputs={filterInputs}
              onFilterApply={onTableFilterApply}
              universalFilterColumns={universalColumns}
              onUniversalFilterSubmit={onUniversalFilterer}
            />
          }
          persistenceId="2096a355-b90e-439f-86e8-4d8e6b969c98675757575"
          paginationControlled={paginationControlled}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
          pageIndexResetSignal={pageIndexResetSignal}
          stickyHeader
          exportData={exportDataFn}
        />
      </SC.Box>
      <ProgressIndicator open={loading || maxMinLoading || exportLoading} />
    </SC.Box>
  );
};

export default IndividualPage;
