/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const FilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.default,
  textTransform: "none",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textAlign: "center",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
  "&:disabled": {
    backgroundColor: "gray",
    color: theme.palette.background.default,
  },
}));

const FilterBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.accordionBody.backgroundColor,
  borderRadius: "5px",
  display: "flex",
  height: "auto",
  justifyContent: "center",
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  width: "auto",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: theme.table.toolbar.fontSize,
  margin: theme.spacing(0),
  opacity: 0.8,
  textAlign: "left",
}));

export default {
  Box,
  FilterBox,
  Title,
  FilterButton,
  FormControl,
  Grid,
  Typography,
};
