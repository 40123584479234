/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  MenuItem,
  MenuItemProps,
  ButtonProps,
  Box,
  Popover,
} from "@mui/material";
import { ArrowRight as ArrowRightIcon } from "@mui/icons-material";
import { LinkProps } from "react-router-dom";
import { styled } from "@mui/material/styles";

// Functions
import { adaptativeSmallScreen } from "components/theme-provider/theme.utils";

const shouldForwardProp = (prop: string) =>
  prop !== "isOpen" && prop !== "parent";

const StyledMenuItem = styled(MenuItem, { shouldForwardProp })<{
  isOpen: boolean;
  to?: string;
  parent?: boolean;
}>(({ theme, isOpen, to, parent }) => ({
  padding: "0px",
  backgroundColor: isOpen ? theme.palette.action.hover : "transparent",
  [theme.breakpoints.down("xl")]: {
    width:
      to === undefined || parent
        ? adaptativeSmallScreen(16.725)
        : adaptativeSmallScreen(10.725),
  },
  [theme.breakpoints.up("xl")]: {
    width: to === undefined || parent ? "16.725rem" : "10.725rem",
  },
}));

const LinkMenuItem: React.ComponentType<
  MenuItemProps & ButtonProps & LinkProps
> = MenuItem as React.ComponentType<MenuItemProps & ButtonProps & LinkProps>;

const ButtonMenuItem: React.ComponentType<MenuItemProps & ButtonProps> =
  MenuItem as React.ComponentType<MenuItemProps & ButtonProps>;

interface LinkMenuCustomProps {
  parent?: boolean;
}

type LinkMenuItemProps = MenuItemProps &
  ButtonProps &
  Omit<LinkProps, "to"> &
  LinkMenuCustomProps & {
    isOpen: boolean;
    to?: string;
    parent?: boolean;
  };

const AdministrationMenuItem = React.forwardRef<any, LinkMenuItemProps>(
  (props, ref) => {
    const { to, isOpen, parent, ...muiProps } = props;

    return to ? (
      <StyledMenuItem
        to={to}
        isOpen={isOpen}
        parent={parent}
        {...muiProps}
        ref={ref}
      />
    ) : (
      <StyledMenuItem isOpen={isOpen} parent={parent} {...muiProps} ref={ref} />
    );
  },
);

const ItemsContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    fontSize: adaptativeSmallScreen(1),
    paddingTop: adaptativeSmallScreen(0.57),
    paddingLeft: adaptativeSmallScreen(1.14),
    paddingBottom: adaptativeSmallScreen(0.57),
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
    paddingTop: "0.57rem",
    paddingLeft: "1.14rem",
    paddingBottom: "0.57rem",
  },
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ParentName = styled(Box)({
  paddingLeft: "0px",
  paddingRight: "6px",
});

const ArrowIcon = styled(ArrowRightIcon)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    fontSize: adaptativeSmallScreen(1.8),
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.8rem",
  },
}));

const MenuContent = styled(Popover)({
  pointerEvents: "none",
});

const Content = styled(Box)({
  pointerEvents: "auto",
  paddingTop: "8px",
  paddingBottom: "8px",
});

export default {
  AdministrationMenuItem,
  ItemsContainer,
  ParentName,
  ArrowIcon,
  MenuContent,
  Content,
};
