/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { CapitalizeFirstCharacter } from "utils/string";
import { QueryOptions, joinOperands } from "./universal.schema";

import SC from "./universal-filter.styles";
import { SelectChangeEvent } from "@mui/material";

const NumberExp = new RegExp(/[^\d,.]+/g);
const StringExp = new RegExp(/[|&;#$!"<>()+]/g);

export interface ColumnsProps {
  index: number;
  label: string;
  value: string;
  type: string;
}

const toCapitalCase = (label: string) => {
  if (label === "TIN No.") {
    return label;
  }
  if (["TIN", "NIN", "NSSF ID", "NSSF_ID", "RN ID"].includes(label)) {
    return label.toUpperCase();
  }
  if (label.includes("TIN") || label.includes("ID")) {
    const newString = label.split(" ");
    const firstChars: string[] = label.split(" ").slice(0, -1);
    return `${CapitalizeFirstCharacter(firstChars.join(" "))} ${newString[
      newString.length - 1
    ].toUpperCase()}`;
  }

  return label.toLowerCase().replace(/(?:^|\s)\S/g, (a) => {
    return a.toUpperCase();
  });
};

export interface FilterProps {
  columns: ColumnsProps[];
  column: string;
  setColumn: (
    column: string | [string, string],
    value: string | [string, string],
  ) => void;
  queries: string;
  value: string;
  join: string;
  onRemoveFilter?: () => void;
  resetSignal: boolean | undefined;
  lastFilter: boolean | undefined;
  firstFilter: boolean | undefined;
  deleteComponent?: boolean | undefined;
}

export const Filter = ({
  columns,
  column,
  queries,
  value,
  join,
  setColumn,
  deleteComponent,
  onRemoveFilter,
  resetSignal,
  lastFilter,
  firstFilter,
}: FilterProps) => {
  const [selectedType, setSelectedType] = useState("string");
  useEffect(() => {
    // setValue("");
  }, [resetSignal]);

  const onColumnSelect = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    setColumn(
      ["column", "selectedType"],
      [event.target.value as string, selectedType],
    );
  };

  const onQuerySelect = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    setColumn(
      ["queries", "selectedType"],
      [event.target.value as string, selectedType],
    );
  };

  const onJoinSelect = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    setColumn("operator", event.target.value as string);
  };

  const handleChange = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    const { value } = event.target as { name: string; value: unknown };

    let newValue = (value as string).trimStart().replace(/\s{2,}/g, " ");

    switch (selectedType) {
      case "number":
        newValue = newValue.replaceAll(NumberExp, "");
        break;
      case "string":
        newValue = newValue.replaceAll(StringExp, "");
        break;
    }
    setColumn("value", newValue);
  };

  const handleOnTextFieldsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    let newValue = value.trimStart().replace(/\s{2,}/g, " ");

    switch (selectedType) {
      case "number":
        newValue = newValue.replaceAll(NumberExp, "");
        break;
      case "string":
        newValue = newValue.replaceAll(StringExp, "");
        break;
    }
    setColumn("value", newValue);
  };

  const sortedColumns = useMemo(() => {
    return columns.sort((a, b) => a.label.localeCompare(b.label));
  }, [columns]);

  useEffect(() => {
    const selectedColumn: ColumnsProps | undefined = _.find(
      sortedColumns,
      (arrayItem) => {
        return arrayItem.value === column;
      },
    );
    if (selectedColumn) {
      setSelectedType(selectedColumn.type);
    }
  }, [column, sortedColumns]);

  const sortedQueries = useMemo(() => {
    const selectedColumn: ColumnsProps | undefined = _.find(
      sortedColumns,
      (arrayItem) => {
        return arrayItem.value === column;
      },
    );
    return QueryOptions.map((operand) => {
      const clonedOperand = operand;
      if (clonedOperand.type === "all") {
        clonedOperand.disabled = false;
        return clonedOperand;
      }

      const clonedOperandArr = clonedOperand.type.split("|");

      if (clonedOperandArr.includes(`${selectedColumn?.type}`)) {
        clonedOperand.disabled = false;
        return clonedOperand;
      }

      clonedOperand.disabled = true;
      return clonedOperand;
    });
  }, [column, sortedColumns]);

  if (deleteComponent) {
    return null;
  }

  return (
    <SC.Grid justifyContent="center" alignItems="center" spacing={2} container>
      <SC.GridSelect xs={3} item>
        <SC.SelectField
          variant="standard"
          id="demo-customized-select"
          value={column}
          onChange={onColumnSelect}
        >
          <SC.MenuItem value="default">
            <em>Select Data Field </em>
          </SC.MenuItem>
          {sortedColumns.map((item: any) => (
            <SC.MenuItem key={item.index} value={item.value}>
              <em>{toCapitalCase(item.label)}</em>
            </SC.MenuItem>
          ))}
        </SC.SelectField>
      </SC.GridSelect>
      <SC.GridSelect xs={3} item>
        <SC.SelectField
          variant="standard"
          labelId="demo-customized-select-label"
          id="demo-customized-select2"
          value={queries}
          onChange={onQuerySelect}
        >
          <SC.MenuItem value="default">
            <em>Select Operand</em>
          </SC.MenuItem>
          {sortedQueries.map((item: any) => (
            <SC.MenuItem
              disabled={item.disabled}
              key={item.index}
              value={item.value}
            >
              <em>{item.label}</em>
            </SC.MenuItem>
          ))}
        </SC.SelectField>
      </SC.GridSelect>
      <SC.Grid xs={3} item>
        <SC.TextField
          value={value}
          onChange={handleOnTextFieldsChange}
          variant="outlined"
          helperText="Value"
        />
      </SC.Grid>
      <SC.GridSelect xs={2} item>
        {!lastFilter && (
          <SC.SelectField
            variant="standard"
            labelId="demo-customized-select-label"
            id="demo-customized-select2"
            value={join}
            onChange={onJoinSelect}
          >
            <SC.MenuItem value="default">
              <em>Join Operator</em>
            </SC.MenuItem>
            {joinOperands.map((item: any) => (
              <SC.MenuItem key={item.index} value={item.value}>
                <em>{item.label}</em>
              </SC.MenuItem>
            ))}
          </SC.SelectField>
        )}
      </SC.GridSelect>
      {!firstFilter ? (
        <SC.Grid xs={1} item>
          <SC.Tooltip title="delete">
            <SC.IconButton aria-label="delete" onClick={onRemoveFilter}>
              <SC.DeleteIcon />
            </SC.IconButton>
          </SC.Tooltip>
        </SC.Grid>
      ) : (
        <SC.Grid xs={1} />
      )}
    </SC.Grid>
  );
};

export default Filter;
