import { TableColumn } from "components/table/table.component";

export type UtilitiesTableSchema = {
  month: Date;
  pay: string;
  trans: string;
  cons: string;
};

export const utilitiesTableColumns: TableColumn<UtilitiesTableSchema>[] = [
  {
    header: "Monthly Activity",
    accessorKey: "month",
    align: "left",
  },
  {
    header: "Payment",
    accessorKey: "pay",
    align: "right",
    format: "currency",
  },
  {
    header: "Transaction",
    accessorKey: "trans",
    align: "right",
    format: "currency",
  },
  {
    header: "Consumption",
    accessorKey: "cons",
    align: "center",
  },
];
