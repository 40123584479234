/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  Avatar,
  Box,
  Fab as FabMui,
  Tooltip,
  InputBase,
  InputLabel,
  Button,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import { styled } from "@mui/material/styles";

const ProfileContainer = styled(Box)({
  position: "relative",
  width: "fit-content",
  height: "fit-content",
  margin: "auto",
});

const Fab = styled(FabMui)({
  position: "absolute",
  zIndex: 1,
  bottom: "-20%",
  right: 0,
  transform: "translate(-50%, -50%)",
  msTransform: "translate(-50%, -50%)",
});

const InputBasePicture = styled(InputBase)({
  display: "none",
});

const LabelPicture = InputLabel;

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.userProfilePicture.width,
  height: theme.userProfilePicture.height,
}));

const DefaultAvatarIcon = styled(AccountCircleIcon)(({ theme }) => ({
  width: theme.userProfilePicture.width,
  height: theme.userProfilePicture.height,
  color: theme.userProfilePicture.defaultColor,
}));

export default {
  ProfileContainer,
  Tooltip,
  Fab,
  AddPhotoAlternateIcon,
  InputBasePicture,
  LabelPicture,
  Button,
  UserAvatar,
  DefaultAvatarIcon,
};
