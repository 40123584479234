/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Grid,
  Typography,
  List,
  Link,
  ListItem,
  ListItemText as ListItemTextBase,
  Input,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import Autocomplete from "components/search-box/search-box.component";

import {
  ExpandLess,
  ExpandMore,
  PhotoLibrary,
  ArrowBack,
  Home,
  Search,
  Menu,
  Clear,
} from "@mui/icons-material";

const MapContainer = styled(Box)(() => ({
  height: "85vh",
  width: "100%",
}));

const ArrowBackIcons = styled(ArrowBack)(() => ({
  color: "#fff",
  cursor: "pointer",
  marginRight: "10px",
}));

const ClearIcon = styled(Clear)(() => ({
  cursor: "pointer",
}));

const HomeIcons = styled(Home)(() => ({
  color: "#fff",
  cursor: "pointer",
}));

const MenuIcons = styled(Menu)(() => ({
  color: "#fff",
  cursor: "pointer",
}));

const SearchIcons = styled(Search)(() => ({
  color: "#fff",
  cursor: "pointer",
  marginLeft: "15px",
}));

const Container = styled(Grid)(() => ({
  width: "100%",
}));

const MapContentContainer = styled(Box)(() => ({
  width: "15rem",
  textAlign: "left",
}));

const MapContentLabel = styled(Typography)(() => ({
  fontSize: "1rem",
  opacity: "80%",
  fontWeight: "bold",
  textAlign: "left",
}));

const MapContentLink = styled(Link)(() => ({
  fontSize: "1rem",
  cursor: "pointer",
  fontWeight: "bold",
  color: "#4681AF",
  textAlign: "left",
}));

const ImageContainer = styled(Box)(() => ({
  width: "100%",
  height: "7rem",
  marginBottom: "1rem",
  marginTop: "1.3rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const HeaderContainer = styled(Grid)(() => ({
  height: "5.7rem",
}));

const TitleBold = styled(Typography)(({ theme }) => ({
  fontSize: theme.map.title.fontSize,
  textAlign: theme.map.title.textAlign,
  marginLeft: theme.map.title.marginLeft,
  fontWeight: theme.map.title.fontWeight,
  wordWrap: "break-word",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.map.title.fontSize,
  wordWrap: "break-word",
}));

const ListContainer = styled(List)(() => ({
  width: "100%",
  maxHeight: "65vh",
  overflow: "hidden",
  overflowY: "scroll",
}));

const GridIconContainer = styled(Grid)(() => ({}));

const ControlledListItem = styled(ListItem)(() => ({
  paddingLeft: "2rem",
  cursor: "pointer",
  selected: {
    border: "0.5rem solid #4681AF",
    paddingLeft: "2rem",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#fff !important",
  },
}));

const ListItemTextSelected = styled(ListItemTextBase)(() => ({
  fontSize: "1.14rem",
  fontWeight: "bolder",
  color: "#4681AF",
}));

const ListItemText = styled(ListItemTextBase)(() => ({
  fontSize: "1.14rem",
  width: "100%",
  display: "block",
}));

const Icon = styled(PhotoLibrary)(() => ({
  opacity: 0.7,
  fontSize: "4rem",
}));

const SearchInput = styled(Input)(() => ({
  width: "100%",
}));

const SearchContainer = styled(Grid)(() => ({
  marginBottom: "0.7rem",
  paddingLeft: "3.21rem",
}));

export default {
  Box,
  Grid,
  MapContainer,
  HeaderContainer,
  TitleBold,
  Title,
  ListContainer,
  ListItem,
  ControlledListItem,
  ListItemText,
  ListItemTextSelected,
  GridIconContainer,
  ExpandLess,
  ExpandMore,
  Icon,
  ImageContainer,
  MapContentContainer,
  MapContentLabel,
  MapContentLink,
  Container,
  Autocomplete,
  ArrowBackIcons,
  HomeIcons,
  SearchIcons,
  MenuIcons,
  ClearIcon,
  SearchContainer,
  SearchInput,
};
