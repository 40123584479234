import {
  Grid as GridBase,
  Box as BoxBase,
  Typography as TypographyBase,
  Container as ContainerBase,
  Divider as DividerBase,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import MapTableContainerBase from "components/map-table-container/map-table-container.component";
import SwitchViewsBase from "components/switch-views/switch-views.component";
import LabelKeyValueBase from "components/label-key-value/label-key-value.component";
import MapContainerBase from "components/map-container/map-container.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

const Grid = GridBase;
const Box = styled(BoxBase)({
  textAlign: "center",
});

const Typography = TypographyBase;

const SwitchViews = SwitchViewsBase;

const LabelKeyValue = styled(LabelKeyValueBase)({
  alignItems: "center",
});

const Container = styled(ContainerBase)({
  padding: "0px",
});

const Divider = styled(DividerBase)({
  color: "red",
});

const MapContainer = MapContainerBase;

const MapTableContainer = MapTableContainerBase;

export default {
  Grid,
  Box,
  Typography,
  SwitchViews,
  LabelKeyValue,
  Container,
  Divider,
  MapContainer,
  MapTableContainer,
  ProgressIndicator,
};
