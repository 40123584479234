/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog as ModalBase,
  Grid,
  Paper as PaperBase,
  List,
  ListItem,
  ListItemText,
  Button as ButtonBase,
  IconButton as IconButtonBase,
} from "@mui/material";
import { DialogActions as MuiDialogActions } from "@mui/material";

import { styled } from "@mui/material/styles";

const Dialog = styled(ModalBase)(() => ({
  "& .MuiPaper-root": {
    width: "inherit",
    borderRadius: "5px",
    padding: "1.14rem",
    paddingRight: "0rem",
  },
}));

const SelectionFields = styled(PaperBase)(({ theme }) => ({
  width: "23.42rem",
  height: "23.42rem",
  border: `0.07rem solid ${theme.border.color}`,
  borderRadius: "0.57rem",
  overflow: "hidden",
  overflowY: "auto",

  "&::-webkit-scrollbar-track": {
    backgroundColor: "inherit",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "inherit",
  },
}));

const PaperContainer = styled(PaperBase)(() => ({
  width: "inherit",
  maxWidth: "inherit",
}));

const Paper = PaperBase;

const IconButton = styled(IconButtonBase)(({ theme }) => ({
  backgroundColor: theme.table.toolbarActions.iconColor,
  color: theme.palette.background.default,
  marginTop: "1.14rem",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
  "&:hover": {
    backgroundColor: theme.table.toolbarActions.iconColor,
  },
  disabled: {
    backgroundColor: theme.table.toolbarActions.iconColor,
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  height: "1.8rem",
  color: theme.table.toolbarActions.iconColor,
  borderColor: theme.table.toolbarActions.iconColor,
  textTransform: "capitalize",
  "&:hover": {
    borderColor: theme.table.toolbarActions.iconColor,
  },
  "&.MuiButton-contained": {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
  },
}));

export default {
  Dialog,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  MuiDialogActions,
  PaperContainer,
  SelectionFields,
  IconButton,
};
