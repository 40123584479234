/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const Pane = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.pane.backgroundColor,
  padding: theme.spacing(4), // = 8px * 4
}));

export default {
  Pane,
};
