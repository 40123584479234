/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { UccReportFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type ReportTableSchema = Omit<
  UccReportFragment,
  "__typename" | "File" | "LastUpdated" | "Line" | "Uuid" | "WhoUpdated"
>;

export interface MobilePhoneNumbersTableFilters {
  firstName: string;
  surname: string;
  phoneNumber: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof MobilePhoneNumbersTableFilters,
  string | string[]
> = {
  firstName: ["Firstname"],
  surname: ["Surname"],
  phoneNumber: ["Kccamobile"],
};

export const UCC_PHONE_NUMBER_TABLE_SCHEMA_DATA_TYPE: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Carrier: "string",
  Count: "number",
  Customerid: "string",
  Firstname: "string",
  Idnumber: "string",
  Idtype: "string",
  Kccamobile: "string",
  Surname: "string",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | AccessorFn<ReportTableSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return UCC_PHONE_NUMBER_TABLE_SCHEMA_DATA_TYPE[accessor];
};

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    header: "First Name",
    accessorKey: "Firstname",
    format: "string",
    meta: { sort: true },
  },
  {
    header: "Surname Name",
    accessorKey: "Surname",
    format: "string",
  },
  {
    header: "Customer ID",
    accessorKey: "Customerid",
    format: "string",
  },
  {
    header: "ID Type",
    accessorKey: "Idtype",
    format: "string",
  },
  {
    header: "ID Number",
    accessorKey: "Idnumber",
    format: "string",
  },
  {
    header: "Count",
    accessorKey: "Count",
    format: "number",
  },
  {
    header: "Carrier",
    accessorKey: "Carrier",
    format: "string",
  },
  {
    header: "Mobile",
    accessorKey: "Kccamobile",
    format: "string",
  },
];
