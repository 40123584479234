/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from "react";

import {
  Accordion as AccordionBase,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails as AccordionDetailsBase,
  Typography,
  TypographyProps,
  Grid,
  Divider,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const Accordion = styled(AccordionBase)({
  width: "100%",
  "&::before": {
    backgroundColor: "transparent",
  },
});

const AccordionSummary = styled(AccordionSummaryBase)(() => ({
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
  },
}));

interface CustomTypographyProps extends PropsWithChildren<TypographyProps> {
  type?: string;
}

const Label = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "type",
})<CustomTypographyProps>(({ type, theme }) => ({
  fontWeight: type === "bold" ? "bold" : 100,
  fontSize: theme.accordionHeader.mainHeader.fontSize,
  textAlign: "center",
}));

const LabelButtonText = ({
  children,
  ...rest
}: CustomTypographyProps): JSX.Element => {
  return <Label {...rest}>{children}</Label>;
};

const AccordionDetails = styled(AccordionDetailsBase)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingRight: theme.spacing(0),
  paddingLeft: theme.spacing(0),
}));

export default {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LabelButtonText,
  Grid,
  Divider,
};
