import React from "react";
import { TableColumn } from "components/table/table.component";
import {
  OwnersAndOfficersFragment,
  IndividualsAssociatedBusinessFragment,
} from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";
import { isValidTin } from "graphql/hasura/rtcs.utils";

export const associatedBusinessescolumns: TableColumn<IndividualsAssociatedBusinessFragment>[] =
  [
    {
      header: "TIN No.",
      accessorKey: "TinNo",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Business TIN",
      accessorKey: "AssociateTin",
      align: "left",
      meta: { sort: false },
      cell: ({
        getValue,
        row: {
          original: { AssociateTin },
        },
      }): React.ReactNode => {
        return isValidTin(AssociateTin as string) ? (
          <Link to={`/non-individuals/${AssociateTin}/identification`}>
            <>{getValue()}</>
          </Link>
        ) : (
          <>{AssociateTin}</>
        );
      },
    },
    {
      header: "Legal Entity Name",
      accessorKey: "AssociateTaxPayerName",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Entity Relationship",
      accessorKey: "EntityDesignation",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Main Activity",
      accessorKey: "MainActivity",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Activity Description",
      accessorKey: "ActvtyDesc",
      align: "left",
      meta: { sort: false },
    },
  ];

export const ownersAndOfficersColumns: TableColumn<OwnersAndOfficersFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Individual TIN No.",
      accessorKey: "Individualtin",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Name",
      accessorKey: "Name",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Business TIN No.",
      accessorKey: "Tinno",
      align: "left",
      meta: { sort: false },
      cell: ({
        getValue,
        row: {
          original: { Tinno },
        },
      }): React.ReactNode => {
        return isValidTin(Tinno as string) ? (
          <Link to={`/non-individuals/${Tinno}/identification`}>
            <>{getValue()}</>
          </Link>
        ) : (
          <>{Tinno}</>
        );
      },
    },
    {
      header: "Business Name",
      accessorKey: "Taxpayername",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Designation",
      accessorKey: "Designation",
      align: "center",
      meta: { sort: false },
    },
    {
      header: "Percent Owned",
      accessorKey: "Pershare",
      align: "right",
      meta: { sort: false },
    },
    {
      header: "Address",
      accessorKey: "Address",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Return No.",
      accessorKey: "Rtnno",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Tax Year",
      accessorKey: "Rtnperiodyear",
      align: "left",
      meta: { sort: false },
    },
    {
      header: "Officers Index No.",
      accessorKey: "Rtnholdingofficersdtlid",
      align: "left",
      meta: { sort: false },
      hiddenColumn: true,
    },
    {
      header: "Owners Index No.",
      accessorKey: "Rtnholdingownersdtlid",
      align: "left",
      meta: { sort: false },
      hiddenColumn: true,
    },
  ];

export default {
  ownersAndOfficersColumns,
  associatedBusinessescolumns,
};
