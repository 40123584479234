import React, { ReactNode } from "react";
import { TableColumn } from "components/table/table.component";
import {
  IndividualCompliancePlusRiskReportFragment,
  IndividualExcessiveOwnerOccupancyFragment,
} from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";

export const noReturnsColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "left",
    },
    {
      header: "URA First Name",
      accessorKey: "Urafirstname",
      align: "left",
    },
    {
      header: "URA Middle Name",
      accessorKey: "Uramiddlename",
      align: "left",
    },
    {
      header: "URA Surname",
      accessorKey: "Urasurname",
      align: "left",
    },
    {
      header: "rMATCH Score",
      accessorKey: "Matchscore",
      align: "center",
    },
    {
      header: "TIN No.",
      accessorKey: "Uratinno",
      align: "center",
      meta: { sort: true },
    },
    {
      header: "Rental Income?",
      accessorKey: "Isincomesrcrental",
      align: "center",
    },
    {
      header: "URA Mobile No.",
      accessorKey: "Mobilenumber",
      align: "center",
    },
    {
      header: "URA Email",
      accessorKey: "Emailid",
      align: "center",
    },
    {
      header: "URA NIN",
      accessorKey: "Nationalid",
      align: "center",
    },
    {
      header: "Tax Year",
      accessorKey: "Rtnperiodyear",
      align: "center",
    },
    {
      header: "Total Number of Properties",
      accessorKey: "Numproperties",
      align: "center",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          <>{getValue()}</>
        </Link>
      ),
    },
    {
      header: "Total Number of Rental Properties",
      accessorKey: "Numrentedproperties",
      align: "center",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
          <>{getValue()}</>
        </Link>
      ),
    },
    {
      header: "Total Adjusted Gross Rental Income",
      accessorKey: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Property",
      accessorKey: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
  ];

export const underreportedColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "left",
    },
    {
      header: "URA First Name",
      accessorKey: "Urafirstname",
      align: "left",
    },
    {
      header: "URA Middle Name",
      accessorKey: "Uramiddlename",
      align: "left",
    },
    {
      header: "URA Surname",
      accessorKey: "Urasurname",
      align: "left",
    },
    {
      header: "rMATCH Score",
      accessorKey: "Matchscore",
      align: "right",
    },
    {
      header: "TIN No.",
      accessorKey: "Uratinno",
      align: "left",
      meta: { sort: true },
    },
    {
      header: "Rental Income?",
      accessorKey: "Isincomesrcrental",
      align: "center",
    },
    {
      header: "URA Mobile No.",
      accessorKey: "Mobilenumber",
    },
    {
      header: "URA Email",
      accessorKey: "Emailid",
    },
    {
      header: "URA NIN",
      accessorKey: "Nationalid",
    },
    {
      header: "Total Number of Properties",
      accessorKey: "Numproperties",
      align: "right",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          <>{getValue()}</>
        </Link>
      ),
    },
    {
      header: "Total Number of Rental Properties",
      accessorKey: "Numrentedproperties",
      align: "right",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
          <>{getValue()}</>
        </Link>
      ),
    },
    {
      header: "Total Adjusted Gross Rental Income",
      accessorKey: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Property",
      accessorKey: "Averagerateperrentedproperty", // tbc
      align: "right",
      format: "currency",
    },
    {
      header: "Return No.",
      accessorKey: "Rtnno",
      align: "left",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => {
        const value = getValue() as string;
        return (
          <Link
            to={`/individuals/${Rnid}/tax-history/${value}`}
            color="inherit"
          >
            {value}
          </Link>
        );
      },
    },
    {
      header: "Amendment",
      accessorKey: "Isamendment",
      align: "center",
    },
    {
      header: "Property Income",
      accessorKey: "Propincincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Return Period",
      accessorKey: "Rtnperiodyear",
      align: "right",
    },
    {
      header: "URA Gross Rent Income",
      accessorKey: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Difference",
      accessorKey: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const compliantColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "left",
    },
    {
      header: "URA First Name",
      accessorKey: "Urafirstname",
      align: "left",
    },
    {
      header: "URA Middle Name",
      accessorKey: "Uramiddlename",
      align: "left",
    },
    {
      header: "URA Surname",
      accessorKey: "Urasurname",
      align: "left",
    },
    {
      header: "rMATCH Score",
      accessorKey: "Matchscore",
      align: "right",
    },
    {
      header: "TIN No.",
      accessorKey: "Uratinno",
      align: "left",
      meta: { sort: true },
    },
    {
      header: "Rental Income?",
      accessorKey: "Isincomesrcrental",
      align: "center",
    },
    {
      header: "URA Mobile No.",
      accessorKey: "Mobilenumber",
    },
    {
      header: "URA Email",
      accessorKey: "Emailid",
    },
    {
      header: "URA NIN",
      accessorKey: "Nationalid",
    },
    {
      header: "Total Number of Properties",
      accessorKey: "Numproperties",
      align: "right",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          <>{getValue()}</>
        </Link>
      ),
    },
    {
      header: "Total Number of Rental Properties",
      accessorKey: "Numrentedproperties",
      align: "right",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
          <>{getValue()}</>
        </Link>
      ),
    },
    {
      header: "Total Adjusted Gross Rental Income",
      accessorKey: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Property",
      accessorKey: "Averagerateperrentedproperty", // tbc
      align: "right",
      format: "currency",
    },
    {
      header: "Return No.",
      accessorKey: "Rtnno",
      align: "left",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => {
        const value = getValue() as string;
        return (
          <Link
            to={`/individuals/${Rnid}/tax-history/${value}`}
            color="inherit"
          >
            {value}
          </Link>
        );
      },
    },
    {
      header: "Amendment",
      accessorKey: "Isamendment",
      align: "center",
    },
    {
      header: "Property Income",
      accessorKey: "Propincincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Return Period",
      accessorKey: "Rtnperiodyear",
      align: "right",
    },
    {
      header: "URA Gross Rent Income",
      accessorKey: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Difference",
      accessorKey: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const noIncomeColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "left",
    },
    {
      header: "URA First Name",
      accessorKey: "Urafirstname",
      align: "left",
    },
    {
      header: "URA Middle Name",
      accessorKey: "Uramiddlename",
      align: "left",
    },
    {
      header: "URA Surname",
      accessorKey: "Urasurname",
      align: "left",
    },
    {
      header: "rMATCH Score",
      accessorKey: "Matchscore",
      align: "center",
    },
    {
      header: "TIN No.",
      accessorKey: "Uratinno",
      align: "center",
      meta: { sort: true },
    },
    {
      header: "Rental Income?",
      accessorKey: "Isincomesrcrental",
      align: "center",
    },
    {
      header: "URA Mobile No.",
      accessorKey: "Mobilenumber",
      align: "center",
    },
    {
      header: "URA Email",
      accessorKey: "Emailid",
      align: "center",
    },
    {
      header: "URA NIN",
      accessorKey: "Nationalid",
      align: "center",
    },
    {
      header: "Total Number of Properties",
      accessorKey: "Numproperties",
      align: "center",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          <>{getValue()}</>
        </Link>
      ),
    },
    {
      header: "Total Number of Rental Properties",
      accessorKey: "Numrentedproperties",
      align: "center",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
          <>{getValue()}</>
        </Link>
      ),
    },
    {
      header: "Total Adjusted Gross Rental Income",
      accessorKey: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Property",
      accessorKey: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
    {
      header: "Return No.",
      accessorKey: "Rtnno",
      align: "center",
      cell: ({
        cell: { getValue },
        row: {
          original: { Rnid },
        },
      }) => {
        const value = getValue() as string;
        return (
          <Link
            to={`/individuals/${Rnid}/tax-history/${value}`}
            color="inherit"
          >
            {value}
          </Link>
        );
      },
    },
    {
      header: "Amendment",
      accessorKey: "Isamendment",
      align: "center",
    },
    {
      header: "Property Income",
      accessorKey: "Propincincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Return Period",
      accessorKey: "Rtnperiodyear",
      align: "center",
    },
    {
      header: "URA Gross Rent Income",
      accessorKey: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Difference",
      accessorKey: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const unregisteredColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      header: "RNID",
      accessorKey: "Rnid",
      align: "left",
    },
    {
      header: "Customer ID",
      accessorKey: "Customerid",
      align: "center",
    },
    {
      header: "First Name",
      accessorKey: "Aggregatefirstname",
      align: "left",
    },
    {
      header: "Surname",
      accessorKey: "Aggregatesurname",
      align: "left",
    },
    {
      header: "Mobile",
      accessorKey: "Aggregatemobile1",
      align: "center",
    },
    {
      header: "Mobile2",
      accessorKey: "Aggregatemobile2",
      align: "center",
    },
    {
      header: "Telephone",
      accessorKey: "Aggregatetelephone",
      align: "center",
    },
    {
      header: "Birth Date",
      accessorKey: "Aggregatebirthdate",
      align: "center",
      format: "date",
    },
    {
      header: "Kcca NIN",
      accessorKey: "Nationalid",
      align: "left",
    },
    {
      header: "Email",
      accessorKey: "Aggregateemail",
      align: "center",
    },

    {
      header: "Total No. Of Properties",
      accessorKey: "Numproperties",
      align: "center",
      cell: ({
        getValue,
        row: {
          original: { Rnid },
        },
      }): ReactNode => {
        return (
          <Link to={`/individuals/${Rnid}/properties`} color="inherit">
            <> {getValue()} </>
          </Link>
        );
      },
    },
    {
      header: "Total No. Of Rented Properties",
      accessorKey: "Numrentedproperties",
      align: "center",
      cell: ({
        getValue,
        row: {
          original: { Rnid },
        },
      }): ReactNode => {
        return (
          <Link to={`/individuals/${Rnid}/properties`} color="inherit">
            <> {getValue()} </>
          </Link>
        );
      },
    },
    {
      header: "Sum Rateable Value Of Rented Properties",
      accessorKey: "Sumrateablevalue",
      align: "right",
      format: "currency",
    },
    {
      header: "Sum Adjusted Gross Rental Income",
      accessorKey: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      header: "Average Rate Per Rented Property",
      accessorKey: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
  ];

export const excessiveOccupancyColumns: TableColumn<IndividualExcessiveOwnerOccupancyFragment>[] =
  [
    {
      header: "Customer No",
      accessorKey: "CustomerId",
      align: "center",
    },
    {
      header: "Aggregate First Name",
      accessorKey: "AggregateFirstName",
      align: "left",
    },
    {
      header: "Aggregate Middle Name",
      accessorKey: "AggregateMiddleName",
      align: "center",
    },
    {
      header: "Aggregate Surname",
      accessorKey: "AggregateSurname",
      align: "left",
    },
    { header: "rMATCH Score", accessorKey: "MatchScore", align: "center" },
    {
      header: "Number of Owner Occupied Properties",
      accessorKey: "NumOwnedOccupied",
      align: "center",
    },
    {
      header: "Total Adjusted Gross Rental Income",
      accessorKey: "SumAdjustedIncome",
      align: "center",
      format: "currency",
    },
    {
      header: "Match Aggregate Owner to URA Taxpayer",
      accessorKey: "MatchAggregateUra",
      align: "left",
    },
    {
      header: "URA First Name",
      accessorKey: "UraFirstname",
      align: "left",
    },
    {
      header: "URA Surname",
      accessorKey: "UraSurname",
      align: "left",
    },
    {
      header: "TIN No.",
      accessorKey: "UraTin",
      align: "left",
    },
    {
      header: "Rental Income?",
      accessorKey: "IsIncomeSrcRental",
      align: "left",
    },
    {
      header: "URA Mobile No.",
      accessorKey: "MobileNumber",
      align: "left",
    },
    {
      header: "URA Email",
      accessorKey: "EmailId",
      align: "left",
    },
    {
      header: "URA NIN",
      accessorKey: "NationalId",
      align: "left",
    },
  ];

export default {
  noReturnsColumns,
};
