/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link, useLocation } from "react-router-dom";

import { NonIndividualIncomeTaxSummariesFragment } from "graphql/hasura/types-and-hooks";

export type ReportTableSchema = Omit<
  NonIndividualIncomeTaxSummariesFragment,
  "__typename"
>;

export const REPORT_TABLE_SCHEMA: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  RtnPeriodYear: "string",
  RtnNo: "string",
  IsAmendment: "string",
  TotIncome: "number",
  TotTax: "number",
  TotRate: "number",
  GrossRentalIncome: "number",
  RentincIncome: "number",
  RentincTax: "number",
  RentincRate: "number",
  ChargeableIncomeP: "number",
  RentincIncomeP: "number",
  ReturnType: "string",
  RtnDt: "string",
};

// TODO: Add column - Rental Income as a % of Total Income
export const columns: TableColumn<ReportTableSchema>[] = [
  {
    header: "Tax Year",
    accessorKey: "RtnPeriodYear",
    align: "center",
    meta: { sort: true },
    cell: ({ cell: { getValue } }) => {
      const value = getValue() as string;
      return <>{value?.replace("-", "/")}</>;
    },
  },
  {
    header: "Return No.",
    accessorKey: "RtnNo",
    align: "center",
    cell: ({ cell: { getValue } }) => {
      const value = getValue() as string;
      const location = useLocation();
      const url = location.pathname;
      return (
        <Link to={`${url}/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Amendment?",
    accessorKey: "IsAmendment",
    align: "center",
  },
  {
    header: "ReturnType",
    accessorKey: "ReturnType",
  },
  {
    header: "Return Date",
    accessorKey: "RtnDt",
    align: "center",
    format: "date",
    meta: { sort: true },
  },
  {
    header: "Total income",
    accessorKey: "TotIncome",
    align: "right",
    format: "currency",
  },
  {
    header: "Total Income Tax Declared",
    accessorKey: "TotTax",
    align: "right",
    format: "currency",
  },
  {
    header: "Income Tax %",
    accessorKey: "TotRate",
    format: "percent",
  },
  {
    header: "Gross Rental Income",
    accessorKey: "GrossRentalIncome",
    align: "right",
    format: "currency",
  },
  {
    header: "Chargeable Rental Income",
    accessorKey: "RentincIncome",
    align: "right",
    format: "currency",
  },
  {
    header: "Chargeable Rental Income %",
    accessorKey: "ChargeableIncomeP",
    align: "right",
    format: "percent",
  },
  {
    header: "Rental Tax",
    accessorKey: "RentincTax",
    align: "right",
    format: "currency",
  },
  {
    header: "Rental Tax %",
    accessorKey: "RentincRate",
    align: "right",
    format: "percent",
  },
  {
    header: "Rental Income %",
    accessorKey: "RentincIncomeP",
    align: "right",
    format: "percent",
  },
];
