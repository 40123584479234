// Dependencies
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

const ContainerApplicator = styled(Container)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export default {
  ContainerApplicator,
};
