import React, { ReactNode } from "react";
import { SnackbarCloseReason } from "@mui/material";
import { ErrorBoundary as ErrorBoundaryBase } from "react-error-boundary";

// Errors
import {
  ServerErrorCodes,
  useAppError,
  useAppErrorHandler,
} from "errors/app.errors";

// Utils
import auth from "graphql/authentication";

// Assets
import SC from "./error-boundary.styles";

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const appErrorData = useAppError();
  const appErrorHandler = useAppErrorHandler();

  React.useEffect(() => {
    if (appErrorData?.code === ServerErrorCodes.INVALID_JWT) {
      appErrorHandler(undefined);
      auth.logOut();
    }
  }, [appErrorData, appErrorHandler]);

  const handleCloseSnack = React.useCallback(
    (
      event?: React.SyntheticEvent<any, Event> | Event,
      reason?: SnackbarCloseReason,
    ) => {
      if (reason === "clickaway") return;
      appErrorHandler(undefined);
    },
    [appErrorHandler],
  );

  const fallbackRenderHandler = React.useCallback(
    ({ error, resetErrorBoundary }: any) => (
      <SC.Card>
        <SC.CardContent>
          <SC.Message>
            Please contact support@ripplenami.com for assistance.
          </SC.Message>
        </SC.CardContent>
      </SC.Card>
    ),
    [],
  );

  return (
    <ErrorBoundaryBase fallbackRender={fallbackRenderHandler}>
      {children}
      <SC.Snackbar open={!!appErrorData} onClose={handleCloseSnack}>
        <SC.Alert
          severity="error"
          message={appErrorData?.message}
          onClose={handleCloseSnack}
        />
      </SC.Snackbar>
    </ErrorBoundaryBase>
  );
};

export default ErrorBoundary;
