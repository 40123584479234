/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography, Card as CardBase } from "@mui/material";
import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import { styled } from "@mui/material/styles";

const Heading = styled(Typography)(() => ({
  font: "1.42rem",
  fontWeight: "bold",
  textAlign: "left",
  marginLeft: "1rem",
  marginTop: "1.14rem",
}));

const Card = styled(CardBase)(({ theme }) => ({
  border: theme.tableCard.card.border,
  borderRadius: theme.tableCard.card.border,
  margin: theme.tableCard.card.margin,
}));

export default {
  Table,
  ProgressIndicator,
  Heading,
  Card,
};
