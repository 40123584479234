/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size";
import _ from "lodash";

// Components
import BannerNavigation from "components/banner-navigation/banner-navigation.component";

// Pages
import HomePage from "pages/home/home.component";
import MyProfilePage from "pages/my-profile/my-profile.component";
import UserManagementPage from "pages/user-management/user-management.component";
import GroupManagementPage from "pages/group-management/group-management.component";
import RoleManagementPage from "pages/role-management/role-management.component";
import HasuraPoc from "pages/hasura-poc/hasura-poc.component";
import IndividualPage from "pages/individual/individual.component";
import NonIndividualPage from "pages/non-individual/non-individual.component";
import PropertiesPage from "pages/properties/properties.component";
import ReportsMainPage from "pages/reports/main/main.component";
import IndividualTabPage from "pages/individual-tab/main/individual-tab.component";
import NonIndividualTabPage from "pages/non-individual-tab/main/non-individual-tab.component";
import PropertiesTabPage from "pages/properties-tab/main/main.component";
import VisibilityPage from "pages/visibility/visibility.component";
import RevisionPage from "pages/revision/revision.component";
import TaxExemptOrganisationPage from "pages/tax-exempt-organisation/tax-exempt-organisation.component";
import TaxpayersWithNoReturns from "pages/reports/taxpayers-with-no-returns/individuals/individuals.component";
import TaxpayersNoReturnsNonIndividuals from "pages/reports/taxpayers-with-no-returns/non-individuals/non-individuals.component";
import TaxpayersWithUndeclaredIncome from "pages/reports/taxpayers-with-undeclared-income/individuals/individuals.component";
import TaxpayersWithUndeclaredIncomeNonIndividuals from "pages/reports/taxpayers-with-undeclared-income/non-individuals/non-individuals.component";
import TaxpayersWithUnderreportedIncome from "pages/reports/taxpayers-with-underreported-income/individuals/individuals.component";
import TaxpayersWithUnderreportedIncomeNonIndividuals from "pages/reports/taxpayers-with-underreported-income/non-individuals/non-individuals.component";
import IndividualsOwnersWithNoTin from "pages/reports/unregistered-landlords/individuals/individuals.component";
import NonIndividualsOwnersWithNoTin from "pages/reports/unregistered-landlords/non-individuals/non-individuals.component";
import UgandaRegistration from "pages/reports/ursb/uganda-registration/uganda-registration.component";
import IndividualRegistration from "pages/reports/ura/individual-registration/individual-registration.component";
import IndividualTaxDetail from "pages/reports/ura/individual-tax-detail/individual-tax-detail.component";
import NonIndividualRegistration from "pages/reports/ura/non-individual-registration/non-individual-registration.component";
import NonIndividualTax from "pages/reports/ura/non-individual-tax-detail/non-individual-tax-detail.component";
import MlhudRegistration from "pages/reports/mlhud/mlhud-registration.component";
import CustomerRegistration from "pages/reports/kcca/customer-registration/customer-registration.component";
import PropertyRegistration from "pages/reports/kcca/property-registration/property-registration.component";
import WaterAndSewage from "pages/reports/nwsc/water-and-sewage.component";
import UmemeSubscriberAndProperty from "pages/reports/umeme/subscriber-and-property/subscriber-and-property.component";
import RCaptureProperties from "pages/reports/rcapture/properties/properties.component";
import RCapturePropertyDashboard from "pages/reports/rcapture/property-dashboard/property-dashboard.component";
import RCaptureUnitTypes from "pages/reports/rcapture/unittypes/unittypes.component";
import RCaptureLessors from "pages/reports/rcapture/lessors/lessors.component";
import RCaptureTenants from "pages/reports/rcapture/tenants/tenants.component";
import RCapturePropertyDetails from "pages/reports/rcapture/property-details/property-details.component";
import DataByCriteria from "pages/reports/data-by-criteria/data-by-criteria/data-by-criteria.component";
import NiraRegistration from "pages/reports/nira/nira-registration.component";
import ExcessiveOwnerOccupancy from "pages/reports/kcca-ura/report.component";
import LandlordIncomeSummary from "pages/reports/declared-vs-reported/landlord-income-summary/landlord-income-summary.component";
import LandlordIncomeDetail from "pages/reports/declared-vs-reported/landlord-income-detail/landlord-income-detail.components";
import TenantIncomeDetail from "pages/reports/declared-vs-reported/tenant-income-detail/tenant-income-detail.component";
import OfficersAndOwnersRegistration from "pages/reports/ura/officers-and-owners/officers-and-owners.component";
import AssociatedRegisteredIndividualsToNonIndividuals from "pages/reports/ura/associated-registered-individuals-to-nonindividuals/associated-registered-individuals-to-nonindividuals.component";
import AssociatedRegisteredNonIndividualsToNonIndividuals from "pages/reports/ura/associated-registered-nonindividuals-to-nonindividuals/associated-registered-nonindividuals-to-nonindividuals.component";
import TenantRentalPayments from "pages/reports/ura/tenant-rental-payments/tenant-rental-payments.component";
import MobilePhoneNumbers from "pages/reports/ucc/mobile-phone-numbers/mobile-phone-numbers.component";

import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";

// Assets
import SC from "./main.styles";

const MainPage: React.FC = () => {
  const pageRef = React.useRef<HTMLDivElement>(null);
  const pageContentRef = React.useRef<HTMLDivElement>(null);

  const pageRefPosition = pageRef.current?.getBoundingClientRect();
  const pageContentPosition = pageContentRef.current?.getBoundingClientRect();

  const pagePositionY = pageRefPosition?.y ?? 0;
  const pageContentPositionY = pageContentPosition?.y ?? 0;

  const windowHeight = useWindowHeight();
  const pageHeight = windowHeight - pagePositionY;
  const pageContentHeight = windowHeight - pageContentPositionY;

  const appPermissionValidator = useAppPermissionValidator();

  interface RouteConfig {
    path: string | string[];
    element?: React.ReactNode;
    component?: React.ComponentType;
  }

  const routes: RouteConfig[] = [
    {
      path: "hasura-poc",
      component: HasuraPoc,
    },
    {
      path: "my-profile",
      component: MyProfilePage,
    },
  ];

  if (appPermissionValidator) {
    if (appPermissionValidator(APP_PERMISSION.HOME_VIEW)) {
      routes.push({
        path: "home",
        component: HomePage,
      });
    }

    if (appPermissionValidator(APP_PERMISSION.INDIVIDUALS_VIEW)) {
      routes.push(
        {
          path: "individuals",
          component: IndividualPage,
        },
        {
          path: "individuals/:repId/*",
          component: IndividualTabPage,
          element: <IndividualTabPage />,
        },
      );
    }

    if (appPermissionValidator(APP_PERMISSION.NON_INDIVIDUALS_VIEW)) {
      routes.push(
        {
          path: "non-individuals",
          component: NonIndividualPage,
        },
        {
          path: "non-individuals/:repId/*",
          component: NonIndividualTabPage,
        },
      );
    }

    if (appPermissionValidator(APP_PERMISSION.PROPERTIES_VIEW)) {
      routes.push(
        {
          path: "properties",
          component: PropertiesPage,
        },
        {
          path: "properties/:repId/*",
          component: PropertiesTabPage,
        },
      );
    }

    if (appPermissionValidator(APP_PERMISSION.REPORTS)) {
      routes.push(
        {
          path: "reports",
          component: ReportsMainPage,
        },
        {
          path: [
            "reports/MDA",
            "reports/MDA/URA",
            "reports/MDA/NWSC",
            "reports/MDA/UMEME",
            "reports/MDA/KCCA",
            "reports/MDA/URSB",
            "reports/MDA/MLHUD",
            "reports/MDA/UCC",
            "reports/MDA/rCAPTURE",
            "reports/MDA/rCAPTURE/unittypes",
            "reports/MDA/rCAPTURE/lessors",
            "reports/MDA/rCAPTURE/tenants",
          ],
          element: <Navigate to={"/reports"} />,
        },
        {
          path: [
            "reports/analytics",
            "reports/analytics/:report",
            "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/tenant",
            "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/tenant/:landlordTin",
            "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/tenant/:landlordTin/:taxYear",
            "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/landlord",
            "reports/analytics/declared-vs-reported-income/individuals-and-non-individuals/landlord/:landlordTin",
          ],
          element: <Navigate to={"/reports"} />,
        },
        {
          path: "reports/MDA/URA/individual-registration",
          component: IndividualRegistration,
        },
        {
          path: "reports/MDA/URA/individual-tax-detail",
          component: IndividualTaxDetail,
        },
        {
          path: "reports/MDA/URA/non-individual-registration",
          component: NonIndividualRegistration,
        },
        {
          path: "reports/MDA/URA/non-individual-tax-detail",
          component: NonIndividualTax,
        },
        {
          path: "reports/MDA/URA/tenant-reported-rent-paid",
          component: TenantRentalPayments,
        },
        {
          path: "reports/MDA/UCC/mobile-phone-numbers",
          component: MobilePhoneNumbers,
        },
        {
          path: "reports/MDA/rCAPTURE/properties",
          component: RCaptureProperties,
        },
        {
          path: "reports/MDA/rCAPTURE/properties/:propertyId",
          component: RCapturePropertyDashboard,
        },
        {
          path: "reports/MDA/rCAPTURE/properties/:id/details",
          component: RCapturePropertyDetails,
        },
        {
          path: "reports/MDA/rCAPTURE/unittypes/:unittypeId",
          component: RCaptureUnitTypes,
        },
        {
          path: "reports/MDA/rCAPTURE/lessors/:lessorId",
          component: RCaptureLessors,
        },
        {
          path: "reports/MDA/rCAPTURE/tenants/:tenantId",
          component: RCaptureTenants,
        },
        {
          path: "reports/MDA/all-MDA-data-by-criteria",
          component: DataByCriteria,
        },
        {
          path: "reports/analytics/unregistered-landlords/individuals",
          component: IndividualsOwnersWithNoTin,
        },
        {
          path: "reports/analytics/unregistered-landlords/non-individuals",
          component: NonIndividualsOwnersWithNoTin,
        },
        {
          path: "reports/analytics/taxpayers-with-no-returns/individuals",
          component: TaxpayersWithNoReturns,
        },
        {
          path: "reports/analytics/taxpayers-with-no-returns/non-individuals",
          component: TaxpayersNoReturnsNonIndividuals,
        },
        {
          path: "reports/analytics/taxpayers-with-no-income/individuals",
          component: TaxpayersWithUndeclaredIncome,
        },
        {
          path: "reports/analytics/taxpayers-with-no-income/non-individuals",
          component: TaxpayersWithUndeclaredIncomeNonIndividuals,
        },
        {
          path: "reports/analytics/taxpayers-with-underreported-income/individuals",
          component: TaxpayersWithUnderreportedIncome,
        },
        {
          path: "reports/analytics/taxpayers-with-underreported-income/non-individuals",
          component: TaxpayersWithUnderreportedIncomeNonIndividuals,
        },
        {
          path: "reports/MDA/URSB/uganda-registration-services-bureau",
          component: UgandaRegistration,
        },
        {
          path: "reports/MDA/KCCA/customer-registration",
          component: CustomerRegistration,
        },
        {
          path: "reports/MDA/KCCA/property-registration",
          component: PropertyRegistration,
        },
        {
          path: "reports/MDA/NWSC/national-water-and-sewage-corporation",
          component: WaterAndSewage,
        },
        {
          path: "reports/MDA/UMEME/subscriber-and-property",
          component: UmemeSubscriberAndProperty,
        },
        {
          path: "reports/ura/individual-registration",
          component: IndividualRegistration,
        },
        {
          path: "reports/ura/individual-tax-detail",
          component: IndividualTaxDetail,
        },
        {
          path: "reports/MDA/mlhud/mlhud-registration",
          component: MlhudRegistration,
        },
        {
          path: "reports/MDA/NIRA-registration",
          component: NiraRegistration,
        },
        {
          path: "reports/MDA/ura/holding-owners-and-officers",
          component: OfficersAndOwnersRegistration,
        },
        {
          path: "reports/MDA/ura/associated-registered-individuals-to-nonindividuals",
          component: AssociatedRegisteredIndividualsToNonIndividuals,
        },
        {
          path: "reports/MDA/ura/associated-registered-nonindividuals-to-nonindividuals",
          component: AssociatedRegisteredNonIndividualsToNonIndividuals,
        },
        {
          path: "reports/analytics/excessive-owner-occupancy/individuals",
          component: ExcessiveOwnerOccupancy,
        },
        {
          path: "reports/analytics/landlord-declared-vs-tenant-reported/individuals-and-non-individuals",
          component: LandlordIncomeSummary,
        },
        {
          path: "reports/analytics/landlord-declared-vs-tenant-reported/individuals-and-non-individuals/landlord/:returnNo/detail",
          component: LandlordIncomeDetail,
        },
        {
          path: "reports/analytics/landlord-declared-vs-tenant-reported/individuals-and-non-individuals/tenant/:landlordTin/:taxYear/detail",
          component: TenantIncomeDetail,
        },
      );
    }

    if (appPermissionValidator(APP_PERMISSION.ADMIN)) {
      routes.push(
        {
          path: "administration/user-management/users",
          component: UserManagementPage,
        },
        {
          path: "administration/user-management/groups",
          component: GroupManagementPage,
        },
        {
          path: "administration/user-management/roles",
          component: RoleManagementPage,
        },
      );

      if (appPermissionValidator(APP_PERMISSION.ADMIN_SET_TAX_EXEMPT)) {
        routes.push({
          path: "administration/tax-exempt-organisations",
          component: TaxExemptOrganisationPage,
        });
      }

      if (appPermissionValidator(APP_PERMISSION.ADMIN_SET_VISIBILITY_LEVEL)) {
        routes.push({
          path: "administration/visibility",
          component: VisibilityPage,
        });
      }

      routes.push({
        path: "administration/revision",
        component: RevisionPage,
      });
    }

    routes.push({
      path: "*",
      element: <>Not Found</>,
    });
  }

  return (
    <SC.Page maxWidth={false} ref={pageRef} maxHeight={pageHeight}>
      <BannerNavigation />
      <SC.PageContent
        maxWidth={false}
        ref={pageContentRef}
        maxHeight={pageContentHeight}
      >
        <Routes>
          {routes.map((route, index) =>
            Array.isArray(route.path) ? (
              route.path.map((p) => (
                <Route
                  key={`${index}-${p}`}
                  path={p}
                  element={route.element}
                  Component={route.component}
                />
              ))
            ) : (
              <Route
                key={index}
                path={route.path}
                element={route.element}
                Component={route.component}
              />
            ),
          )}
        </Routes>
      </SC.PageContent>
    </SC.Page>
  );
};

export default MainPage;
