/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Box, IconButton as IconButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const TablePaginationAction = styled(Box)(({ theme }) => ({
  display: "flex",
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(1),
  "& .MuiTablePagination-caption": {
    color: "red",
  },
}));

const IconButton = styled(IconButtonBase)(({ theme }) => ({
  padding: theme.spacing(1),
  "& .MuiSvgIcon-root": {
    fontSize: theme.table.toolbarActions.fontSize,
  },
  "&:not(.Mui-disabled) .MuiSvgIcon-root": {
    color: theme.table.tablePagination.actionsIconColor,
  },
}));

export default {
  TablePaginationAction,
  IconButton,
};
