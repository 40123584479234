/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography as TypographyBase,
  Container as ContainerBase,
  Paper as PaperBase,
  Grid as GridBase,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import CalendarSelect from "components/calendar-select/calendar-select.component";

const Typography = styled(TypographyBase)(({ theme }) => ({
  h6: {
    fontWeight: theme.table.tableRangeActions.fontWeight,
    fontSize: theme.table.tableRangeActions.fontSize,
  },
  body1: {
    textAlign: "right",
  },
}));
const Paper = styled(PaperBase)(() => ({}));
const Grid = styled(GridBase)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: "15px",
}));

const Container = styled(ContainerBase)(() => ({
  textAlign: "left",
}));

const SelectField = styled(CalendarSelect)(() => ({
  "& .MuiSelect-root em": {
    color: "#000",
  },
}));

export default {
  Container,
  Typography,
  Paper,
  Grid,
  SelectField,
  Select,
};
