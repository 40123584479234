/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Routes,
  Route,
  useLocation,
  useParams,
  useMatch,
} from "react-router-dom";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Aggregate_PropertyArgs,
  useAggregatePropertyFilterQuery,
} from "graphql/hasura/types-and-hooks";
import { AggregatePropertyQuery_AggregateProperty_ElementKey } from "graphql/hasura/rtcs.types";
// Utils
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";

// Components
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";
import { formatNumber } from "../../../utils/math";
import ValuationTab from "../valuation-tab/valuation-tab.component";
import UtilitiesTab from "../utilities-tab/utilities-tab.component";
import DetailsTab from "../details-tab/details-tab.component";
import OwnerTab from "../owner-tab/owner-tab.component";
import PropertyNotes from "../notes-tab/notes-tab.component";

// Styles
import SC from "./properties-tab.styles";

export interface IndividualTabRouteParams {
  repId: string;
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rMatchdata: any;
}

interface AggregateProperty {
  label: string;
  accessorKey: AggregatePropertyQuery_AggregateProperty_ElementKey;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PropertiesTabPage = ({ rMatchdata }: Props) => {
  const match = useMatch("*");
  const path = match?.pathname ?? "";
  const pathArr = path.split("/");
  pathArr.pop();

  const appPermissionValidator = useAppPermissionValidator();

  const camvId = rMatchdata.CamvId || ""; // TODO: camvId is not used
  const Rnid = rMatchdata.Rnid || ""; // TODO: camvId is not used
  const PropertyNo = rMatchdata.PropertyNo || ""; // TODO: camvId is not used
  const customerId = rMatchdata.CustomerId || ""; // TODO: camvId is not used
  const NwscReference = rMatchdata.NwscCustomerId; // TODO: camvId is not used

  const propertyAddressGroup: AggregateProperty[] = [
    {
      label: "RNID",
      accessorKey:
        "Rnid" as AggregatePropertyQuery_AggregateProperty_ElementKey,
    },
    { label: "Property No.", accessorKey: "PropertyNo" },
    { label: "Plot No.", accessorKey: "PlotNo" },
    { label: "Flat No.", accessorKey: "FlatNo" },
    { label: "House No.", accessorKey: "HouseNo" },
    { label: "Block No.", accessorKey: "BlockNo" },
  ];
  const locationGroup: AggregateProperty[] = [
    { label: "Street", accessorKey: "StreetId" },
    { label: "Address", accessorKey: "Address" },
    { label: "Village", accessorKey: "Village" },
    { label: "Parish", accessorKey: "Parish" },
    { label: "Division", accessorKey: "Division" },
  ];
  const valuationGroup: AggregateProperty[] = [
    { label: "Property Rental Status", accessorKey: "PropertyRentedStatus" },
    { label: "Gross Value", accessorKey: "GrossValue", format: "currency" },
    {
      label: "Rateable Value",
      accessorKey: "RateableValue",
      format: "currency",
    },
    {
      label: "Current Rateable Value",
      accessorKey: "CurrentRateableValue",
      format: "currency",
    },
  ];

  const queryVariables: Query_RootRtcs_Db_Ph_Aggregate_PropertyArgs = {
    where: camvId ? { CamvId: { _eq: camvId } } : { Rnid: { _eq: Rnid } },
    limit: 1,
  };

  const { data, error } = useAggregatePropertyFilterQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !camvId && !Rnid,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const handleError = useAppErrorHandler(error);

  // In order to avoid the warning raised in the browser inspector's console:
  // "Cannot update a component (ErrorBoundary) while rendering a different component (IndividualTabPage)",
  // we need to call handleError inside of useEffect.
  React.useEffect(() => {
    if (data && data.rtcs_db_Fnaggregate_Property.length === 0) {
      handleError(
        new UIError(
          UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
          `Invalid Search Params`,
        ),
      );
    }
  }, [data, handleError]);

  // memoize the data
  const propertyData = React.useMemo(
    () => data?.rtcs_db_Fnaggregate_Property[0] || null,
    [data],
  );

  if (!propertyData) return null;

  const propertiesHeader = [
    {
      index: 0,
      label: propertyData.Address ?? "",
      type: "bold",
    },
    { index: 1, label: "RNID", type: "bold" },
    { index: 2, label: Rnid },
    { index: 3, label: "CustomerId", type: "bold" },
    { index: 4, label: customerId },
  ];

  const headerContent = [
    {
      index: 0,
      title: "Property Address",
      data: [
        ...propertyAddressGroup.map(({ accessorKey, label }, index) => {
          // eslint-disable-next-line no-return-assign
          return {
            label,
            index,
            value:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // eslint-disable-next-line no-nested-ternary
              accessorKey === "Rnid"
                ? Rnid
                : accessorKey === "PropertyNo"
                  ? PropertyNo
                  : propertyData[accessorKey] || "",
          };
        }),
      ],
    },

    {
      index: 1,
      title: "Location",
      data: [
        ...locationGroup.map(({ accessorKey, label }, index) => {
          return {
            label,
            index,
            value: propertyData[accessorKey],
          };
        }),
      ],
    },

    {
      index: 2,
      title: "Valuation ",
      data: [
        ...valuationGroup.map(({ accessorKey, label, format }, index) => {
          return {
            label,
            index,
            value:
              accessorKey === "GrossValue"
                ? formatNumber(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    propertyData[accessorKey],
                  )
                : propertyData[accessorKey] || "",
            format,
          };
        }),
      ],
    },
  ];
  const tabsData = [
    { label: "Details", path: "details", value: 0 },
    { label: "Owner", path: "owner", value: 1 },
    { label: "Utilities", path: "utilities", value: 2 },
    { label: "Valuation", path: "valuation", value: 3 },
    { label: "Field Data", path: "field-data", value: 4 },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // let CamvId: any = null;
  // if (data.rtcs_db_Fnaggregate_Property.length > 0) {
  //   CamvId = data.rtcs_db_Fnaggregate_Property[0].CamvId ?? "";
  // }

  interface RouteConfig {
    path: string | string[];
    element: JSX.Element;
  }

  const routes: RouteConfig[] = [
    {
      path: "utilities",
      element: <UtilitiesTab reference={NwscReference} />,
    },
    {
      path: "valuation",
      element: <ValuationTab Rnid={Rnid} />,
    },
    {
      path: "details",
      element: <DetailsTab propertyData={propertyData} Rnid={Rnid} />,
    },
    {
      path: "owner",
      element: (
        <OwnerTab camvId={propertyData.CamvId || ""} propertyNo={PropertyNo} />
      ),
    },
  ];

  if (appPermissionValidator?.(APP_PERMISSION.PROPERTIES_VIEW_NOTES)) {
    tabsData.push({ label: "Notes", path: "notes", value: 5 });
    routes.push({
      path: `notes/:noteID?`,
      element: <PropertyNotes />,
    });
  }

  routes.push({
    path: "*",
    element: <>Content Not Found</>,
  });

  const { BlockNo, PlotNo, StreetId } = propertyData;

  return (
    <SC.Container disableGutters>
      <SC.Breadcrumb
        label={` ${BlockNo ? `${BlockNo},` : ""} Plot ${
          PlotNo ? `${PlotNo},` : ""
        } ${StreetId ?? ""}`}
      />
      <SC.ListSubheader>
        <SC.SubHeader
          headerData={{
            header: propertiesHeader,
            content: headerContent,
            source: "Aggregate",
          }}
          tabsData={tabsData}
        />
      </SC.ListSubheader>
      <SC.Box>
        <Routes>
          {routes.map((route, index) =>
            Array.isArray(route.path) ? (
              route.path.map((p) => (
                <Route key={`${index}-${p}`} path={p} element={route.element} />
              ))
            ) : (
              <Route key={index} path={route.path} element={route.element} />
            ),
          )}
        </Routes>
      </SC.Box>
    </SC.Container>
  );
};

export default PropertiesTabPage;
