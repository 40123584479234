/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { UraRptIndividualTaxSummariesFragment } from "graphql/hasura/types-and-hooks";

export type TaxHistorySchema = Omit<
  UraRptIndividualTaxSummariesFragment,
  "__typename"
>;

export const TaxHistorySchemaMap: Record<
  keyof TaxHistorySchema,
  "string" | "number" | "date"
> = {
  RtnPeriodYear: "date",
  RtnNo: "number",
  IsAmendment: "string",
  TotIncome: "number",
  TotTax: "number",
  RentincTax: "number",
  GrossRentalIncome: "number",
  TotRate: "number",
  ChargeableIncome: "number",
  RentincRate: "number",
  RentalIncomeP: "number",
  ChargeableIncomeP: "number",
  ReturnType: "string",
  RtnDt: "date",
};

export const getAccessorType = (
  accessor: keyof TaxHistorySchema | AccessorFn<TaxHistorySchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return TaxHistorySchemaMap[accessor];
};

export const taxHistoryColumns: TableColumn<TaxHistorySchema>[] = [
  {
    header: "Tax Year",
    accessorKey: "RtnPeriodYear",
    align: "left",
    meta: { sort: true },
  },
  {
    header: "Return No.",
    accessorKey: "RtnNo",
    align: "left",
    cell: ({ cell: { getValue } }) => {
      const value = getValue() as string;
      const location = useLocation();
      const url = location.pathname;
      return (
        <Link to={`${url}/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Amendment",
    accessorKey: "IsAmendment",
    align: "center",
  },
  {
    header: "ReturnType",
    accessorKey: "ReturnType",
    format: "string",
  },
  {
    header: "Return Date",
    accessorKey: "RtnDt",
    align: "center",
    format: "date",
    meta: { sort: true },
  },
  {
    header: "Total Income",
    accessorKey: "TotIncome",
    align: "right",
    format: "currency",
  },
  {
    header: "Total Income Tax Declared",
    accessorKey: "TotTax",
    align: "right",
    format: "currency",
  },
  {
    header: "IncomeTax %",
    accessorKey: "TotRate",
    align: "right",
    format: "percent",
  },
  {
    header: "Total Gross Rental Income",
    accessorKey: "GrossRentalIncome",
    align: "right",
    format: "currency",
  },
  {
    header: "Total Chargeable Rental Income",
    accessorKey: "ChargeableIncome",
    align: "right",
    format: "currency",
  },
  {
    header: "Rental Tax",
    accessorKey: "RentincTax",
    align: "right",
    format: "currency",
  },
  {
    header: "Rental Tax %",
    accessorKey: "RentincRate",
    align: "right",
    format: "percent",
  },
  {
    header: "Chargeable Income Percent",
    accessorKey: "ChargeableIncomeP",
    hiddenColumn: true,
    align: "right",
    format: "percent",
  },
  {
    header: "Rental Income Percent",
    accessorKey: "RentalIncomeP",
    hiddenColumn: true,
    align: "right",
    format: "percent",
  },
  {
    header: "Rental Income / Total Income (%)",
    // @ts-ignore
    accessorKey: "n/a",

    align: "center",
    cell: ({
      row: {
        original: { RentalIncomeP },
      },
    }) => {
      return RentalIncomeP;
    },
    enableSorting: false,
  },
];
