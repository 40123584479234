import React from "react";

// Assets
import SC from "./table-no-records-statement.styles";

export const NoRecordsStatement: React.FC = () => {
  return (
    <SC.NoRecordsStatement variant="h6">
      No records found.
    </SC.NoRecordsStatement>
  );
};

export default NoRecordsStatement;
