import { TableColumn } from "components/table/table.component";
import {
  AggregateOwnerPrimaryOwnerFragment,
  PropertiesValuationFragment,
} from "graphql/hasura/types-and-hooks";

export type AggregatePrimaryOwnerTableSchema = Omit<
  AggregateOwnerPrimaryOwnerFragment,
  "__typename"
>;

type SupportedType = Record<string, unknown> | PropertiesValuationFragment;

export const AggregateColumns: TableColumn<SupportedType>[] = [
  {
    header: "Source",
    accessorKey: "source" as keyof SupportedType,
    align: "center",
    format: "string",
  },
  {
    header: "Parameter",
    accessorKey: "parameter" as keyof SupportedType,
    align: "left",
  },
  {
    header: "Value",
    accessorKey: "value" as keyof SupportedType,
    align: "center",
    format: "currency",
  },
];
