/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { TableColumn } from "components/table/table.component";
import { NonIndividualCompliancePlusRiskReportFragment } from "graphql/hasura/types-and-hooks";
import FormattedValue from "components/formatted-value/formatted-value";

export type ReportTableSchema = Omit<
  NonIndividualCompliancePlusRiskReportFragment,
  | "__typename"
  | "Aggregatecustomerid"
  | "Tinno"
  | "Mobilenumber"
  | "Emailid"
  | "Compliancecategory"
  | "Formerbusinessname"
  | "LastUpdated"
  | "Percentagedifference"
  | "Sumgrossvalue"
  | "Sumrateablevalue"
  | "Tradingcenter"
  | "Uraname"
  | "Rentincincome"
  | "RiskCode"
>;

export const NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Rnid: "string",
  Aggregatename: "string",
  Matchscore: "number",
  Uratinno: "string",
  Isincomesrcrental: "number",
  Numproperties: "number",
  Numrentedproperties: "number",
  Averagerateperrentedproperty: "number",
  Rtnno: "string",
  Isamendment: "string",
  Rtnperiodyear: "string",
  Difference: "number",
  Sumofadjustedincome: "number",
  Grossrentalincome: "number",
  Source: "string",
  RiskScore: "number",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | AccessorFn<ReportTableSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const taxpayersColumns: TableColumn<ReportTableSchema>[] = [
  {
    header: "RNID",
    accessorKey: "Rnid",
    align: "center",
    cell: ({ cell: { getValue } }) => {
      const value = getValue() as string;
      return (
        <Link to={`/non-individuals/${value}/identification`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Risk Score",
    accessorKey: "RiskScore",
    align: "center",
    meta: { sort: true },
  },
  {
    header: "Source",
    accessorKey: "Source",
    align: "center",
    format: "string",
  },
  {
    header: "Entity Legal Name",
    accessorKey: "Aggregatename",
    align: "left",
    format: "string",
  },
  {
    header: "rMATCH Score",
    accessorKey: "Matchscore",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { Rnid },
      },
      cell,
    }) => {
      const value = getValue() as number;

      if (value === 0) return 0;

      return (
        <Link to={`/non-individuals/${Rnid}/analysis`} color="inherit">
          {cell.row.id === "0" ? (
            <FormattedValue format="percent" value={value / 100} />
          ) : (
            <FormattedValue format="number" value={value} />
          )}
        </Link>
      );
    },
  },
  {
    header: "TIN No.",
    accessorKey: "Uratinno",
    align: "center",
    format: "string",
  },
  {
    header: "Rental Income?",
    accessorKey: "Isincomesrcrental",
    align: "center",
    format: "string",
  },
  {
    header: "Total Number of Properties",
    accessorKey: "Numproperties",
    align: "center",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => (
      <Link to={`/non-individuals/${Rnid}/properties`} color="inherit">
        <>{getValue()}</>
      </Link>
    ),
  },
  {
    header: "Total Number of Rental Properties",
    accessorKey: "Numrentedproperties",
    align: "center",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => (
      <Link to={`/non-individuals/${Rnid}/properties/rented`} color="inherit">
        <>{getValue()}</>
      </Link>
    ),
  },
  {
    header: "Total Adjusted Gross Rental Income",
    accessorKey: "Sumofadjustedincome",
    align: "right",
    format: "currency",
  },
  {
    header: "Average Rate Per Property",
    accessorKey: "Averagerateperrentedproperty",
    align: "right",
    format: "currency",
  },
  {
    header: "Return No.",
    accessorKey: "Rtnno",
    align: "center",
    cell: ({
      cell: { getValue },
      row: {
        original: { Rnid },
      },
    }) => {
      const value = getValue() as string;
      return (
        <Link
          to={`/non-individuals/${Rnid}/tax-history/${value}`}
          color="inherit"
        >
          {value}
        </Link>
      );
    },
  },
  {
    header: "Amendment",
    accessorKey: "Isamendment",
    align: "center",
    format: "string",
  },
  {
    header: "Return Period",
    accessorKey: "Rtnperiodyear",
    align: "center",
    format: "string",
  },
  {
    header: "URA Gross Rent Income",
    accessorKey: "Grossrentalincome",
    align: "right",
    format: "currency",
  },
  {
    header: "Difference",
    accessorKey: "Difference",
    align: "right",
    format: "currency",
  },
];
