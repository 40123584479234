/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box as ContainerBase, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table from "components/table/table.component";

const Container = styled(ContainerBase)(() => ({
  margin: "0px",
  padding: "0px",
}));

const Box = ContainerBase;

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: theme.reports.title.fontWeight,
  fontSize: theme.reports.title.fontSize,
  textAlign: theme.reports.title.textAlign,
  marginRight: theme.reports.title.marginRight,
  opacity: theme.reports.title.opacity,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: theme.table.toolbar.fontSize,
  textAlign: "left",
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(3),
  opacity: 0.8,
}));

export default {
  Typography,
  Table,
  ProgressIndicator,
  Box,
  Title,
  Header,
  Container,
};
