import React, { useRef } from "react";

// Component
import ExpandedMoreIcon from "@mui/icons-material/ExpandMore";
import LabelKeyValue from "components/label-key-value/label-key-value.component";
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";

// styles
import { Maybe } from "graphql/types-and-hooks";
import SC from "./accordion-body.styles";

export interface ItemLeftProps {
  label: string;
  value: Maybe<string> | undefined;
  index: number;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

export interface AccordionBodyProps {
  itemsLeft?: {
    index: number;
    format?: keyof typeof FORMAT_VALUE_OPTIONS;
    label: string;
    value: Maybe<string> | undefined;
  }[];
  itemsRight?: {
    index: number;
    format?: keyof typeof FORMAT_VALUE_OPTIONS;
    label: string;
    value: Maybe<string> | undefined;
  }[];
  title?: string;
  subtitle?: string;
  expanded?: boolean;
  customerId: string;
}

export const AccordionBody = ({
  itemsLeft = undefined,
  itemsRight = undefined,
  expanded = false,
  title = "",
  subtitle = "",
  customerId = "",
}: AccordionBodyProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [expand, setExpand] = React.useState(expanded);

  React.useEffect(() => {
    setExpand(expanded);
  }, [expanded]);

  const onExpand = () => {
    setExpand(!expand);
  };

  return (
    <SC.Paper elevation={0}>
      <SC.Box py={1} px={2}>
        <SC.LabelTitle variant="subtitle2">
          {customerId ? `${title} - ${customerId}` : `${title}`}
        </SC.LabelTitle>
      </SC.Box>
      <SC.Accordion ref={ref} square elevation={0} expanded={expand}>
        <SC.AccordionSummary
          expandIcon={<ExpandedMoreIcon color="disabled" />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          onClick={onExpand}
        >
          <SC.LabelSubtitle variant="subtitle2">{subtitle}</SC.LabelSubtitle>
        </SC.AccordionSummary>
        <SC.AccordionDetails>
          <SC.Grid container spacing={2}>
            <SC.Grid item xs={6}>
              {itemsLeft?.map((item: ItemLeftProps) => (
                <LabelKeyValue
                  key={item.index}
                  label={item.label}
                  value={item.value ?? ""}
                  format={item.format}
                />
              ))}
            </SC.Grid>
            <SC.Grid item xs>
              {itemsRight?.map((item: ItemLeftProps) => (
                <LabelKeyValue
                  key={item.index}
                  label={item.label}
                  value={item.value ?? ""}
                  format={item.format}
                />
              ))}
            </SC.Grid>
          </SC.Grid>
        </SC.AccordionDetails>
      </SC.Accordion>
    </SC.Paper>
  );
};

export default AccordionBody;
