/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  Box as BoxBase,
  Typography as TypographyBase,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { styled } from "@mui/material/styles";

const AuthorBox = styled(BoxBase)(() => ({
  display: "flex",
  margin: "0px",
  marginTop: "1.14rem",

  "& > *": {
    marginRight: "1.14rem",
  },
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.notes.authorProfilePicture.width,
  height: theme.notes.authorProfilePicture.height,
}));

const DefaultAvatarIcon = styled(AccountCircleIcon)(({ theme }) => ({
  width: theme.notes.authorProfilePicture.width,
  height: theme.notes.authorProfilePicture.height,
  color: theme.notes.authorProfilePicture.defaultColor,
}));

const AuthorName = styled(TypographyBase)(({ theme }) => ({
  fontSize: theme.notes.authorName.fontSize,
}));

export default {
  AuthorName,
  AuthorBox,
  UserAvatar,
  DefaultAvatarIcon,
};
