import React, { useCallback, useState } from "react";

// Components
import Accordion from "components/accordion/accordion.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import NoRecordsStatement from "components/table-no-records-statement/table-no-records-statement.component";
import { INITIAL_ROWS_PER_PAGE } from "components/table/table.component";
import TextField from "components/text-field/text-field.component";

import { CustomerRegistrationTable } from "pages/reports/kcca/customer-registration/customer-registration.component";
import { PropertyRegistrationTable } from "pages/reports/kcca/property-registration/property-registration.component";
import { MlhudRegistrationTable } from "pages/reports/mlhud/mlhud-registration.component";
import { NiraRegistrationTable } from "pages/reports/nira/nira-registration.component";
import { WaterAndSewageTable } from "pages/reports/nwsc/water-and-sewage.component";
import { RCapturePropertiesTable } from "pages/reports/rcapture/properties/properties.component";
import { MobilePhoneNumbersTable } from "pages/reports/ucc/mobile-phone-numbers/mobile-phone-numbers.component";
import { SubscriberAndPropertyTable } from "pages/reports/umeme/subscriber-and-property/subscriber-and-property.component";
import { AssociatedRegisteredIndividualsToNonIndividualsTable } from "pages/reports/ura/associated-registered-individuals-to-nonindividuals/associated-registered-individuals-to-nonindividuals.component";
import { AssociatedRegisteredNonIndividualsToNonIndividualsTable } from "pages/reports/ura/associated-registered-nonindividuals-to-nonindividuals/associated-registered-nonindividuals-to-nonindividuals.component";
import { IndividualRegistrationTable } from "pages/reports/ura/individual-registration/individual-registration.component";
import { IndividualTaxDetailTable } from "pages/reports/ura/individual-tax-detail/individual-tax-detail.component";
import { NonIndividualRegistrationTable } from "pages/reports/ura/non-individual-registration/non-individual-registration.component";
import { NonIndividualTaxTable } from "pages/reports/ura/non-individual-tax-detail/non-individual-tax-detail.component";
import { OfficersAndOwnersRegistrationTable } from "pages/reports/ura/officers-and-owners/officers-and-owners.component";
import { TenantRentalPaymentsTable } from "pages/reports/ura/tenant-rental-payments/tenant-rental-payments.component";
import { UgandaRegistrationTable } from "pages/reports/ursb/uganda-registration/uganda-registration.component";

// Assets
import SC from "./data-by-criteria.styles";

interface Filters {
  phoneNumber: string;
  email: string;
  surname: string;
  firstName: string;
  businessName: string;
}

interface DataByCriteriaFiltersProps {
  onChange: (filters: Filters) => void;
}

const emptyFilters: Filters = {
  phoneNumber: "",
  email: "",
  surname: "",
  firstName: "",
  businessName: "",
};

const TABLE_MAX_HEIGHT = 500;
const TABLE_SPACING = 2;
const NO_RECORDS_STATEMENT = <NoRecordsStatement />;

const DataByCriteriaFilters: React.FC<DataByCriteriaFiltersProps> = ({
  onChange,
}) => {
  const [formData, setFormData] = useState<Filters>(emptyFilters);
  const [searchedFormData, setSearchedFormData] =
    useState<Filters>(emptyFilters);

  const handleOnChange = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFormData((currentData) => ({
        ...currentData,
        [event.target.name]: event.target.value,
      }));
      event.preventDefault();
    },
    []
  );

  const handleSubmit = useCallback(
    (event: React.ChangeEvent<HTMLFormElement>): void => {
      onChange(formData);
      setSearchedFormData(formData);
      event.preventDefault();
    },
    [formData, onChange]
  );

  const isButtonDisabled = () => {
    if (Object.values(formData).every((value) => value === "")) return true;
    if (
      formData.phoneNumber === searchedFormData.phoneNumber &&
      formData.email === searchedFormData.email &&
      formData.surname === searchedFormData.surname &&
      formData.firstName === searchedFormData.firstName &&
      formData.businessName === searchedFormData.businessName
    )
      return true;
    return false;
  };

  return (
    <SC.FilterBox p={2}>
      <SC.FormControl component="form" onSubmit={handleSubmit}>
        <SC.Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <SC.Grid item xs={12} sm={4} md={2}>
            <TextField
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2}>
            <TextField
              fullWidth
              id="surname"
              name="surname"
              label="Surname"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2}>
            <TextField
              fullWidth
              id="businessName"
              name="businessName"
              label="Business Name"
              onChange={handleOnChange}
            />
          </SC.Grid>
          <SC.Grid item xs={12} sm={4} md={2}>
            <SC.FilterButton disabled={isButtonDisabled()} type="submit">
              Search MDA Data
            </SC.FilterButton>
          </SC.Grid>
        </SC.Grid>
      </SC.FormControl>
    </SC.FilterBox>
  );
};

const DataByCriteria: React.FC = () => {
  const [filters, setFilters] = useState<Filters>(emptyFilters);
  const [tablesLoaded, setTablesLoaded] = useState({
    shouldRenderIndividualRegistration: true,
    shouldRenderAssociatedRegisteredIndividualsToNonIndividuals: false,
    shouldRenderAssociatedRegisteredNonIndividualsToNonIndividuals: false,
    shouldRenderIndividualTaxDetail: false,
    shouldRenderNonIndividualRegistration: false,
    shouldRenderNonIndividualTax: false,
    shouldRenderOfficersAndOwnersRegistration: false,
    shouldRenderTenantRentalPayments: false,
    shouldRenderPropertyRegistration: false,
    shouldRenderCustomerRegistration: false,
    shouldRenderWaterAndSewageTable: false,
    shouldRenderSubscriberAndPropertyTable: false,
    shouldRenderUgandaRegistrationTable: false,
    shouldRenderMlhudRegistrationTable: false,
    shouldRenderNiraRegistrationTable: false,
    shouldRenderMobilePhoneNumbersTable: false,
    shouldRenderRCapturePropertiesTable: false,
  });

  const renderAssociatedRegisteredIndividualsToNonIndividuals =
    useCallback(() => {
      setTablesLoaded((currentTablesLoaded) => ({
        ...currentTablesLoaded,
        shouldRenderAssociatedRegisteredIndividualsToNonIndividuals: true,
      }));
    }, []);
  const renderAssociatedRegisteredNonIndividualsToNonIndividuals =
    useCallback(() => {
      setTablesLoaded((currentTablesLoaded) => ({
        ...currentTablesLoaded,
        shouldRenderAssociatedRegisteredNonIndividualsToNonIndividuals: true,
      }));
    }, []);
  const renderIndividualTaxDetail = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderIndividualTaxDetail: true,
    }));
  }, []);
  const renderNonIndividualRegistration = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderNonIndividualRegistration: true,
    }));
  }, []);
  const renderNonIndividualTax = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderNonIndividualTax: true,
    }));
  }, []);
  const renderOfficersAndOwnersRegistration = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderOfficersAndOwnersRegistration: true,
    }));
  }, []);
  const renderTenantRentalPayments = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderTenantRentalPayments: true,
    }));
  }, []);
  const renderPropertyRegistration = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderPropertyRegistration: true,
    }));
  }, []);
  const renderCustomerRegistration = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderCustomerRegistration: true,
    }));
  }, []);
  const renderWaterAndSewage = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderWaterAndSewageTable: true,
    }));
  }, []);
  const renderSubscriberAndProperty = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderSubscriberAndPropertyTable: true,
    }));
  }, []);
  const renderUgandaRegistration = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderUgandaRegistrationTable: true,
    }));
  }, []);
  const renderMlhudRegistration = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderMlhudRegistrationTable: true,
    }));
  }, []);
  const renderNiraRegistration = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderNiraRegistrationTable: true,
    }));
  }, []);
  const renderMobilePhoneNumbers = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderMobilePhoneNumbersTable: true,
    }));
  }, []);
  const renderRCaptureProperties = useCallback(() => {
    setTablesLoaded((currentTablesLoaded) => ({
      ...currentTablesLoaded,
      shouldRenderRCapturePropertiesTable: true,
    }));
  }, []);
  const onChangeFilters = useCallback(
    (currentFilters: Filters): void => {
      setFilters(currentFilters);
    },
    [setFilters]
  );

  const areFiltersEmpty = Object.values(filters).every((value) => value === "");

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <DataByCriteriaFilters onChange={onChangeFilters} />
      {areFiltersEmpty ? undefined : (
        <>
          <Accordion
            defaultExpanded
            displayButtonLabel
            header={<SC.Title>Uganda Revenue Authority</SC.Title>}
            content={
              <SC.Grid container spacing={TABLE_SPACING}>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderIndividualRegistration ? (
                    <IndividualRegistrationTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderNonIndividualRegistration}
                      title="Registration - Individuals"
                    />
                  ) : undefined}
                </SC.Grid>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderNonIndividualRegistration ? (
                    <NonIndividualRegistrationTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderIndividualTaxDetail}
                      title="Registration - NonIndividuals"
                    />
                  ) : undefined}
                </SC.Grid>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderIndividualTaxDetail ? (
                    <IndividualTaxDetailTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderNonIndividualTax}
                      title="Tax Submission - Individuals"
                    />
                  ) : undefined}
                </SC.Grid>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderNonIndividualTax ? (
                    <NonIndividualTaxTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderOfficersAndOwnersRegistration}
                      title="Tax Submission - NonIndividuals"
                    />
                  ) : undefined}
                </SC.Grid>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderOfficersAndOwnersRegistration ? (
                    <OfficersAndOwnersRegistrationTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={
                        renderAssociatedRegisteredIndividualsToNonIndividuals
                      }
                      title="Holding Owners and Officers"
                    />
                  ) : undefined}
                </SC.Grid>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderAssociatedRegisteredIndividualsToNonIndividuals ? (
                    <AssociatedRegisteredIndividualsToNonIndividualsTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={
                        renderAssociatedRegisteredNonIndividualsToNonIndividuals
                      }
                      title="Associated Registered Individuals To NonIndividuals"
                    />
                  ) : undefined}
                </SC.Grid>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderAssociatedRegisteredNonIndividualsToNonIndividuals ? (
                    <AssociatedRegisteredNonIndividualsToNonIndividualsTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderTenantRentalPayments}
                      title="Associated Registered NonIndividuals To NonIndividuals"
                    />
                  ) : undefined}
                </SC.Grid>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderTenantRentalPayments ? (
                    <TenantRentalPaymentsTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderPropertyRegistration}
                      title="Tenant Reported Rent Paid"
                    />
                  ) : undefined}
                </SC.Grid>
              </SC.Grid>
            }
          />
          <Accordion
            defaultExpanded
            displayButtonLabel
            header={<SC.Title>Kampala Capital City Authority</SC.Title>}
            content={
              <SC.Grid container spacing={TABLE_SPACING}>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderPropertyRegistration ? (
                    <PropertyRegistrationTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderCustomerRegistration}
                      title="Properties - Registration"
                    />
                  ) : undefined}
                </SC.Grid>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderCustomerRegistration ? (
                    <CustomerRegistrationTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderWaterAndSewage}
                      title="Customers - Registration"
                    />
                  ) : undefined}
                </SC.Grid>
              </SC.Grid>
            }
          />
          <Accordion
            defaultExpanded
            displayButtonLabel
            header={
              <SC.Title>National Water and Sewerage Corporation</SC.Title>
            }
            content={
              <SC.Grid container spacing={TABLE_SPACING}>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderWaterAndSewageTable ? (
                    <WaterAndSewageTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderSubscriberAndProperty}
                      title="Individual Registration"
                    />
                  ) : undefined}
                </SC.Grid>
              </SC.Grid>
            }
          />
          <Accordion
            defaultExpanded
            displayButtonLabel
            header={<SC.Title>UMEME</SC.Title>}
            content={
              <SC.Grid container spacing={TABLE_SPACING}>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderSubscriberAndPropertyTable ? (
                    <SubscriberAndPropertyTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderUgandaRegistration}
                      title="Subscriber And Property"
                    />
                  ) : undefined}
                </SC.Grid>
              </SC.Grid>
            }
          />
          <Accordion
            defaultExpanded
            displayButtonLabel
            header={<SC.Title>Uganda Registration Services Bureau</SC.Title>}
            content={
              <SC.Grid container spacing={TABLE_SPACING}>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderUgandaRegistrationTable ? (
                    <UgandaRegistrationTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderMlhudRegistration}
                      title="Owners and Shareholders"
                    />
                  ) : undefined}
                </SC.Grid>
              </SC.Grid>
            }
          />
          <Accordion
            defaultExpanded
            displayButtonLabel
            header={
              <SC.Title>
                Ministry of Lands, Housing and Urban Development
              </SC.Title>
            }
            content={
              <SC.Grid container spacing={TABLE_SPACING}>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderMlhudRegistrationTable ? (
                    <MlhudRegistrationTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderNiraRegistration}
                      title="Mlhud - Registration"
                    />
                  ) : undefined}
                </SC.Grid>
              </SC.Grid>
            }
          />
          <Accordion
            defaultExpanded
            displayButtonLabel
            header={
              <SC.Title>
                National Identification and Registration Authority
              </SC.Title>
            }
            content={
              <SC.Grid container spacing={TABLE_SPACING}>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderNiraRegistrationTable ? (
                    <NiraRegistrationTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderMobilePhoneNumbers}
                      title="NIRA - Registration"
                    />
                  ) : undefined}
                </SC.Grid>
              </SC.Grid>
            }
          />
          <Accordion
            defaultExpanded
            displayButtonLabel
            header={<SC.Title>Uganda Communication Commission</SC.Title>}
            content={
              <SC.Grid container spacing={TABLE_SPACING}>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderMobilePhoneNumbersTable ? (
                    <MobilePhoneNumbersTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      onLoaded={renderRCaptureProperties}
                      title="Mobile Registration Data"
                    />
                  ) : undefined}
                </SC.Grid>
              </SC.Grid>
            }
          />
          <Accordion
            defaultExpanded
            displayButtonLabel
            header={<SC.Title>rCAPTURE REPORTS</SC.Title>}
            content={
              <SC.Grid container spacing={TABLE_SPACING}>
                <SC.Grid item xs={12}>
                  {tablesLoaded.shouldRenderRCapturePropertiesTable ? (
                    <RCapturePropertiesTable
                      defaultFilters={filters}
                      initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
                      maxHeight={TABLE_MAX_HEIGHT}
                      noDataComponent={NO_RECORDS_STATEMENT}
                      title="rCAPTURE Properties"
                    />
                  ) : undefined}
                </SC.Grid>
              </SC.Grid>
            }
          />
        </>
      )}
    </SC.Box>
  );
};

export default DataByCriteria;
