/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { NiraFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type NiraSchema = Omit<NiraFragment, "__typename">;

export interface NiraRegistrationTableFilters {
  firstName: string;
  surname: string;
  phoneNumber: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof NiraRegistrationTableFilters,
  string | string[]
> = {
  firstName: ["GivenName"],
  surname: ["Surname"],
  phoneNumber: ["MobileNumber"],
};

// @ts-ignore
export const NiraSchemaMap: Record<keyof NiraSchema, "string" | "number"> = {
  PseudoNIN: "string",
  Surname: "string",
  NationalID: "string",
  MobileNumber: "string",
  Email: "string",
  GivenName: "string",
  DateOfBirth: "string",
  // BirthAddress: "string",
};

export const getAccessorType = (
  accessor: keyof NiraSchema | AccessorFn<NiraSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NiraSchemaMap[accessor];
};

export const niraTableColumns: TableColumn<NiraSchema>[] = [
  {
    header: "First Name",
    accessorKey: "GivenName",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Last Name",
    accessorKey: "Surname",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Date of Birth",
    accessorKey: "DateOfBirth",
    align: "center",
    format: "date",
    meta: { sort: false },
  },
  {
    header: "NIN",
    accessorKey: "NationalID",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Email",
    accessorKey: "Email",
    align: "left",
    meta: { sort: false },
  },
  {
    header: "Mobile",
    accessorKey: "MobileNumber",
    align: "left",
    meta: { sort: false },
  },
];
