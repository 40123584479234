/* eslint-disable import/prefer-default-export */
import { TableColumn } from "components/table/table.component";
import { LandlordIncomeDetailFragment } from "graphql/hasura/types-and-hooks";

export const landlordIncomeDetailColumns: TableColumn<LandlordIncomeDetailFragment>[] =
  [
    {
      header: "Return ID No.",
      accessorKey: "RtnRentalIncomeId",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      header: "Return No.",
      accessorKey: "RtnNo",
      align: "center",
      format: "string",
    },
    {
      header: "TIN No.",
      accessorKey: "TinNo",
      align: "center",
      format: "string",
    },
    {
      header: "Taxpayer Name",
      accessorKey: "TaxPayerName",
      align: "left",
      format: "string",
    },
    {
      header: "Return Date",
      accessorKey: "RtnDt",
      align: "center",
      format: "date",
    },
    {
      header: "Return Period From",
      accessorKey: "RtnFromDt",
      align: "center",
      format: "date",
    },
    {
      header: "Return Period To",
      accessorKey: "RtnToDt",
      align: "center",
      format: "date",
    },
    {
      header: "Tenant TIN",
      accessorKey: "TenantTin",
      align: "center",
      format: "string",
    },
    {
      header: "Tenant Name",
      accessorKey: "TenantName",
      align: "left",
      format: "string",
    },
    {
      header: "Premises Location",
      accessorKey: "PremiseLocation",
      align: "center",
      format: "string",
    },
    {
      header: "Tenancy Period From",
      accessorKey: "TenancyPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      header: "Tenancy Period To",
      accessorKey: "TenancyPeriodTo",
      align: "center",
      format: "date",
    },
    {
      header: "Rent Reported",
      accessorKey: "RentalIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Is Amendment",
      accessorKey: "IsAmendment",
      align: "center",
      format: "string",
    },
    {
      header: "Is Resident",
      accessorKey: "IsResident",
      align: "center",
      format: "string",
    },
    {
      header: "Tenant Tax Period",
      accessorKey: "RtnPeriodYear",
      align: "center",
      format: "string",
    },
    {
      header: "Tenant Tax Date",
      accessorKey: "RtnDtTaxSummary",
      align: "center",
      format: "date",
    },
    {
      header: "Tenant From Date",
      accessorKey: "RtnFromDtTaxSummary",
      align: "center",
      format: "date",
    },
    {
      header: "Tenant To Date",
      accessorKey: "RtnToDtTaxSummary",
      align: "center",
      format: "date",
    },
    {
      header: "Gross Rent Income",
      accessorKey: "GrossRentalIncome",
      align: "right",
      format: "currency",
    },
    {
      header: "Chargeable Rent Income",
      accessorKey: "AssessedChrgbleRentlIncm",
      align: "right",
      format: "currency",
    },
    {
      header: "Rent Income",
      accessorKey: "RentincIncome",
      align: "right",
      format: "currency",
    },
  ];
