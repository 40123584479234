import React, { ReactNode } from "react";

// GraphQL
import {
  useGetCurrentUserPermissionsQuery,
  GetCurrentUserPermissionsQuery,
  PermissionType,
} from "graphql/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

export const APP_PERMISSION = {
  HOME: "Home",
  HOME_VIEW: "Home.View Home",
  HOME_VIEW_AUDITOR_DASHBOARD: "Home.View Auditor Dashboard",
  HOME_VIEW_AUDITOR_MANAGER_DASHBOARD: "Home.View Auditor Manager Dashboard",

  INDIVIDUALS: "Individuals",
  INDIVIDUALS_VIEW: "Individuals.View Individuals",
  INDIVIDUALS_EDIT: "Individuals.Edit Individuals",
  INDIVIDUALS_VIEW_NOTES: "Individuals.View Notes",

  NON_INDIVIDUALS: "Non-Individuals",
  NON_INDIVIDUALS_VIEW: "Non-Individuals.View Non-Individuals",
  NON_INDIVIDUALS_EDIT: "Non-Individuals.Edit Non-Individuals",
  NON_INDIVIDUALS_VIEW_NOTES: "Non-Individuals.View Notes",

  PROPERTIES: "Properties",
  PROPERTIES_VIEW: "Properties.View Properties",
  PROPERTIES_EDIT: "Properties.Edit Properties",
  PROPERTIES_VIEW_IMAGES: "Properties.View Images",
  PROPERTIES_VIEW_NOTES: "Properties.View Notes",

  REPORTS: "Reports",

  ADMIN: "Administration",
  ADMIN_EXPORT: "Administration.Export",
  ADMIN_ADDEDIT_USERS: "Administration.Add/Edit Users",
  ADMIN_VIEW_DATA_PERMISSIONS: "Administration.View Data Permissions",
  ADMIN_EDIT_DATA_PERMISSIONS: "Administration.Edit Data Permissions",
  ADMIN_SET_VISIBILITY_LEVEL: "Administration.Set Visibility Level",
  ADMIN_SET_TAX_EXEMPT: "Administration.Set Tax Exempt",
} as const;

export type AppPermissionKey = keyof typeof APP_PERMISSION;
export type AppPermission = (typeof APP_PERMISSION)[AppPermissionKey];
export type PermissionsQuery =
  GetCurrentUserPermissionsQuery["GetCurrentUserPermissions"];

export const isAppPermissionValid = (
  permissionsList: PermissionsQuery,
  appPermission: AppPermission
): boolean => {
  const permissionIDs = appPermission.split(".");
  let appPermissionsIsValid = false;

  const categoryName = permissionIDs[0];
  const parentPermission = permissionsList.find(
    (permission) => permission?.name === categoryName
  );

  if (parentPermission?.enabled && permissionIDs.length > 1) {
    const permissionName = permissionIDs[1];
    const childPermission = parentPermission?.children?.find(
      (permission) => permission.name === permissionName
    );

    if (childPermission) {
      appPermissionsIsValid = childPermission.enabled ?? false;
    }
  } else {
    appPermissionsIsValid = parentPermission?.enabled ?? false;
  }

  return appPermissionsIsValid;
};

export function useAppPermissionValidator():
  | ((appPermission: AppPermission) => boolean)
  | undefined {
  const { data, error } = useGetCurrentUserPermissionsQuery({
    variables: {
      type: PermissionType.Application,
    },
  });

  useAppErrorHandler(error);

  if (error || !data) return undefined;

  return (appPermission: AppPermission): boolean =>
    isAppPermissionValid(data.GetCurrentUserPermissions, appPermission);
}

export interface AppPermissionValidatorProps {
  appPermission: AppPermission;
  loadingComponent?: JSX.Element;
  children: ReactNode;
}

export const AppPermissionValidator: React.FC<AppPermissionValidatorProps> = ({
  appPermission,
  loadingComponent = null,
  children,
}): JSX.Element => {
  const validator = useAppPermissionValidator();

  if (!validator) return <>{loadingComponent}</>;

  return <>{validator(appPermission) ? children : null}</>;
};

export default AppPermissionValidator;
