/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { PropsWithChildren } from "react";

// Styles
import SC from "./calendar-select.styles";
import { SelectChangeEvent, SelectProps } from "@mui/material";

export interface Menu {
  index: number;
  label: string;
  value: string;
}

export interface SelectFieldProps {
  label?: string;
  data: Menu[];
  disabled?: boolean;
  value?: string;
  onChange?: (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => void;
}

export const SelectField = ({ label, data, ...rest }: SelectFieldProps) => (
  <SC.FormControl>
    <SC.Select variant="outlined" displayEmpty={false} {...rest}>
      <SC.MenuItem value="">{label}</SC.MenuItem>
      {data.map((item: Menu) => (
        <SC.MenuItem key={item.index} value={item.value}>
          {item.label}
        </SC.MenuItem>
      ))}
    </SC.Select>
  </SC.FormControl>
);

export default SelectField;
