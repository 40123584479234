// Dependencies
import { Box as BoxBase, Checkbox } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";

import { styled } from "@mui/material/styles";

const Box = styled(BoxBase)({});

const VisibilityIcon = styled(Checkbox)(() => ({
  paddingTop: "0px",
}));

export default {
  Box,
  RoomIcon,
  VisibilityIcon,
};
