// Dependencies
import React, { useEffect, useMemo } from "react";

// Graphql
import { CLIENT_NAME } from "graphql/client";
import {
  Order_By,
  Query_RootRtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs,
  Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column,
  useGetMaxMinRptRentalTenantPaymentsQuery,
  useGetPaginatedRptRentalTenantPaymentsQuery,
  useRptRentalTenantPaymentsFilterQuery,
} from "graphql/hasura/types-and-hooks";

// Components
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

// Data export
import { useExportData } from "exports/useExportData";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  aggregateArrayToObject,
  jsonToStringQueryVariables,
} from "graphql/hasura/rtcs.utils";
import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";
import {
  columns as reportColumns,
  getAccessorType,
  TenantRentalPaymentsTableFilters,
  DEFAULT_FILTER_ACCESSOR,
} from "./tenant-rental-payments.schema";

// Styles
import SC from "./tenant-rental-payments.style";

interface TenantRentalPaymentsTableProps {
  defaultFilters?: TenantRentalPaymentsTableFilters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  onLoaded?: () => void;
  title?: string;
}

const TABLE_NAME = "rtcs_db_FnRPT_RentalTenantPayments";

export const TenantRentalPaymentsTable: React.FC<TenantRentalPaymentsTableProps> =
  React.memo(
    ({
      defaultFilters,
      initialRowsPerPage = INITIAL_ROWS_PER_PAGE.REPORTS,
      maxHeight,
      noDataComponent,
      onLoaded,
      title = "Tenant Reported Rent Paid",
    }) => {
      const defaultFilterConditions = useMemo(() => {
        const { firstName, surname } = defaultFilters ?? {
          firstName: "",
          surname: "",
        };

        return getConditionsFromDefaultFilters(
          {
            ...(defaultFilters ?? {}),
            firstName: firstName === "" ? "" : `%${firstName}`,
            surname: surname === "" ? "" : `%${surname}`,
          },
          DEFAULT_FILTER_ACCESSOR,
        );
      }, [defaultFilters]);
      const hasUnhandledDefaultFilter = useMemo(
        () =>
          getHasUnhandledDefaultFilter(
            defaultFilters ?? {},
            DEFAULT_FILTER_ACCESSOR,
          ),
        [defaultFilters],
      );
      const [filterConditions, setFilterConditions] = React.useState<
        FilterCondition[]
      >([] as FilterCondition[]);
      const [queryVariables, setQueryVariables] =
        React.useState<Query_RootRtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs>({
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        });

      const [pageIndexResetSignal, setPageIndexResetSignal] =
        React.useState(false);

      const { data, loading, error } =
        useGetPaginatedRptRentalTenantPaymentsQuery({
          context: { clientName: CLIENT_NAME.HASURA },
          skip: !queryVariables || hasUnhandledDefaultFilter,
          variables: {
            params: jsonToStringQueryVariables(queryVariables ?? {}),
            aggregateParams: jsonToStringQueryVariables({
              where: queryVariables?.where,
            }),
          },
        });

      const {
        data: maxMinData,
        loading: maxMinLoading,
        error: maxMinError,
      } = useGetMaxMinRptRentalTenantPaymentsQuery({
        context: { clientName: CLIENT_NAME.HASURA },
      });

      // Data export
      const [exportDataFn, { loading: exportLoading, error: exportError }] =
        useExportData(TABLE_NAME, queryVariables);

      useAppErrorHandler(error || exportError || maxMinError);

      // Table Columns
      const columns = React.useMemo(() => reportColumns, []);

      // Pagination
      const handlePaginatedFetchData: TableFetchDataFunction =
        React.useCallback(({ pageIndex, pageSize, sortBy }) => {
          const defaultSortColumn = {
            id: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.LandlordName,
            desc: true,
          };
          const sortByColumn =
            sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
          setQueryVariables((oldVariables) => ({
            ...oldVariables,
            orderBy: {
              [sortByColumn.id]: sortByColumn.desc
                ? Order_By.Desc
                : Order_By.Asc,
            },
            limit: pageSize,
            offset: pageIndex * pageSize,
          }));
        }, []);

      const totalRowsCount = hasUnhandledDefaultFilter
        ? 0
        : parseInt(
            data?.rtcs_db_FnRPT_RentalTenantPayments_aggregatecm[0].value ??
              "-1",
            10,
          );

      const paginationControlled = React.useMemo(
        () => ({
          fetchData: handlePaginatedFetchData,
          loading,
          totalRowsCount,
        }),
        [totalRowsCount, loading, handlePaginatedFetchData],
      );

      // TODO: Replace for useLazyQuery when the function has been updated
      // to return a Promise instead of void.
      // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
      const { refetch: paymentsFilterFn } =
        useRptRentalTenantPaymentsFilterQuery({
          skip: true,
          context: {
            clientName: CLIENT_NAME.HASURA,
          },
        });

      const fetchSuggestions = React.useCallback(
        async (value: string, column: string) => {
          try {
            let suggestions: string[] = [];

            if (value !== "") {
              const filterVariables: Query_RootRtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs =
                {
                  where: { [column]: { _ilike: `${value}%` } },
                  distinct_on: [
                    column as Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column,
                  ],
                };
              const paymentsFilterData = await paymentsFilterFn({
                params: jsonToStringQueryVariables(filterVariables),
              });
              suggestions =
                paymentsFilterData.data?.rtcs_db_FnRPT_RentalTenantPayments.map(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (option) => option[column],
                );
            }

            // eslint-disable-next-line @typescript-eslint/return-await
            return Promise.resolve((suggestions ?? []) as string[]);
          } catch (err) {
            return Promise.reject(err);
          }
        },
        [paymentsFilterFn],
      );

      const newObj = aggregateArrayToObject(
        maxMinData?.rtcs_db_FnRPT_RentalTenantPayments_aggregatecm,
      );

      const minRentPaid = newObj?.['min("RentPaid")'];
      const maxRentPaid = newObj?.['max("RentPaid")'];

      // filterInpute
      const filterInputs: TableFilterInput[] = React.useMemo(() => {
        return [
          {
            type: TableFilterType.AUTOFILL,
            label: "Landlord Name",
            columnName:
              Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.LandlordName,
            fetchSuggestions,
          },
          {
            type: TableFilterType.AUTOFILL,
            label: "Landlord Tin",
            columnName:
              Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.LandlordTin,
            fetchSuggestions,
          },
          {
            type: TableFilterType.AUTOFILL,
            label: "Tenant Name",
            columnName:
              Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.TenantName,
            fetchSuggestions,
          },
          {
            type: TableFilterType.AUTOFILL,
            label: "Tenant Tin",
            columnName:
              Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.TenantTin,
            fetchSuggestions,
          },
          {
            type: TableFilterType.AUTOFILL,
            label: "Premises Location",
            columnName:
              Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.PremiseLocation,
            fetchSuggestions,
          },
          {
            type: TableFilterType.RANGE_SLIDER,
            label: "Min-Max of Rent Paid",
            min: minRentPaid,
            max: maxRentPaid,
            columnName:
              Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column.RentPaid,
          },
        ];
      }, [fetchSuggestions, minRentPaid, maxRentPaid]);

      const onTableFilterApply = React.useCallback(
        (conditions: FilterCondition[]) => {
          setFilterConditions(conditions);
          setPageIndexResetSignal((previousSignal) => !previousSignal);
        },
        [],
      );

      const universalColumns: ColumnsProps[] = React.useMemo(() => {
        return columns.map((column, i) => {
          return {
            index: i,
            label: `${column.header}`,
            value: `${column.accessorKey}`,
            type: `${getAccessorType(column.accessorKey)}`,
          };
        });
      }, [columns]);

      const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
        const columnObject = condition[Object.keys(condition)[0]];
        if (columnObject[Object.keys(columnObject)[0]] === "") {
          // eslint-disable-next-line no-param-reassign
          condition = {};
        }
        setFilterConditions([condition]);
        setPageIndexResetSignal((previousSignal) => !previousSignal);
      };

      useEffect(() => {
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        }));
      }, [defaultFilterConditions, filterConditions]);
      useEffect(() => {
        if (!loading) onLoaded?.();
      }, [loading, onLoaded]);

      return (
        <>
          <SC.ProgressIndicator
            open={loading || exportLoading || maxMinLoading}
          />
          <SC.Table
            title={title}
            actionsOnRight={[
              "hide/show-columns",
              "filter-results",
              "export-to-excel-sheet/csv",
            ]}
            // eslint-disable-next-line no-alert
            onAction={() => alert("under construction")}
            columns={columns}
            data={data?.rtcs_db_FnRPT_RentalTenantPayments ?? []}
            leftPanel={
              <TableFilter
                filterInputs={filterInputs}
                onFilterApply={onTableFilterApply}
                universalFilterColumns={universalColumns}
                onUniversalFilterSubmit={onUniversalFilterer}
              />
            }
            stickyHeader
            persistenceId="0a848f5c-98fc-47cc-b7ff-1f73286e732f"
            paginationControlled={paginationControlled}
            initialRowsPerPage={initialRowsPerPage}
            pageIndexResetSignal={pageIndexResetSignal}
            exportData={exportDataFn}
            maxHeight={maxHeight}
            noDataComponent={noDataComponent}
          />
        </>
      );
    },
  );

const TenantRentalPayments: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <TenantRentalPaymentsTable />
    </SC.Box>
  );
};

export default TenantRentalPayments;
