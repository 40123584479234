/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl as FormControlBase,
  Select as SelectBase,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const FormControl = styled(FormControlBase)(() => ({
  width: "100%",
}));

const Select = styled(SelectBase)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.default,
  borderColor: theme.palette.background.default,
  borderWidth: "0px",
  textAlign: "left",
  fontSize: theme.selectField.fontSize,
  color: theme.palette.primary.main,
  elevation: 0,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  backgroundColor: theme.accordionBody.backgroundColor,
  paddingLeft: theme.selectField.helperTextPaddingLeft,
}));

export default { FormControl, Select, MenuItem, HelperText };
