/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import Autocomplete from "@mui/material/Autocomplete";
import Search from "@mui/icons-material/Search";

import { styled } from "@mui/material/styles";

// Components
import { TextField as TextFieldBase } from "components/text-field/text-field.component";

const SearchBox = styled(Autocomplete)({
  popupIndicatorOpen: {
    transform: "none",
  },
});

const TextField = styled(TextFieldBase)(({ theme }) => ({
  "& label.Mui-focused:not(.Mui-error)": {
    color: theme.searchBox.labelTextColor,
  },
  "& .MuiFilledInput-root": {
    border: theme.searchBox.input.border,
    "& .MuiInputBase-input": {
      color: theme.searchBox.input.textColor,
    },
  },
  "& .MuiFilledInput-underline": {
    "&:after": {
      borderColor: theme.searchBox.inputUnderline.borderColor,
    },
  },
  "& .MuiFormHelperText-root.Mui-focused:not(.Mui-error)": {
    color: theme.searchBox.helperTextColor,
  },
}));

const SearchIcon = styled(Search)(({ theme }) => ({
  color: theme.searchBox.iconColor,
}));

export default {
  SearchBox,
  TextField,
  SearchIcon,
};
