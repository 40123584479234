/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React, { PropsWithChildren } from "react";
import {
  Grid as GridBase,
  Paper as PaperBase,
  Box as BoxBase,
  BoxProps,
  Checkbox,
  Container as ContainerBase,
  Button as ButtonBase,
  CardMedia as CarmediaBase,
  Typography as TypographyBase,
} from "@mui/material";
import { DialogActions as MuiDialogActions } from "@mui/material";
import { Link as LinkBase, LinkProps } from "react-router-dom";
import { Alert as AlertBase } from "components/alert/alert.component";

import { styled } from "@mui/material/styles";

// Asset
import BGImage from "assets/images/URA_Background.png";

const Container = styled(ContainerBase)(({ theme }) => ({
  height: "100%",
  position: "absolute",
  maxWidth: "none !important",
  backgroundImage: `url(${BGImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const Grid = styled(GridBase)({
  justifyContent: "center",
  textAlign: "center",
});

const GridParent = styled(GridBase)(({ theme }) => ({
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
  left: "0",
  position: "absolute",
  paddingTop: theme.spacing(6),
}));

const Paper = styled(PaperBase)(({ theme }) => ({
  height: "100%",
  borderRadius: theme.landingPage.borderRadius,
}));

const ImageBox = styled(BoxBase)(({ theme }) => ({
  paddingTop: theme.spacing(6),
}));

const TexFieldBox = styled(BoxBase)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const TermsBox = styled(BoxBase)(({ theme }) => ({
  position: "absolute",
  bottom: theme.landingPage.termsBox.bottom,
  left: theme.landingPage.termsBox.left,
  transform: "translate(-50%, -50%)",
}));

const HelpContainerBox = styled(BoxBase)({
  display: "flex",
  justifyContent: "space-between",
});

const HelpBoxRight = styled(BoxBase)({
  textAlign: "right",
});

const HelpBoxLeft = styled(BoxBase)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const RememberBox = styled(BoxBase)({
  textAlign: "left",
});

interface CustomBoxProps extends PropsWithChildren<BoxProps> {
  marginTop?: number | string;
  // component?: React.ElementType;
  // onSubmit?: React.FormEventHandler<Element>;
}

const Box = styled(BoxBase, {
  shouldForwardProp: (prop) => prop !== "marginTop",
})<CustomBoxProps>(({ theme, marginTop }) => ({
  alignSelf: "center",
  textAlign: "center",
  marginTop: marginTop ? `${marginTop}px` : theme.spacing(2),
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  height: theme.landingPage.button.height,
  color: theme.table.toolbarActions.iconColor,
  borderColor: theme.table.toolbarActions.iconColor,
  textTransform: "capitalize",
  "&:hover": {
    borderColor: theme.table.toolbarActions.iconColor,
  },
  "&.MuiButton-contained": {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
    width: "100%",
  },
}));

const ButtonContainer = styled(MuiDialogActions)(({ theme }) => ({
  justifyContent: "flex-end",
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: "100%",
}));

const CardMedia = styled(CarmediaBase)(({ theme }) => ({
  width: theme.spacing(40),
  height: theme.spacing(25),
  margin: "auto",
}));

const Typography = styled(TypographyBase)(({ theme }) => ({
  color: theme.landingPage.typography.color,
  marginTop: theme.landingPage.typography.marginTop,
}));

const LinkText = styled(TypographyBase)(({ theme }) => ({
  color: theme.landingPage.typography.color,
  textDecoration: "none",
}));

type LinkBreadcrumbProps = LinkProps;

const CustomLinkStyles = styled(LinkBase)(({ theme }) => ({
  color: theme.palette.primary.main,
  pointerEvents: "auto",
  fontSize: theme.landingPage.typography.fontSize,
  textTransform: "capitalize",
  textDecoration: "none",
}));

const Link: React.FC<LinkBreadcrumbProps> = (props) => {
  return <CustomLinkStyles {...props} />;
};

const HelpLink = styled(TypographyBase)(({ theme }) => ({
  color: theme.landingPage.typography.color,
  textDecoration: "none",
  fontSize: theme.landingPage.typography.fontSize,
}));

const HelpText = styled(TypographyBase)(({ theme }) => ({
  fontSize: theme.landingPage.typography.fontSize,
  color: theme.palette.primary.main,
}));

const Alert = styled(AlertBase)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
export default {
  Box,
  ImageBox,
  TexFieldBox,
  TermsBox,
  HelpContainerBox,
  HelpBoxRight,
  HelpBoxLeft,
  RememberBox,
  Checkbox,
  Grid,
  GridParent,
  Paper,
  Container,
  Button,
  ButtonContainer,
  CardMedia,
  Typography,
  Link,
  LinkText,
  HelpLink,
  HelpText,
  Alert,
};
