/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Box as BoxMui, List } from "@mui/material";
import { styled } from "@mui/material/styles";

const Box = styled(BoxMui)(({ theme }) => ({
  paddingRight: "2rem",
  height: "300px",
  overflowY: "auto",
  marginTop: theme.spacing(2),
}));

export default {
  Box,
  List,
};
