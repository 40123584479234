/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { SubscriberAndPropertyFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";

export type TableSchema = Omit<SubscriberAndPropertyFragment, "__typename">;

export interface SubscriberAndPropertyTableFilters {
  firstName: string;
  phoneNumber: string;
}

export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof SubscriberAndPropertyTableFilters,
  string | string[]
> = {
  firstName: ["CustomerName"],
  phoneNumber: ["Telephone"],
};

export const UMEME_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof TableSchema,
  "string" | "number"
> = {
  CustomerName: "string",
  Customerno: "string",
  District: "string",
  MeterNo: "string",
  Street: "string",
  SupplyPointNo: "string",
  TariffDesc: "string",
  Telephone: "string",
  Town: "string",
  XCoord: "number",
  YCoord: "number",
  Longitude: "number",
  Latitude: "number",
};

export const getAccessorType = (
  accessor: keyof TableSchema | AccessorFn<TableSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return UMEME_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const TableColumns: TableColumn<TableSchema>[] = [
  {
    header: "Meter No",
    accessorKey: "MeterNo",
    align: "center",
    meta: { sort: true },
  },
  {
    header: "Customer No.",
    accessorKey: "Customerno",
    align: "center",
    format: "string",
  },
  {
    header: "Supply Point No.",
    accessorKey: "SupplyPointNo",
    align: "center",
    format: "string",
  },
  {
    header: "Customer Name",
    accessorKey: "CustomerName",
    format: "string",
  },
  {
    header: "District",
    accessorKey: "District",
    format: "string",
  },
  {
    header: "Street",
    accessorKey: "Street",
    format: "string",
  },
  {
    header: "TariffDesc",
    accessorKey: "TariffDesc",
    format: "string",
  },
  {
    header: "Telephone",
    accessorKey: "Telephone",
    format: "string",
  },
  {
    header: "Town",
    accessorKey: "Town",
    format: "string",
  },
  {
    header: "Latitude",
    accessorKey: "Latitude",
    align: "center",
  },
  {
    header: "Longitude",
    accessorKey: "Longitude",
    align: "center",
  },
  {
    header: "XCoord",
    accessorKey: "XCoord",
    format: "string",
  },
  {
    header: "YCoord",
    accessorKey: "YCoord",
    format: "string",
  },
];
