import React, { ReactNode } from "react";

// Styles
import SC from "./left-pane.styles";

interface LeftPaneProps {
    children: ReactNode
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LeftPane: React.FC<LeftPaneProps> = ({ children }) => (
  <SC.Container>
    <SC.Box py={2}>{children}</SC.Box>
  </SC.Container>
);

export default LeftPane;
