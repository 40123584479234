/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import _ from "lodash";
import { AccessorFn } from "@tanstack/react-table";
import { TableColumn } from "components/table/table.component";
import { Link, useLocation } from "react-router-dom";

import { Rpt_NonIndividualBalanceSheetFragment } from "graphql/hasura/types-and-hooks";

export type ReportTableSchema = Omit<
  Rpt_NonIndividualBalanceSheetFragment,
  | "__typename"
  | "AccntRcvdOthers"
  | "AccntRcvdOverSix"
  | "AdvanceCorporate"
  | "AdvanceRecoverable"
  | "ApplicationFund"
  | "BalRevenueAuth"
  | "BankBalance"
  | "BankOverDraft"
  | "CnsmPckInventory"
  | "Debenture"
  | "DeferTaxLiability"
  | "DeferredAsset"
  | "Deposits"
  | "Depreciation"
  | "EquityShare"
  | "FinishTradeGoods"
  | "FixedDeposit"
  | "FurnitureFixture"
  | "GeneralReserve"
  | "GovtSecurities"
  | "HandCash"
  | "IncomeTaxProv"
  | "IntLeaseAsset"
  | "IntangibleAssets"
  | "LandBuild"
  | "LessDrawings"
  | "LiableLeaseAsset"
  | "LoanSubCompany"
  | "MotorVehicles"
  | "NetCurrentAsset"
  | "NetFixedAsset"
  | "OtherInvestments"
  | "OtherProvision"
  | "OtherReserve"
  | "OthrCurrentAsset"
  | "OthrDeprecAsset"
  | "OthrLiability"
  | "PlantMachine"
  | "Prepayments"
  | "ProfitLossAccount"
  | "ProprietorCapital"
  | "ProvForBadDebts"
  | "RawMtrlInventory"
  | "RevaluationReserve"
  | "SecureDebtIssued"
  | "SecureLoanBank"
  | "SecureLoanOther"
  | "SecureLoanParties"
  | "SecureTotLoan"
  | "SourceFund"
  | "StockInProcess"
  | "SundryCreditors"
  | "TotAccntRcvdDebtors"
  | "TotAssetLoanAdvance"
  | "TotBalanceAvail"
  | "TotCurrLiability"
  | "TotInventory"
  | "TotalLoanFund"
  | "TotalPropFund"
  | "TotalProvision"
  | "TranslationReserve"
  | "UnpaidMatureDebts"
  | "UnsecureCreditorLoan"
  | "UnsecureLoanBank"
  | "UnsecureLoanOther"
  | "UnsecureLoanParties"
  | "UnsecureTotLoan"
  | "TinNo"
>;

export const REPORT_TABLE_SCHEMA: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  RtnId: "string",
  RtnPeriodYear: "string",
  RtnNo: "string",
  IsAmendment: "string",
  TotFixedAsset: "number",
  TotCurrentAsset: "number",
  TotLiabilityProv: "number",
  TotLoanAdvance: "number",
  TotTradeInvestment: "number",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | AccessorFn<ReportTableSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return REPORT_TABLE_SCHEMA[accessor];
};

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    header: "Tax Year",
    accessorKey: "RtnPeriodYear",
    align: "center",
    cell: ({ cell: { getValue } }) => {
      const value = getValue() as string;
      return <>{value?.replace("-", "/")}</>;
    },
    meta: { sort: true },
  },
  {
    header: "Return No.",
    accessorKey: "RtnNo",
    align: "center",
    cell: ({ cell: { getValue } }) => {
      const value = getValue() as string;
      const location = useLocation();
      const url = location.pathname;
      return (
        <Link to={`${url}/${value}/balance-sheet`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    header: "Amendment?",
    accessorKey: "IsAmendment",
    align: "center",
  },
  {
    header: "Total Fixed Assets (UGX)",
    accessorKey: "TotFixedAsset",
    align: "right",
    format: "currency",
  },
  {
    header: "Total Investments (UGX)",
    accessorKey: "TotTradeInvestment",
    align: "right",
    format: "currency",
  },
  {
    header: "Total Current Assets (UGX)",
    accessorKey: "TotCurrentAsset",
    align: "right",
    format: "currency",
  },
  {
    header: "Total Loans and Advances (UGX)",
    accessorKey: "TotLoanAdvance",
    align: "right",
    format: "currency",
  },
  {
    header: "Total Current Liabilities and Provisions (UGX)",
    accessorKey: "TotLiabilityProv",
    align: "right",
    format: "currency",
  },
];
