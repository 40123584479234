import React, { SyntheticEvent } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// Props
import { IndividualTabRouteParams } from "pages/individual-tab/main/individual-tab.component";

// Styles
import SC from "./tab-sub-header.styles";

export interface TabProps {
  label: string;
  path: string;
  value: number;
}

export interface TabSubHeaderProps {
  tabs: TabProps[];
}

export const TabSubHeader: React.FC<TabSubHeaderProps> = ({ tabs }) => {
  const navigate = useNavigate();
  const { repId } = useParams<{ repId: string }>();
  const location = useLocation();

  const [value, setValue] = React.useState<number>();

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    // setValue(newValue);
    const { pathname } = location;
    const pathArr = pathname.split("/");
    const tabPath = tabs[newValue].path;
    // replace the existing route with a new tab route.
    navigate(`/${pathArr[1]}/${repId}/${tabPath}`);
  };

  React.useEffect(() => {
    const { pathname } = location;
    // the pathname, convert into array and remove the first element.
    const pathArr = pathname.split("/").slice(1);
    // get the tabname from the list of array
    const tabName = pathArr[2];

    if (tabName) {
      // loop through the tabs to find the element eqivalent to tab name and update the state
      const currentTab = tabs.find((tab) => tab.path === tabName);
      if (currentTab) {
        setValue(currentTab.value);
      }
    }
  }, [location, tabs]);

  return (
    <SC.Box>
      <SC.Tabs
        value={value ?? false}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((item) => (
          <SC.Tab
            id={`id-tabs-${item.value}`}
            key={item.value}
            label={item.label}
            value={item.value}
            aria-controls={`simple-tabpanel-${item.value}`}
          />
        ))}
      </SC.Tabs>
    </SC.Box>
  );
};

export default TabSubHeader;
