/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button as ButtonBase,
  CircularProgress
} from "@mui/material";

import { styled } from "@mui/material/styles"

const ToolbarButton = styled(ButtonBase)(( { theme  }) => ({
  textTransform: "capitalize",
    fontSize: theme.table.toolbar.fontSize,
    border: theme.table.tablePagination.inputBorder,
    marginLeft: theme.spacing(3),
    "&:hover": {
      border: theme.table.tablePagination.inputBorder,
    },
}));

const IndicatorProgress = styled(CircularProgress)(() => ({
  position: "absolute",
    top: "10%",
    left: "35%",
    zIndex: 1,
}));

export default {
  ToolbarButton,
  IndicatorProgress,
};
