// Dependencies
import { Box as BoxBase, Typography } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import { styled } from "@mui/material/styles";

const Box = styled(BoxBase)({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: theme.table.toolbar.fontSize,
  textAlign: "left",
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(3),
  opacity: 0.8,
}));

export default {
  Box,
  RoomIcon,
  Title,
};
