/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import { TableColumn } from "components/table/table.component";
import React from "react";
import { Link } from "react-router-dom";
import { IndividualPropertyDetailsFragment } from "graphql/hasura/types-and-hooks";

import FormattedValue from "components/formatted-value/formatted-value";
import { getColumnTotal } from "components/table/utils";

export const propertiesTableColumns: TableColumn<
  IndividualPropertyDetailsFragment | Record<string, unknown>
>[] = [
  {
    header: "RNID",
    accessorKey: "Propertyrnid",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { Propertyrnid },
      },
    }) => {
      return (
        <Link to={`/properties/${Propertyrnid}/details`} color="inherit">
          <>{getValue()}</>
        </Link>
      );
    },
  },
  {
    header: "Source Property ID",
    accessorKey: "Propertyno",
    footer: "Totals",
    align: "center",
    meta: { sort: true },
  },
  {
    header: "Customer ID",
    accessorKey: "Customerid",
    align: "center",
  },
  {
    header: "Source",
    accessorKey: "Source",
    align: "center",
  },
  {
    header: "Mobile Number",
    accessorKey: "Mobilenumber",
    format: "string",
  },
  {
    header: "Owner First Name",
    accessorKey: "Ownerfirstname",
    format: "string",
  },
  {
    header: "Owner Legal Surname Maiden",
    accessorKey: "Ownerlegalsurnamemaiden",
    format: "string",
  },
  {
    header: "Aggregate Firstname",
    accessorKey: "Aggregatefirstname",
    format: "string",
  },
  {
    header: "Aggregate Surname",
    accessorKey: "Aggregatesurname",
    format: "string",
  },
  {
    header: "Aggregate Middlename",
    accessorKey: "Aggregatemiddlename",
    format: "string",
  },
  {
    header: "Aggregate Mobile 1",
    accessorKey: "Aggregatemobile1",
    format: "string",
  },
  {
    header: "Aggregate Telephone",
    accessorKey: "Aggregatetelephone",
    format: "string",
  },
  {
    header: "Aggregate Mobile 2",
    accessorKey: "Aggregatemobile2",
    format: "string",
  },
  {
    header: "Aggregate Birthdate",
    accessorKey: "Aggregatebirthdate",
    format: "date",
  },
  {
    header: "Aggregate Email",
    accessorKey: "Aggregateemail",
  },
  {
    header: "Last Update",
    accessorKey: "LastUpdated",
    align: "center",
    format: "date",
  },
  {
    header: "Property Type",
    accessorKey: "Propertytype",
    align: "center",
  },
  {
    header: "Sub Property Type",
    accessorKey: "Subpropertytype",
    align: "center",
  },
  {
    header: "Plot No.",
    accessorKey: "Plotno",
    align: "center",
  },
  {
    header: "Block No.",
    accessorKey: "Blockno",
    align: "center",
  },
  {
    header: "House No.",
    accessorKey: "Houseno",
    align: "center",
  },
  {
    header: "Flat No.",
    accessorKey: "Flatno",
    align: "center",
  },
  {
    header: "Street",
    accessorKey: "Streetid",
    align: "left",
  },
  {
    header: "Parish",
    accessorKey: "Parish",
    align: "left",
  },
  {
    header: "Address",
    accessorKey: "Address",
    align: "left",
  },
  {
    header: "Village",
    accessorKey: "Village",
    align: "center",
  },
  {
    header: "Division",
    accessorKey: "Division",
    align: "center",
  },
  {
    header: "District",
    accessorKey: "District",
    align: "center",
  },
  {
    header: "Rented Status",
    accessorKey: "Propertyrentedstatus",
    align: "center",
  },
  {
    header: "Rnid Owner",
    accessorKey: "Rnidowner",
    align: "center",
  },
  {
    header: "Gross Value",
    accessorKey: "Grossvalue",
    align: "right",
    format: "currency",
    footer: (props): React.ReactNode => {
      const total = getColumnTotal(props);
      return <FormattedValue format="currency" value={total} />;
    },
  },
  {
    header: "Rateable Value",
    accessorKey: "Rateablevalue",
    align: "right",
    format: "currency",
    footer: (props): React.ReactNode => {
      const total = getColumnTotal(props);
      return <FormattedValue format="currency" value={total} />;
    },
  },
  {
    header: "Current Rateable Value",
    accessorKey: "Currentrateablevalue",
    align: "right",
    format: "currency",
    footer: (props): React.ReactNode => {
      const total = getColumnTotal(props);
      return <FormattedValue format="currency" value={total} />;
    },
  },
  {
    header: "Adjusted Gross Income",
    accessorKey: "Grossrentvalueadjusted",
    align: "right",
    format: "currency",
    footer: (props): React.ReactNode => {
      const total = getColumnTotal(props);
      return <FormattedValue format="currency" value={total} />;
    },
  },
  {
    header: "Field Assess Date",
    accessorKey: "Createddate",
    align: "center",
    format: "date",
  },
  {
    header: "Latitude",
    accessorKey: "Latitude",
    align: "center",
  },
  {
    header: "Longitude",
    accessorKey: "Longitude",
    align: "center",
  },
  // {
  //   header: "Customer Matchscore",
  //   accessorKey: "Customermatchscore",
  //   align: "center",
  // },
];
