/* eslint-disable no-alert */
import React, { useEffect, useMemo } from "react";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";

import Alert from "components/alert/alert.component";

import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Order_By,
  Query_RootRtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsbArgs,
  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column,
  useGetPaginatedUgandaRegistrationQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// Utils
import {
  FilterCondition,
  TableFilter,
} from "components/table-filter/table-filter.component";
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import {
  DEFAULT_FILTER_ACCESSOR,
  getAccessorType,
  UgandaRegistrationColumns,
  UgandaRegistrationTableFilters,
} from "./uganda-registration.schema";

// Assets
import SC from "./uganda-registration.styles";

interface UgandaRegistrationTableProps {
  defaultFilters?: UgandaRegistrationTableFilters;
  initialRowsPerPage?: number;
  displayHeader?: boolean;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  onLoaded?: () => void;
  title?: string;
}

const TABLE_NAME = "rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB";

export const UgandaRegistrationTable: React.FC<UgandaRegistrationTableProps> =
  React.memo(
    ({
      defaultFilters,
      initialRowsPerPage = INITIAL_ROWS_PER_PAGE.REPORTS,
      displayHeader,
      maxHeight,
      noDataComponent,
      onLoaded,
      title = "Subscriber and Property",
    }) => {
      const defaultFilterConditions = useMemo(() => {
        const { businessName } = defaultFilters ?? {
          businessName: "",
        };

        return getConditionsFromDefaultFilters(
          {
            ...(defaultFilters ?? {}),
            businessName: businessName === "" ? "" : `%${businessName}`,
          },
          DEFAULT_FILTER_ACCESSOR,
        );
      }, [defaultFilters]);

      const hasUnhandledDefaultFilter = useMemo(
        () =>
          getHasUnhandledDefaultFilter(
            defaultFilters ?? {},
            DEFAULT_FILTER_ACCESSOR,
          ),
        [defaultFilters],
      );

      const [filterConditions, setFilterConditions] = React.useState<
        FilterCondition[]
      >([] as FilterCondition[]);

      const [queryVariables, setQueryVariables] = React.useState<
        Query_RootRtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsbArgs | undefined
      >({
        where: mergeWithDefaultConditions(
          defaultFilterConditions,
          filterConditions,
        ),
      });

      const [pageIndexResetSignal, setPageIndexResetSignal] =
        React.useState(false);

      const [infoMessage, setInfoMessage] = React.useState<string | undefined>(
        "",
      );

      const { data, loading, error } = useGetPaginatedUgandaRegistrationQuery({
        context: { clientName: CLIENT_NAME.HASURA },
        skip: !queryVariables || hasUnhandledDefaultFilter,
        variables: {
          params: jsonToStringQueryVariables(queryVariables ?? {}),
          aggregateParams: jsonToStringQueryVariables({
            where: queryVariables?.where,
          }),
        },
      });

      const [exportDataFn, { loading: exportLoading, error: exportError }] =
        useExportData(TABLE_NAME, queryVariables);

      //! implement the no data error
      useEffect(() => {
        if (
          data &&
          data.rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB.length === 0
        ) {
          setInfoMessage("Filter Query Returned No Data");
          return;
        }
        setInfoMessage(undefined);
      }, [data]);

      useAppErrorHandler(error || exportError);

      const UgandaRegistrationData = React.useMemo(() => {
        const UgandaRegData =
          data?.rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB ?? [];
        return UgandaRegData;
      }, [data]);

      const columns = React.useMemo(() => UgandaRegistrationColumns, []);

      const universalColumns: ColumnsProps[] = useMemo(() => {
        return columns.map((column, i) => {
          return {
            index: i,
            label: `${column.header}`,
            value: `${column.accessorKey}`,
            type: `${getAccessorType(column.accessorKey)}`,
          };
        });
      }, [columns]);

      const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
        const columnObject = condition[Object.keys(condition)[0]];
        if (columnObject[Object.keys(columnObject)[0]] === "") {
          // eslint-disable-next-line no-param-reassign
          condition = {};
        }
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: condition,
          offset: 0,
        }));
        setPageIndexResetSignal((previousSignal) => !previousSignal);
      };

      const handlePaginatedFetchData: TableFetchDataFunction =
        React.useCallback((params) => {
          const { pageIndex, pageSize, sortBy } = params;
          const defaultSortColumn = {
            id: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.BusinessName,
            desc: true,
          };
          const sortByColumn =
            sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
          setQueryVariables((oldVariables) => ({
            ...oldVariables,
            orderBy: {
              [sortByColumn.id]: sortByColumn.desc
                ? Order_By.Desc
                : Order_By.Asc,
            },
            limit: pageSize,
            offset: pageIndex * pageSize,
          }));
        }, []);

      const totalRowsCount = parseInt(
        data?.rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm[0]
          .value ?? "-1",
        10,
      );

      const paginationControlled = React.useMemo(
        () => ({
          fetchData: handlePaginatedFetchData,
          loading,
          totalRowsCount,
        }),
        [handlePaginatedFetchData, loading, totalRowsCount],
      );

      const onTableFilterSearch = React.useCallback(
        (conditions: FilterCondition[]) => {
          setFilterConditions(conditions);
          setPageIndexResetSignal((previousSignal) => !previousSignal);
        },
        [],
      );

      useEffect(() => {
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        }));
      }, [defaultFilterConditions, filterConditions]);

      useEffect(() => {
        if (!loading) onLoaded?.();
      }, [loading, onLoaded]);

      return (
        <SC.Container pl={2} pr={2} pt={2}>
          <Table
            actionsOnRight={[
              "hide/show-columns",
              "filter-results",
              "export-to-excel-sheet/csv",
            ]}
            columns={columns}
            data={UgandaRegistrationData}
            onAction={() => alert("under construction")}
            title={title}
            headerPanel={
              displayHeader ? (
                <SC.Title>MDA - Uganda Registration Services Bureau</SC.Title>
              ) : undefined
            }
            leftPanel={
              <TableFilter
                searchInputs={[
                  {
                    label: "Entity Name",
                    columnNames: [
                      Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.BusinessName,
                      Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.CompanyName,
                    ],
                  },
                  {
                    label: "First Name",
                    columnNames: [
                      Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.PartnersFirstname,
                      Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.DirectorFirstname,
                      Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.ShareholderFirstname,
                    ],
                  },
                  {
                    label: "Surname",
                    columnNames: [
                      Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.PartnersSurname,
                      Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.DirectorSurname,
                      Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column.ShareholdersSurname,
                    ],
                  },
                ]}
                onSearchChange={onTableFilterSearch}
                universalFilterColumns={universalColumns}
                onUniversalFilterSubmit={onUniversalFilterer}
              />
            }
            persistenceId="0dc8fe17-e48b-48fd-8ff9-bcbe47682dae"
            paginationControlled={paginationControlled}
            stickyHeader
            initialRowsPerPage={initialRowsPerPage}
            pageIndexResetSignal={pageIndexResetSignal}
            exportData={exportDataFn}
            maxHeight={maxHeight}
            noDataComponent={noDataComponent}
          />
          {infoMessage && <Alert message={infoMessage} />}
          <ProgressIndicator open={loading || exportLoading} />
        </SC.Container>
      );
    },
  );
const UgandaRegistration: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <UgandaRegistrationTable displayHeader />
    </SC.Box>
  );
};

export default UgandaRegistration;
