import React from "react";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import { UraRegIndividualDataQuery_UraIndividual_ElementKey } from "graphql/hasura/rtcs.types";
import {
  Query_RootRtcs_Db_Ph_Rpt_IndividualCompositeArgs,
  Query_RootRtcs_Db_Ph_Ura_TownArgs,
  useUraRegIndividualDataQuery,
  useUraTownQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";
import {
  AccordionHeader,
  Header,
  Content,
} from "components/accordion-header/accordion-header.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

type BirthFieldElementKey = "BirthFieldCityTownName";

const isBirthFieldElementKey = (
  maybeBirthFieldElementKey: unknown,
): maybeBirthFieldElementKey is BirthFieldElementKey => {
  return (
    _.isString(maybeBirthFieldElementKey) &&
    maybeBirthFieldElementKey.startsWith("BirthField")
  );
};

interface UraIndividual {
  label: string;
  accessorKey?:
    | UraRegIndividualDataQuery_UraIndividual_ElementKey
    | BirthFieldElementKey;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

export const identifiers: UraIndividual[] = [
  { label: "First Name", accessorKey: "UraFirstName" },
  { label: "Middle Name", accessorKey: "UraMiddleName" },
  { label: "Surname", accessorKey: "UraSurName" },
  { label: "Family Name", accessorKey: "UraFamilyName" },
  { label: "Birth Date", accessorKey: "BirthDt", format: "date" },
  { label: "Birth City", accessorKey: "BirthFieldCityTownName" },
  { label: "National ID", accessorKey: "NationalId" },
  { label: "TIN", accessorKey: "UraTinNo" },
  { label: "RNID", accessorKey: "Rnid" },
];
export const homeAddress: UraIndividual[] = [
  { label: "Plot No.", accessorKey: "HomeAddrPlotno" },
  { label: "Street Address", accessorKey: "HomeAddrStrretAddr" },
  { label: "Building Name", accessorKey: "HomeAddrBuildingname" },
  { label: "District Name", accessorKey: "HomeAddrDistrictName" },
  { label: "Town", accessorKey: "HomeAddrTownName" },
  { label: "Subcounty", accessorKey: "HomeAddrSubCountyName" },
  { label: "Parish", accessorKey: "HomeAddrParishName" },
  { label: "Village", accessorKey: "HomeAddrVillageName" },
  { label: "Trade Centre", accessorKey: "HomeAddrTradeCentre" },
  { label: "Local Council", accessorKey: "HomeAddrLocalCouncil" },
];
export const contactInfo: UraIndividual[] = [
  { label: "Email Address", accessorKey: "EmailId" },
  { label: "Landline Phone No.", accessorKey: "LandlineNumber" },
  { label: "Mobile No.", accessorKey: "MobileNumber" },
  { label: "" }, // create separator
  { label: "Income Source Rental?", accessorKey: "IsIncomeSrcRental" },
  { label: "Income Source Property?", accessorKey: "IsIncomeSrcPrpty" },
  { label: "Income Source Business?", accessorKey: "IsIncomeSrcBsns" },
  { label: "Income Source Employment?", accessorKey: "IsIncomeSrcEmp" },
];

export interface IndividualTabHeaderUraProps {
  rnid: string;
  tinNos: string;
}

export const IndividualTabHeaderUra: React.FC<IndividualTabHeaderUraProps> = ({
  rnid,
  tinNos,
}) => {
  const tinNo = tinNos.split(",")?.[0];
  const queryVariablesRegIndividual: Query_RootRtcs_Db_Ph_Rpt_IndividualCompositeArgs =
    {
      where: { UraTinNo: { _eq: tinNo } },
      limit: 1,
    };

  const { data, error, loading } = useUraRegIndividualDataQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariablesRegIndividual,
    variables: {
      params: jsonToStringQueryVariables(queryVariablesRegIndividual),
    },
  });

  const regIndividual = data?.rtcs_db_FnRPT_IndividualComposite?.[0];

  const queryVariablesBirthCityTown: Query_RootRtcs_Db_Ph_Ura_TownArgs = {
    where: { TownId: { _eq: regIndividual?.BirthCity } },
  };
  const {
    data: uraTownData,
    error: uraTownError,
    loading: uraTownLoading,
  } = useUraTownQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !regIndividual?.BirthCity,
    variables: {
      params: jsonToStringQueryVariables(queryVariablesBirthCityTown),
    },
  });

  useAppErrorHandler(error || uraTownError);

  if (!regIndividual) {
    return null;
  }

  const birthFieldInfo: Record<BirthFieldElementKey, string> = {
    BirthFieldCityTownName: uraTownData?.rtcs_db_Fnura_Town?.[0].TownName ?? "",
  };

  const header: Header[] = [
    {
      index: 0,
      label: `${regIndividual.Firstname ?? ""} ${regIndividual.Surname ?? ""}`,
      type: "bold",
    },
    { index: 1, label: "RNID", type: "bold" },
    { index: 2, label: rnid },
    { index: 3, label: "TIN", type: "bold" },
    { index: 4, label: `${tinNo}` },
  ];

  const mapFunction = (info: UraIndividual, index: number) => {
    const { accessorKey } = info;
    let value = "";

    if (accessorKey)
      value = isBirthFieldElementKey(accessorKey)
        ? birthFieldInfo[accessorKey]
        : regIndividual?.[accessorKey];

    return { ...info, index, value };
  };
  const content: Content[] = [
    {
      index: 0,
      title: "Names and Identifiers",
      data: identifiers.map(mapFunction),
    },
    {
      index: 1,
      title: "Home Address",
      data: homeAddress.map(mapFunction),
    },
    {
      index: 2,
      title: "Contact Info and Related",
      data: contactInfo.map(mapFunction),
    },
  ];

  const isLoading = loading || uraTownLoading;

  return (
    <AccordionHeader
      header={header}
      content={content}
      source="URA"
      isLoading={isLoading}
    />
  );
};

export default IndividualTabHeaderUra;
