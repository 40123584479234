/* eslint-disable no-alert */
// Dependencies
import React, { useEffect, useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Order_By,
  Query_RootRtcs_Db_Ph_Aggregate_OwnerArgs,
  Rtcs_Db_Ph_Aggregate_Owner_Select_Column,
  useAggregateOwnerQuery,
  usePaginatedAggregateOwnerQuery,
} from "graphql/hasura/types-and-hooks";
import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import Alert from "components/alert/alert.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import {
  CustomerRegistrationTableFilters,
  customerTableColumns,
  DEFAULT_FILTER_ACCESSOR,
  getAccessorType,
} from "./customer-registration.schema";

// Assets
import SC from "./customer-registration.styles";

interface CustomerRegistrationTableProps {
  defaultFilters?: CustomerRegistrationTableFilters;
  initialRowsPerPage?: number;
  displayHeader?: boolean;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  onLoaded?: () => void;
  title?: string;
}

const TABLE_NAME = "rtcs_db_Fnaggregate_Owner";

export const CustomerRegistrationTable: React.FC<CustomerRegistrationTableProps> =
  React.memo(
    ({
      defaultFilters,
      initialRowsPerPage = INITIAL_ROWS_PER_PAGE.REPORTS,
      displayHeader,
      maxHeight,
      noDataComponent,
      onLoaded,
      title = "Customers - Registration",
    }) => {
      const defaultFilterConditions = useMemo(() => {
        const { firstName, surname, email, businessName } = defaultFilters ?? {
          firstName: "",
          surname: "",
          email: "",
          businessName: "",
        };

        return getConditionsFromDefaultFilters(
          {
            ...(defaultFilters ?? {}),
            firstName: firstName === "" ? "" : `%${firstName}`,
            surname: surname === "" ? "" : `%${surname}`,
            email: email === "" ? "" : `%${email}`,
            businessName: businessName === "" ? "" : `%${businessName}`,
          },
          DEFAULT_FILTER_ACCESSOR,
        );
      }, [defaultFilters]);

      const hasUnhandledDefaultFilter = useMemo(
        () =>
          getHasUnhandledDefaultFilter(
            defaultFilters ?? {},
            DEFAULT_FILTER_ACCESSOR,
          ),
        [defaultFilters],
      );

      const [filterConditions, setFilterConditions] = React.useState<
        FilterCondition[]
      >([] as FilterCondition[]);

      const [queryVariables, setQueryVariables] =
        React.useState<Query_RootRtcs_Db_Ph_Aggregate_OwnerArgs>({
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        });

      const [pageIndexResetSignal, setPageIndexResetSignal] =
        React.useState(false);
      const [infoMessage, setInfoMessage] = React.useState<string | undefined>(
        "",
      );

      const { data, loading, error } = usePaginatedAggregateOwnerQuery({
        context: { clientName: CLIENT_NAME.HASURA },
        skip: !queryVariables || hasUnhandledDefaultFilter,
        variables: {
          params: jsonToStringQueryVariables(queryVariables ?? {}),
          aggregateParams: jsonToStringQueryVariables({
            where: queryVariables?.where,
          }),
        },
      });

      const [exportDataFn, { loading: exportLoading, error: exportError }] =
        useExportData(TABLE_NAME, queryVariables);

      //! implement the no data error
      useEffect(() => {
        if (data && data.rtcs_db_Fnaggregate_Owner.length === 0) {
          setInfoMessage("Filter Query Returned No Data");
          return;
        }
        setInfoMessage(undefined);
      }, [data]);

      useAppErrorHandler(error || exportError);

      const reportData = React.useMemo(() => {
        const report = data?.rtcs_db_Fnaggregate_Owner ?? [];
        return report;
      }, [data]);

      const columns = useMemo(() => customerTableColumns, []);

      const universalColumns: ColumnsProps[] = React.useMemo(() => {
        return columns.map((column, i) => {
          return {
            index: i,
            label: `${column.header}`,
            value: `${column.accessorKey}`,
            type: `${getAccessorType(column.accessorKey)}`,
          };
        });
      }, [columns]);

      const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
        const columnObject = condition[Object.keys(condition)[0]];
        if (columnObject[Object.keys(columnObject)[0]] === "") {
          // eslint-disable-next-line no-param-reassign
          condition = {};
        }
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: condition,
        }));
        setPageIndexResetSignal((previousSignal) => !previousSignal);
      };

      const handlePaginatedFetchData: TableFetchDataFunction =
        React.useCallback((params) => {
          const { pageIndex, pageSize, sortBy } = params;
          const defaultSortColumn = {
            id: Rtcs_Db_Ph_Aggregate_Owner_Select_Column.Id,
            desc: true,
          };
          const sortByColumn =
            sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
          setQueryVariables((oldVariables) => ({
            ...oldVariables,
            orderBy: {
              [sortByColumn.id]: sortByColumn.desc
                ? Order_By.Desc
                : Order_By.Asc,
            },
            limit: pageSize,
            offset: pageIndex * pageSize,
          }));
        }, []);

      const totalRowsCount = parseInt(
        data?.rtcs_db_Fnaggregate_Owner_aggregatecm[0].value ?? "-1",
        10,
      );

      const paginationControlled = React.useMemo(
        () => ({
          fetchData: handlePaginatedFetchData,
          loading,
          totalRowsCount,
        }),
        [totalRowsCount, loading, handlePaginatedFetchData],
      );

      // TODO: Replace for useLazyQuery when the function has been updated
      // to return a Promise instead of void.
      // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
      const { refetch: PropertyFilterFn } = useAggregateOwnerQuery({
        skip: true,
        context: {
          clientName: CLIENT_NAME.HASURA,
        },
      });

      const fetchSuggestions = React.useCallback(
        async (value: string, column: string) => {
          try {
            let suggestions: string[] = [];

            if (value !== "") {
              const filterVariables: Query_RootRtcs_Db_Ph_Aggregate_OwnerArgs =
                {
                  where: {
                    [column]: { _ilike: `${value}%` },
                  },
                  distinct_on: [
                    column as Rtcs_Db_Ph_Aggregate_Owner_Select_Column,
                  ],
                  limit: 10,
                };
              const propertyFilterData = await PropertyFilterFn({
                params: jsonToStringQueryVariables(filterVariables),
              });
              suggestions =
                propertyFilterData.data?.rtcs_db_Fnaggregate_Owner.map(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (option) => option[column],
                );
            }

            // eslint-disable-next-line @typescript-eslint/return-await
            return Promise.resolve((suggestions ?? []) as string[]);
          } catch (err) {
            return Promise.reject(err);
          }
        },
        [PropertyFilterFn],
      );

      const filterInputs: TableFilterInput[] = React.useMemo(() => {
        return [
          {
            type: TableFilterType.AUTOFILL,
            label: "Customer Type",
            columnName: Rtcs_Db_Ph_Aggregate_Owner_Select_Column.CustomerType,
            fetchSuggestions,
          },

          {
            type: TableFilterType.AUTOFILL,
            label: "First Name",
            columnName: Rtcs_Db_Ph_Aggregate_Owner_Select_Column.Firstname,
            fetchSuggestions,
          },

          {
            type: TableFilterType.AUTOFILL,
            label: "Surname",
            columnName: Rtcs_Db_Ph_Aggregate_Owner_Select_Column.Surname,
            fetchSuggestions,
          },

          {
            type: TableFilterType.AUTOFILL,
            label: "Entity Name",
            columnName:
              Rtcs_Db_Ph_Aggregate_Owner_Select_Column.EntityLegalName,
            fetchSuggestions,
          },

          {
            type: TableFilterType.AUTOFILL,
            label: "TIN",
            columnName: Rtcs_Db_Ph_Aggregate_Owner_Select_Column.Tin,
            fetchSuggestions,
          },

          {
            type: TableFilterType.AUTOFILL,
            label: "Mobile Number",
            columnName: Rtcs_Db_Ph_Aggregate_Owner_Select_Column.Mobile,
            fetchSuggestions,
          },

          {
            type: TableFilterType.AUTOFILL,
            label: "Email",
            columnName: Rtcs_Db_Ph_Aggregate_Owner_Select_Column.Email,
            fetchSuggestions,
          },
        ];
      }, [fetchSuggestions]);

      const onTableFilterApply = React.useCallback(
        (conditions: FilterCondition[]) => {
          setFilterConditions(conditions);
          setPageIndexResetSignal((previousSignal) => !previousSignal);
        },
        [],
      );

      useEffect(() => {
        setQueryVariables((oldQueryVariables) => ({
          ...oldQueryVariables,
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        }));
      }, [defaultFilterConditions, filterConditions]);

      useEffect(() => {
        if (!loading) onLoaded?.();
      }, [loading, onLoaded]);

      return (
        <SC.Container pl={2} pr={2} pt={2}>
          <Table
            actionsOnRight={[
              "hide/show-columns",
              "filter-results",
              "export-to-excel-sheet/csv",
            ]}
            title={title}
            columns={columns}
            data={reportData}
            initialRowsPerPage={initialRowsPerPage}
            onAction={() => alert("under construction")}
            headerPanel={
              displayHeader ? (
                <SC.Title>MDA - Kampala Capital City Authority</SC.Title>
              ) : undefined
            }
            leftPanel={
              <TableFilter
                filterInputs={filterInputs}
                onFilterApply={onTableFilterApply}
                universalFilterColumns={universalColumns}
                onUniversalFilterSubmit={onUniversalFilterer}
              />
            }
            persistenceId="a7f2614d-8cd1-4744-bbe9-506610a1496e"
            paginationControlled={paginationControlled}
            stickyHeader
            pageIndexResetSignal={pageIndexResetSignal}
            exportData={exportDataFn}
            maxHeight={maxHeight}
            noDataComponent={noDataComponent}
          />
          {infoMessage && <Alert message={infoMessage} />}
          <ProgressIndicator open={loading || exportLoading} />
        </SC.Container>
      );
    },
  );

const CustomerRegistration: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <CustomerRegistrationTable displayHeader />
    </SC.Box>
  );
};

export default CustomerRegistration;
