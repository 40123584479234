/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = Box;

const Title = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "left",
}));

export default {
  Container,
  Title,
};
