/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { UraNonIndividualsAssociatedBusinessFragment } from "graphql/hasura/types-and-hooks";

export type NonIndividualTableSchema = Omit<
  UraNonIndividualsAssociatedBusinessFragment,
  "__typename"
>;
export interface AssociatedRegisteredNonIndividualsToNonIndividualsTableFilters {
  businessName: string;
  surname: string;
}

// @ts-ignore
export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof NonIndividualTableSchema,
  "string" | "number" | "Date"
> = {
  TinNo: "string",
  TaxPayerName: "string",
  AssociateTin: "string",
  AssociatedNonIndividual: "string",
  MainActivity: "string",
  ActvtyDesc: "string",
};
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof AssociatedRegisteredNonIndividualsToNonIndividualsTableFilters,
  string
> = {
  businessName: "TaxPayerName",
  surname: "Surname",
};

export const getAccessorType = (
  accessor:
    | keyof NonIndividualTableSchema
    | AccessorFn<NonIndividualTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const individualTableColumns: TableColumn<NonIndividualTableSchema>[] = [
  {
    header: "TIN No.",
    accessorKey: "TinNo",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { TinNo },
      },
    }) => {
      return (
        <Link to={`/non-individuals/${TinNo}/identification`} color="inherit">
          <>{getValue()}</>
        </Link>
      );
    },
    meta: { sort: true },
  },
  {
    header: "Taxpayer Name",
    accessorKey: "TaxPayerName",
    align: "left",
    format: "string",
  },
  {
    header: "Associate TIN",
    accessorKey: "AssociateTin",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { AssociateTin },
      },
    }) => {
      return (
        <Link
          to={`/non-individuals/${AssociateTin}/identification`}
          color="inherit"
        >
          <>{getValue()}</>
        </Link>
      );
    },
  },
  {
    header: "Associated Business",
    accessorKey: "AssociatedNonIndividual",
    align: "left",
    format: "string",
  },
  {
    header: "Main Activity",
    accessorKey: "MainActivity",
    align: "left",
    format: "string",
  },
  {
    header: "Activity Description",
    accessorKey: "ActvtyDesc",
    align: "left",
    format: "string",
  },
];
