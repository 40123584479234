// Dependencies
import React, { useCallback } from "react";
import { useAppErrorHandler } from "errors/app.errors";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Ura_CombinedOfficersAndOwnersArgs,
  useNonIndividualOwnersAndOfficersQuery,
  Query_RootRtcs_Db_Ura_NonIndividualsAssociatedBusinessArgs,
  useNonIndividualsAssociatedIndividualsQuery,
  Query_RootRtcs_Db_Ura_IndividualsAssociatedBusinessArgs,
  useIndividualsAssociatedBusinessQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { ColumnDef } from "@tanstack/react-table";
import {
  associatedBusinessescolumns,
  associatedIndividualscolumns,
  columns,
} from "./owners-officers.schema";

// Assets
import SC from "./owners-officers.styles";

export interface OwnersOfficersProps {
  tin: string;
}

const TABLE_NAME = "ura_CombinedOfficersAndOwners";

const OwnersOfficers: React.FC<OwnersOfficersProps> = ({ tin }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Ura_CombinedOfficersAndOwnersArgs =
    {
      where: { Tinno: { _eq: tin } },
    };

  const ascNonIndividualsQueryVariables: Query_RootRtcs_Db_Ura_NonIndividualsAssociatedBusinessArgs =
    {
      where: { TinNo: { _eq: tin } },
    };

  const ascIndividualsQueryVariables: Query_RootRtcs_Db_Ura_IndividualsAssociatedBusinessArgs =
    {
      where: { AssociateTin: { _eq: tin } },
    };

  const {
    loading: ownerOfficersLoading,
    data: ownerOfficersData,
    error: ownerOfficersError,
  } = useNonIndividualOwnersAndOfficersQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const {
    loading: ascNonIndividualsLoading,
    data: ascNonIndividualsData,
    error: ascNonIndividualsError,
  } = useNonIndividualsAssociatedIndividualsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin,
    variables: {
      params: jsonToStringQueryVariables(ascNonIndividualsQueryVariables ?? {}),
    },
  });

  const {
    loading: ascIndividualsLoading,
    data: ascIndividualsData,
    error: ascIndividualsError,
  } = useIndividualsAssociatedBusinessQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin,
    variables: {
      params: jsonToStringQueryVariables(ascIndividualsQueryVariables ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME);

  const exportOwnersOfficers = useCallback(
    (columnz: ColumnDef<any, any>[]) =>
      ownerOfficersData
        ? exportDataFn(
            columnz,
            undefined,
            ownerOfficersData?.rtcs_db_Fnura_CombinedOfficersAndOwners ?? [],
          )
        : null,
    [ownerOfficersData, exportDataFn],
  );

  const exportAscNonIndividuals = useCallback(
    (columnz: ColumnDef<any, any>[]) =>
      ascNonIndividualsData
        ? exportDataFn(
            columnz,
            undefined,
            ascNonIndividualsData.rtcs_db_Fnura_NonIndividualsAssociatedBusiness ??
              [],
          )
        : null,
    [ascNonIndividualsData, exportDataFn],
  );

  const exportAscIndividuals = useCallback(
    (columnz: ColumnDef<any, any>[]) =>
      ascIndividualsData
        ? exportDataFn(
            columnz,
            undefined,
            ascIndividualsData.rtcs_db_Fnura_IndividualsAssociatedBusiness ??
              [],
          )
        : null,
    [ascIndividualsData, exportDataFn],
  );

  useAppErrorHandler(
    ownerOfficersError ||
      ascNonIndividualsError ||
      ascIndividualsError ||
      exportError,
  );

  return (
    <>
      <SC.ProgressIndicator
        open={
          ownerOfficersLoading ||
          ascNonIndividualsLoading ||
          ascIndividualsLoading ||
          exportLoading
        }
      />
      <SC.Card>
        <SC.Table
          title="Associated Registered Individuals"
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "export-to-excel-sheet/csv",
          ]}
          onAction={() => null}
          persistenceId="5b85031b-473c-470e-b24e-b067d7367d49"
          columns={associatedIndividualscolumns}
          data={
            ascIndividualsData?.rtcs_db_Fnura_IndividualsAssociatedBusiness ??
            []
          }
          exportData={exportAscIndividuals}
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          title="Associated Registered NonIndividuals"
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "export-to-excel-sheet/csv",
          ]}
          onAction={() => null}
          persistenceId="5b85031b-473c-470e-b24e-b067d7367d49"
          columns={associatedBusinessescolumns}
          data={
            ascNonIndividualsData?.rtcs_db_Fnura_NonIndividualsAssociatedBusiness ??
            []
          }
          exportData={exportAscNonIndividuals}
        />
      </SC.Card>

      <SC.Card>
        <SC.Table
          title="Owners and Officers"
          actionsOnRight={[
            "fullscreen-expand",
            "hide/show-columns",
            "export-to-excel-sheet/csv",
          ]}
          onAction={() => null}
          persistenceId="1b281584-f039-4013-91c4-df1affed3625"
          columns={columns}
          data={
            ownerOfficersData?.rtcs_db_Fnura_CombinedOfficersAndOwners ?? []
          }
          exportData={exportOwnersOfficers}
        />
      </SC.Card>
    </>
  );
};

export default OwnersOfficers;
