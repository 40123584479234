/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card as CardBase,
  Typography as TypographyBase,
  Box as BoxBase,
  Grid as GridBase,
  Container as ContainerBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LabelKeyValueBase from "components/label-key-value/label-key-value.component";

const Card = styled(CardBase)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const LabelKeyValue = LabelKeyValueBase;

const Container = styled(ContainerBase)(({ theme }) => ({
  padding: theme.spacing(2),
  overflowY: "scroll",
  height: "100%",
}));

const Typography = styled(TypographyBase)(({ theme }) => ({
  h5: {
    fontSize: theme.card.title.fontSize,
    fontWeight: theme.card.title.fontWeight,
    marginBottom: theme.spacing(2),
  },
  h6: {
    fontSize: theme.card.subtitle.fontSize,
    fontWeight: theme.card.subtitle.fontWeight,
  },
}));

const Box = styled(BoxBase)(({ theme }) => ({
  textAlign: "left",
  marginTop: theme.spacing(3),
}));

const Grid = GridBase;

export default {
  Card,
  Grid,
  LabelKeyValue,
  Typography,
  Box,
  Container,
};
