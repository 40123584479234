/* eslint-disable @typescript-eslint/no-explicit-any */
import { Paper as PaperBase } from "@mui/material";
import { PhotoLibrary } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const Paper = styled(PaperBase)(() => ({
  height: "100%",
  display: "grid",
  placeItems: "center",
}));

const Icon = styled(PhotoLibrary)(({ theme }) => ({
  fontSize: theme.imagePlaceholder.icon.fontSize,
  color: theme.imagePlaceholder.icon.color,
}));

export default {
  Paper,
  Icon,
};
