/* eslint-disable @typescript-eslint/ban-ts-comment */
// Dependencies
import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import { ColumnDef } from "@tanstack/react-table";
import {
  Query_RootRtcs_Db_Ph_Rpt_IndividualTaxSummaryDataArgs,
  useUraRptIndividualTaxSummariesQuery,
  Order_By,
} from "graphql/hasura/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import useExportData from "exports/useExportData";
import { taxHistoryColumns } from "./tax-history.schema";
import SC from "./tax-history.styles";

export interface TaxHistoryProps {
  tin: string;
}

const persistenceId = "e6e52d15-3b67-456f-8133-bac140052d87";

const TABLE_NAME = "RPT_IndividualTaxSummaryData";

const TaxHistory: React.FC<TaxHistoryProps> = ({ tin }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_IndividualTaxSummaryDataArgs =
    {
      where: { TinNo: { _eq: tin } },
      // @ts-ignore
      orderBy: { RtnPeriodYear: Order_By.Desc, RtnDt: Order_By.Desc },
    };
  const {
    loading,
    data: taxHistoryReturnsData,
    error,
  } = useUraRptIndividualTaxSummariesQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  const taxHistoryData = useMemo(() => {
    return taxHistoryReturnsData?.rtcs_db_FnRPT_IndividualTaxSummaryData ?? [];
  }, [taxHistoryReturnsData]);

  useAppErrorHandler(error || exportError);

  return (
    <>
      <SC.ProgressIndicator open={loading} />
      <SC.Table
        stickyHeader
        actionsOnRight={["hide/show-columns", "export-to-excel-sheet/csv"]}
        title="Tax History"
        onAction={() => null}
        enableMultiSort
        persistenceId={persistenceId}
        columns={taxHistoryColumns}
        paginationControlled={undefined}
        initialRowsPerPage={undefined}
        pageIndexResetSignal={undefined}
        data={taxHistoryData}
        exportData={(columns: ColumnDef<any, any>[]) =>
          exportDataFn(columns, undefined, taxHistoryData)
        }
      />
      <SC.ProgressIndicator open={exportLoading} />
    </>
  );
};

export default TaxHistory;
