/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, CardContent, Typography, IconButton } from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import NoteAuthor from "../note-author/note-author.component";
import NoteToolbar from "../note-toolbar/note-toolbar.component";

import { styled } from "@mui/material/styles";

const AttachmentIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.notes.attachments.iconButton.padding,
  marginRight: theme.notes.attachments.iconButton.marginRight,
  color: theme.palette.primary.dark,
  "&:not(.Mui-disabled) .MuiSvgIcon-root": {
    fontSize: theme.notes.attachments.iconButton.fontSize,
  },
}));

const NoteContainer = styled(Card)(({ theme }) => ({
  marginBottom: theme.notes.container.marginBottom,
}));

const NoteContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.notes.container.content.paddingTop,
  paddingBottom: theme.notes.container.content.paddingBottom,
  paddingRight: theme.notes.container.content.paddingRight,
  paddingLeft: theme.notes.container.content.paddingLeft,
  "&:last-child": {
    paddingBottom: theme.notes.container.content.paddingBottom,
  },
}));

const NoteTimestamp = styled(Typography)(({ theme }) => ({
  marginTop: theme.notes.container.content.timestamp.marginTop,
}));

const NoteBody = styled(Box)(({ theme }) => ({
  marginTop: "0px",
  "& p": {
    marginBottom: "0px",
    marginTop: theme.notes.container.content.body.paddingTop,
    fontSize: theme.notes.body.fontSize,
  },
}));

export default {
  NoteContainer,
  NoteToolbar,
  NoteContent,
  NoteAuthor,
  NoteTimestamp,
  NoteBody,
  AttachmentIconButton,
  AttachFile,
  Typography,
  Box,
};
