import React from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Aggregate_PropertyArgs,
  useAggregatePropertyFilterQuery,
} from "graphql/hasura/types-and-hooks";

// Utilis
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import { useAppErrorHandler } from "errors/app.errors";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import PrimaryTable from "./primary-table/primary-table.component";
import SecondaryTable from "./secondary-table/secondary-table.component";
import AlternateTable from "./alternate-table/alternate-table.component";

// Styles
import SC from "./owner-tab.styles";

interface OwnersTabProps {
  camvId: string;
  // eslint-disable-next-line react/require-default-props
  propertyNo?: string;
}

const OwnerTab: React.FC<OwnersTabProps> = ({ camvId, propertyNo }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Aggregate_PropertyArgs = {
    where: camvId
      ? { CamvId: { _eq: camvId } }
      : { PropertyNo: { _eq: propertyNo } },
    limit: 1,
  };

  const { data, error, loading } = useAggregatePropertyFilterQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !propertyNo && !camvId,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  useAppErrorHandler(error);

  if (loading) {
    return <ProgressIndicator open />;
  }

  if (data && data?.rtcs_db_Fnaggregate_Property.length > 0) {
    const customerId = `${data?.rtcs_db_Fnaggregate_Property[0].CustomerId}`;
    return (
      <SC.Box>
        <PrimaryTable propertyIdentifier={customerId} />
        <SecondaryTable propertyIdentifier={customerId} />
        <AlternateTable propertyIdentifier={customerId} />
      </SC.Box>
    );
  }
  return <>No Data Available</>;
};

export default OwnerTab;
