// Dependencies
import {
  List as ListBase,
  ListItem as ListItemBase,
  ListItemText as ListItemTextBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const List = styled(ListBase)({
  color: "#747474",
});

const ListItem = styled(ListItemBase)({});

const ListItemText = styled(ListItemTextBase)({});

export default {
  List,
  ListItem,
  ListItemText,
};
