import React from "react";
import Typography from "@mui/material/Typography";
import { SliderProps } from "@mui/material/Slider";
import { FormattedNumber } from "react-intl";

// Assets
import SC from "./range-slider.styles";

const defaultValueLabelFormat = (value: number) => (
  <FormattedNumber value={value} notation="compact" />
);

export interface RangeSliderProps extends SliderProps {
  label: string;
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
  color = "primary",
  disabled = false,
  max = 100,
  min = 0,
  valueLabelDisplay = "on",
  valueLabelFormat = defaultValueLabelFormat,
  label = "Range Slider",
  ...props
}) => {
  return (
    <SC.SliderWrapper>
      <SC.Slider
        color={color}
        disabled={disabled}
        max={max}
        min={min}
        size="small"
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
        aria-labelledby="range-slider"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <Typography id="range-slider" gutterBottom>
        {label}
      </Typography>
    </SC.SliderWrapper>
  );
};

export default RangeSlider;
