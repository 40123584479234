/* eslint-disable import/prefer-default-export */
import { TableColumn } from "components/table/table.component";
import { TenantIncomeDetailFragment } from "graphql/hasura/types-and-hooks";

export const tenantIncomeDetailColumns: TableColumn<TenantIncomeDetailFragment>[] =
  [
    {
      header: "Return ID No.",
      accessorKey: "RtnRentPaidId",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      header: "Tenant Return No.",
      accessorKey: "RtnNo",
      align: "center",
      format: "string",
    },
    {
      header: "Tenant TIN",
      accessorKey: "TenantTin",
      align: "center",
      format: "string",
    },
    {
      header: "Tenant Taxpayer Name",
      accessorKey: "TenantTaxPayerName",
      align: "left",
      format: "string",
    },
    {
      header: "Landlord TIN",
      accessorKey: "LandlordTin",
      align: "center",
      format: "string",
    },
    {
      header: "Landlord Name",
      accessorKey: "LandlordName",
      align: "left",
      format: "string",
    },
    {
      header: "Premises Location",
      accessorKey: "PremiseLocation",
      align: "center",
      format: "string",
    },
    {
      header: "rMATCH Score",
      accessorKey: "RmatchScore",
      align: "center",
      format: "string",
    },
    {
      header: "Tenancy Period From",
      accessorKey: "TenancyPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      header: "Tenancy Period To",
      accessorKey: "TenancyPeriodTo",
      align: "center",
      format: "date",
    },
    {
      header: "Rent Reported",
      accessorKey: "RentPaid",
      align: "right",
      format: "currency",
    },
    {
      header: "Landlord Tax Year",
      accessorKey: "TaxYear",
      align: "center",
      format: "string",
    },
    {
      header: "Landlord Return Period From",
      accessorKey: "LandlordPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      header: "landlord Return Period To",
      accessorKey: "LandlordPeriodTo",
      align: "center",
      format: "date",
    },
    {
      header: "Tenant Rent Contribution %",
      accessorKey: "Contribution",
      align: "center",
      format: "percent",
    },
    {
      header: "Tenant Rent Contribution Amount",
      accessorKey: "ContributionAmount",
      align: "right",
      format: "currency",
    },
  ];
