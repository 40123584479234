/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid,
  ImageList as GridListBase,
  ImageListItem as ImageListItemBase,
  Button as ButtonBase,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { PhotoLibrary as PhotoLibraryIcon } from "@mui/icons-material";
import ImagePlaceholder from "components/image-placeholder/image-placeholder.component";
import LightBox from "components/lightbox/lightbox.component";
import { Carousel as CarouselBase } from "react-responsive-carousel";

const MainImage = styled(GridListBase)(() => ({
  width: "100%",
}));

const ImageThumbnails = styled(GridListBase)(({ theme }) => ({
  width: "100%",
  height: theme.imageList.height,
  display: "flex",
  justifyContent: "center",
  overflowY: "hidden",
  marginBottom: theme.spacing(2),
}));

const GridListTile = styled(ImageListItemBase)(() => ({
  cursor: "pointer",
}));

const Button = styled(ButtonBase)(() => ({
  top: "50%",
  transform: "translateY(-50%)",
}));

const Carousel = styled(CarouselBase)(() => ({
  maxHeight: "300px",

  ".slide .selected": {
    maxHeight: "300px",
  },
}));

export default {
  Grid,
  MainImage,
  ImageThumbnails,
  GridListTile,
  Button,
  PhotoLibraryIcon,
  LightBox,
  ImagePlaceholder,
  Box,
  Carousel,
};
