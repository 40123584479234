/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import {
  Grid as GridMui,
  GridProps,
  InputLabel,
  Box,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";

interface CustomGridProps extends GridProps {
  MarginTop?: number;
  scrollable?: boolean;
  paddingright?: string;
}

const Grid = styled(GridMui, {
  shouldForwardProp: (prop) =>
    prop !== "MarginTop" && prop !== "scrollable" && prop !== "paddingright",
})<CustomGridProps>(({ MarginTop, scrollable, paddingright, theme }) => ({
  alignSelf: "center",
  textAlign: "center",
  display: "flex",
  position: "relative",
  marginTop: MarginTop || MarginTop === 0 ? `${MarginTop}px` : theme.spacing(2),
  overflow: scrollable ? "auto" : "unset",
  paddingRight: paddingright ? paddingright : "none",
}));

const InputLabelPermissions = styled(InputLabel)(({ theme }) => ({
  textAlign: theme.InputLabel.textAlign,
  marginLeft: theme.InputLabel.marginLeft,
}));

const InputLabelTab = styled(InputLabel)(({ theme }) => ({
  textAlign: theme.InputLabel.textAlign,
  position: theme.InputLabel.position,
}));

const PermissionsHeading = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export default {
  Grid,
  InputLabelPermissions,
  InputLabelTab,
  PermissionsHeading,
  Checkbox,
};
