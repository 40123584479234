/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import { LandlordIncomeSummaryFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import FormattedValue from "components/formatted-value/formatted-value";

export type LandlordIncomeSummaryTableSchema = Omit<
  LandlordIncomeSummaryFragment,
  "__typename"
>;

export const LANDLORD_INCOME_SUMMARY_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof LandlordIncomeSummaryTableSchema,
  "string" | "number" | "Date"
> = {
  LandlordTin: "string",
  LandlordTaxpayerName: "string",
  TaxYear: "string",
  ReturnNo: "string",
  ReturnPeriodFrom: "Date",
  ReturnPeriodTo: "Date",
  RentReportedByTenants: "number",
  GrossRentalIncome: "number",
  RentDeclaredByLandlord: "number",
  RmatchScore: "number",
  Difference: "number",
  DifferencePercentage: "string",
  GrossDifference: "number",
  GrossDifferencePercentage: "string",
  LandlordTinSource: "string",
  ReturnNoSource: "string",
};

export const getAccessorType = (
  accessor:
    | keyof LandlordIncomeSummaryTableSchema
    | AccessorFn<LandlordIncomeSummaryTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return LANDLORD_INCOME_SUMMARY_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const LandlordIncomeSummaryColumns: TableColumn<LandlordIncomeSummaryTableSchema>[] =
  [
    {
      header: "Landlord TIN",
      accessorKey: "LandlordTin",
      align: "center",
      cell: ({
        getValue,
        row: {
          original: { LandlordTin, LandlordTinSource },
        },
      }) => {
        if (LandlordTinSource === "Individual") {
          return (
            <Link to={`/individuals/${LandlordTin}/analysis`} color="inherit">
              <>{getValue()}</>
            </Link>
          );
        }
        return (
          <Link to={`/non-individuals/${LandlordTin}/analysis`} color="inherit">
            <>{getValue()}</>
          </Link>
        );
      },
    },
    {
      header: "Landlord Taxpayer Name",
      accessorKey: "LandlordTaxpayerName",
      align: "left",
      format: "string",
    },
    {
      header: "Tax Year",
      accessorKey: "TaxYear",
      align: "center",
      format: "string",
    },
    {
      header: "Return No.",
      accessorKey: "ReturnNo",
      align: "center",
      format: "string",
    },
    {
      header: "Return Period From",
      accessorKey: "ReturnPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      header: "Return Period To",
      accessorKey: "ReturnPeriodTo",
      align: "center",
      format: "date",
    },
    {
      header: "Rent Income Reported by Tenant",
      accessorKey: "RentReportedByTenants",
      align: "right",
      cell: ({
        getValue,
        row: {
          original: { LandlordTin, TaxYear },
        },
        cell,
      }) => {
        const value = getValue() as number;
        return (
          <Link to={`tenant/${LandlordTin}/${TaxYear}/detail`} color="inherit">
            {cell.row.id === "0" ? (
              <FormattedValue format="currency" value={value || ""} />
            ) : (
              <FormattedValue value={value || ""} format="number" />
            )}
          </Link>
        );
      },
    },
    {
      header: "Rent Income Declared by Landlord",
      accessorKey: "RentDeclaredByLandlord",
      align: "right",
      cell: ({
        getValue,
        row: {
          original: { ReturnNo },
        },
        cell,
      }) => {
        const value = getValue() as number;
        return (
          <Link to={`landlord/${ReturnNo}/detail`} color="inherit">
            {cell.row.id === "0" ? (
              <FormattedValue format="currency" value={value} />
            ) : (
              <FormattedValue value={value} format="number" />
            )}
          </Link>
        );
      },
    },
    {
      header: "rMATCH Score",
      accessorKey: "RmatchScore",
      align: "center",
      format: "string",
    },
    {
      header: "Difference - Tenant Report vs Landlord Declared",
      accessorKey: "Difference",
      align: "right",
      format: "currency",
    },
    {
      header: "Diff %",
      accessorKey: "DifferencePercentage",
      align: "center",
      format: "percent",
    },

    {
      header: "Landlord TIN Source",
      accessorKey: "LandlordTinSource",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      header: "Return No. Source",
      accessorKey: "ReturnNoSource",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
  ];
