/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";

// Styles
import SC from "./select-field.styles";
import { SelectChangeEvent } from "@mui/material";

export interface MenuProps {
  key: string;
  label: string;
  value?: string;
}

export interface SelectFieldProps {
  label?: string;
  options: MenuProps[];
  defaultValue?: string;
  onSelect: (selected: string) => void;
  noborder?: boolean;
}

export const SelectField = ({
  label,
  options,
  onSelect,
  defaultValue,
}: SelectFieldProps) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selected = event.target.value as string;
    onSelect(selected);
  };

  return (
    <SC.FormControl>
      <SC.Select
        variant="outlined"
        displayEmpty
        onChange={handleChange}
        value={defaultValue}
      >
        {options.map(({ key, value, label: optLabel }: MenuProps) => (
          <SC.MenuItem key={key} value={value}>
            <em>{optLabel} </em>
          </SC.MenuItem>
        ))}
      </SC.Select>
      <SC.HelperText>{label}</SC.HelperText>
    </SC.FormControl>
  );
};

export default SelectField;
