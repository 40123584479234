/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";

export type ReportTableSchema = {
  CustomerId: number;
  AggregateFirstName: string;
  AggregateMiddleName: string;
  AggregateSurname: string;
  MatchScore: number;
  MatchState: string;
  NumOwnedOccupied: number;
  MatchAggregateUra: number;
  UraFirstname: string;
  UraSurname: string;
  EmailId: string;
  MobileNumber: string;
  IsIncomeSrcRental: string;
  UraTin: string;
  NationalId: string;
  SumAdjustedIncome: number;
  Rnid: string;
};

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  CustomerId: "number",
  AggregateFirstName: "string",
  AggregateMiddleName: "string",
  AggregateSurname: "string",
  MatchScore: "string",
  MatchState: "string",
  NumOwnedOccupied: "string",
  MatchAggregateUra: "string",
  UraFirstname: "string",
  UraSurname: "string",
  EmailId: "string",
  MobileNumber: "string",
  IsIncomeSrcRental: "string",
  UraTin: "string",
  NationalId: "string",
  SumAdjustedIncome: "number",
  Rnid: "string",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | AccessorFn<ReportTableSchema> | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const reportTableColumns: TableColumn<ReportTableSchema>[] = [
  {
    header: "RNID",
    accessorKey: "Rnid",
    align: "center",
  },
  {
    header: "Customer No",
    accessorKey: "CustomerId",
    align: "center",
  },
  {
    header: "Aggregate First Name",
    accessorKey: "AggregateFirstName",
    align: "left",
  },
  {
    header: "Aggregate Middle Name",
    accessorKey: "AggregateMiddleName",
    align: "center",
  },
  {
    header: "Aggregate Surname",
    accessorKey: "AggregateSurname",
    align: "left",
  },
  { header: "rMATCH Score", accessorKey: "MatchScore", align: "center" },
  {
    header: "Number of Owner Occupied Properties",
    accessorKey: "NumOwnedOccupied",
    align: "center",
  },
  {
    header: "Total Adjusted Gross Rental Income",
    accessorKey: "SumAdjustedIncome",
    align: "center",
    format: "currency",
  },
  {
    header: "Match Aggregate Owner to URA Taxpayer",
    accessorKey: "MatchAggregateUra",
    align: "left",
  },
  {
    header: "URA First Name",
    accessorKey: "UraFirstname",
    align: "left",
  },
  {
    header: "URA Surname",
    accessorKey: "UraSurname",
    align: "left",
  },
  {
    header: "TIN No.",
    accessorKey: "UraTin",
    align: "left",
  },
  {
    header: "Rental Income?",
    accessorKey: "IsIncomeSrcRental",
    align: "left",
  },
  {
    header: "URA Mobile No.",
    accessorKey: "MobileNumber",
    align: "left",
  },
  {
    header: "URA Email",
    accessorKey: "EmailId",
    align: "left",
  },
  {
    header: "URA NIN",
    accessorKey: "NationalId",
    align: "left",
  },
];
