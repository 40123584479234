/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import SC from "./tax-exempt-organisation.styles";

export const TaxExemptOrganisation = () => {
  return <SC.Box mt={3}></SC.Box>;
};

export default TaxExemptOrganisation;
