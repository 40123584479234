/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { Tab as TabBase, Tabs as TabsBase, TabProps, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Tabs = styled(TabsBase)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,

  "& .MuiTabs-flexContainer": {
    justifyContent: "flex-end",
  },
  "& .MuiTabs-indicator": {
    height: "5px",
    backgroundColor: theme.tab.color,
    width: "inherite",
  },
}));

interface TabPropsCustom extends TabProps {
  selected?: boolean;
}

const Tab = styled(TabBase, {
  shouldForwardProp: (prop) => prop !== "selected",
})<TabPropsCustom>(({ theme, selected }) => ({
  textTransform: "none",
  minWidth: "auto",
  width: "auto",
  fontWeight: selected ? "bold" : 500,
  color: theme.tab.color,
  fontSize: theme.tab.fontSize,
  borderColor: theme.tab.color,
  "& .MuiTab-wrapper": {
    color: theme.label.color,
  },
}));

export default {
  Tabs,
  Tab,
  Box,
};
