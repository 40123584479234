/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
// Dependencies
import React from "react";
import {
  ListItem as ListItemMui,
  ListItemProps,
  ListItemIcon,
  ListItemText,
  ButtonBaseProps,
  Checkbox,
  Tooltip,
} from "@mui/material";

import { styled } from "@mui/material/styles";

type CustomListItemProps = ListItemProps &
  ButtonBaseProps & {
    isSelected?: boolean;
    isTreeItem?: boolean;
  };

const StyledListItem = styled(ListItemMui)<CustomListItemProps>(
  ({ theme, isSelected, isTreeItem }) => ({
    color: isSelected ? theme.selectableList.textColor : "inherit",
    paddingTop: isTreeItem
      ? theme.selectableList.paddingTopTreeItem
      : "inherit",
    paddingBottom: isTreeItem
      ? theme.selectableList.paddingBottomTreeItem
      : "inherit",
    backgroundColor: isSelected ? theme.selectableList.isSelected : "inherit",
    "&:hover": {
      backgroundColor: isSelected
        ? theme.selectableList.hoverColorSelected
        : theme.selectableList.hoverColor,
    },
    "&:after": {
      color: isSelected ? theme.selectableList.isSelected : "none",
      borderLeft: isSelected ? theme.selectableList.afterBorderLeft : "none",
      borderTop: isSelected ? theme.selectableList.afterBorderTop : "none",
      borderBottom: isSelected
        ? theme.selectableList.afterBorderBottom
        : "none",
      content: '""',
      position: "absolute",
      right: "-2rem",
    },
  }),
);

const ListItem = (props: CustomListItemProps): JSX.Element => {
  return <StyledListItem {...props} />;
};

export default {
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Tooltip,
};
