/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  Accordion,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails,
  Typography,
  IconButton,
  List,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const AccordionSummary = styled(AccordionSummaryBase)({
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
  },
});

const Label = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export default {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Label,
  IconButton,
  List,
};
