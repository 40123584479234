/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid as GridBase,
  Box as BoxBase,
  Typography as TypographyBase,
  Container as ContainerBase,
  Divider as DividerBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import RoomIcon from "@mui/icons-material/Room";

import MapTableContainerBase from "components/map-table-container/map-table-container.component";
import SwitchViewsBase from "components/switch-views/switch-views.component";
import LabelKeyValueBase from "components/label-key-value/label-key-value.component";
import MapContainerBase from "components/map-container/map-container.component";

const Grid = GridBase;
const Box = styled(BoxBase)({
  textAlign: "center",
});

const Typography = TypographyBase;

const SwitchViews = SwitchViewsBase;

const LabelKeyValue = styled(LabelKeyValueBase)({
  alignItems: "center",
});

const Container = styled(ContainerBase)({
  padding: "0px",
  margin: "0px",
});

const Divider = styled(DividerBase)({
  color: "red",
});

const MapContainer = MapContainerBase;

const MapTableContainer = MapTableContainerBase;

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.table.toolbar.fontWeight,
  fontSize: theme.table.toolbar.fontSize,
  textAlign: "left",
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(3),
  opacity: 0.8,
}));

export default {
  Grid,
  Box,
  Typography,
  Title,
  SwitchViews,
  LabelKeyValue,
  Container,
  Divider,
  MapContainer,
  MapTableContainer,
  RoomIcon,
};
