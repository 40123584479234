/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Grid as GridMui,
  InputLabel as InputLabelMui,
  Box as BoxMui,
  GridProps,
  BoxProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

interface CustomGridProps extends GridProps {
  MarginTop?: number;
}

const Grid = styled(GridMui, {
  shouldForwardProp: (prop) => prop !== "MarginTop",
})<CustomGridProps>(({ MarginTop }) => ({
  marginTop: MarginTop ? `${MarginTop}px` : "0px",
  alignSelf: "center",
  textAlign: "center",
}));

interface CustomBoxProps extends BoxProps {
  disabledMarginTop?: boolean;
}

const Box = styled(BoxMui, {
  shouldForwardProp: (prop) => prop !== "disabledMarginTop",
})<CustomBoxProps>(({ disabledMarginTop, theme }) => ({
  marginTop: disabledMarginTop ? "0px" : theme.spacing(4),
}));

const InputLabel = styled(InputLabelMui)({
  textAlign: "left",
  transform: "translate(12px, 10px) scale(0.75)",
});

export default {
  Grid,
  Box,
  InputLabel,
};
